import { useLocation } from 'react-router-dom';
import { regexForSidebarButtonType, SidebarButtonType } from '../core';

export function useSelectedButtonTypeLocation() {
  const { pathname } = useLocation();

  const foundType = Object.values(SidebarButtonType).find((type) => {
    const regexForType = regexForSidebarButtonType[type];
    if (regexForType && pathname.match(regexForType)) {
      return type;
    }
  });

  return foundType ?? SidebarButtonType.HOMEPAGE;
}
