import { COMMENTS_DATE_FORMAT } from 'core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface Props {
  readonly className?: string;
  readonly showQueryLimit: boolean;
  readonly queryLimit: number;
  readonly queryCount: number;
  readonly isFree: boolean;
  readonly expiresAt?: Date;
}

export const RemainingQuestionLimit: React.FC<Props> = (p) => {
  const { showQueryLimit, queryCount, queryLimit, isFree, expiresAt } = p;
  const freeTierSpan =
    isFree && expiresAt ? (
      <span>
        Free Polco Trial until {moment(expiresAt).format(COMMENTS_DATE_FORMAT)}
      </span>
    ) : null;
  const remainingQueryCount = _.isNaN(queryCount)
    ? 0
    : queryLimit - (queryCount ?? 0);
  const questionCountSpan =
    remainingQueryCount <= 50 ? (
      <span>
        You have <b>{remainingQueryCount > 0 ? remainingQueryCount : 0}</b> question
        {remainingQueryCount === 1 ? '' : 's'} left to ask.
      </span>
    ) : null;
  return (
    <>
      {showQueryLimit && (freeTierSpan || questionCountSpan) && (
        <div
          className={`${p.className ?? 0} pn-ai-assistant-query-count-banner font-size-sm d-flex flex-row justify-content-between align-items-center bg-brand-l px-4 py-2`}
        >
          <span>
            {freeTierSpan}
            {freeTierSpan && questionCountSpan ? <span>: </span> : null}
            {questionCountSpan}
          </span>
        </div>
      )}
    </>
  );
};
