import { ClientQuestionSetId } from 'client/shared/core/question-set';
import { BenchmarkSurveyType, ScaleType } from 'core';

export interface BenchmarkRequest {
  readonly id: string | null;
  readonly status: BenchmarkRequestStatus;
  readonly publishingEntity: {
    readonly id: string;
    readonly name: string;
    readonly presidingAreaName: string | null;
  };
  readonly reportDueDate: Date | null;
  readonly clientDueDate: Date | null;
  readonly surveyFormat: BenchmarkRequestSurveyFormat | null;
  readonly surveyType: BenchmarkSurveyType;
  readonly questionSetData: readonly BenchmarkRequestQuestionSetData[];
  readonly closeDate: Date | null;
  readonly fips: string | null;
  readonly areas: readonly BenchmarkRequestArea[];
  readonly hasTrends: boolean;
  readonly trendYears: string | null;
  readonly customBenchmarks: string | null;
  readonly additionalNotes: string | null;
  readonly mailedCount: number | null;
  readonly emailedCount: number | null;
  readonly eligibleSamplingResponseCount: number | null;
  readonly responseRate: number | null;
}

export type BenchmarkRequestTableItem = Pick<
  BenchmarkRequest,
  'status' | 'reportDueDate' | 'surveyType'
> & {
  readonly id: string;
  readonly publishingEntity: {
    readonly id: string;
    readonly name: string;
  };
};

export type Building_BenchmarkRequest = Omit<
  BenchmarkRequest,
  'publishingEntity' | 'surveyType'
> & {
  readonly publishingEntity?: {
    readonly id: string;
    readonly name: string;
    readonly presidingAreaName: string | null;
  };
  readonly surveyType?: BenchmarkSurveyType;
};

export enum BenchmarkRequestStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface BenchmarkRequestQuestionSetData {
  readonly id: string | null;
  readonly questionSetId: ClientQuestionSetId | null;
  readonly language: string | null;
  readonly optIn: boolean;
  readonly weighted: boolean;
}

export interface BenchmarkRequestArea {
  readonly id: string | null;
  readonly name: string | null;
  readonly url: string | null;
  readonly group: BenchmarkRequestAreaGroup | null;
  readonly weight: number | null;
}

export enum BenchmarkRequestSurveyFormat {
  ONLINE_ONLY = 'ONLINE_ONLY',
  MAIL_ONLY = 'MAIL_ONLY',
  BOTH_ONLINE_AND_MAIL = 'BOTH_ONLINE_AND_MAIL',
}

export enum BenchmarkRequestAreaGroup {
  A = 'A',
  B = 'B',
}

export const ReadableBenchmarkRequestSurveyFormat: Record<
  BenchmarkRequestSurveyFormat,
  string
> = {
  [BenchmarkRequestSurveyFormat.ONLINE_ONLY]: 'Online Only',
  [BenchmarkRequestSurveyFormat.MAIL_ONLY]: 'Mail Only',
  [BenchmarkRequestSurveyFormat.BOTH_ONLINE_AND_MAIL]: 'Online and Mail',
};

export interface DataDictionaryRow {
  readonly title: string;
  readonly reportTitle: string | null;
  readonly variableName: string | null;
  readonly multipleSelect: string;
  readonly benchmarkArea: string | null;
  readonly weightingLabel: string | null;
  readonly scaleThreshold: number | null;
  readonly scaleType: ScaleType | null;
  readonly choices: readonly {
    readonly label: string;
    readonly value: number | null;
  }[];
  readonly gridLabel: string | null;
}
