import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { MutationInfos } from 'client/shared/graphql-mutations';

import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { PerformanceDataTx } from 'client/shared/graphql-util/transforms';
import { ActivationState, FeatureSettingType } from 'core';
import _ from 'lodash';

export function useSavedDataPoints(args: {
  readonly publishingEntityId: ClientPublishingEntityId;
  readonly loadVisualizationData: boolean;
}) {
  const { publishingEntityId, loadVisualizationData } = args;
  const { fn: saveVisualizationMut } = useMutationInfo(
    MutationInfos.adminSaveNewVisualization
  );
  const saveVisualization = async (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => {
    const transformed = PerformanceDataTx.multipleFipsSavedVisualizationInput_toGql(
      publishingEntityId,
      clientInput
    );
    await saveVisualizationMut({
      variables: {
        input: transformed,
      },
    });
  };

  const { data, error, loading } = useQueryInfo(
    QueryInfos.adminPublishingEntityVisualizations,
    {
      variables: {
        publishingEntityId: publishingEntityId,
        notShowingVisualizations: !loadVisualizationData,
      },
    }
  );
  const visualizationData =
    data?.openPublishingEntityById?.trackVariableVisualizations.map(
      PerformanceDataTx.gqlVisualization_toClient
    ) ?? [];
  const allVisualizations = _.orderBy(visualizationData, 'recordedAt', 'desc');
  const activeVisualizations = allVisualizations.filter(
    (v) => v.state === ActivationState.ACTIVE
  );
  const featureSetting = data?.openPublishingEntityById?.featureSettings?.find(
    (fs) => fs.featureSettingName === FeatureSettingType.SAVED_DATA_POINTS
  );

  const plan = data?.openPublishingEntityById?.strategicPlans[0];

  const limit = featureSetting
    ? featureSetting.isUnlimited
      ? Infinity
      : featureSetting.quantity
    : null;

  return {
    allVisualizations,
    activeVisualizations,
    publisherMax: limit,
    saveVisualization,
    error,
    loading,
    plan,
  };
}
