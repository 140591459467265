import * as React from 'react';
import './styles.scss';

import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';

const baseClass = 'pn-view-in-new-tab';
interface Props {
  readonly copy?: string;
  readonly hasHover?: boolean;
}

export const ViewInNewTab: React.FC<Props> = (p) => {
  const renderIcon = () => (
    <MaterialIcon
      className={`${baseClass}-icon`}
      icon={MaterialIconName.OPEN_IN_NEW}
    />
  );
  return (
    <span
      className={`${
        p.hasHover ? `${baseClass}-hover` : ''
      } d-inline-flex align-items-center`}
    >
      {p.copy}
      {p.hasHover ? <span>{renderIcon()}</span> : renderIcon()}
    </span>
  );
};

ViewInNewTab.defaultProps = {
  copy: 'View',
};
