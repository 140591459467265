export interface AiAssistantConversation {
  readonly modelVersion?: string;
  readonly userId?: string;
  readonly publishingEntityId?: string;
  readonly messages: readonly AiAssistantMessage[];
}

export interface AiAssistantMessage {
  readonly type: AiAssistantMessageType.SINGLE;
  readonly role: AiAssistantMessageRole;
  readonly content: string;
  readonly timestamp: Date;
  readonly bypassRole?: boolean;
}

export interface AiAssistantMessageGroup {
  readonly type: AiAssistantMessageType.GROUP;
  readonly role: AiAssistantMessageRole;
  readonly content: readonly string[];
  readonly timestamp: Date;
}

export interface AiAssistantQueryStatus {
  readonly type: AiAssistantQueryStatusType;
  readonly loadingText?: string;
  readonly message?: string;
}

export interface AiAssistantPublisherInteractions {
  readonly publishingEntityId: string;
  readonly queryCount: number;
  readonly sessionCount: number;
}

export enum AiAssistantQueryStatusType {
  RESPONDED = 'responded',
  PROCESSING = 'processing',
}

export enum AiAssistantSessionStatusType {
  ACTIVE = 'active',
}

export enum AiAssistantMessageRole {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}

export enum AiAssistantMessageType {
  SINGLE = 'single',
  GROUP = 'group',
}
export const INTRO_MESSAGES_LENGTH = 2;
