import * as React from 'react';
import './styles.scss';
import { Popover as TinyPopover, PopoverProps } from 'react-tiny-popover';
import { KeyPressKey } from 'client/shared/core/types';

interface CustomProps {
  readonly onEscape?: () => void;
}

export const Popover: React.FC<PopoverProps & CustomProps> = (props) => {
  const useProps: PopoverProps = {
    ...props,
    containerClassName: `pn-popover-content ${props.containerClassName || ''}`,
  };

  if (process.env.NODE_ENV === 'test') {
    return (
      <div>
        {props.children}
        {props.isOpen ? props.content : null}
      </div>
    );
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KeyPressKey.ESCAPE) {
      props.onEscape?.();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyDown={onKeyDown} tabIndex={-1}>
      <TinyPopover {...useProps}>{props.children}</TinyPopover>
    </div>
  );
};
