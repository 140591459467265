import _ from 'lodash';
import * as React from 'react';
import { useAiAssistantSession } from 'client/admin/hooks/use-ai-assistant-session';
import { FeatureSettingType, ONE_HOUR_MS } from 'core';
import { useQueryInfo, writeQueryFor } from 'client/shared/containers/query';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { AiAssistantSessionTx } from 'client/admin/graphql-util/transforms';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import {
  AiAssistantMessage,
  AiAssistantMessageRole,
  AiAssistantMessageType,
  AiAssistantQueryStatus,
  AiAssistantQueryStatusType,
  AiAssistantSessionStatusType,
  INTRO_MESSAGES_LENGTH,
} from 'client/admin/core/ai-assistant-session';
import { getConfig } from 'client/shared/core/config';
import { AiAssistantId } from '@polco-us/types';
import { isFeatureSettingEnabled } from 'client/admin/core/feature-settings';
import { aiAssistantMessagesClientToGql } from 'client/admin/graphql-util/transforms/ai-assistant-session';

export interface UseAiAssistantData {
  readonly isLoading: boolean;
  readonly isSuperAdmin?: boolean;
  readonly states?: {
    readonly session: {
      readonly id: string;
      readonly isSessionExpired: boolean;
      readonly refreshSession: () => Promise<string>;
      readonly conversation: {
        readonly messages: readonly AiAssistantMessage[];
        readonly setMessages: (value: readonly AiAssistantMessage[]) => void;
      };
      readonly queryStatus: {
        readonly value?: null | AiAssistantQueryStatus;
        readonly setValue: (value: null | AiAssistantQueryStatus) => void;
        readonly refetch: (sessionId: string) => Promise<void>;
      };
    };
    readonly onCancelRequest: () => void;
    readonly publisher: {
      readonly queryCount?: number;
      readonly queryLimit: number;
      readonly isUnlimited: boolean;
      readonly showQueryLimit: boolean;
    };
    readonly events: {
      readonly isOnSendInProgress: {
        readonly value: boolean;
        readonly setValue: (value: boolean) => void;
      };
      readonly isOnRefreshInProgress: {
        readonly value: boolean;
        readonly setValue: (value: boolean) => void;
      };
    };
  };
}

const QUERY_POLLING_INTERVAL_MS = 500;
const SESSION_POLLING_INTERVAL_MS = ONE_HOUR_MS / 6;

export interface UseAiAssistantDataProps {
  readonly assistantId: AiAssistantId;
}

const config = getConfig();
const POLLY_LIMIT = config.envFeatures.pollyFreeQuestionLimit;
const GRANT_WRITER_LIMIT = config.envFeatures.grantWriterFreeQuestionLimit;

export function useAiAssistantData(props: UseAiAssistantDataProps) {
  const { assistantId } = props;
  const [isSessionExpired, setIsSessionExpired] = React.useState<boolean>(false);
  const [currentQueryStatus, setCurrentQueryStatus] = React.useState<
    null | AiAssistantQueryStatus | undefined
  >(undefined);
  const {
    sessionId,
    refreshSessionId,
    isLoading: isAiAssistantSessionIdLoading,
    activePublishingEntity,
    isSuperAdmin,
  } = useAiAssistantSession({
    assistantId: props.assistantId,
    onSessionRefresh: () => {
      setIsSessionExpired(false);
    },
  });

  const ignoreFeatureSettings = useFlagEnabled(KnownFlag.IGNORE_FEATURE_SETTINGS);

  const { featureSettingType, featureSettingDefault } = React.useMemo(() => {
    switch (assistantId) {
      case AiAssistantId.POLLY:
        return {
          featureSettingType: FeatureSettingType.AI_ASSISTANT,
          featureSettingDefault: POLLY_LIMIT,
        };
      case AiAssistantId.GRANT_WRITER:
        return {
          featureSettingType: FeatureSettingType.GRANT_WRITER_ASSISTANT,
          featureSettingDefault: GRANT_WRITER_LIMIT,
        };
    }
  }, [assistantId]);

  const aiAssistantEnabled = React.useMemo(() => {
    if (!activePublishingEntity?.featureSettings) {
      return false;
    }
    const featureSettings = activePublishingEntity.featureSettings.map(
      ({ enabled, featureSettingName }) => {
        return { featureSettingName, enabledForPublisher: enabled };
      }
    );

    return (
      ignoreFeatureSettings ||
      featureSettingDefault > 0 ||
      isFeatureSettingEnabled(featureSettings, featureSettingType)
    );
  }, [assistantId, activePublishingEntity?.featureSettings]);
  const { publisherQueryLimit, isUnlimited } = React.useMemo(() => {
    if (!activePublishingEntity?.featureSettings) {
      return {
        publisherQueryLimit: featureSettingDefault,
        isUnlimited: false,
      };
    }
    const publisherFeatureSetting = activePublishingEntity?.featureSettings.find(
      (s) => s.featureSettingName === featureSettingType
    );
    return {
      publisherQueryLimit:
        publisherFeatureSetting?.quantity || featureSettingDefault,
      isUnlimited: publisherFeatureSetting?.isUnlimited || false,
    };
  }, [activePublishingEntity?.featureSettings, assistantId]);
  const {
    loading: publisherInteractionsIsLoading,
    data: publisherInteractionsGql,
    refetch: refetchPublisherInteractions,
  } = useQueryInfo(QueryInfos.adminPublishingEntityAiAssistantInteractions, {
    skip: !activePublishingEntity?.id || !aiAssistantEnabled,
    variables: {
      publishingEntityId: activePublishingEntity?.id ?? '',
      assistantId,
    },
  });
  const skipQueries =
    !sessionId ||
    isAiAssistantSessionIdLoading ||
    !aiAssistantEnabled ||
    (publisherInteractionsIsLoading && !publisherInteractionsGql);

  const {
    loading: conversationIsLoading,
    data: conversationData,
    client: apolloClient,
  } = useQueryInfo(QueryInfos.currentAiAssistantSessionConversation, {
    variables: {
      sessionId: sessionId ?? '',
      assistantId,
    },
    skip: skipQueries,
  });
  const sessionConversation = conversationData
    ? AiAssistantSessionTx.aiAssistantSessionConversationGqlToClient(
        conversationData.currentAiAssistantSession
      )
    : null;
  const messages = sessionConversation?.conversation?.messages ?? [];
  const setMessages = React.useCallback(
    (updatedMessages: readonly AiAssistantMessage[]) => {
      if (!sessionId || !conversationData?.currentAiAssistantSession.conversation) {
        return;
      }
      const gqlMessages = aiAssistantMessagesClientToGql(updatedMessages);
      writeQueryFor(apolloClient, QueryInfos.currentAiAssistantSessionConversation)(
        { sessionId, assistantId },
        {
          currentAiAssistantSession: {
            ...conversationData.currentAiAssistantSession,
            conversation: {
              ...conversationData.currentAiAssistantSession.conversation,
              messages: gqlMessages,
            },
          },
        }
      );
    },
    [conversationData, apolloClient, sessionId, assistantId]
  );
  const {
    loading: sessionStatusIsLoading,
    startPolling: sessionStatusStartPolling,
    stopPolling: sessionStatusStopPolling,
  } = useQueryInfo(QueryInfos.currentAiAssistantSessionStatus, {
    variables: {
      sessionId: sessionId ?? '',
      assistantId: assistantId,
    },
    skip: skipQueries,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true, // Required to change loading when variables change
    onCompleted: (d) => {
      if (isSessionExpired) {
        sessionStatusStopPolling();
        return;
      }
      const newSessionStatus = d?.currentAiAssistantSession?.status
        ? AiAssistantSessionTx.aiAssistantSessionStatusTypeGqlToClient(
            d.currentAiAssistantSession.status
          )
        : null;
      if (
        newSessionStatus !== AiAssistantSessionStatusType.ACTIVE &&
        messages.length > INTRO_MESSAGES_LENGTH
      ) {
        sessionStatusStopPolling();
        setIsSessionExpired(true);
      }
      if (newSessionStatus === AiAssistantSessionStatusType.ACTIVE) {
        sessionStatusStartPolling(SESSION_POLLING_INTERVAL_MS);
      }
    },
  });

  const {
    loading: queryStatusIsLoading,
    startPolling: queryStatusStartPolling,
    stopPolling: queryStatusStopPolling,
    refetch: refetchQueryStatus,
  } = useQueryInfo(QueryInfos.currentAiAssistantQueryStatus, {
    variables: {
      sessionId: sessionId ?? '',
      assistantId: assistantId,
    },
    fetchPolicy: 'no-cache', // Ensure querying resumes when navigating away from AI assistant
    skip: skipQueries,
    notifyOnNetworkStatusChange: true, // Required to change loading when variables change
    onCompleted: (data) => {
      const queryStatusTx = data
        ? AiAssistantSessionTx.aiAssistantQueryStatusGqlToClient(
            data.currentAiAssistantSession
          )
        : null;
      const status = queryStatusTx?.status;

      if (status !== currentQueryStatus) {
        if (status && shouldIgnoreIfIsProcessing(status)) {
          setCurrentQueryStatus({
            type: status.type,
            loadingText: status.loadingText,
            message: status.message,
          });
        } else {
          setCurrentQueryStatus(status ?? null);
        }
      }
    },
  });

  const [isOnSendInProgress, setIsOnSendInProgress] = React.useState<boolean>(false);
  const [isOnRefreshInProgress, setIsOnRefreshInProgress] =
    React.useState<boolean>(false);

  const isLoading =
    isAiAssistantSessionIdLoading ||
    (_.isUndefined(currentQueryStatus) && queryStatusIsLoading) ||
    (messages.length < 1 && conversationIsLoading) ||
    (isSessionExpired && sessionStatusIsLoading) ||
    publisherInteractionsIsLoading;

  const showQueryLimit = !isUnlimited;

  const refetchPublisherInteractionsQuery = React.useCallback(
    async (publisherId: string) => {
      await refetchPublisherInteractions({
        publishingEntityId: publisherId,
      });
    },
    [refetchPublisherInteractions]
  );

  React.useEffect(() => {
    if (!aiAssistantEnabled || !sessionId) {
      queryStatusStopPolling();
      sessionStatusStopPolling();
    }
  }, [aiAssistantEnabled, sessionId]);

  React.useEffect(() => {
    if (currentQueryStatus?.type === AiAssistantQueryStatusType.PROCESSING) {
      queryStatusStartPolling(QUERY_POLLING_INTERVAL_MS);
    } else {
      queryStatusStopPolling();
    }

    if (!currentQueryStatus?.message) return;

    const newMessage: AiAssistantMessage = {
      type: AiAssistantMessageType.SINGLE,
      role: AiAssistantMessageRole.ASSISTANT,
      content: currentQueryStatus.message ?? '',
      timestamp: new Date(),
    };

    if (messages[messages.length - 1].role === AiAssistantMessageRole.USER) {
      // This is the first response to this query - add it as a new message
      // Ideally we'd have message IDs to compare, but alas...
      setMessages([...messages, newMessage]);
    } else {
      // This is an update to the existing response - replace the last message
      setMessages([...messages.slice(0, -1), newMessage]);
    }

    if (currentQueryStatus.type === AiAssistantQueryStatusType.RESPONDED) {
      sessionStatusStartPolling(SESSION_POLLING_INTERVAL_MS);
    }
  }, [currentQueryStatus?.type, currentQueryStatus?.message]);

  // actual value used in the context

  const ctxValue: UseAiAssistantData = React.useMemo(
    () => ({
      isLoading,
      isSuperAdmin,
      states:
        !isLoading && aiAssistantEnabled && sessionId && activePublishingEntity
          ? {
              session: {
                id: sessionId,
                isSessionExpired: isSessionExpired,
                refreshSession: async () => {
                  const newSessionId = refreshSessionId();
                  await refetchPublisherInteractionsQuery(
                    activePublishingEntity.id
                  ).catch(console.error);
                  return newSessionId;
                },
                conversation: {
                  messages,
                  setMessages: (value: readonly AiAssistantMessage[]) =>
                    setMessages([...value]),
                },
                queryStatus: {
                  value: currentQueryStatus,
                  setValue: setCurrentQueryStatus,
                  refetch: async (id: string) => {
                    await refetchQueryStatus({
                      sessionId: id,
                    });
                  },
                },
              },
              onCancelRequest: () => {
                queryStatusStopPolling();
              },
              publisher: {
                queryCount:
                  publisherInteractionsGql?.openPublishingEntityById
                    ?.aiAssistantInteractions?.queryCount,
                queryLimit: publisherQueryLimit,
                showQueryLimit: showQueryLimit,
                isUnlimited,
              },
              events: {
                isOnSendInProgress: {
                  value: isOnSendInProgress,
                  setValue: setIsOnSendInProgress,
                },
                isOnRefreshInProgress: {
                  value: isOnRefreshInProgress,
                  setValue: setIsOnRefreshInProgress,
                },
              },
            }
          : undefined,
    }),
    [
      isLoading,
      isSuperAdmin,
      activePublishingEntity,
      aiAssistantEnabled,
      sessionId,
      isSessionExpired,
      messages,
      currentQueryStatus,
      publisherQueryLimit,
      isOnSendInProgress,
      isOnRefreshInProgress,
      refreshSessionId,
      refetchPublisherInteractionsQuery,
      showQueryLimit,
      refetchQueryStatus,
    ]
  );

  return ctxValue;
}

function shouldIgnoreIfIsProcessing(status: AiAssistantQueryStatus) {
  return status.type === AiAssistantQueryStatusType.PROCESSING;
}
