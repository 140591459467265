import * as React from 'react';
import './styles.scss';
import { SidebarButtonType } from 'client/admin/core';
import { AdminSidebarComponent } from './admin-sidebar-section';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import {
  SidebarButton,
  SidebarItem,
  SidebarSection,
  isSidebarButton,
  isSidebarSection,
} from './types';
import {
  AppLink,
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import _ from 'lodash';
import { getConfig } from 'client/shared/core/config';
import { MenuItem, trackFilterQueryParameters } from 'core';
import { useLocation } from 'react-router';
import logoLightText from 'client/assets/polco-logo-light-text-sidebar.svg';

export const COPY = {
  upgradeTier: {
    description: 'Elevate your decision-making with advanced features',
    go: 'Upgrade Now!',
  },
};

const config = getConfig();

const isUpgradeAccountTierCta = (item: MenuItem) =>
  item.id === 'ACCOUNT_UPGRADE_TIER_CTA';

function makeNewMenuOptions(args: {
  readonly menuItems?: ReadonlyArray<MenuItem>;
}): ReadonlyArray<SidebarItem> {
  const { menuItems } = args;
  const featureSpotlightItem = menuItems?.find(
    (item) => item.id === SidebarButtonType.FEATURE_SPOTLIGHT
  );
  const remainingItems = menuItems?.filter(
    (item) => item.id !== SidebarButtonType.FEATURE_SPOTLIGHT
  );

  const featureSpotlightMenuItem: SidebarButton | null = featureSpotlightItem
    ? {
        id: featureSpotlightItem.id,
        url: featureSpotlightItem.url,
        text: featureSpotlightItem.text,
        icon: featureSpotlightItem.icon,
      }
    : null;

  return _.compact([
    featureSpotlightMenuItem,
    ...(remainingItems || []).map<SidebarSection | null>((menuItem) => {
      const isTrackHeader = menuItem.id === 'TRACK';
      return menuItem.isShown && !isUpgradeAccountTierCta(menuItem)
        ? {
            id: menuItem.id,
            name: menuItem.text,
            icon: menuItem.icon,
            children: _.compact(
              menuItem.childrenMenuItems.map<SidebarButton | null>((child) =>
                child.isShown && child.text
                  ? {
                      id: child.id,
                      text: child.text,
                      icon: child.icon,
                      materialIcon: child.materialIcon as MaterialIconName,
                      url:
                        child.id.startsWith('BALANCING_ACT') &&
                        child.text !== 'Learn More'
                          ? `${config.envFeatures.balancingActAdminBaseUrl}${child.url}`
                          : child.url,
                      isNew: child.isNew || undefined,
                      retainQueryParameters: isTrackHeader
                        ? trackFilterQueryParameters
                        : undefined,
                    }
                  : null
              )
            ),
          }
        : null;
    }),
  ]);
}

export interface Props {
  readonly className?: string;
  readonly showSidebar: boolean;
  readonly handleSidebarToggle: (direction: boolean) => void;
  readonly menuItems: ReadonlyArray<MenuItem>;
}

const baseClass = 'pn-admin-sidebar';

export const AdminSidebar: React.FC<Props> = ({
  menuItems,
  handleSidebarToggle,
  showSidebar,
  className,
}) => {
  const { pathname } = useLocation();

  const menuItemsData = makeNewMenuOptions({
    menuItems,
  });
  // This are all the child options + clickable options at top level
  const firstLevelMenuButtons = menuItemsData.filter(isSidebarButton);
  const menuSections = menuItemsData.filter(isSidebarSection);
  const secondLevelMenuButtons = _.flatMap(
    menuSections,
    (menuSection) => menuSection.children
  );
  const flattenMenuItems = [...firstLevelMenuButtons, ...secondLevelMenuButtons];
  const selectedButtonId =
    flattenMenuItems.find(
      // NOTE: we are excluding BA options because those execute redirects outside of polco-node app
      (menuItem) =>
        (!menuItem.id.includes('BALANCING_ACT') || menuItem.text === 'Learn More') &&
        menuItem.url
          ? pathname.includes(menuItem.url)
          : false
    )?.id || '';

  function getDataForTiersCta() {
    const upgradeAccountTierCtaButton = menuItems.find(isUpgradeAccountTierCta);
    if (upgradeAccountTierCtaButton) {
      return {
        isShown: upgradeAccountTierCtaButton.isShown,
        text: upgradeAccountTierCtaButton.text,
        url: upgradeAccountTierCtaButton.url ?? '',
      };
    }
  }

  const upgradeTierCTA = getDataForTiersCta();

  return (
    <nav
      className={`
        ${baseClass}-container
        ${className ? className : ''} 
        ${showSidebar ? 'expanded d-block' : 'd-none'} d-lg-block u-no-print
        sticky-top
      `}
    >
      <div
        className={`d-flex flex-column ${baseClass}-container-contents-desktop flex-start sticky-top`}
      >
        <div
          className={`d-flex flex-row align-items-center ${baseClass}-logo-container-outer`}
        >
          <AppLink to={ClientUrlUtils.admin.base.path()}>
            <div
              className={`${baseClass}-logo-container d-flex flex-column ${
                showSidebar ? 'expanded pr-1' : ''
              } ${`${baseClass}-logo-container`}`}
            >
              {/*  */}
              <div className={`${baseClass}-logo-inner-container`}>
                <img
                  alt="Polco"
                  className={`${baseClass}-logo-image`}
                  src={logoLightText}
                />
              </div>
            </div>
          </AppLink>
          {showSidebar && (
            <Btn
              action={() => {
                handleSidebarToggle(false);
              }}
              className="d-lg-none"
              customPadding="px-2"
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon
                className={`text-gray-30 ${baseClass}-container-menu-open`}
                icon={MaterialIconName.MENU_OPEN}
              />
            </Btn>
          )}
        </div>
        <div className={`${baseClass}-buttons-container d-flex flex-column`}>
          {menuItemsData.map((sidebarElement) => (
            <AdminSidebarComponent
              elementConfig={sidebarElement}
              key={sidebarElement.id}
              selectedOptionId={selectedButtonId}
            />
          ))}
          {upgradeTierCTA?.isShown ? (
            <div
              className={`${baseClass}-upgrade-container rounded text-center p-3 bg-polco-green-l text-gray-60`}
            >
              <div className="font-weight-bold">{COPY.upgradeTier.description}</div>
              <AppLink
                className={`${baseClass}-upgrade-button rounded px-3 py-2 mt-3 font-weight-bold text-gray-60`}
                to={upgradeTierCTA.url}
              >
                <div className="d-flex align-items-center">
                  <MaterialIcon icon={MaterialIconName.CHECK_CIRCLE} />
                  <span className="flex-grow-1">{upgradeTierCTA.text}</span>
                </div>
              </AppLink>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </nav>
  );
};
