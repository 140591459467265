import * as React from 'react';
import { AdminSidebar } from '../../components/sidebar';
import { useQueryAdminInfo } from 'client/admin/shared/containers/query-admin';
import { isStandardAdminData } from '../../types';
import { MenuItemsTx } from 'client/shared/graphql-util/transforms';

export interface Props {
  readonly className?: string;
  readonly showSidebar: boolean;
  readonly handleSidebarToggle: (direction: boolean) => void;
}

export const AdminSidebarContainer: React.FC<Props> = (p) => {
  const { data: result } = useQueryAdminInfo();
  const isStandardAdmin = isStandardAdminData(result);
  const menuItems = isStandardAdmin
    ? result.admin.menuItems.map(MenuItemsTx.gqlMenuItemToClient)
    : [];

  return <AdminSidebar {...p} menuItems={menuItems} />;
};
AdminSidebarContainer.displayName = 'AdminSidebarContainer';
