import * as React from 'react';
import { ActionLink, AppLink, Btn, MaterialIcon, MaterialIconName } from '../base';
import { Modal as BsModal, ModalProps as BsModalProps } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import classNames from 'classnames';
import './styles.scss';
import { ButtonTypes } from '../base/btn';
import { AppLinkProps } from '../base/app-link';
import polcoLogo from 'client/assets/polco-logo-dark-text.svg';

export interface ModalAction {
  readonly label: string;
  readonly action: () => void | Promise<void>;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly type?: ButtonTypes;
}

export interface ModalProps {
  readonly isOpen: boolean;
  readonly ariaLabelCloseButton?: string;
  readonly isDismissable?: boolean; // default true, overlay click or esc key
  readonly isCloseable?: boolean; //  default true, corner X click
  readonly size?: BsModalProps['size'] | 'md';
  readonly headerIsLogo?: boolean;
  readonly bodyClassName?: string;
  readonly header?: {
    readonly title: React.ReactNode;
    readonly className?: string;
    readonly small?: boolean;
    readonly bsClassName?: string;
    readonly as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    readonly divider?: boolean;
  };
  readonly footer?: {
    readonly primaryAction: ModalAction;
    readonly secondaryAction?: ModalAction;
    readonly auxillaryAction?: ModalAction;
    readonly linkAction?: AppLinkProps & { readonly content: string };
    readonly buttonsCentered?: boolean;
    readonly buttonsEnd?: boolean;
    readonly className?: string;
    readonly bsClassName?: string;
    readonly buttonsClassName?: string;
    readonly divider?: boolean;
  };
  readonly className?: string;
  readonly onRequestClose?: (event?: React.MouseEvent | React.KeyboardEvent) => void;
  readonly children: React.ReactNode;
  readonly animate?: boolean;
  readonly centered?: boolean;
  readonly displayIcon?: MaterialIconName;
  readonly displayImage?: string;
}

const baseClass = 'pn-modal';

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    ariaLabelCloseButton,
    onRequestClose,
    isDismissable,
    isCloseable,
    isOpen,
    headerIsLogo,
    header,
    children,
    footer,
    size,
    className,
    animate,
    displayIcon,
    displayImage,
    bodyClassName,
  } = props;
  const headerClassName = `${header?.className ?? ''} ${
    header?.small ? 'pn-modal-header-small' : ''
  }`;
  return (
    <BsModal
      animation={animate ?? false}
      backdrop={isDismissable !== false ? true : 'static'}
      backdropClassName={`${baseClass}-backdrop`}
      centered={props.centered}
      dialogClassName={classNames(baseClass, className)}
      keyboard={isDismissable !== false}
      onHide={() => onRequestClose && onRequestClose()}
      onShow={() => {
        // Workaround for https://github.com/twbs/bootstrap/issues/27071
        // We use sticky-top in sort of unexpected locations, so the padding
        // and margin that Bootstrap adds to prevent layout shift when
        // the scroll gutter disappears, messes with our layout.
        // Another global solution would be
        // .sticky-top {
        //  margin-right: 0px !important
        //  padding-right: 0px !important
        // }
        // but that overrides any normal padding the element might actually need.
        // Whereas the below removes the inline style that Bootstrap adds
        // and is much less likely to interfere.
        const stickies = document.getElementsByClassName('sticky-top');
        for (let i = 0; i < stickies.length; i++) {
          const element = stickies[i] as HTMLElement;
          element.style.removeProperty('margin-right');
          element.style.removeProperty('padding-right');
        }
      }}
      show={isOpen}
      size={size === 'md' ? undefined : size || 'lg'}
    >
      <BsModal.Header
        className={classNames(header?.bsClassName, {
          [`${baseClass}-header-logo`]: !!headerIsLogo,
          'border-bottom-1': header?.divider,
        })}
      >
        {!!headerIsLogo && (
          <img
            alt="Polco logo"
            className={`${baseClass}-polco-logo`}
            src={polcoLogo}
          />
        )}
        {header?.title && (
          <BsModal.Title as={`${header.as ?? 'h2'}`} className={headerClassName}>
            {header.title}
          </BsModal.Title>
        )}
        {isCloseable !== false && (
          <CloseButton
            aria-label={ariaLabelCloseButton || 'Close'}
            className="text-gray-60"
            onClick={onRequestClose}
          />
        )}
      </BsModal.Header>
      {displayIcon && (
        <MaterialIcon
          className={`${baseClass}-display-icon text-center py-3`}
          icon={displayIcon}
        />
      )}
      {displayImage && (
        <img alt="" className={`${baseClass}-display-image`} src={displayImage} />
      )}
      {children && (
        <BsModal.Body className={`${bodyClassName ?? ''}`}>{children}</BsModal.Body>
      )}
      {footer && (
        <BsModal.Footer
          className={classNames([
            footer.bsClassName,
            { 'border-top-1': footer.divider },
          ])}
        >
          <div
            className={classNames([
              footer?.className,
              'pn-modal-footer',
              'd-flex',
              'w-100',
              'align-items-center',
              'justify-content-between',
              'flex-wrap',
            ])}
          >
            {footer.auxillaryAction && (
              <ActionLink
                action={() => footer?.auxillaryAction?.action()}
                className={classNames([
                  'pn-modal-footer-auxillary',
                  'pl-0',
                  'mr-auto',
                  'my-3',
                  footer.auxillaryAction.className,
                ])}
              >
                {footer.auxillaryAction.label}
              </ActionLink>
            )}
            {footer.linkAction && (
              <AppLink
                {...footer.linkAction}
                className={`${footer.linkAction.className ?? ''} my-3`}
              >
                <span>{footer.linkAction.content}</span>
              </AppLink>
            )}
            <div
              className={classNames([
                'd-flex',
                props.footer?.buttonsClassName,
                {
                  'justify-content-around w-100': props.footer?.buttonsCentered,
                  'justify-content-end': !props.footer?.buttonsCentered,
                  'flex-grow-1':
                    !props.footer?.buttonsEnd && !props.footer?.buttonsCentered,
                },
              ])}
            >
              {footer.secondaryAction && (
                <Btn
                  action={() => footer?.secondaryAction?.action()}
                  className={`pn-modal-footer-secondary mr-2 ${
                    footer.secondaryAction.className ?? ''
                  }`}
                  disabled={footer.secondaryAction.disabled}
                  loading={footer.secondaryAction.loading}
                  type={footer.secondaryAction?.type || ButtonTypes.SECONDARY}
                >
                  {footer.secondaryAction.label}
                </Btn>
              )}
              <Btn
                action={
                  () => footer?.primaryAction.action() // null checks needed for typescript
                }
                className={`pn-modal-footer-primary ${
                  footer.primaryAction.className ?? ''
                }`}
                disabled={footer.primaryAction.disabled}
                loading={footer.primaryAction.loading}
                type={footer.primaryAction.type || ButtonTypes.PRIMARY}
              >
                {footer.primaryAction.label}
              </Btn>
            </div>
          </div>
        </BsModal.Footer>
      )}
    </BsModal>
  );
};

Modal.displayName = 'Modal';
