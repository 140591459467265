import { BenchmarkData, TrendData } from 'client/shared/core/question';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import {
  ApiDate,
  AverageChoiceValueData,
  BenchmarkValue,
  ExtractGql,
  maybeMap,
  TrendValue,
} from 'core';
import _ from 'lodash';

type GqlQuestionAggregateResult = NonNullable<
  NonNullable<
    Gql.AdminOpenQuestionAggregateResults['openQuestion']
  >['aggregateResults']
>;

type GqlSurveyQuestionAggregateResult = NonNullable<
  ExtractGql<
    ExtractGql<
      NonNullable<Gql.AdminOpenQuestionSetAggregateResults['openContentSetById']>,
      'Survey'
    >['contents'][0],
    'Question'
  >['aggregateResults']
>;

type GqlBenchmarkAggregateResult = NonNullable<
  | GqlSurveyQuestionAggregateResult['benchmark']
  | GqlQuestionAggregateResult['benchmark']
>;

type GqlTrendAggregateResult = NonNullable<
  GqlSurveyQuestionAggregateResult['trend'] | GqlQuestionAggregateResult['trend']
>;

type GqlAverageChoiceValueData = NonNullable<
  NonNullable<
    GqlQuestionAggregateResult['trend']
  >['historicalDataPoints'][0]['averageChoiceValueData']
>;
export namespace BenchmarkAggregateTx {
  export function gqlToClientBenchmarkValue(
    gqlBenchmarkValue: Gql.BenchmarkValue
  ): BenchmarkValue {
    switch (gqlBenchmarkValue) {
      case Gql.BenchmarkValue.MUCH_HIGHER:
        return BenchmarkValue.MUCH_HIGHER;
      case Gql.BenchmarkValue.HIGHER:
        return BenchmarkValue.HIGHER;
      case Gql.BenchmarkValue.SIMILAR:
        return BenchmarkValue.SIMILAR;
      case Gql.BenchmarkValue.LOWER:
        return BenchmarkValue.LOWER;
      case Gql.BenchmarkValue.MUCH_LOWER:
        return BenchmarkValue.MUCH_LOWER;
    }
  }

  export function gqlToClientTrendValue(gqlTrendValue: Gql.TrendValue): TrendValue {
    switch (gqlTrendValue) {
      case Gql.TrendValue.HIGHER:
        return TrendValue.HIGHER;
      case Gql.TrendValue.SIMILAR:
        return TrendValue.SIMILAR;
      case Gql.TrendValue.LOWER:
        return TrendValue.LOWER;
    }
  }

  export function gqlToClientAverageChoiceValue(
    averageChoiceValueData: GqlAverageChoiceValueData
  ): AverageChoiceValueData {
    return {
      ...averageChoiceValueData,
      averageChoiceValue: _.round(averageChoiceValueData.averageChoiceValue, 1),
    };
  }

  export function gqlToClientTrendAggregateResult(
    gqlTrendResult: GqlTrendAggregateResult
  ): TrendData {
    return {
      value: BenchmarkAggregateTx.gqlToClientTrendValue(gqlTrendResult.value),
      historicalDataPoints: gqlTrendResult.historicalDataPoints.map((dataPoint) => ({
        aggregateValue: dataPoint.scalePercent,
        date: ApiDate.fromApi(dataPoint.date),
        averageChoiceValueData: maybeMap(
          dataPoint.averageChoiceValueData,
          BenchmarkAggregateTx.gqlToClientAverageChoiceValue
        ),
      })),
      trendSignificance: gqlTrendResult.trendSignificance,
    };
  }

  export function gqlToClientBenchmarkAggregateResult(
    gqlBenchmarkResult: GqlBenchmarkAggregateResult
  ): BenchmarkData {
    return {
      value: BenchmarkAggregateTx.gqlToClientBenchmarkValue(
        gqlBenchmarkResult.value
      ),
      rank: gqlBenchmarkResult.rank,
      totalDataPoints: gqlBenchmarkResult.totalDataPoints,
      dateRange: {
        start: ApiDate.fromApi(gqlBenchmarkResult.dateRange.start),
        end: ApiDate.fromApi(gqlBenchmarkResult.dateRange.end),
      },
      benchmarkSignificance: null,
    };
  }
}
