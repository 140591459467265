import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { TrackVariableTx } from 'client/shared/graphql-util/transforms';
import { StatisticType, TrackAreaMode } from 'core'; // TODO: import from core or @polco-us/types...?

export const useTrackVariables = (args: {
  readonly fipsCodes: readonly string[];
  readonly variableNames: readonly string[];
  readonly type: StatisticType;
  readonly pubId: string | null;
}) => {
  const { data, loading, error } = useQueryInfo(QueryInfos.openTrackVariables, {
    variables: {
      fipsCodes: args.fipsCodes,
      variableNames: args.variableNames,
      statisticType: args.type,
      areaMode: TrackAreaMode.LOCAL,
      publishingEntityId: args.pubId,
    },
  });

  const trackVariables = data?.openTrackVariables
    ? data.openTrackVariables.map(
        TrackVariableTx.gqlTrackVariableWithDistributionsToClient
      )
    : [];

  return {
    trackVariables,
    loading,
    error,
  };
};
