import { nameForSidebarButtonType } from 'client/admin/core';
import { useSelectedButtonTypeLocation } from 'client/admin/hooks/use-selected-button-type-location';
import { LoadedAdminData } from 'client/admin/shared/types';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { splitFullname } from 'core';
import * as React from 'react';
import { AppcuesLaunchpad } from '../appcues-launchpad';
import { HelpCenterDropdown } from '../help-center-dropdown';
import { PublishingEntitySelector } from '../publishing-entity-selector';
import { getConfig } from 'client/shared/core/config';
import { UserAvatar } from '../user-avatar';
import questionMark from './assets/question-mark.svg';
import './styles.scss';
import { ShareFeatureDropdown } from '../share-feature-dropdown';
import {
  RequestableFeatureButton,
  RequestableFeatureState,
} from 'client/admin/learn-more/components/requestable-feature-button';
import { useRequestedFeatures } from 'client/admin/hooks';
import { useHistory } from 'react-router';

export interface IAdminHeaderProps {
  readonly adminData: LoadedAdminData | null; // null -> loading
  readonly managedPublishingEntities: readonly {
    readonly id: string;
    readonly name: string;
    readonly presidingAreaName: string | null;
  }[];
  readonly handleSidebarToggle?: () => void;
  readonly hideLiveEventWarning?: boolean;
  readonly onFilterChange: (newFilter: string) => void;
  readonly onSelectNewEntity: (id: ClientPublishingEntityId) => void;
  readonly onClickLogout: () => void;
  readonly onOpen?: () => void;
}

const baseClass = 'pn-admin-header';
const REQUEST_INFO_FEATURE = 'request-info-on-page';

export const AdminHeader: React.FC<IAdminHeaderProps> = (p) => {
  const selectedButtonType = useSelectedButtonTypeLocation();
  const headerName = nameForSidebarButtonType[selectedButtonType];
  const history = useHistory();

  const { loading: requestedFeaturesLoading, requestFeature } =
    useRequestedFeatures();
  const [requestedInfoThisSession, setRequestedInfoThisSession] =
    React.useState<boolean>(false);
  const infoRequestState = requestedInfoThisSession
    ? RequestableFeatureState.PENDING
    : RequestableFeatureState.AVAILABLE;

  const onRequestInfo = React.useCallback(async () => {
    await requestFeature({
      variables: {
        features: `${REQUEST_INFO_FEATURE}-${history.location.pathname}`,
      },
    });
    setRequestedInfoThisSession(true);
  }, [requestFeature, history]);

  const isDemoEnv = getConfig().isDemoEnv ?? false;
  const isEnvisioUser = p.adminData?.admin.isEnvisioUser;
  const isEnterpriseCustomer =
    p.adminData?.admin.activePublishingEntity.isEnterprise;

  React.useEffect(() => {
    document.title = headerName ? `Polco | ${headerName}` : 'Polco';
  }, [headerName]);

  const welcomeText =
    isDemoEnv || isEnvisioUser
      ? 'Welcome!'
      : `Welcome, ${splitFullname(p.adminData?.admin.name).firstName}!`;

  return (
    <>
      <header className={`${baseClass} sticky-top bg-white`}>
        <div
          className={`${baseClass}-contents px-3 pb-2 pb-lg-0 justify-content-lg-between flex-column-reverse flex-lg-row align-items-lg-center`}
        >
          <h1 className={`${baseClass}-page-title px-lg-3 py-lg-3 m-0`}>
            {nameForSidebarButtonType[selectedButtonType]}
          </h1>
          <div className={`${baseClass}-right-side d-flex align-items-center`}>
            {p.handleSidebarToggle && (
              <Btn
                action={p.handleSidebarToggle}
                className={`d-lg-none ${baseClass}-right-side-open-icon`}
                type={ButtonTypes.SEAMLESS}
              >
                <MaterialIcon className="d-lg-none" icon={MaterialIconName.MENU} />
              </Btn>
            )}
            <div className="d-lg-none flex-grow-1"></div>
            {!isEnterpriseCustomer && !requestedFeaturesLoading && (
              <div className="ml-lg-3 pl-lg-4 pr-4">
                <RequestableFeatureButton
                  events={{ onRequest: onRequestInfo }}
                  featureState={infoRequestState}
                  hubspotKey={REQUEST_INFO_FEATURE}
                />
              </div>
            )}
            <ShareFeatureDropdown>
              <MaterialIcon
                className={`${baseClass}-share mr-3 p-1`}
                icon={MaterialIconName.SHARE}
                iconType="material-icons-outlined"
              />
            </ShareFeatureDropdown>

            <HelpCenterDropdown>
              <img
                alt="Help"
                className={`${baseClass}-help-icon mr-3 p-1`}
                src={questionMark}
              />
            </HelpCenterDropdown>
            <AppcuesLaunchpad className={`${baseClass}-appcues pr-4 mb-2`} />

            {p.adminData ? (
              <PublishingEntitySelector
                activePublishingEntityId={
                  p.adminData.admin.activePublishingEntity.id
                }
                containerClassName={`${baseClass}-profile-popover-container`}
                onClickLogout={p.onClickLogout}
                onFilterChange={p.onFilterChange}
                onOpen={p.onOpen}
                onSelectNewEntity={p.onSelectNewEntity}
                publishers={p.managedPublishingEntities}
              >
                <div
                  className={`${baseClass}-user-avatar-container d-flex align-items-center p-1`}
                >
                  <UserAvatar name={p.adminData.admin.name} />
                  <div className={`${baseClass}-user-avatar-icon`}>
                    <MaterialIcon
                      ariaLabel="Account submenu"
                      icon={MaterialIconName.ARROW_DROP_DOWN}
                    />
                  </div>
                </div>
              </PublishingEntitySelector>
            ) : null}
            {p.adminData ? (
              <div className={`${baseClass}-information d-none d-md-block`}>
                {welcomeText}
                <div className={`${baseClass}-information-subtext`}>
                  Administrator for {p.adminData.admin.activePublishingEntity.name}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </header>
    </>
  );
};
