import { TabBar } from 'client/shared/components/base/tab-bar';
import {
  ActivationState,
  BenchmarkFilter,
  TrackVariableWithDistributions,
  VisualizationType,
  wrap,
} from 'core';
import React from 'react';
import './styles.scss';
import {
  EmbedDataPointContext,
  VisualizationPicker,
} from 'client/shared/components/visualization-picker';

interface Props {
  readonly trackVariable: TrackVariableWithDistributions | null;
  readonly ideasCopy: Record<string, React.ReactNode>;
  readonly fipsCode: string;
}

enum ReportCardTabBarKey {
  DATA_DETAILS = 'DATA_DETAILS',
  IDEAS = 'IDEAS',
}

const tabBarOptions = [
  {
    key: ReportCardTabBarKey.DATA_DETAILS,
    label: 'Data Details',
  },
  {
    key: ReportCardTabBarKey.IDEAS,
    label: 'Ideas from Grade "A" Communities',
  },
];

export const ReportCardTabBar: React.FC<Props> = (p) => {
  const { trackVariable, ideasCopy, fipsCode } = p;
  const [selectedTabBar, setSelectedTabBar] = React.useState<ReportCardTabBarKey>(
    ReportCardTabBarKey.DATA_DETAILS
  );
  return (
    <div>
      <TabBar<ReportCardTabBarKey>
        selected={
          tabBarOptions.find((option) => option.key === selectedTabBar) ??
          tabBarOptions[0]
        }
        tabSelected={(tab) => {
          setSelectedTabBar(tab.key);
        }}
        tabs={tabBarOptions}
      />
      {wrap(() => {
        switch (selectedTabBar) {
          case ReportCardTabBarKey.DATA_DETAILS:
            return (
              <div>
                {!!trackVariable && (
                  <VisualizationPicker
                    embedContext={EmbedDataPointContext.CONTENT_POST}
                    expanded
                    visualizationData={{
                      visualizationType: VisualizationType.LINE, //TODO update visualization picker to display correctly while not requiring this parameter
                      trackVariable,
                      recordedAt: null,
                      baseFips: fipsCode,
                      publishingEntityId: '',
                      benchmarkFilter: BenchmarkFilter.DEFAULT,
                      state: ActivationState.ACTIVE,
                      mapData: {
                        fipsAreasShapes: {},
                        mapBoundCoordinates: undefined,
                      },
                    }}
                  />
                )}
              </div>
            );
          case ReportCardTabBarKey.IDEAS:
            return ideasCopy[trackVariable?.name ?? ''];
        }
      })}
    </div>
  );
};
ReportCardTabBar.displayName = 'ReportCardTabBar';
