import { Btn, ButtonTypes, TextInput } from 'client/shared/components/base';
import { Modal } from 'client/shared/components/modal';
import { AnalyticsValueType } from 'core';
import _ from 'lodash';
import * as React from 'react';
import './styles.scss';

import { useTrackGoals } from 'client/admin/hooks/use-track-goals';
interface Props {
  readonly variableLabel: string;
  readonly variableId: string;
  readonly planId: string;
  readonly publishingEntityId: string;
  readonly valueType: AnalyticsValueType;
  readonly isOpen: boolean;
  readonly minValue?: number;
  readonly maxValue?: number;
  readonly currentGoalData?: {
    readonly value: number;
    readonly id: string;
  };
  readonly suffix: string | null;
  readonly events: {
    readonly cancel: () => void;
    readonly onGoalSaved?: () => void;
  };
}

export const copy = {
  header: (name: string) => `Add/Edit a Goal for ${_.startCase(name)}`,
  value: 'Goal Value',
};

const getPrefixFromValueType = (type: AnalyticsValueType) => {
  switch (type) {
    case AnalyticsValueType.CURRENCY:
      return '$';
    case AnalyticsValueType.PERCENT:
      return '%';
    default:
      return;
  }
};

const getInputClassNameFromValueType = (type: AnalyticsValueType) => {
  switch (type) {
    case AnalyticsValueType.CURRENCY:
      return 'goal-padding-currency';
    case AnalyticsValueType.PERCENT:
      return 'goal-padding-percent';
    default:
      return;
  }
};

export const ManageGoalModal: React.FC<Props> = ({
  variableLabel,
  variableId,
  planId,
  valueType,
  isOpen,
  minValue,
  maxValue,
  events,
  currentGoalData,
  suffix,
  publishingEntityId,
}) => {
  const [goalVal, setGoalVal] = React.useState<string>(
    currentGoalData?.value.toString() ?? ''
  );

  const { saveNewTrackGoal, updateTrackGoal, deleteTrackGoal } = useTrackGoals({
    publishingEntityId,
    skipQueryingPlans: true,
  });
  // required for when we switch from single view to group view
  React.useEffect(() => {
    setGoalVal(currentGoalData?.value.toString() ?? '');
  }, [currentGoalData]);

  const sanitizeValueAndCompare = (input: string) => {
    let sanitizedValue = input
      .replace(/[^0-9.,-]/g, '') // Allow only numbers, periods, commas, and hyphens
      .replace(/(?!^)-/g, '') // Remove hyphens that are not at the start
      .replace(/(\..*?)\./g, '$1');

    if (!_.isUndefined(maxValue) && parseFloat(sanitizedValue) > maxValue) {
      sanitizedValue = `${maxValue}`;
    }

    if (!_.isUndefined(minValue) && parseFloat(sanitizedValue) < minValue) {
      sanitizedValue = `${minValue}`;
    } // Ensure only one decimal point
    return sanitizedValue;
  };

  const handleGoalChange = (val: string) => {
    const sanitizedValue = sanitizeValueAndCompare(val);

    setGoalVal(sanitizedValue);
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pastedValue = e.clipboardData.getData('Text');
    const sanitizedValue = sanitizeValueAndCompare(pastedValue);
    setGoalVal(sanitizedValue);
    e.preventDefault(); // Prevent the default paste action
  };
  return (
    <Modal
      bodyClassName="px-4 pb-4 pt-0"
      centered
      className="pn-manage-goal-modal"
      header={{
        title: '',
        bsClassName: 'pb-0',
      }}
      isCloseable
      isDismissable={false}
      isOpen={isOpen}
      onRequestClose={events.cancel}
      size="md"
    >
      <div className="d-flex flex-column">
        <div className="font-weight-bold mb-4">{copy.header(variableLabel)}</div>
        <div className="font-size-sm font-weight-bold">{copy.value}</div>
        <TextInput
          ariaLabel="Goal Value"
          className="mb-0 pn-manage-goal-modal-input"
          hideIdentifierBackground
          identifier={getPrefixFromValueType(valueType)}
          identifierTrailing={valueType === AnalyticsValueType.PERCENT}
          inputClassName={getInputClassNameFromValueType(valueType)}
          onChange={handleGoalChange}
          onPaste={handlePaste}
          value={goalVal}
        />
        {suffix && (
          <div className="font-size-sm font-italic text-gray-50 pl-1">{suffix}</div>
        )}
        <div className="w-100 d-flex justify-content-between pt-3 mt-3">
          <div className="flex-grow-1">
            {currentGoalData && (
              <Btn
                action={async () => {
                  await deleteTrackGoal(currentGoalData.id);
                  events.cancel();
                }}
                type={ButtonTypes.DANGER}
              >
                Delete
              </Btn>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <Btn
              action={() => {
                setGoalVal('');
                events.cancel();
              }}
              className="mr-3"
              type={ButtonTypes.SECONDARY}
            >
              Cancel
            </Btn>
            <Btn
              action={async () => {
                currentGoalData
                  ? await updateTrackGoal({
                      goalId: currentGoalData.id,
                      value: parseFloat(goalVal),
                    })
                  : await saveNewTrackGoal({
                      value: parseFloat(goalVal),
                      variableId,
                      planId,
                    });
                events.onGoalSaved?.();
                events.cancel();
              }}
              type={ButtonTypes.PRIMARY}
            >
              Save
            </Btn>
          </div>
        </div>
      </div>
    </Modal>
  );
};
