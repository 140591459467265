import * as React from 'react';
import { AdminData, AdminState } from '../../types';
import { useQueryAdminInfo } from '../query-admin';

import { AdminHeader } from '../../components/header';
import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import { useDefaultLanguage } from 'client/shared/hooks';
import { privateAdminFromUserAdmin } from 'client/admin/core/user';
import { CurrentAdminManagedPublishingEntities } from 'client/shared/graphql-client/graphql-operations.g';
import { ExtractGql } from 'core';
import qs from 'query-string';

type PrivateAdmin = ExtractGql<
  NonNullable<
    NonNullable<
      CurrentAdminManagedPublishingEntities['currentUser']['user']
    >['admin']
  >,
  'PrivateAdmin'
>;

export const AdminHeaderContainer: React.FC<{
  readonly hideLiveEventWarning?: boolean;
  readonly handleSidebarToggle?: () => void;
  readonly onProfileChangeStarted?: () => void;
}> = (p) => {
  const [filter, setFilter] = React.useState('');

  const managedPublishingEntities = useQueryInfo(
    QueryInfos.currentAdminManagedPublishingEntities,
    {
      variables: {
        filter: filter.length > 0 ? filter : null,
      },
    }
  );
  const privateAdmin = privateAdminFromUserAdmin<PrivateAdmin>(
    managedPublishingEntities.data?.currentUser.user?.admin
  );

  const { data: adminQueryResult, refreshQueries: refetch } = useQueryAdminInfo();

  const { fn: adminSetActivePublishingEntityFn } = useMutationInfo(
    MutationInfos.adminSetActivePublishingEntity,
    {
      refetchQueries: refetch,
    }
  );

  const queryParameters = qs.parse(location.search);

  const [activePublishingEntityId] = React.useState(
    queryParameters['active-publishing-entity-id']
  );

  const adminActivePublisher = getActivePublisher(adminQueryResult);

  React.useEffect(() => {
    if (
      activePublishingEntityId &&
      typeof activePublishingEntityId === 'string' &&
      activePublishingEntityId !== adminActivePublisher?.id
    ) {
      p.onProfileChangeStarted && p.onProfileChangeStarted();
      adminSetActivePublishingEntityFn({
        variables: { publishingEntityId: activePublishingEntityId },
      }).catch(console.error);
    }
  }, [activePublishingEntityId]);

  const { fn: logOutFn } = useMutationInfo(MutationInfos.logout);
  const selectLanguageText = useDefaultLanguage();

  switch (adminQueryResult.type) {
    case AdminState.ADMIN:
    case AdminState.PENDING_APPROVAL_ADMIN:
    case AdminState.INCOMPLETE_PUB_PROFILE_ADMIN:
    case AdminState.PENDING_PUB_ACTIVATION_ADMIN:
    case AdminState.PENDING_EMAIL_VERIFICATION_ADMIN:
      return (
        <AdminHeader
          adminData={adminQueryResult}
          handleSidebarToggle={p.handleSidebarToggle}
          hideLiveEventWarning={p.hideLiveEventWarning}
          managedPublishingEntities={(
            privateAdmin?.managedPublishingEntities ?? []
          ).map((pub) => ({
            id: pub.id,
            name: selectLanguageText(pub.name),
            presidingAreaName: pub.presidingArea?.name ?? null,
          }))}
          onClickLogout={() => logOutFn({ refetchQueries: refetch })}
          onFilterChange={setFilter}
          onOpen={() => {
            setFilter('');
          }}
          onSelectNewEntity={async (id) => {
            p.onProfileChangeStarted && p.onProfileChangeStarted();
            await adminSetActivePublishingEntityFn({
              variables: { publishingEntityId: id },
            });
          }}
        />
      );
    default:
      // If the page is loading show a placeholder header
      // If it is an error, we can trust the page to display a valuable message. We don't have enough info (as the header container) to provide any insight
      return (
        <AdminHeader
          adminData={null}
          hideLiveEventWarning={p.hideLiveEventWarning}
          managedPublishingEntities={[]}
          onClickLogout={() => {}}
          onFilterChange={() => {}}
          onSelectNewEntity={() => {}}
        />
      );
  }
};

function getActivePublisher(adminQueryResult: AdminData) {
  switch (adminQueryResult.type) {
    case AdminState.ADMIN:
    case AdminState.PENDING_APPROVAL_ADMIN:
    case AdminState.INCOMPLETE_PUB_PROFILE_ADMIN:
    case AdminState.PENDING_PUB_ACTIVATION_ADMIN:
      return adminQueryResult.admin.activePublishingEntity;
    default:
      return null;
  }
}

AdminHeaderContainer.displayName = 'AdminHeaderContainer';
