import { useQueryInfo } from 'client/shared/containers/query';
import { useLocalStorage } from 'client/shared/helpers/hooks';
import uuid from 'uuid';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { privateAdminFromUserAdmin } from '../core/user';
import { ExtractGql, wrap } from 'core';
import { CurrentAdmin } from 'client/shared/graphql-client/graphql-operations.g';
import { useHistory } from 'react-router';

type GqlPrivateAdmin = ExtractGql<
  NonNullable<NonNullable<CurrentAdmin['currentUser']['user']>['admin']>,
  'PrivateAdmin'
>;

const getAiAssistantStorageKey = (assistantId: string) =>
  `aiAssistantSession-${assistantId}`;

export function useAiAssistantSession(args: {
  readonly onSessionRefresh: () => void;
  readonly assistantId: string;
}) {
  const currentAdminQuery = useQueryInfo(QueryInfos.currentAdmin, {
    notifyOnNetworkStatusChange: true, // Required to change loading when variables change
  });

  const privateAdmin = privateAdminFromUserAdmin<GqlPrivateAdmin>(
    currentAdminQuery.data?.currentUser?.user?.admin
  );
  const userId = currentAdminQuery.data?.currentUser?.user?.id;
  const activePublishingEntityId = privateAdmin?.activePublishingEntity?.id;
  const localStorage = useLocalStorage();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const sessionIdParam = queryParams.get('session_id');
  const localStorageExists = !!localStorage;
  const localStorageKey = getAiAssistantStorageKey(args.assistantId);

  const transformedLocalStorageAiAssistantSession = (): {
    readonly userId: string;
    readonly activePublishingEntityId: string;
    readonly currentSessionId: string;
  } | null => {
    const localStorageAiAssistantSession =
      localStorage?.getItem(localStorageKey) ?? null;
    const parsedLocalStorageAiAssistantSession = localStorageAiAssistantSession
      ? parseLocalStorageAiAssistantSession(localStorageAiAssistantSession)
      : null;

    const currentSessionId = wrap(() => {
      if (sessionIdParam) {
        return sessionIdParam;
      } else if (parsedLocalStorageAiAssistantSession?.currentSessionId) {
        return parsedLocalStorageAiAssistantSession.currentSessionId;
      } else {
        return null;
      }
    });

    if (
      !parsedLocalStorageAiAssistantSession ||
      !parsedLocalStorageAiAssistantSession?.userId ||
      !parsedLocalStorageAiAssistantSession?.activePublishingEntityId ||
      !currentSessionId
    ) {
      return null;
    }

    return {
      userId: parsedLocalStorageAiAssistantSession.userId,
      activePublishingEntityId:
        parsedLocalStorageAiAssistantSession.activePublishingEntityId,
      currentSessionId,
    };
  };
  const needsRefresh = () => {
    if (currentAdminQuery.loading) return false;
    if (
      (!userId || !activePublishingEntityId) &&
      !transformedLocalStorageAiAssistantSession()
    ) {
      return false;
    }
    return (
      transformedLocalStorageAiAssistantSession()?.userId !== userId ||
      transformedLocalStorageAiAssistantSession()?.activePublishingEntityId !==
        activePublishingEntityId
    );
  };

  if (needsRefresh()) {
    args.onSessionRefresh();
    const newSessionid = uuid.v4();
    if (!userId || !activePublishingEntityId) {
      if (localStorageExists) {
        localStorage.removeItem(localStorageKey);
      }
    } else {
      if (localStorageExists) {
        localStorage.setItem(
          localStorageKey,
          JSON.stringify({
            userId,
            activePublishingEntityId,
            currentSessionId: newSessionid,
          })
        );
      }
    }
  }

  function refreshSessionId() {
    args.onSessionRefresh();
    const newSessionid = uuid.v4();
    if (!localStorageExists) {
      return newSessionid;
    }
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        userId,
        activePublishingEntityId,
        currentSessionId: newSessionid,
      })
    );
    return newSessionid;
  }

  return {
    isLoading:
      !userId ||
      !activePublishingEntityId ||
      !transformedLocalStorageAiAssistantSession()?.currentSessionId,
    sessionId: transformedLocalStorageAiAssistantSession()?.currentSessionId ?? null,
    activePublishingEntity: privateAdmin?.activePublishingEntity,
    refreshSessionId,
    isSuperAdmin: privateAdmin?.superAdmin,
  };
}

function parseLocalStorageAiAssistantSession(
  localStorageAiAssistantSession: string
) {
  try {
    return JSON.parse(localStorageAiAssistantSession);
  } catch (e) {
    return null;
  }
}
