import {
  Building_Metacondition,
  Building_QuestionChoiceCondition,
  Building_ValidatedMetacondition,
  Subrule_Metacondition,
  Subrule_Question,
} from 'client/admin/core/conditions';
import { clientToGql_combinationOperator } from 'client/shared/core/conditions';
import { QuestionConditions } from 'client/shared/core/question';
import {
  ConditionsInput,
  MetaConditionInput,
  QuestionChoiceConditionInput,
} from 'client/shared/graphql-client/graphql-operations.g';
import { QuestionChoiceCondition } from 'core';
import { compact, flatten, groupBy } from 'lodash';

/**
 * All the transforms in this namespace run on the assumption that a entities reference id IS the entities id. This makes it so we can do
 * single pass transforms. Outside of these transforms, this assumption should not be made. We can make it here because we are creating
 * the reference ids, so we can assume how they are created
 */
export namespace ConditionTx {
  export function coreToBuilding(
    conditions: QuestionConditions
  ): readonly Building_Metacondition[] {
    // Group all question choice conditions by their metacondition for later transformation
    const questionChoiceConditionsByMetaconditionId = groupBy(
      conditions.questionChoiceConditions,
      (c) => c.metaConditionId
    );

    // Transform all question metaconditions into building metaconditions
    return conditions.metaConditions.map((metacondition) => {
      const questionChoiceConditions =
        questionChoiceConditionsByMetaconditionId[metacondition.id] ?? [];
      const questionSubrules = ConditionTx.questionChoiceConditionsToSubrules(
        questionChoiceConditions
      );

      const metaconditionSubrules = ConditionTx.childMetaconditionIdsToSubrules(
        metacondition.childMetaConditionIds
      );

      return {
        id: metacondition.id,
        ephemeralReferenceId: metacondition.id,
        displayName: metacondition.displayName,
        combinationOperator: metacondition.combinationOperator,
        questionSubrules,
        metaconditionSubrules,
      };
    });
  }

  export function questionChoiceConditionsToSubrules(
    conditions: readonly QuestionChoiceCondition[]
  ): readonly Subrule_Question[] {
    const questionChoiceConditionsByQuestionId = groupBy(
      conditions,
      (c) => c.questionId
    );

    const questionSubrules: readonly Subrule_Question[] = Object.entries(
      questionChoiceConditionsByQuestionId
    ).map(([questionId, questionConditions]) => {
      const transformedConditions = questionConditions.map(
        ConditionTx.questionChoiceCondition_CoreToBuilding
      );
      return {
        questionEphemeralReferenceId: questionId,
        childQuestionChoiceConditions: transformedConditions,
      };
    });

    return questionSubrules;
  }

  export function questionChoiceCondition_CoreToBuilding(
    condition: QuestionChoiceCondition
  ): Building_QuestionChoiceCondition {
    return {
      id: condition.id,
      metaconditionEphemeralReferenceId: condition.metaConditionId,
      questionEphemeralReferenceId: condition.questionId,
      questionChoiceEphemeralReferenceId: condition.questionChoiceId,
    };
  }

  export function childMetaconditionIdsToSubrules(
    childMetaconditionIds: readonly string[]
  ): readonly Subrule_Metacondition[] {
    return childMetaconditionIds.map((childMetaconditionId) => {
      return {
        metaconditionEphemeralReferenceId: childMetaconditionId,
      };
    });
  }

  export function clientToGql(
    conditions: readonly Building_ValidatedMetacondition[]
  ): ConditionsInput {
    return conditions.reduce(
      (acc, val) => {
        const transformedCondition = buildingMetaconditionToGql(val);

        return {
          metaConditions: [
            ...acc.metaConditions,
            transformedCondition.metacondition,
          ],
          questionChoiceConditions: [
            ...acc.questionChoiceConditions,
            ...transformedCondition.questionChoiceConditions,
          ],
        };
      },
      {
        metaConditions: [],
        questionChoiceConditions: [],
      } as ConditionsInput
    );
  }

  function buildingMetaconditionToGql(condition: Building_ValidatedMetacondition): {
    readonly metacondition: MetaConditionInput;
    readonly questionChoiceConditions: readonly QuestionChoiceConditionInput[];
  } {
    const gqlMetacondition: MetaConditionInput = {
      id: condition.id,
      displayName: condition.displayName,
      ephemeralReferenceId: condition.ephemeralReferenceId,
      combinationOperator: clientToGql_combinationOperator(
        condition.combinationOperator
      ),
      childMetaConditionReferenceIds: compact(
        condition.metaconditionSubrules.map(
          (subrule) => subrule.metaconditionEphemeralReferenceId
        )
      ), // TODO: Remove compact once validation types are implemented
    };

    const gqlQuestionChoiceConditions = flatten(
      condition.questionSubrules.map(
        (subrule): readonly QuestionChoiceConditionInput[] =>
          // TODO: Remove compact and ternary expression once validation types are implemented
          compact(
            subrule.childQuestionChoiceConditions.map((questionChoiceCondition) =>
              questionChoiceCondition.questionChoiceEphemeralReferenceId &&
              subrule.questionEphemeralReferenceId
                ? {
                    metaConditionReferenceId:
                      questionChoiceCondition.metaconditionEphemeralReferenceId,
                    questionReferenceId:
                      questionChoiceCondition.questionEphemeralReferenceId,
                    questionChoiceReferenceId:
                      questionChoiceCondition.questionChoiceEphemeralReferenceId,
                  }
                : null
            )
          )
      )
    );

    return {
      metacondition: gqlMetacondition,
      questionChoiceConditions: gqlQuestionChoiceConditions,
    };
  }
}
