import * as React from 'react';
import moment from 'moment';
import { MaterialIconName, Pill, PillTypes } from 'client/shared/components/base';
import {
  CtaLinkType,
  FipsArea,
  FipsAreaWithShortName,
  MMMM_YEAR_FORMAT,
  StatisticType,
  TrackAreaMode,
  TrackDatum,
  TrackDomain,
  TrackVariableMetadata,
  VariableDemographicSegment,
  VariableDisplayType,
  getMostRecentDatumCurrentPub,
  wrap,
} from 'core';
import {
  domainCardIndicatorVariables,
  domainCardIndicatorVariablesOA,
  domainLabels,
  indicatorVariableNames,
} from 'client/shared/core/track/labels';
import './styles.scss';
import {
  DomainCardIndicator,
  demographicSegmentLabel,
} from 'client/shared/components/domain-card-indicator';
import { PrimaryIndicator } from 'client/shared/components/primary-indicator';
import { Tooltip } from 'client/shared/components/tooltip';
import {
  AnalyticsDomain,
  BenchmarkFilter,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  domainCardCopy,
  getBackgroundColorClass,
  getFilteredBenchmarkFooter,
  NCS_LINK,
} from 'client/shared/core/performance-data';
import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import { DownloadImageButton } from '../download-image';
import { AIQuickAction } from '../ai-quick-actions';

const baseClass = 'pn-dashboard-domain-card';

interface Props {
  readonly areaMode: TrackAreaMode;
  readonly domain: AnalyticsDomain;
  readonly domainData: TrackDomain;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly displayType: VariableDisplayType;
  readonly currentFips: FipsAreaWithShortName;
}

const indicatorsOnly = (
  variables: readonly string[],
  domainData: TrackDomain,
  displayType: VariableDisplayType,
  currentFips: FipsArea
) => {
  return (
    <div className={`${baseClass}-indicator-container d-flex flex-column pr-3`}>
      <div className={`${baseClass}-key-indicator-title font-weight-bold`}>
        {domainCardCopy.indicatorsLabel}
        <div className={`d-flex flex-column flex-xl-row ${baseClass}-oa-indicators`}>
          {variables.map((variable, index) => {
            const indicatorData = domainData.indicators.find(
              (indicator) => indicator.name === variable
            );
            return (
              <DomainCardIndicator
                className={`${baseClass}-key-indicator mt-2 mt-xl-3 flex-grow-1
                font-weight-normal col-12 col-xl-6 d-flex flex-column justify-content-between`}
                displayType={displayType}
                indicatorData={getMostRecentDatumCurrentPub(
                  currentFips,
                  indicatorData
                )}
                key={index}
                variable={indicatorData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export const DomainCard: React.FC<Props> = (p) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const varsToUse = wrap(() => {
    switch (p.displayType) {
      case VariableDisplayType.OLDER_ADULTS:
        return domainCardIndicatorVariablesOA;
      case VariableDisplayType.DEFAULT:
        return domainCardIndicatorVariables;
    }
  });
  const indicatorVariables = varsToUse[p.domain];
  const footer =
    p.areaMode === TrackAreaMode.LOCAL
      ? getFilteredBenchmarkFooter(p.benchmarkFilter)
      : undefined;
  const indexScoreData = p.domainData.indexScores.at(0);
  const indexScoreDatum = getMostRecentDatumCurrentPub(
    p.currentFips,
    indexScoreData
  );
  const residentSentimentVariable = p.domainData.sentimentValues.find(
    (sv) => sv.name === indicatorVariableNames[p.domain][0]
  );
  return (
    <div className={`${baseClass} p-3 mt-4`} ref={ref}>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h3>{domainLabels[p.domain]}</h3>
          {p.areaMode === TrackAreaMode.NATIONAL ? (
            <AIQuickAction className="my-1" promptKey={`${p.domain}-overview`} />
          ) : (
            <DomainCardContent
              displayType={p.displayType}
              domain={p.domain}
              indexScoreData={indexScoreDatum}
              variable={indexScoreData ?? null}
            />
          )}
        </div>

        <div className="d-flex align-items-center">
          {p.areaMode !== TrackAreaMode.NATIONAL && (
            <TrackDatePill
              date={indexScoreDatum?.recordedAt ?? null}
              isIndexScore={indexScoreData?.statisticType === StatisticType.INDEX}
            />
          )}
          <DownloadImageButton
            buttonClassName={`save-image-track-overview-${p.domain.toLowerCase()}`}
            imageRef={ref}
            name={`${domainLabels[p.domain]} Summary`}
          />
        </div>
      </div>
      {p.displayType === VariableDisplayType.OLDER_ADULTS ||
      p.areaMode === TrackAreaMode.NATIONAL ? (
        indicatorsOnly(
          indicatorVariables,
          p.domainData,
          p.displayType,
          p.currentFips
        )
      ) : (
        <div className={`${baseClass}-indicator-container row d-flex flex-wrap`}>
          <div
            className={`${baseClass}-key-indicator-title col-sm-12 col-lg-6 font-weight-bold`}
          >
            {domainCardCopy.indicatorsLabel}
          </div>
          <div
            className={`${baseClass}-resident-sentiment-title col-sm-12 col-lg-6 font-weight-bold`}
          >
            {domainCardCopy.residentSentimentLabel}
          </div>
          <div className={`${baseClass}-key-indicator col-sm-12 col-lg-6`}>
            {indicatorVariables.map((variable, index) => {
              const indicatorData = p.domainData.indicators.find(
                (indicator) => indicator.name === variable
              );

              const recentDatum = indicatorData
                ? getMostRecentDatumCurrentPub(p.currentFips, indicatorData)
                : undefined;
              return (
                <DomainCardIndicator
                  className={`${index !== indicatorVariables.length - 1 ? 'mb-2' : ''}`}
                  indicatorData={recentDatum}
                  key={index}
                  variable={indicatorData}
                />
              );
            })}
          </div>
          <div className={`${baseClass}-resident-sentiment col-sm-12 col-lg-6`}>
            <PrimaryIndicator
              benchmarkFilter={p.benchmarkFilter}
              className="h-100"
              ctaProps={{
                link: NCS_LINK,
                text: domainCardCopy.viewNcs,
                type: CtaLinkType.EXTERNAL,
              }}
              indicatorData={getMostRecentDatumCurrentPub(
                p.currentFips,
                residentSentimentVariable
              )}
              variable={residentSentimentVariable}
            />
          </div>
        </div>
      )}

      <DownloadImageFooter footer={footer} />
    </div>
  );
};

DomainCard.displayName = 'DomainCard';

interface FacetProps {
  readonly domain: AnalyticsDomain;
  readonly variableDemographicSegment: VariableDemographicSegment;
}

const DomainCardFacets: React.FC<FacetProps> = (p) => {
  return (
    <div
      className={`${baseClass}-facets d-flex d-row flex-wrap justify-content-start`}
    >
      <Pill
        className="segment"
        key={p.variableDemographicSegment}
        type={PillTypes.FACET}
      >
        {demographicSegmentLabel(p.variableDemographicSegment)}
      </Pill>
    </div>
  );
};

interface DomainProps {
  readonly domain: AnalyticsDomain;
  readonly indexScoreData?: TrackDatum;
  readonly variable: TrackVariableMetadata | null;
  readonly displayType?: VariableDisplayType;
  readonly showDemographicPill?: boolean;
  readonly className?: string;
  readonly hideAiQuickAction?: boolean;
  readonly aiQuickActionOverride?: string;
}

export const DomainCardContent: React.FC<DomainProps> = (p) => {
  const fixedIndexScore = Number(p.indexScoreData?.value.toFixed());
  const indexScore = p.indexScoreData;
  const isIndexScoreValueValid = !!indexScore && indexScore.value !== null;
  return (
    <div className={`${baseClass}-content ${p.className ?? ''} w-100`}>
      {p.displayType === VariableDisplayType.OLDER_ADULTS &&
        p.showDemographicPill && (
          <div
            className={`${baseClass}-top d-flex flex-row flex-wrap justify-content-between mb-3`}
          >
            <DomainCardFacets
              domain={p.domain}
              variableDemographicSegment={
                p.variable?.demographicSegment ?? VariableDemographicSegment.ALL
              }
            />
          </div>
        )}
      <div className={`${baseClass}-header`}>
        <div className={`${baseClass}-index-score`}>
          {isIndexScoreValueValid && (
            <div className="d-flex align-items-center">
              <span
                className={`${baseClass}-index-score-value font-weight-bold mr-2 ${getBackgroundColorClass(
                  fixedIndexScore
                )}`}
              >
                {fixedIndexScore}
              </span>
              <span className="h5 font-weight-normal text-gray-60 m-0">/ 100</span>
              {!p.hideAiQuickAction && (
                <AIQuickAction
                  className="ml-3"
                  promptKey={p.aiQuickActionOverride ?? `${p.domain}-overview`}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface TrackDatePillProps {
  readonly date: Date | null;
  readonly isIndexScore: boolean;
}

export const TrackDatePill: React.FC<TrackDatePillProps> = (p) => {
  return (
    <Tooltip
      className="mr-3"
      content={domainCardCopy.indexDateUpdate}
      id="domain-index-score-date-tooltip"
    >
      <Pill icon={MaterialIconName.RESTORE} type={PillTypes.ICON}>
        {p.isIndexScore
          ? domainCardCopy.scoreLastUpdated
          : domainCardCopy.lastUpdated}
        <span className="ml-1 font-weight-bold">
          {!!p.date
            ? moment.utc(p.date).format(MMMM_YEAR_FORMAT)
            : domainCardCopy.unknown}
        </span>
      </Pill>
    </Tooltip>
  );
};
