import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { BenchmarkSurveyType, BenchmarkType, ExtractGql, maybeMap } from 'core';

type GqlBenchmarkType = NonNullable<
  ExtractGql<
    NonNullable<Gql.AdminContentSetRespondentsStats['openContentSetById']>,
    'Survey'
  >['benchmarkType']
>;

export function gqlToSurveyType(
  gqlType: Gql.BenchmarkSurveyTypes
): BenchmarkSurveyType {
  switch (gqlType) {
    case Gql.BenchmarkSurveyTypes.CASOA:
      return BenchmarkSurveyType.CASOA;
    case Gql.BenchmarkSurveyTypes.NBS:
      return BenchmarkSurveyType.NBS;
    case Gql.BenchmarkSurveyTypes.NCS:
      return BenchmarkSurveyType.NCS;
    case Gql.BenchmarkSurveyTypes.NCS_OA:
      return BenchmarkSurveyType.NCS_OA;
    case Gql.BenchmarkSurveyTypes.NES:
      return BenchmarkSurveyType.NES;
    case Gql.BenchmarkSurveyTypes.NLES:
      return BenchmarkSurveyType.NLES;
    case Gql.BenchmarkSurveyTypes.NES_LE:
      return BenchmarkSurveyType.NES_LE;
    case Gql.BenchmarkSurveyTypes.NPSS:
      return BenchmarkSurveyType.NPSS;
    case Gql.BenchmarkSurveyTypes.ARPA_RESIDENT:
      return BenchmarkSurveyType.ARPA_RESIDENT;
    case Gql.BenchmarkSurveyTypes.ARPA_BUSINESS:
      return BenchmarkSurveyType.ARPA_BUSINESS;
    case Gql.BenchmarkSurveyTypes.NCI:
      return BenchmarkSurveyType.NCI;
  }
}

export function clientSurveyTypeToGql(
  type: BenchmarkSurveyType
): Gql.BenchmarkSurveyTypes {
  switch (type) {
    case BenchmarkSurveyType.CASOA:
      return Gql.BenchmarkSurveyTypes.CASOA;
    case BenchmarkSurveyType.NBS:
      return Gql.BenchmarkSurveyTypes.NBS;
    case BenchmarkSurveyType.NCS:
      return Gql.BenchmarkSurveyTypes.NCS;
    case BenchmarkSurveyType.NCS_OA:
      return Gql.BenchmarkSurveyTypes.NCS_OA;
    case BenchmarkSurveyType.NES:
      return Gql.BenchmarkSurveyTypes.NES;
    case BenchmarkSurveyType.NLES:
      return Gql.BenchmarkSurveyTypes.NLES;
    case BenchmarkSurveyType.NES_LE:
      return Gql.BenchmarkSurveyTypes.NES_LE;
    case BenchmarkSurveyType.NPSS:
      return Gql.BenchmarkSurveyTypes.NPSS;
    case BenchmarkSurveyType.ARPA_BUSINESS:
      return Gql.BenchmarkSurveyTypes.ARPA_BUSINESS;
    case BenchmarkSurveyType.ARPA_RESIDENT:
      return Gql.BenchmarkSurveyTypes.ARPA_RESIDENT;
    case BenchmarkSurveyType.NCI:
      return Gql.BenchmarkSurveyTypes.NCI;
  }
}

export namespace BenchmarkTypeTx {
  export function gqlToClient(b: GqlBenchmarkType): BenchmarkType {
    return {
      type: maybeMap(b.type, gqlToSurveyType),
      weighted: b.weighted,
    };
  }
}
