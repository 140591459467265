import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useId } from 'react-use-id-hook';
import './styles.scss';
import {
  AppLink,
  EventPropagationStopper,
  ExpandMode,
  MaterialIcon,
  MaterialIconName,
  Pill,
  PillTypes,
  Well,
  WellType,
} from 'client/shared/components/base';
import { BenchmarkIndicator } from 'client/shared/components/benchmark-indicator';
import {
  MMMM_YEAR_FORMAT,
  YEAR_FORMAT,
  ActivationState,
  VisualizationType,
  SavedVisualizationFilterStates,
  TrackDatum,
  TrackVariable,
  FipsArea,
  VariableDisplayType,
  NationalDataFips,
} from 'core';
import { DifferenceIndicator } from 'client/shared/components/difference-indicator';
import { CollapseBtn } from 'client/shared/components/base/collapse-btn';
import {
  BenchmarkFilter,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  analyticsBenchmarkToClient,
  formatIndicatorValue,
  getFilteredBenchmarkFooter,
  getFilteredBenchmarkValue,
} from 'client/shared/core/performance-data';
import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { useGenerateImage } from 'client/shared/hooks';
import { Tooltip } from '../tooltip';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { EmbedLogoFooter } from '../embed-logo-footer';
import { EmbedDataPointContext } from '../visualization-picker';
import { DownloadImageButton } from '../download-image';
import { demographicSegmentLabel } from '../domain-card-indicator';
import { TrackLineChart } from '../domain-indicators-group/domain-indicators-group-line-chart';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group/domain-indicators-group-wrapper';
import { GqlGoal } from 'client/admin/hooks/use-track-goals';
import { useAdminPermissions } from 'client/admin/hooks/use-admin-permissions';
import { PermissionType } from '@polco-us/types';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import { ManageGoalModal } from 'client/admin/track/overview/components/manage-goal-modal';
import { AIQuickAction } from '../ai-quick-actions';

export interface Props {
  readonly benchmarkFilter: BenchmarkFilter;
  readonly className?: string;
  readonly variable: TrackVariable;
  readonly currentFips: Pick<FipsArea, 'id'>;
  readonly expanded?: boolean;
  readonly toggleExpanded?: (variableId: string) => void;
  readonly embedContext?: EmbedDataPointContext;
  readonly showFooter?: boolean;
  readonly saveVisualization?: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly displayType?: VariableDisplayType;
  readonly comparisonGroupId?: string;
  readonly goal?: GqlGoal;
  readonly planId?: string;
  readonly publishingEntityId: string | null;
  readonly hideAiQuickAction?: boolean;
  readonly hidePlaceName?: boolean;
}

const baseClass = 'pn-domain-community-statistic';

const communityStatisticsCopy = {
  differenceIndicator:
    "This number represents the change in this indicator's value since the previous measurement.",
  dataUnavailable: 'Data unavailable',
  chartWell: (description: string | null, fipsName?: string) => {
    return (
      <>
        This chart illustrates the change over time in
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  noChartText: (description: string | null, fipsName?: string) => {
    return (
      <>
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  indicatorMetaData: (args: {
    readonly source: string;
    readonly recordedAt: Date | null;
    readonly dateLevel: DateLevel | null;
  }) => {
    const dateFormat =
      args.dateLevel === DateLevel.YEAR ? YEAR_FORMAT : MMMM_YEAR_FORMAT;
    return (
      <div
        className={`${baseClass}-indicator-metadata font-size-xs mt-3 display-flex justify-content-end`}
      >
        <div>
          Data from <span className="font-weight-bold">{args.source}</span>
          {args.recordedAt && (
            <>
              {' in '}
              <span className="font-weight-bold">
                {moment.utc(args.recordedAt).format(dateFormat)}
              </span>
            </>
          )}
        </div>
      </div>
    );
  },
};

export const CommunityStatisticsCard: React.FC<Props> = (p) => {
  const communityStatisticsId = useId();
  const ref = React.useRef<HTMLDivElement>(null);
  const generateImage = useGenerateImage(ref, {
    imageName: `${p.variable.label} indicator`,
  });
  const [goalModalOpen, setGoalModalOpen] = React.useState(false);
  const variable = p.variable;
  const demographicSegment = variable.demographicSegment;
  const { doesCurrentAdminHavePermissions: havePermissions } = useAdminPermissions();
  const canManageGoals = havePermissions([PermissionType.MANAGE_TRACK_GOALS]);
  const goalsEnabled = useFlagEnabled(KnownFlag.TRACK_GOALS);

  const sortedValues = _.reverse(
    _.sortBy(
      variable.areasData.find((ad) => ad.fipsArea.id === p.currentFips.id)
        ?.performanceData,
      ({ recordedAt }) => recordedAt
    )
  );

  const recentValue =
    sortedValues.length && sortedValues[0].value !== null ? sortedValues[0] : null;

  const lineData: readonly DomainIndicatorsFipsAreaRow[] = React.useMemo(
    () =>
      _.compact([
        recentValue
          ? {
              id: variable.id,
              rank: 0,
              dataAreaFips: recentValue.fipsArea.id,
              name: recentValue.fipsArea.name,
              color: '#4e61ba',
              recentDatum: recentValue,
              trackAreaData: {
                fipsArea: recentValue.fipsArea,
                performanceData: sortedValues,
              },
              variableName: variable.name,
              variableValueType: variable.valueType,
              variableDateLevel: variable.dateLevel,
              variableDirection: variable.direction,
            }
          : null,
      ]),
    [variable, recentValue, sortedValues]
  );

  const lineVisibility = React.useMemo(
    () => (recentValue ? { [recentValue.fipsArea.id]: true } : null),
    [recentValue]
  );

  const [hoverLine, setHoverLine] = React.useState<string | null>(null);

  const embeddableTrackEnabled =
    !p.canNotManageDataPoints &&
    p.embedContext !== EmbedDataPointContext.VISUALIZATION;

  if (!p.variable.areasData.length) {
    return (
      <div className={`${baseClass} ${p.className || ''} p-3`}>
        <div className="font-weight-bold font-size-lg text-gray-40">
          {communityStatisticsCopy.dataUnavailable}
        </div>
      </div>
    );
  }

  const isMultiplePointsData = sortedValues.length > 1;

  const benchmarkValue = recentValue
    ? getFilteredBenchmarkValue(recentValue, p.benchmarkFilter)
    : null;

  const footer =
    p.currentFips.id !== NationalDataFips && variable.direction
      ? getFilteredBenchmarkFooter(p.benchmarkFilter)
      : undefined;
  const allAreas = p.comparisonGroupId
    ? variable.areasData.map((ad) => ad.fipsArea.id)
    : [p.currentFips.id];
  return (
    <div
      className={`${baseClass} ${p.className || ''} p-3 ${
        p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''
      }`}
      id={communityStatisticsId}
      ref={ref}
    >
      <div className="d-flex justify-content-between">
        <div
          className={`${baseClass}-title-container d-flex flex-wrap justify-content-between align-items-start`}
        >
          <div className="d-flex align-items-start flex-column justify-content-start">
            <div className={`font-weight-bold font-size-lg mr-4`}>
              {variable.label}
            </div>
            {p.displayType === VariableDisplayType.OLDER_ADULTS && (
              <div
                className={`${baseClass}-demographic-segment d-flex d-row flex-wrap justify-content-start`}
              >
                <Pill
                  className="segment"
                  key={demographicSegment}
                  type={PillTypes.FACET}
                >
                  {demographicSegmentLabel(demographicSegment)}
                </Pill>
              </div>
            )}
            {benchmarkValue && p.variable.direction && (
              <BenchmarkIndicator
                benchmarkValue={analyticsBenchmarkToClient(benchmarkValue)}
                className={`${baseClass}-benchmark-indicator my-1`}
                expandMode={ExpandMode.ALWAYS_EXPANDED}
              />
            )}
          </div>
        </div>
        <div className="d-flex">
          {embeddableTrackEnabled &&
          p.saveVisualization &&
          recentValue &&
          p.currentFips ? (
            <DomainCardOptionsMenu
              benchmarkFilter={p.benchmarkFilter}
              canManageGoals={canManageGoals && goalsEnabled}
              canNotManageDataPoints={p.canNotManageDataPoints}
              currentFips={p.currentFips.id}
              disableSaveVisualization={p.disableSaveVisualization}
              embeddableTrackEnabled={embeddableTrackEnabled}
              events={{
                generateImage,
                saveVisualization: p.saveVisualization,
                setGoalModalOpen,
              }}
              goal={p.goal}
              groupFips={allAreas}
              imageDownloadEnabled
              planId={p.planId}
              recentValue={recentValue}
              variable={variable}
              visualizationType={VisualizationType.LINE}
            />
          ) : (
            <DownloadImageButton
              buttonClassName={`save-image-track-community-statistics-${variable.name.replace(/\s/g, '')}`}
              imageRef={ref}
              name={`${variable.label} Indicator`}
            />
          )}
          {!!p.toggleExpanded && (
            <CollapseBtn
              action={() => p.toggleExpanded?.(variable.id)}
              ariaControls={communityStatisticsId}
              expanded={!!p.expanded}
            />
          )}
        </div>
      </div>
      {p.showFooter && (
        <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
      )}
      {!p.hideAiQuickAction && (
        <AIQuickAction className="my-1" promptKey={variable.name} />
      )}
      <div
        className={`${baseClass}-data d-flex flex-row flex-wrap justify-content-between align-items-center`}
      >
        {recentValue ? (
          <>
            <div className={`d-flex flex-row align-items-center`}>
              <div
                className={`${baseClass}-indicator-value font-weight-bold mr-2 text-liberty`}
              >
                {formatIndicatorValue(recentValue.value, variable.valueType)}
              </div>
              <div className="font-size-sm text-gray-40">{variable.suffix}</div>
              {recentValue.previousValue && (
                <DifferenceIndicator
                  className="ml-2 cursor-pointer"
                  difference={recentValue.value - recentValue.previousValue?.value}
                  isNeutralPalette
                  tooltip={communityStatisticsCopy.differenceIndicator}
                  tooltipId={`difference-indicator-information-${variable.name}`}
                  valueType={variable.valueType}
                  variableDateLevel={variable.dateLevel}
                />
              )}
              {recentValue.fipsArea.id !== p.currentFips.id && !p.hidePlaceName && (
                <Tooltip
                  content={`Data from ${recentValue.fipsArea.name}`}
                  id={`demographics-${p.currentFips}-${variable.name}`}
                >
                  <MaterialIcon
                    ariaLabel={`Data from ${recentValue.fipsArea.name}`}
                    className="font-size-lg ml-2"
                    icon={MaterialIconName.INFO_OUTLINE}
                  />
                </Tooltip>
              )}
            </div>
          </>
        ) : (
          <div
            className={`${baseClass}-data-unavailable font-weight-bold text-gray-40`}
          >
            {communityStatisticsCopy.dataUnavailable}
          </div>
        )}
      </div>
      {!!p.expanded && (
        <div className="mt-2">
          {(!!p.goal || isMultiplePointsData) && (
            <TrackLineChart
              data={lineData}
              fipsAreaVisibility={lineVisibility ?? {}}
              goalLine={p.goal?.value}
              hidePlaceName={p.hidePlaceName}
              hoverLine={hoverLine}
              setHoverLine={setHoverLine}
              variable={variable}
            />
          )}
          <Well
            className="mb-0"
            noTopMargin
            type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
          >
            {isMultiplePointsData
              ? communityStatisticsCopy.chartWell(
                  variable.description,
                  p.hidePlaceName ? undefined : recentValue?.fipsArea.name
                )
              : communityStatisticsCopy.noChartText(
                  variable.description,
                  recentValue?.fipsArea.name
                )}
          </Well>
        </div>
      )}
      {variable.source !== null &&
        communityStatisticsCopy.indicatorMetaData({
          source: variable.source,
          recordedAt: recentValue?.recordedAt ?? null,
          dateLevel: variable.dateLevel,
        })}
      <DownloadImageFooter footer={footer} />
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && (
        <EmbedLogoFooter className={p.expanded ? 'pt-2' : ''} />
      )}
      {p.planId && p.publishingEntityId && (
        <ManageGoalModal
          currentGoalData={p.goal}
          events={{
            onGoalSaved: () => {
              if (!p.expanded) {
                p.toggleExpanded?.(variable.id);
              }
            },
            cancel: () => setGoalModalOpen(false),
          }}
          isOpen={goalModalOpen}
          planId={p.planId}
          publishingEntityId={p.publishingEntityId}
          suffix={variable.suffix}
          valueType={variable.valueType}
          variableId={variable.id}
          variableLabel={variable.label}
        />
      )}
    </div>
  );
};

interface DomainCardOptionsMenuProps {
  readonly imageDownloadEnabled: boolean;
  readonly currentFips?: string;
  readonly groupFips: readonly string[];
  readonly embeddableTrackEnabled: boolean;
  readonly recentValue?: TrackDatum;
  readonly variable: TrackVariable;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly visualizationType: VisualizationType | null;
  readonly events: {
    readonly generateImage?: () => Promise<void>;
    readonly saveVisualization?: (
      clientInput: CreateSavedVisualizationInputWithMultipleFips
    ) => Promise<void>;
    readonly setGoalModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly className?: string;
  readonly comparisonGroupId?: string;
  readonly canManageGoals?: boolean;
  readonly goal?: GqlGoal;
  readonly planId?: string;
}

export const DomainCardOptionsMenu: React.FC<DomainCardOptionsMenuProps> = (
  props
) => {
  const { generateImage, saveVisualization, setGoalModalOpen } = props.events;

  const showEnabledSaveDataPointsMenuItem =
    !!props.visualizationType &&
    !props.canNotManageDataPoints &&
    props.embeddableTrackEnabled &&
    !!saveVisualization &&
    !props.disableSaveVisualization;

  const showDisabledSaveDataPointsMenuItem =
    !!props.visualizationType &&
    !props.canNotManageDataPoints &&
    props.embeddableTrackEnabled &&
    !!saveVisualization &&
    props.disableSaveVisualization;

  const showGoalMenuItem =
    props.canManageGoals && !!setGoalModalOpen && !!props.planId;
  return (
    <BsDropdown className={`no-show-in-image ${props.className || ''}`}>
      <EventPropagationStopper>
        <div className={`${baseClass}-toggle-wrapper`}>
          <BsDropdown.Toggle
            className={`${baseClass}-action-toggle p-0 m-0`}
            variant="link"
          >
            <MaterialIcon
              className={`${baseClass}-action-toggle-icon`}
              icon={MaterialIconName.MORE_VERT}
            />
          </BsDropdown.Toggle>
        </div>
      </EventPropagationStopper>

      <BsDropdown.Menu alignRight className={`${baseClass}-options-menu`}>
        <EventPropagationStopper>
          {props.imageDownloadEnabled && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-image-track-community-statistics-${props.variable.name.replace(/\s/g, '')}`}
              onClick={generateImage}
            >
              <div>{DROPDOWN_COPY.image}</div>
            </BsDropdown.Item>
          )}
          {showEnabledSaveDataPointsMenuItem && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-data-point`}
              disabled={props.disableSaveVisualization}
              onClick={async () => {
                await saveVisualization({
                  baseFips: props.currentFips ?? null,
                  recordedAt: props.recentValue?.recordedAt ?? null,
                  benchmarkFilter: props.benchmarkFilter,
                  state: ActivationState.ACTIVE,
                  variableId: props.variable.id,
                  visualizationType: props.visualizationType ?? null,
                  groupFips: props.groupFips,
                  comparisonGroupId: props.comparisonGroupId,
                });
              }}
            >
              <div>{DROPDOWN_COPY.dataPoint}</div>
            </BsDropdown.Item>
          )}
          {showGoalMenuItem && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-goal`}
              onClick={async () => {
                setGoalModalOpen(true);
              }}
            >
              <div>{DROPDOWN_COPY.goal}</div>
            </BsDropdown.Item>
          )}
          {showDisabledSaveDataPointsMenuItem && (
            <Tooltip
              className={`${baseClass}-tooltip font-weight-normal`}
              content=""
              htmlContent={
                <div>
                  You have exceeded your maximum number of active saved data points.
                  Edit your{' '}
                  <AppLink
                    className="text-white"
                    to={ClientUrlUtils.admin.savedDataPoints.path({
                      tab: SavedVisualizationFilterStates.ACTIVE,
                    })}
                  >
                    Saved Data Points
                  </AppLink>{' '}
                  to make room for more.
                </div>
              }
              id="visualization-explainer"
              place="bottom"
            >
              <BsDropdown.Item
                className={`${baseClass}-dropDown-item save-data-point`}
                disabled={props.disableSaveVisualization}
              >
                <div>{DROPDOWN_COPY.dataPoint}</div>
              </BsDropdown.Item>
            </Tooltip>
          )}
        </EventPropagationStopper>
      </BsDropdown.Menu>
    </BsDropdown>
  );
};

const DROPDOWN_COPY = {
  image: 'Save as Image',
  dataPoint: 'Save Data Point',
  goal: 'Add/Edit Goal',
};

CommunityStatisticsCard.displayName = 'CommunityStatisticsCard';
