import * as React from 'react';
import './styles.scss';
import _ from 'lodash';
import {
  ActionLink,
  AppLink,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { Popover } from 'client/shared/components/base/popover';
import { ViewInNewTab } from 'client/admin/shared/components/view-new-tab';
import { getConfig } from 'client/shared/core/config';
import { MailTo } from 'client/shared/components/mail-to';

const knowledgeBaseUrl = getConfig().knowledgeBaseUrl;
const SUCCESS_EMAIL = 'success@polco.us';
const baseClass = 'pn-help-center';

const HELP_CENTER_COPY = {
  suggestedArticles: 'Suggested Articles',
  title: 'Help',
  knowledgeBase: 'Knowledge Base',
  support: 'Email Support',
  emailSubject: 'Polco support request',
};

export const HelpCenterDropdown: React.FC<{ readonly className?: string }> = (p) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef}>
      <Popover
        containerClassName={`${baseClass}-tooltip`}
        containerParent={containerRef.current || undefined}
        content={<HelpCenterBody isOpen={isOpen} setIsOpen={setIsOpen} />}
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        onEscape={() => setIsOpen(false)}
        positions={['bottom']}
      >
        <div className={`${baseClass}-action-container`}>
          <ActionLink
            action={() => setIsOpen(!isOpen)}
            className={`${baseClass}-selector-button`}
          >
            {p.children}
          </ActionLink>
        </div>
      </Popover>
    </div>
  );
};

const HelpCenterBody: React.FC<{
  readonly isOpen: boolean;
  readonly setIsOpen: (open: boolean) => void;
}> = (p) => {
  const helpLinks: readonly HelpActionProps[] = _.compact([
    {
      actionOrUrl: knowledgeBaseUrl,
      title: HELP_CENTER_COPY.knowledgeBase,
      newTab: true,
    },
    {
      actionOrUrl: null,
      title: HELP_CENTER_COPY.support,
      mailTo: SUCCESS_EMAIL,
    },
  ]);

  return (
    <div className={`${p.isOpen ? 'p-2' : 'd-none'}`}>
      {helpLinks.map((link) => (
        <HelpAction {...link} key={link.title} />
      ))}
    </div>
  );
};

type ActionURLOrMailTo =
  | {
      readonly actionOrUrl: string | (() => void);
      readonly mailTo?: undefined;
    }
  | {
      readonly actionOrUrl: null;
      readonly mailTo: string;
    };
type HelpActionProps = ActionURLOrMailTo & {
  readonly className?: string;
  readonly title: string;
  readonly newTab?: boolean;
};

const HelpAction: React.FC<HelpActionProps> = (p) => {
  return (
    <div
      className={`font-size-sm p-2 ${baseClass}-dropdown-item ${p.className ?? ''}`}
    >
      {typeof p.actionOrUrl === 'function' ? (
        <ActionLink action={p.actionOrUrl} className="text-jungle">
          {p.newTab ? <ViewInNewTab copy={p.title} hasHover /> : p.title}
        </ActionLink>
      ) : p.mailTo ? (
        <MailTo
          boldFont
          className="text-jungle"
          email={p.mailTo}
          subject={HELP_CENTER_COPY.emailSubject}
        >
          {p.title}
          <MaterialIcon className="ml-1" icon={MaterialIconName.OPEN_IN_NEW} />
        </MailTo>
      ) : (
        p.actionOrUrl && (
          <AppLink
            className="text-jungle"
            target={p.newTab ? '_blank' : undefined}
            to={p.actionOrUrl}
            useIcon
          >
            {p.title}
          </AppLink>
        )
      )}
    </div>
  );
};
