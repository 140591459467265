import {
  ActivationState,
  AnalyticsDomainType,
  AnalyticsVariable,
  ApiDate,
  ClientSavedVisualization,
  ClientVisualizationId,
  CreateVisualizationInputWithMultipleFips,
  DistributionBin,
  DistributionCoreData,
  ElementType,
  VariableDirection,
  FipsAreaWithShortName,
  SafeRecordDictionary,
  Optional,
} from 'core';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { domainLabels } from 'client/shared/core/track/labels';
import { TrackVariableTx } from './track-variables';
import { MultiPolygon } from '@turf/helpers';
import { transformGqlMultiPolygonToClientMultiPolygon } from 'client/admin/shared/containers/maps/common';
type GqlVisualization = NonNullable<
  Gql.AdminPublishingEntityVisualizations['openPublishingEntityById']
>['trackVariableVisualizations'][0];

export namespace PerformanceDataTx {
  export function gqlPerformanceDatumDistribtuionToClient(
    distributions:
      | Gql.TrackVariableWithDistribution['areasData'][0]['performanceData'][0]['distributions']
      | null
  ): readonly DistributionCoreData[] {
    return distributions
      ? distributions.map((dist) => {
          return {
            distributionId: dist.id,
            bins: gqlDistributionBinsToClient(dist.bins),
            comparisonGroupId: dist.comparisonGroup ? dist.comparisonGroup.id : null,
            filter: dist.benchmarkFilter,
            geoType: dist.geoType,
          };
        })
      : [];
  }

  function gqlDistributionBinsToClient(
    bins:
      | Gql.TrackVariableWithDistribution['areasData'][0]['performanceData'][0]['distributions'][0]['bins']
      | null
  ): readonly DistributionBin[] {
    return bins
      ? bins.map((bin) => {
          return {
            id: bin.id,
            percentile: bin.percentile,
            percentValue: bin.percentValue,
            count: bin.count,
          };
        })
      : [];
  }

  export function gqlFipsAreaToClient(
    fipsArea: Gql.TrackVariableWithDistribution['areasData'][0]['area']
  ): FipsAreaWithShortName {
    return {
      id: fipsArea.id,
      name: fipsArea.name,
      type: fipsArea.type,
      shortName: null,
    };
  }

  export function gqlFipsAreaWithShortNameToClient(
    pubWithFipsArea: Gql.AdminPublishingEntityComparisonGroups['openPublishingEntityById']
  ): FipsAreaWithShortName | null {
    if (!pubWithFipsArea) {
      return null;
    }
    const { fipsArea } = pubWithFipsArea;
    if (!fipsArea) {
      return null;
    }
    if (pubWithFipsArea.systemType === 'DEMO_PUBLISHER') {
      return {
        // Use publisher name for demo publishers to obscure place
        name: pubWithFipsArea.name.defaultValue,
        shortName: pubWithFipsArea.name.defaultValue,
        id: fipsArea.id,
        type: fipsArea.type,
      };
    }
    return {
      id: fipsArea.id,
      name: fipsArea.name,
      shortName: fipsArea.shortName,
      type: fipsArea.type,
    };
  }

  export function gqlVariableToClient(
    variable: Gql.PerformanceDatum_Variable
  ): AnalyticsVariable {
    return {
      id: variable.id,
      name: variable.name,
      description: variable.description,
      label: variable.label,
      source: variable.source,
      suffix: variable.suffix,
      domains: variable.domains.map((d) => gqlVariableDomaintoClient(d)),
      statisticType: variable.statisticType,
      valueType: variable.valueType,
      dateLevel: variable.dateLevel,
      includedInIndex: variable.includedInIndex,
      direction: gqlVariableDirectionToClient(variable.direction),
      isDefault: variable.isDefault,
      isOA: variable.isOA,
      demographicSegment: variable.demographicSegment,
    };
  }
  export function gqlVariableDirectionToClient(
    direction: Gql.TrackVariableDirection | null
  ): VariableDirection | null {
    switch (direction) {
      case Gql.TrackVariableDirection.POS:
        return VariableDirection.POS;
      case Gql.TrackVariableDirection.NEG:
        return VariableDirection.NEG;
      case null:
        return null;
    }
  }

  export type ClientVisualizationVariable = Pick<
    AnalyticsVariable,
    'id' | 'domains' | 'name' | 'label' | 'description' | 'statisticType'
  >;

  export function gqlVariableDomaintoClient(
    domain: ElementType<Gql.PerformanceDatum_Variable['domains']>
  ): AnalyticsDomainType {
    return {
      domain: domain.domain,
      subdomain: domain.subdomain,
    };
  }

  export function activationState_toGql(
    state: ActivationState
  ): Gql.SavedVisualizationActivationState {
    switch (state) {
      case ActivationState.ACTIVE:
        return Gql.SavedVisualizationActivationState.ACTIVE;
      case ActivationState.INACTIVE:
        return Gql.SavedVisualizationActivationState.INACTIVE;
    }
  }
  function activationState_toClient(
    state: Gql.SavedVisualizationActivationState
  ): ActivationState {
    switch (state) {
      case Gql.SavedVisualizationActivationState.ACTIVE:
        return ActivationState.ACTIVE;
      case Gql.SavedVisualizationActivationState.INACTIVE:
        return ActivationState.INACTIVE;
    }
  }

  export function multipleFipsSavedVisualizationInput_toGql(
    pubId: string,
    clientInput: CreateVisualizationInputWithMultipleFips
  ): Gql.TrackVariableVisualizationInput {
    return {
      baseFips: clientInput.baseFips,
      publishingEntityId: pubId,
      recordedAt: ApiDate.toApi(clientInput.recordedAt),
      benchmarkFilter: clientInput.benchmarkFilter,
      state: activationState_toGql(clientInput.state),
      variableId: clientInput.variableId,
      visualizationType: clientInput.visualizationType,
      groupFips: clientInput.groupFips,
      comparisongroupId: clientInput.comparisonGroupId,
    };
  }

  // export function gqlVisualizationVariable_toClient(
  //   variable: GqlVisualization['variable']
  // ): ClientVisualizationVariable {
  //   return {
  //     id: variable.id,
  //     name: variable.name,
  //     domains: variable.domains.map((d) => gqlVariableDomaintoClient(d)),
  //     label: variable.label,
  //     description: variable.description,
  //   };
  // }

  export function gqlVisualization_toClientBase(
    visualization: Pick<GqlVisualization, 'id' | 'state'>
  ): Pick<ClientSavedVisualization, 'id' | 'state'> {
    return {
      id: visualization.id as ClientVisualizationId,
      state: activationState_toClient(visualization.state),
    };
  }

  export function gqlVisualization_toClient(
    visualization: Optional<GqlVisualization, 'boundingBox'>
  ): ClientSavedVisualization {
    const variables = visualization.trackVariables.map(
      TrackVariableTx.gqlTrackVariableWithDistributionsToClient
    );

    const currentFipsArea = variables
      .at(0)
      ?.areasData.find((ad) => ad.fipsArea.id === visualization.fips)?.fipsArea;

    const title = `Track > ${domainLabels[variables[0].domains[0].domain]} > ${
      variables[0].label
    }${currentFipsArea ? ` - ${currentFipsArea.shortName ?? currentFipsArea.name}` : ''}`;

    const areas: SafeRecordDictionary<string, MultiPolygon> = {};

    if (visualization.visualizationAreas) {
      visualization.visualizationAreas.forEach((fipsValue) => {
        if (fipsValue.shape) {
          areas[fipsValue.id] =
            transformGqlMultiPolygonToClientMultiPolygon(fipsValue.shape) ??
            undefined;
        }
      });
    }

    return {
      id: visualization.id as ClientVisualizationId,
      title,
      baseFips: visualization.fips,
      benchmarkFilter: visualization.benchmarkFilter,
      recordedAt: ApiDate.fromApi(visualization.recordedAt),
      publishingEntityId: visualization.publishingEntityId,
      trackVariables: variables.map((v) => v),
      state: activationState_toClient(visualization.state),
      visualizationType: visualization.visualizationType,
      mapBoundCoordinates: visualization.boundingBox ?? undefined,
      comparisonGroupId: visualization.comparisonGroupId ?? undefined,
      placeShapeByFips: areas,
    };
  }
}
