/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './styles.scss';
import { KeyPressKey } from 'client/shared/core/types';

export interface Props {
  readonly id: string;
  readonly content: string;
  readonly htmlContent?: React.ReactElement;
  readonly children: React.ReactElement;
  readonly disable?: boolean;
  readonly className?: string;
  readonly place?: 'top' | 'right' | 'bottom' | 'left';
  readonly anchorClassName?: string;
  readonly isOpen?: boolean;
  readonly setIsOpen?: (value: boolean) => void;
}

const baseClass = 'pn-tooltip';

export const Tooltip: FC<Props> = ({
  id,
  content,
  htmlContent,
  className,
  children,
  place,
  disable,
  anchorClassName,
  isOpen: customIsOpen,
  setIsOpen: customSetIsOpen,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KeyPressKey.ENTER) {
      setIsOpen(true);
    }
    if (e.key === KeyPressKey.ESCAPE) {
      setIsOpen(false);
    }
  };
  if (disable) {
    return <>{children}</>;
  }

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      <a
        aria-describedby={isOpen ? id : undefined}
        aria-label={content}
        className={`${baseClass}-anchor ${anchorClassName || ''}`}
        data-tooltip-id={id}
        href={customSetIsOpen ? undefined : '#'}
        onClick={(e) => e.preventDefault()}
        onKeyDown={onKeyDown}
      >
        {children}
      </a>
      <ReactTooltip
        className={
          (className ?? '') +
          // Fixes invisible text in Safari;
          // some bug with how it handles opacity animations.
          // I couldn't find anything in react-tooltip's Issues.
          isSafari
            ? 'disable-transition'
            : ''
        }
        clickable={true}
        id={id}
        isOpen={customIsOpen ?? isOpen}
        place={place ?? 'top'}
        setIsOpen={customSetIsOpen ?? setIsOpen}
        variant="dark"
      >
        {htmlContent || content}
      </ReactTooltip>
    </>
  );
};
Tooltip.displayName = 'Tooltip';
