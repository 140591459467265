import * as React from 'react';
import { WithLocationSelector } from '../containers/with-location-selector';
import { useTrackVariables } from 'client/shared/hooks/use-track-variables';
import { StatisticType } from 'core';
import { AdminPageLoader } from 'client/admin/shared/components/page-loader';
import { TrackVariablesTable } from '../components/track-variables-table';
import { ReportCardTabBar } from '../components/report-card-tab-bar';

export const RentBurdenScorecard: React.FC = () => {
  return (
    <>
      <h1 className="mb-4">Rent Burden Report</h1>
      <p className="mb-2">
        <b>Explore, Contribute, and Innovate for a Thriving Community:</b> This
        report provides a concise snapshot of key community metrics related to
        housing and rent burden. This report combines reliable data sources to
        highlight measurable outcomes such as local employment rates, housing
        availability, health indicators, and public safety statistics. Designed for
        transparency and engagement, the report card aims to inform residents and
        guide decision-makers by presenting objective, actionable data that impacts
        the community's quality of life.
      </p>
      <WithLocationSelector>
        {({ fipsCode, fipsName }) => {
          return (
            <>
              <h2 className="my-4">Rent Burden Scorecard - {fipsName}</h2>
              {fipsCode && <RentBurdenScorecardReport fipsCode={fipsCode} />}
            </>
          );
        }}
      </WithLocationSelector>
    </>
  );
};

const RentBurdenScorecardReport: React.FC<{
  readonly fipsCode: string;
}> = (p) => {
  const [selectedVariableName, setSelectedVariableName] = React.useState<
    string | null
  >(null);

  const { trackVariables, loading } = useTrackVariables({
    fipsCodes: [p.fipsCode],
    variableNames: [
      'overall_homeless_rate_per_100k',
      'housing_burden_rate',
      'multifamily_pct',
      'housing_burden_owner_rate',
      'housing_overcrowding_rate',
      'median_home_value',
    ],
    type: StatisticType.INDICATOR,
    pubId: null,
  });

  React.useEffect(() => {
    setSelectedVariableName(trackVariables[0]?.name ?? null);
  }, [trackVariables[0]?.name]);

  const currentVariable = trackVariables.find(
    (variable) => variable.name === selectedVariableName
  );

  return loading ? (
    <AdminPageLoader />
  ) : (
    <div>
      <TrackVariablesTable
        selectedVariableName={selectedVariableName}
        setSelectedVariableName={setSelectedVariableName}
        trackVariables={trackVariables}
      />
      {selectedVariableName && (
        <ReportCardTabBar
          fipsCode={p.fipsCode}
          ideasCopy={ideas}
          trackVariable={currentVariable ?? null}
        />
      )}
    </div>
  );
};

const ideas: Record<string, React.ReactNode> = {
  multifamily_pct: (
    <div>
      <p className="font-size-lg mb-2">Availability of multifamily housing</p>
      <ul>
        <li>Update zoning codes to allow higher-density and mixed-use housing.</li>
        <li>
          Implement inclusionary zoning to require affordable multifamily units
          within new developments.
        </li>
        <li>
          Offer tax credits, subsidies, and density bonuses for affordable housing
          projects.
        </li>
        <li>Streamline permitting and reduce fees for multifamily developments.</li>
        <li>
          Redevelop vacant or underutilized properties for multifamily housing.
        </li>
        <li>
          Use public-private partnerships and collaborate with nonprofits to share
          costs and risks.
        </li>
        <li>
          Apply for federal/state housing grants and utilize tax-credit programs.
        </li>
        <li>
          Promote awareness and acceptance of multifamily housing, involving
          residents in planning.
        </li>
        <li>
          Develop specialized multifamily housing for seniors and workforce
          populations near needed services.
        </li>
      </ul>
    </div>
  ),
  overall_homeless_rate_per_100k: (
    <div>
      <p className="font-size-lg mb-2">Homelessness rate</p>
      <ul>
        <li>Increase access to subsidized and low-cost housing options.</li>
        <li>
          Provide mental health, addiction recovery, and job training programs
          tailored to at-risk individuals.
        </li>
        <li>
          Prioritize placing people in stable housing before addressing other
          challenges.
        </li>
        <li>Improve capacity and resources at emergency shelters.</li>
        <li>
          Partner with organizations experienced in addressing homelessness to
          amplify impact.
        </li>
        <li>
          Leverage federal programs like HUD grants to support local homelessness
          initiatives.
        </li>
      </ul>
    </div>
  ),
  housing_burden_owner_rate: (
    <div>
      <p className="font-size-lg mb-2">Housing cost burden for homeowners</p>
      <ul>
        <li>
          Offer property tax relief programs for low- and moderate-income homeowners.
        </li>
        <li>
          Expand access to low-interest loans or grants for home repairs and energy
          efficiency upgrades.
        </li>
        <li>
          Promote financial education programs to help homeowners better manage
          housing costs.
        </li>
        <li>
          Develop shared equity or community land trust programs to reduce housing
          costs.
        </li>
        <li>
          Advocate for federal and state housing subsidies to assist homeowners.
        </li>
        <li>
          Encourage local policies that cap property tax increases for long-time
          homeowners.
        </li>
      </ul>
    </div>
  ),
  housing_burden_rate: (
    <div>
      <p className="font-size-lg mb-2">Housing Cost Burden for Renters</p>
      <ul>
        <li>Simplify permitting for multifamily apartments.</li>
        <li>
          Banking land and releasing it annually at rate to keep jobs and housing
          aligned.
        </li>
        <li>Offer property tax breaks for landlords who keep rents affordable.</li>
        <li>
          Establish community land trusts to keep housing affordable in perpetuity.
        </li>
        <li>Provide emergency rental assistance to prevent homelessness.</li>
        <li>Use modular housing solutions.</li>
        <li>
          Permit accessory dwelling units (ADUs) and duplexes in single-family zones
          to increase housing supply.
        </li>
        <li>
          Partner with banks and non-profits to offer first-time homebuyer
          assistance.
        </li>
      </ul>
    </div>
  ),
  housing_overcrowding_rate: (
    <div>
      <p className="font-size-lg mb-2">
        Occupied housing units that are overcrowded
      </p>
      <ul>
        <li>
          Promote the development of larger affordable housing units to accommodate
          bigger families.
        </li>
        <li>
          Encourage zoning changes to allow for the construction of accessory
          dwelling units (ADUs).
        </li>
        <li>
          Expand rental assistance programs to help families afford appropriately
          sized homes.
        </li>
        <li>
          Support initiatives to rehabilitate and increase the supply of
          underutilized or vacant properties.
        </li>
        <li>
          Partner with developers to create affordable multifamily housing options in
          high-demand areas.
        </li>
        <li>
          Raise community awareness about housing programs designed to prevent
          overcrowding.
        </li>
      </ul>
    </div>
  ),
  median_home_value: (
    <div>
      <p className="font-size-lg mb-2">Median home value</p>
      <ul>
        <li>
          Increase the development of diverse housing types to balance supply and
          demand.
        </li>
        <li>
          Encourage policies that streamline the approval process for new housing
          projects.
        </li>
        <li>
          Promote public-private partnerships to develop affordable housing
          initiatives.
        </li>
        <li>
          Provide incentives for developers to include affordable units in new
          developments.
        </li>
        <li>
          Advocate for federal and state funding to support affordable housing
          programs.
        </li>
        <li>
          Boost homebuyer assistance programs to make homeownership accessible for
          more people.
        </li>
      </ul>
    </div>
  ),
};
