import React from 'react';
import {
  AiAssistantMessage,
  AiAssistantMessageRole,
  AiAssistantMessageType,
  AiAssistantQueryStatus,
  AiAssistantQueryStatusType,
} from 'client/admin/core';
import _ from 'lodash';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { FetchResult } from '@apollo/client';
import { AdminAiAssistantPostMessage } from 'client/shared/graphql-client/graphql-operations.g';
import {
  Result,
  GqlError,
  PolcoGqlError,
  PolcoGqlErrors,
  SUPPORT_EMAIL,
} from 'core';
import { AiAssistantId } from '@polco-us/types';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MessageInputDisableReason } from '../containers/ai-assistant-container';
import { useAiAssistantData } from './use-ai-assistant-data';
import { useSearchParams } from 'client/shared/hooks';

enum AI_ASSISTANT_ERRORS {
  POST_MESSAGE_API_ERROR = `There was an unexpected error with completing your query.`,
  CANCEL_QUERY_API_ERROR = `There was an unexpected error with canceling your query.`,
  REQUIRE_PUBLISHER_INTERACTIONS_ERROR = `There was an unexpected error.`,
}

interface UseAiAssistantSessionProps {
  readonly assistantId: AiAssistantId;
  readonly events?: {
    readonly onOpenFullScreen?: () => void;
    readonly onCloseDrawer?: () => void;
  };
}

export function useAiAssistantSession(props: UseAiAssistantSessionProps) {
  const { events, assistantId } = props;
  const { states, isSuperAdmin, isLoading } = useAiAssistantData({
    assistantId,
  });
  const searchParams = useSearchParams();
  const hasSystemError = React.useMemo(() => {
    return states
      ? _.some(states.session.conversation.messages, ({ role }) => {
          return role === AiAssistantMessageRole.SYSTEM;
        })
      : false;
  }, [states?.session.conversation.messages]);
  const [isMessageInputDisabled, setMessageInputDisabled] = React.useState<boolean>(
    hasSystemError ||
      !states ||
      states.session.queryStatus?.value?.type ===
        AiAssistantQueryStatusType.PROCESSING
  );

  const { fn: mPostMessage } = useMutationInfo(
    MutationInfos.adminAiAssistantPostMessage
  );

  const { fn: mCancelProcessing } = useMutationInfo(
    MutationInfos.adminAiAssistantCancelProcessing
  );

  async function postMessage(args: {
    sessionId: string;
    message: string;
    setMessages: (value: readonly AiAssistantMessage[]) => void;
    setQueryValue: (value: null | AiAssistantQueryStatus) => void;
    existingMessages: readonly AiAssistantMessage[];
  }) {
    const { sessionId, message, setMessages, setQueryValue, existingMessages } =
      args;
    const postResult = await Result.fromPromise<
      FetchResult<AdminAiAssistantPostMessage>,
      GqlError<PolcoGqlError<PolcoGqlErrors.PolcoApiError>>
    >(
      mPostMessage({
        variables: {
          sessionId,
          messageContent: message,
          assistantId,
        },
      })
    );

    if (Result.isFailure(postResult)) {
      createSystemError({
        errorType: AI_ASSISTANT_ERRORS.POST_MESSAGE_API_ERROR,
        assistantId,
        sessionId: sessionId,
        setMessages,
        existingMessages,
        includeExistingMessages: true,
      });
      setQueryValue(null);
    }
    await states?.session.queryStatus.refetch(sessionId);
  }
  async function cancelProcessing(args: {
    sessionId: string;
    setMessages: (value: readonly AiAssistantMessage[]) => void;
    setQueryValue: (value: null | AiAssistantQueryStatus) => void;
    existingMessages: readonly AiAssistantMessage[];
  }) {
    const { sessionId, setMessages, setQueryValue, existingMessages } = args;
    const postResult = await Result.fromPromise<
      unknown,
      GqlError<PolcoGqlError<PolcoGqlErrors.PolcoApiError>>
    >(
      mCancelProcessing({
        variables: {
          sessionId,
          assistantId,
        },
      })
    );

    if (Result.isFailure(postResult)) {
      createSystemError({
        errorType: AI_ASSISTANT_ERRORS.CANCEL_QUERY_API_ERROR,
        assistantId,
        sessionId: sessionId,
        setMessages,
        existingMessages,
        includeExistingMessages: true,
      });
      setQueryValue(null);
    }
    await states?.session.queryStatus.refetch(sessionId);
  }

  const aiEvents = React.useMemo(() => {
    if (states) {
      const { events: stateEvents, session, onCancelRequest } = states;

      return {
        onSend: async (textContent: string, clearMessageContent: () => void) => {
          stateEvents.isOnSendInProgress.setValue(true);
          setMessageInputDisabled(true);
          session.conversation.setMessages([
            ...session.conversation.messages,
            {
              type: AiAssistantMessageType.SINGLE,
              role: AiAssistantMessageRole.USER,
              content: textContent,
              timestamp: new Date(),
            },
          ]);
          clearMessageContent();
          await postMessage({
            sessionId: session.id,
            message: textContent,
            setMessages: session.conversation.setMessages,
            setQueryValue: session.queryStatus.setValue,
            existingMessages: session.conversation.messages,
          });
          stateEvents.isOnSendInProgress.setValue(false);
        },
        onLoadMore: () => {},
        onCancelProcessing: async () => {
          await cancelProcessing({
            sessionId: session.id,
            setMessages: session.conversation.setMessages,
            setQueryValue: session.queryStatus.setValue,
            existingMessages: session.conversation.messages,
          });
          onCancelRequest();
          session.queryStatus.setValue(null);
        },
        refreshSessionId: async () => {
          stateEvents.isOnRefreshInProgress.setValue(true);
          await session.refreshSession();
          stateEvents.isOnRefreshInProgress.setValue(false);
        },
        ...events,
      };
    }
    return {
      onDisclaimerAccepted: () => {},
      onSend: () => {},
      onCancelProcessing: () => {},
      refreshSessionId: () => {},
      onLoadMore: () => {},
      ...events,
    };
  }, [states, events, cancelProcessing]);

  React.useEffect(() => {
    const newValue =
      hasSystemError ||
      states?.session.queryStatus.value?.type ===
        AiAssistantQueryStatusType.PROCESSING;
    if (isMessageInputDisabled !== newValue) {
      setMessageInputDisabled(newValue);
    }
  }, [hasSystemError, states?.session.queryStatus.value?.type]);

  React.useEffect(() => {
    if (_.isNaN(states?.publisher.queryCount) && !!states?.session) {
      createSystemError({
        assistantId,
        errorType: AI_ASSISTANT_ERRORS.REQUIRE_PUBLISHER_INTERACTIONS_ERROR,
        sessionId: states.session.id,
        setMessages: states.session.conversation.setMessages,
        existingMessages: [],
        includeExistingMessages: false,
      });
      return;
    }
  }, [states?.publisher.queryCount, states?.session.id]);

  const { queryLimit, showQueryLimit } = states
    ? states.publisher
    : { queryLimit: 0, showQueryLimit: true };

  const hasMaxQueryCount = React.useMemo(() => {
    return !showQueryLimit || states?.publisher.isUnlimited
      ? false
      : !_.isNaN(states?.publisher.queryCount) &&
          Number(states?.publisher.queryCount) >= queryLimit;
  }, [states?.publisher.queryCount, states?.publisher.isUnlimited]);

  const queryLimitReached = hasMaxQueryCount && !isSuperAdmin;

  const messageInputDisabledReason = React.useMemo(() => {
    const isInProgress =
      states?.session.queryStatus.value?.type ===
        AiAssistantQueryStatusType.PROCESSING ||
      states?.events.isOnSendInProgress.value;

    if (hasSystemError) {
      return undefined;
    }
    if (queryLimitReached && !isInProgress) {
      return MessageInputDisableReason.QUERY_LIMIT_REACHED;
    } else if (states?.session.isSessionExpired) {
      return MessageInputDisableReason.EXPIRED_SESSION;
    }
    return undefined;
  }, [
    hasSystemError,
    states?.session.isSessionExpired,
    queryLimitReached,
    states?.session.queryStatus.value?.type,
    states?.events.isOnSendInProgress.value,
  ]);

  const conversation = states?.session.conversation;

  const [messageContent, setMessageContent] = React.useState<string>(
    assistantId === AiAssistantId.POLLY ? (searchParams.pollyPrompt() ?? '') : ''
  );
  const aiAssistantMessages = React.useMemo(() => {
    const messages = [
      ...(conversation?.messages ?? []),
      ...(messageInputDisabledReason
        ? _.compact([getMessageInputDisabledMessage(messageInputDisabledReason)])
        : []),
    ];
    const conversationMessages = _.filter(
      messages,
      ({ role }) => role !== AiAssistantMessageRole.SYSTEM
    );
    const systemMessages = _.filter(
      messages,
      ({ role }) => role === AiAssistantMessageRole.SYSTEM
    );
    return [...conversationMessages, ...systemMessages];
  }, [conversation, messageInputDisabledReason]);

  return {
    aiAssistantMessages,
    states: states,
    loading: isLoading,
    isSuperAdmin: isSuperAdmin,
    messageInputDisabledReason,
    events: aiEvents,
    isMessageInputDisabled,
    setMessageInputDisabled,
    queryLimit,
    queryLimitReached,
    showQueryLimit,
    messageContent,
    setMessageContent,
  };
}

const assistantDetailsById: Record<AiAssistantId, string> = {
  [AiAssistantId.POLLY]: 'Polly, your dedicated Polco Data Analyst',
  [AiAssistantId.GRANT_WRITER]:
    'Grace, your dedicated Polco Grant Writing Assistant',
};
const getSupportError = (assistantId: AiAssistantId) =>
  `Something went wrong, and we\'re unable to load ${assistantDetailsById[assistantId]}. Please contact ${SUPPORT_EMAIL} for support.`;

function getAiAssistantErrorContent(
  type: AI_ASSISTANT_ERRORS,
  assistantId: AiAssistantId
) {
  switch (type) {
    case AI_ASSISTANT_ERRORS.POST_MESSAGE_API_ERROR: {
      return `${AI_ASSISTANT_ERRORS.POST_MESSAGE_API_ERROR} ${getSupportError(assistantId)}`;
    }
    case AI_ASSISTANT_ERRORS.CANCEL_QUERY_API_ERROR: {
      return `${AI_ASSISTANT_ERRORS.CANCEL_QUERY_API_ERROR} ${getSupportError(assistantId)}`;
    }
    case AI_ASSISTANT_ERRORS.REQUIRE_PUBLISHER_INTERACTIONS_ERROR: {
      return `${AI_ASSISTANT_ERRORS.REQUIRE_PUBLISHER_INTERACTIONS_ERROR} ${getSupportError(assistantId)}`;
    }
    default:
      return getSupportError(assistantId);
  }
}

function createSystemError(args: {
  readonly errorType: AI_ASSISTANT_ERRORS;
  readonly assistantId: AiAssistantId;
  readonly sessionId: string;
  readonly setMessages: (value: readonly AiAssistantMessage[]) => void;
  readonly existingMessages: readonly AiAssistantMessage[];
  readonly includeExistingMessages?: boolean;
}) {
  const content = getAiAssistantErrorContent(args.errorType, args.assistantId);

  args.setMessages([
    ...args.existingMessages,
    {
      type: AiAssistantMessageType.SINGLE,
      role: AiAssistantMessageRole.SYSTEM,
      content: content,
      timestamp: new Date(),
    },
  ]);
}

function getMessageInputDisabledMessage(reason: MessageInputDisableReason) {
  const defaultMessage: AiAssistantMessage = {
    type: AiAssistantMessageType.SINGLE,
    role: AiAssistantMessageRole.ASSISTANT,
    content:
      'Your session has expired.\n\nIt has been a pleasure assisting you. Feel free to reach out to me anytime you think I can help you.',
    timestamp: new Date(),
  };
  switch (reason) {
    case MessageInputDisableReason.EXPIRED_SESSION: {
      return {
        ...defaultMessage,
        content:
          'Your session has expired.\n\nIt has been a pleasure assisting you. Feel free to reach out to me anytime you think I can help you.',
      };
    }
    case MessageInputDisableReason.QUERY_LIMIT_REACHED: {
      return {
        ...defaultMessage,
        role: AiAssistantMessageRole.SYSTEM,
        content: "You've reached the limit of questions for this month.",
      };
    }
  }
}
