export interface PaginationRequest {
  readonly page: number;
  readonly perPage: number;
}

export interface PaginationResponse<T> {
  readonly request: PaginationRequest;
  readonly count: number;
  readonly totalPages: number;
  readonly results: readonly T[];
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 100;
