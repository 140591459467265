import { FetchResult } from '@apollo/client';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { TextInputProps } from 'client/shared/components/base/text-input';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { RBRef } from 'client/shared/core/types';
import { AdminSendShareEmail } from 'client/shared/graphql-client/graphql-operations.g';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { EMAIL_VALIDATION_REGEX, GqlError, Result } from 'core';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useId } from 'react-use-id-hook';

export interface Props {
  readonly setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface Fields {
  readonly recipientEmail: string;
  readonly recipientName: string;
  readonly message?: string;
}
const copy = {
  labels: {
    recipientEmail: 'Recipient Email',
    recipientName: 'Recipient Name',
    message: 'Message',
  },
  errorMessages: {
    recipientEmail: {
      required: 'Recipient Email required',
      pattern: 'Email must be in valid format',
    },
    recipientName: {
      required: 'Recipient Name required',
    },
    message: {
      maxLength: 'Maximum length of 500 characters exceeded',
    },
  },
};
type ValidationSet = Record<keyof Fields, RegisterOptions>;
export const validations: ValidationSet = {
  recipientEmail: {
    required: {
      value: true,
      message: copy.errorMessages.recipientEmail.required,
    },
    pattern: {
      value: EMAIL_VALIDATION_REGEX,
      message: copy.errorMessages.recipientEmail.pattern,
    },
  },
  recipientName: {
    required: {
      value: true,
      message: copy.errorMessages.recipientName.required,
    },
  },
  message: {
    required: false,
    maxLength: { value: 500, message: copy.errorMessages.message.maxLength },
  },
};
const baseClass = 'pn-admin-share-email-form';

export const AdminShareEmailForm: React.FC<Props> = (p) => {
  const { fn: sendAdminShareEmail } = useMutationInfo(
    MutationInfos.adminSendSharePolcoEmail
  );
  const { register, handleSubmit, errors, clearErrors, formState, watch } =
    useForm<Fields>({
      mode: 'onChange',
    });

  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };

  const submitted = formState.isSubmitted;

  const emailControllerId = useId();
  const emailFeedbackId = useId();

  const nameControllerId = useId();
  const nameFeedbackId = useId();

  const messageControllerId = useId();
  const messageFieidValue = watch('message');
  async function submit(fields: Fields) {
    clearErrors();

    // Do the mutation
    const result = await Result.fromPromise<
      FetchResult<AdminSendShareEmail, any, any>,
      GqlError<Error>
    >(
      sendAdminShareEmail({
        variables: {
          emailArgs: {
            ...fields,
            message: fields.message === '' ? undefined : fields.message,
          },
        },
      })
    );
    if (Result.isSuccess(result)) {
      p.setFormSubmitted(true);
    }
  }
  const text = (
    label: string,
    k: keyof Fields,
    id: string,
    feedbackId?: string,
    opts?: {
      readonly inputType?: TextInputProps['inputType'];
      readonly autoComplete?: string;
      readonly placeholder?: string;
      readonly defaultValue?: string;
      readonly required?: boolean;
      readonly asTextArea?: boolean;
    }
  ) => {
    const isInvalid = !!errors[k]?.message && submitted;
    return (
      <Form.Group as={Col}>
        <Form.Label className="font-size-m w-100" htmlFor={id}>
          <div className="d-flex justify-content-between">
            <div>
              {' '}
              {label}
              {opts?.required ? (
                <span className="text-danger">*</span>
              ) : (
                <span className="font-weight-normal"> (optional)</span>
              )}
            </div>
            {opts?.asTextArea ? (
              <div
                className={`font-weight-normal ${messageFieidValue && messageFieidValue.length > 500 ? 'text-danger' : ''}`}
              >
                {messageFieidValue?.length ?? 0} / 500
              </div>
            ) : null}
          </div>
        </Form.Label>
        <Form.Control
          aria-errormessage={isInvalid ? feedbackId : undefined}
          aria-invalid={isInvalid}
          aria-label={label}
          as={opts?.asTextArea ? 'textarea' : undefined}
          autoComplete={opts?.autoComplete}
          className={`rounded accessible-input ${
            !!errors[k]?.message && submitted ? 'has-error' : ''
          }`}
          defaultValue={opts?.defaultValue}
          id={id}
          isInvalid={isInvalid}
          name={k}
          placeholder={opts?.placeholder}
          ref={createRef(validations[k] ?? {})}
          required={opts?.required}
          rows={opts?.asTextArea ? 4 : undefined}
          type={opts?.inputType}
        />

        <Form.Control.Feedback
          className={submitted ? 'd-block' : ''}
          id={feedbackId}
          type="invalid"
        >
          {errors[k]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  return (
    <Form className={`${baseClass}`} onSubmit={handleSubmit(submit)}>
      <Form.Row>
        {text(
          copy.labels.recipientName,
          'recipientName',
          nameControllerId,
          nameFeedbackId,
          {
            required: true,
          }
        )}
        {text(
          copy.labels.recipientEmail,
          'recipientEmail',
          emailControllerId,
          emailFeedbackId,
          {
            required: true,
          }
        )}
      </Form.Row>
      <Form.Row>
        {text(copy.labels.message, 'message', messageControllerId, undefined, {
          asTextArea: true,
        })}
      </Form.Row>
      <Btn action={handleSubmit(submit)} className="mt-2" type={ButtonTypes.PRIMARY}>
        Send
      </Btn>
    </Form>
  );
};
