import { Props as AdminPublisherTitleProps } from 'client/admin/content/shared/components/publisher-title';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import {
  MultiLanguageContent,
  SelectLanguageTextFunction,
} from 'client/shared/hooks';
import { AreaLookupType, ExtractGql } from 'core';
type GqlPublishingEntity = ExtractGql<
  NonNullable<Gql.AdminPublishingWizardSchedule['openContentSetById']>,
  'QuestionSet'
>['publishingEntity'];
export type PublisherData = Pick<GqlPublishingEntity, 'id'> & {
  readonly name: string | MultiLanguageContent;
  readonly assets: readonly Omit<GqlPublishingEntity['assets'][0], '__typename'>[];
};

export namespace PublisherTx {
  export function gqlToClient(
    pub: PublisherData,
    translate: SelectLanguageTextFunction,
    nameExtra?: string | MultiLanguageContent
  ): AdminPublisherTitleProps {
    const extra = nameExtra
      ? typeof nameExtra === 'string'
        ? nameExtra
        : translate(nameExtra)
      : undefined;
    const pubName = typeof pub.name === 'string' ? pub.name : translate(pub.name);
    return {
      name: extra ? `${pubName} - ${extra}` : pubName,
      logoUrl:
        pub.assets.filter((i) => i.type === Gql.PublishingEntityAssetType.PRIMARY)[0]
          ?.url || null,
    };
  }

  export function areaLookupTypes_gqlToCore(
    areaType: Gql.AreaLookupType
  ): AreaLookupType {
    switch (areaType) {
      case Gql.AreaLookupType.ANY:
        return AreaLookupType.ANY;
      case Gql.AreaLookupType.CITY:
        return AreaLookupType.CITY;
      case Gql.AreaLookupType.COUNTY:
        return AreaLookupType.COUNTY;
      case Gql.AreaLookupType.STATE:
        return AreaLookupType.STATE;
      case Gql.AreaLookupType.SUB_CITY:
        return AreaLookupType.SUB_CITY;
    }
  }
}
