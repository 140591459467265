export enum AttributeType {
  AGE_BRACKET = 'age_bracket',
  AGE_BRACKET_50_PLUS = 'age_bracket_50_plus',
  BIRTH_DATE = 'birth_date',
  CENSUS_BLOCK = 'census_block',
  CENSUS_BLOCK_GROUP = 'census_block_group',
  CENSUS_TRACT = 'census_tract',
  CITY_COUNCIL_COMMISSIONER_DISTRICT = 'city_council_commissioner_district',
  CITY_WARD = 'city_ward',
  COUNTY = 'county',
  EMPLOYMENT_STATUS = 'employment_status',
  GENDER = 'gender',
  PRECINCT = 'precinct',
  STATE = 'state',
  ZIP_CODE = 'zip_code',
  VERIFIED = 'verified',
  LOCATION = 'location',
  RACE = 'race',
  INCOME_LEVEL = 'income_level',
  CHILDREN_IN_HOUSEHOLD = 'children_in_household',
  INDIVIDUALS_IN_HOUSEHOLD = 'individuals_in_household',
  OVER_65_IN_HOUSEHOLD = 'over_65_in_household',
  HOUSING_COST = 'housing_cost',
  RENT_OR_OWN_HOME = 'rent_or_own_home',
  RENT_OR_OWN_HOME_MORTAGE = 'rent_or_own_home_mortgage',
  TYPE_OF_HOME = 'type_of_home',
  TYPE_OF_HOME_ASSISTED_CARE = 'type_of_home_assisted_care',
  COMPLETED_EDUCATION = 'completed_education',
  ARMED_FORCES = 'armed_forces',
  SEXUAL_ORIENTATION = 'sexual_orientation',
  GENDER_IDENTITY = 'gender_identity',
  SCHOOL_AGED_CHILDREN = 'school_aged_children',
  ENGLISH_FIRST_LANGUAGE = 'english_first_language',
  COUNTRY_OF_ORIGIN = 'country_of_origin',
  POLITICAL_VIEWS = 'political_views',
  RELIGIOUS_IDENTITY = 'religious_identity',
  CENSUS_BLOCK_GROUP_LOCATION = 'census_block_group_location',
  ETHNICITY = 'ethnicity',
  DEPRECATED_RACE_AND_ETHNICITY = 'race_and_ethnicity', // Should probably not be used anywhere. It is a "stash" of the old race/ethnicity data before we re-worked it
  BUSINESS_INDUSTRY = 'business_industry',
  BUSINESS_NUMBER_OF_EMPLOYEES = 'business_number_of_employees',
  BUSINESS_AREA = 'business_area',
  BUSINESS_SIZE = 'business_size',
  HEADQUARTERS_LOCATION = 'headquarters_location',
  BUSINESS_OWNER_DIVERSITY = 'business_owner_diverse',
  BATCH_IMPORT_ID = 'batch_import_id',
  DISABILITY_OR_CONDITION = 'disability_or_condition',
  TYPE_OF_DISABILITY_OR_CONDITION = 'type_of_disability_or_condition',
}

export enum AttributeValueType {
  DATE = 'date',
  NUMBER = 'number',
  STRING = 'string',
}

export type AttributeTypes = {
  readonly [key in AttributeType]: {
    readonly type: AttributeValueType;
    readonly name: string;
  };
};

export const attributeTypes: AttributeTypes = {
  [AttributeType.BIRTH_DATE]: {
    type: AttributeValueType.STRING,
    name: 'Birth Date',
  },
  [AttributeType.AGE_BRACKET]: {
    type: AttributeValueType.STRING,
    name: 'Age Bracket',
  },
  [AttributeType.AGE_BRACKET_50_PLUS]: {
    type: AttributeValueType.STRING,
    name: 'Age Bracket',
  },
  [AttributeType.CENSUS_BLOCK]: {
    type: AttributeValueType.NUMBER,
    name: 'Census Block',
  },
  [AttributeType.CENSUS_BLOCK_GROUP]: {
    type: AttributeValueType.NUMBER,
    name: 'Census Block Group',
  },
  [AttributeType.CENSUS_TRACT]: {
    type: AttributeValueType.NUMBER,
    name: 'Census Tract',
  },
  [AttributeType.CITY_COUNCIL_COMMISSIONER_DISTRICT]: {
    type: AttributeValueType.STRING,
    name: 'City Council Commissioner District',
  },
  [AttributeType.CITY_WARD]: {
    type: AttributeValueType.STRING,
    name: 'City Ward',
  },
  [AttributeType.COUNTY]: {
    type: AttributeValueType.STRING,
    name: 'County',
  },
  [AttributeType.EMPLOYMENT_STATUS]: {
    type: AttributeValueType.STRING,
    name: 'Employment Status',
  },
  [AttributeType.GENDER]: {
    type: AttributeValueType.STRING,
    name: 'Gender',
  },
  [AttributeType.PRECINCT]: {
    type: AttributeValueType.STRING, // Storing as string as it is number | string in L2
    name: 'Precinct',
  },
  [AttributeType.STATE]: {
    type: AttributeValueType.STRING,
    name: 'State',
  },
  [AttributeType.ZIP_CODE]: {
    type: AttributeValueType.STRING,
    name: 'Zip Code',
  },
  [AttributeType.VERIFIED]: {
    type: AttributeValueType.STRING,
    name: 'Verified',
  },
  [AttributeType.LOCATION]: {
    type: AttributeValueType.STRING,
    name: 'Location',
  },
  [AttributeType.DEPRECATED_RACE_AND_ETHNICITY]: {
    type: AttributeValueType.STRING,
    name: 'Race and Ethnicity',
  },
  [AttributeType.RACE]: {
    type: AttributeValueType.STRING,
    name: 'Race',
  },
  [AttributeType.ETHNICITY]: {
    type: AttributeValueType.STRING,
    name: 'Ethnicity',
  },
  [AttributeType.INCOME_LEVEL]: {
    type: AttributeValueType.STRING,
    name: 'Income Level',
  },
  [AttributeType.CHILDREN_IN_HOUSEHOLD]: {
    type: AttributeValueType.STRING,
    name: 'Children in Household',
  },
  [AttributeType.INDIVIDUALS_IN_HOUSEHOLD]: {
    type: AttributeValueType.STRING,
    name: 'Number of Household Members',
  },
  [AttributeType.OVER_65_IN_HOUSEHOLD]: {
    type: AttributeValueType.STRING,
    name: 'Over 65 in Household',
  },
  [AttributeType.HOUSING_COST]: {
    type: AttributeValueType.STRING,
    name: 'Housing Cost',
  },
  [AttributeType.RENT_OR_OWN_HOME]: {
    type: AttributeValueType.STRING,
    name: 'Rent or Own Home',
  },
  [AttributeType.RENT_OR_OWN_HOME_MORTAGE]: {
    type: AttributeValueType.STRING,
    name: 'Rent or Own Home',
  },
  [AttributeType.TYPE_OF_HOME]: {
    type: AttributeValueType.STRING,
    name: 'Type of Home',
  },
  [AttributeType.TYPE_OF_HOME_ASSISTED_CARE]: {
    type: AttributeValueType.STRING,
    name: 'Type of Home',
  },
  [AttributeType.COMPLETED_EDUCATION]: {
    type: AttributeValueType.STRING,
    name: 'Completed Education Level',
  },
  [AttributeType.ARMED_FORCES]: {
    type: AttributeValueType.STRING,
    name: 'Armed Forces',
  },
  [AttributeType.SEXUAL_ORIENTATION]: {
    type: AttributeValueType.STRING,
    name: 'Sexual Orientation',
  },
  [AttributeType.GENDER_IDENTITY]: {
    type: AttributeValueType.STRING,
    name: 'Gender Identity',
  },
  [AttributeType.SCHOOL_AGED_CHILDREN]: {
    type: AttributeValueType.STRING,
    name: 'School Aged Children',
  },
  [AttributeType.ENGLISH_FIRST_LANGUAGE]: {
    type: AttributeValueType.STRING,
    name: 'English First Language',
  },
  [AttributeType.COUNTRY_OF_ORIGIN]: {
    type: AttributeValueType.STRING,
    name: 'Country of Origin',
  },
  [AttributeType.POLITICAL_VIEWS]: {
    type: AttributeValueType.STRING,
    name: 'Political Views',
  },
  [AttributeType.RELIGIOUS_IDENTITY]: {
    type: AttributeValueType.STRING,
    name: 'Religious Identity',
  },
  [AttributeType.CENSUS_BLOCK_GROUP_LOCATION]: {
    type: AttributeValueType.STRING,
    name: 'Census Block Group Location',
  },
  [AttributeType.BUSINESS_AREA]: {
    type: AttributeValueType.STRING,
    name: 'Business Area',
  },
  [AttributeType.BUSINESS_INDUSTRY]: {
    type: AttributeValueType.STRING,
    name: 'Business Industry',
  },
  [AttributeType.BUSINESS_NUMBER_OF_EMPLOYEES]: {
    type: AttributeValueType.STRING,
    name: 'Number of Employees',
  },
  [AttributeType.HEADQUARTERS_LOCATION]: {
    type: AttributeValueType.STRING,
    name: 'Headquarters Location',
  },
  [AttributeType.BUSINESS_OWNER_DIVERSITY]: {
    type: AttributeValueType.STRING,
    name: 'Business Owner Diversity',
  },
  [AttributeType.BUSINESS_SIZE]: {
    type: AttributeValueType.STRING,
    name: 'Business Size',
  },
  [AttributeType.BATCH_IMPORT_ID]: {
    type: AttributeValueType.STRING,
    name: 'Batch Import ID',
  },
  [AttributeType.DISABILITY_OR_CONDITION]: {
    type: AttributeValueType.STRING,
    name: 'Disability or Condition',
  },
  [AttributeType.TYPE_OF_DISABILITY_OR_CONDITION]: {
    type: AttributeValueType.STRING,
    name: 'Type of Disability or Condition',
  },
};

// WARNING: this list applies to the old, respondent_question_choices-based results. The list of what is supported
// in benchmark surveys for breakdowns is dependent on the demographic questions in the survey
// (plus eventually probably a separate standard list for L2 data)
export const ALLOWED_BREAKDOWN_ATTRIBUTE_TYPES = [
  AttributeType.LOCATION,
  AttributeType.BIRTH_DATE,
  AttributeType.AGE_BRACKET,
  AttributeType.GENDER,
  AttributeType.PRECINCT,
  AttributeType.CITY_COUNCIL_COMMISSIONER_DISTRICT,
  AttributeType.VERIFIED,
  AttributeType.CHILDREN_IN_HOUSEHOLD,
  AttributeType.OVER_65_IN_HOUSEHOLD,
  AttributeType.HOUSING_COST,
  AttributeType.RENT_OR_OWN_HOME,
  AttributeType.TYPE_OF_HOME,
  AttributeType.COMPLETED_EDUCATION,
  AttributeType.ARMED_FORCES,
  AttributeType.SEXUAL_ORIENTATION,
  AttributeType.GENDER_IDENTITY,
  AttributeType.SCHOOL_AGED_CHILDREN,
  AttributeType.ENGLISH_FIRST_LANGUAGE,
  AttributeType.COUNTRY_OF_ORIGIN,
  AttributeType.POLITICAL_VIEWS,
  AttributeType.RELIGIOUS_IDENTITY,
  AttributeType.CENSUS_BLOCK_GROUP_LOCATION,
  AttributeType.RACE,
  AttributeType.DEPRECATED_RACE_AND_ETHNICITY,
  AttributeType.INCOME_LEVEL,
  AttributeType.ETHNICITY,
];
