import * as React from 'react';
import './styles.scss';
import {
  useTable,
  useSortBy,
  Column,
  ColumnInstance,
  UseSortByColumnProps,
  CellProps,
  TableCommonProps,
  useResizeColumns,
  UseResizeColumnsColumnProps,
  useFlexLayout,
  usePagination,
  TableInstance,
  UsePaginationInstanceProps,
  TableState,
  UsePaginationState,
  UseSortByOptions,
  UsePaginationOptions,
  UseResizeColumnsOptions,
  Row,
  UseSortByState,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
  Dropdown,
} from 'client/shared/components/base';
import { DEFAULT_PAGE_SIZE, toMutable } from 'core';
import { handlePressEnter } from 'client/shared/core/helpers';
import _ from 'lodash';
import classNames from 'classnames';
import { usePdfGeneration } from 'client/shared/hooks';
import { DropLocation } from 'client/shared/components/base/dropdown';

export type TypedColumn<T extends object> = Column<T> & {
  readonly Cell?: (args: CellProps<T>) => React.ReactNode;
  readonly Footer?: (args: CellProps<T>) => React.ReactNode;
  readonly disableSortBy?: boolean; // Default: false
  readonly headerClass?: string;
  readonly sticky?: 'left' | 'right';
  readonly headerAriaHidden?: boolean;
};
interface Props<T extends object> {
  readonly columns: readonly TypedColumn<T>[];
  readonly data: readonly T[];
  readonly className?: string;
  readonly pageSize?: number;
  readonly headerClassName?: string;
  readonly cellPadding?: string;
  readonly cellClassName?: string;
  readonly rowClassName?: (row: T) => string;
  readonly hideEntryCount?: boolean;
  readonly initialState?: Partial<TableState<T> & UseSortByState<T>>;
  readonly pluginOptions?: UseSortByOptions<T> &
    UsePaginationOptions<T> &
    UseResizeColumnsOptions<T>;
  readonly hidePagination?: boolean;
  readonly hideCellBorder?: boolean;
  readonly staticCells?: ReadonlySet<number>;
  readonly onRowClick?: (row: T) => any;
  readonly centeredContent?: boolean;
  readonly hideBottomCellBorder?: boolean;
  readonly headerTextSize?: string;
  readonly cellGrey?: string;
  readonly customheaderBorderColor?: string;
  readonly alternativeNavigationButtons?: boolean;
  readonly pageEntriesChangeArgs?: {
    readonly onEntriesChange: (value: string) => void;
    readonly pageSizeList: readonly number[];
  };
  readonly paginationClassName?: string;
  readonly onUpdatePageIndex?: (index: number) => void;
  readonly hideHeader?: boolean;
  readonly removeZebraStriping?: boolean;
}
// React-table types do not know which react-table features you are supporting
// We use these type to specify which features are currently supported by our UI component

interface TypedTableState<T extends object>
  extends TableState<T>,
    UsePaginationState<T> {}

interface Table<T extends object>
  extends TableCommonProps,
    TableInstance<T>,
    UsePaginationInstanceProps<T> {
  readonly state: TypedTableState<T>;
}
type TableColumn<T extends object> = TableCommonProps &
  ColumnInstance<T> &
  UseSortByColumnProps<T> &
  UseResizeColumnsColumnProps<T> & {
    readonly headerClass?: string;
  };

const baseClass = 'pn-table';

export function Table<T extends object>(
  props: React.PropsWithChildren<Props<T>>
): React.ReactElement {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100,
      width: 250,
      maxWidth: 400,
    }),
    []
  );

  const { columns, data, initialState } = props;
  const memoizedColumns = React.useMemo(() => toMutable(columns), [columns]);
  const memoizedData = React.useMemo(() => toMutable(data), [data]);
  const tableInstance = useTable<T>(
    {
      columns: memoizedColumns,
      data: memoizedData,
      defaultColumn,
      initialState,
      ...props.pluginOptions,
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
    usePagination,
    useSticky
  ) as unknown as Table<T>;
  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    footerGroups,
    state: { pageIndex, pageSize },
  } = tableInstance;

  React.useEffect(() => {
    if (props.pageSize && tableInstance.state.pageSize !== props.pageSize) {
      tableInstance.setPageSize(props.pageSize);
    } else if (
      !props.pageSize &&
      tableInstance.state.pageSize !== DEFAULT_PAGE_SIZE
    ) {
      tableInstance.setPageSize(DEFAULT_PAGE_SIZE);
    }
  }, [props.pageSize, tableInstance]);

  const isPdfGeneration = usePdfGeneration();
  const stickyValue = _.compact(memoizedColumns.map((col) => col.sticky));
  const resizingDisabled =
    !!props?.pluginOptions?.disableResizing || isPdfGeneration;
  const hasResizer = resizingDisabled ? false : stickyValue.length === 0;

  const entryContent = !props.hideEntryCount
    ? `Showing ${pageIndex * pageSize + 1} - ${Math.min(
        rows.length,
        (pageIndex + 1) * pageSize
      )} of ${rows.length} entries`
    : '';

  return (
    <div className={`${baseClass}`}>
      <div className={`table sticky ${props.className ?? ''}`} {...getTableProps()}>
        {!props.hideHeader && (
          <div className="thead">
            <div
              className={`tr d-flex align-items-stretch ${
                props.customheaderBorderColor
                  ? props.customheaderBorderColor
                  : 'border-gray-40'
              } border-bottom`}
              role="row"
            >
              {allColumns.map((c, cIdx) => {
                const originalColumn = columns.find((cc) => cc.Header === c.Header);
                const column = c as unknown as TableColumn<T>;
                return (
                  <div
                    className={classNames(
                      `th px-3 d-flex align-items-center font-weight-bold font-size-${
                        props.headerTextSize ? props.headerTextSize : 'sm'
                      } bg-gray-10 ${
                        props.centeredContent && cIdx >= 1
                          ? 'justify-content-center'
                          : ''
                      }`,
                      `${props.headerClassName}${cIdx === allColumns.length - 1 ? '-last' : ''}`,
                      props.staticCells
                        ? props.staticCells.has(cIdx)
                          ? 'flex-grow-0'
                          : 'flex-grow-1'
                        : 'flex-grow-1'
                    )}
                    {...column.getHeaderProps()}
                    aria-hidden={originalColumn?.headerAriaHidden || false}
                    aria-sort={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'descending'
                          : 'ascending'
                        : 'none'
                    }
                  >
                    {column.canSort ? (
                      <div className="th-sort">
                        <div
                          className="d-flex align-items-center px-1"
                          tabIndex={0}
                          {...{
                            ...column.getHeaderProps(column.getSortByToggleProps()),
                            style: {},
                            // Default "Toggle sort" title overriden in order to skip the screen reader to read it
                            // otherwise it will read it as part of each column cell as part of the th (header) column content
                            title: undefined,
                            // Apply button role in order to make the screen reader to provide instructions to click it
                            role: 'button',
                          }}
                        >
                          <div className={`${column.headerClass || ''}`}>
                            {column.render('Header')}
                          </div>
                          <MaterialIcon
                            ariaHidden={true}
                            className="sort-symbol ml-2 no-show-in-image"
                            icon={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? MaterialIconName.KEYBOARD_ARROW_DOWN
                                  : MaterialIconName.KEYBOARD_ARROW_UP
                                : MaterialIconName.UNFOLD_MORE
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={`${column.headerClass || ''}`}>
                        {column.render('Header')}
                      </div>
                    )}
                    {/* The onClick on the resizer prevents resizing from triggering a sort as well */}
                    {hasResizer && cIdx !== allColumns.length - 1 && (
                      <div
                        aria-hidden={true}
                        className={`resizer`}
                        {...column.getResizerProps()}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        role="presentation"
                      >
                        <div className="resizer-element" />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="tbody" {...getTableBodyProps()}>
          {page.map((row, rowIdx) => {
            prepareRow(row);

            const rowClick = props.onRowClick
              ? () => props.onRowClick?.(row.original)
              : null;

            return (
              <div
                /* Not using clickable div as it makes finding rows in tests very complicated */
                className={classNames(
                  'tr',
                  !props.removeZebraStriping && rowIdx % 2 === 1 ? 'bg-gray-5' : '',
                  props.rowClassName ? props.rowClassName(row.original) : '',
                  rowClick && 'cursor-pointer'
                )}
                onClick={rowClick ? () => rowClick() : undefined}
                onKeyDown={rowClick ? handlePressEnter(rowClick) : undefined}
                role={rowClick ? 'button' : undefined}
                tabIndex={0}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, cIdx) => {
                  return (
                    <div
                      className={classNames(
                        'td d-flex align-items-center',
                        props.cellGrey
                          ? `border-gray-${props.cellGrey}`
                          : 'border-gray-40',
                        props.cellPadding || 'p-3',
                        props.cellClassName,
                        props.staticCells && props.staticCells.has(cIdx)
                          ? 'flex-grow-0'
                          : 'flex-grow-1',
                        cIdx !== allColumns.length - 1 && !props.hideCellBorder
                          ? 'border-right'
                          : '',
                        cIdx !== 0 && props.centeredContent
                          ? 'justify-content-center'
                          : '',
                        props.hideBottomCellBorder ? '' : 'border-bottom-1'
                      )}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {/* If there are no footers to render in the row, don't render the row. 
      It would be just whitespace. */}
      {props.columns.some((col) => col.Footer !== undefined) && (
        <div className="tfoot">
          {footerGroups.map((group) => (
            <div className="tr" {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <div className="td p-3" {...column.getFooterProps()}>
                  {column.render('Footer')}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {rows.length === 0 ? (
        <div className="text-center">No rows found</div>
      ) : (
        <>
          {entryContent && (
            <div
              className={`${
                props.hidePagination
                  ? 'd-none'
                  : `pagination d-flex m-1 ${props.paginationClassName || ''} ${
                      props.alternativeNavigationButtons
                        ? 'justify-content-md-end justify-content-start'
                        : 'justify-content-end'
                    } align-items-center`
              }`}
            >
              {entryContent && <span aria-label={entryContent}>{entryContent}</span>}
              {rows.length > page.length && (
                <>
                  <Btn
                    action={() => {
                      gotoPage(0);
                      props.onUpdatePageIndex && props.onUpdatePageIndex(0);
                    }}
                    ariaLabel="Go to first page"
                    className="mirror"
                    disabled={!canPreviousPage}
                    type={ButtonTypes.SEAMLESS}
                  >
                    <MaterialIcon
                      className="text-color-gray-5"
                      icon={
                        props.alternativeNavigationButtons
                          ? MaterialIconName.LAST_PAGE
                          : MaterialIconName.KEYBOARD_TAB
                      }
                    />
                  </Btn>
                  <Btn
                    action={() => {
                      previousPage();
                      props.onUpdatePageIndex &&
                        props.onUpdatePageIndex(pageIndex - 1);
                    }}
                    ariaLabel="Go to previous page"
                    disabled={!canPreviousPage}
                    type={ButtonTypes.SEAMLESS}
                  >
                    <MaterialIcon
                      className="text-color-gray-5"
                      icon={MaterialIconName.KEYBOARD_ARROW_LEFT}
                    />
                  </Btn>
                  <Btn
                    action={() => {
                      nextPage();
                      props.onUpdatePageIndex &&
                        props.onUpdatePageIndex(pageIndex + 1);
                    }}
                    ariaLabel="Go to next page"
                    disabled={!canNextPage}
                    type={ButtonTypes.SEAMLESS}
                  >
                    <MaterialIcon
                      className="text-color-gray-5"
                      icon={MaterialIconName.KEYBOARD_ARROW_RIGHT}
                    />
                  </Btn>
                  <Btn
                    action={() => {
                      gotoPage(pageCount - 1);
                      props.onUpdatePageIndex &&
                        props.onUpdatePageIndex(pageCount - 1);
                    }}
                    ariaLabel="Go to last page"
                    disabled={!canNextPage}
                    type={ButtonTypes.SEAMLESS}
                  >
                    <MaterialIcon
                      className="text-color-gray-5"
                      icon={
                        props.alternativeNavigationButtons
                          ? MaterialIconName.LAST_PAGE
                          : MaterialIconName.KEYBOARD_TAB
                      }
                    />
                  </Btn>
                </>
              )}
              {props.pageEntriesChangeArgs && (
                <PageSizingSelector
                  activeValue={props.pageSize ?? 5}
                  onEntriesChange={props.pageEntriesChangeArgs.onEntriesChange}
                  pageSizeList={props.pageEntriesChangeArgs.pageSizeList}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

const PageSizingSelector = (props: {
  readonly onEntriesChange?: (value: string) => void;
  readonly activeValue: number;
  readonly pageSizeList: readonly number[];
}) => {
  const pageSizeList = props.pageSizeList.map((size) => size.toString());
  return (
    <Dropdown
      className={`dropdown-alternative-style`}
      dropLocation={DropLocation.UP}
      keySelect={(item) => item}
      labelSelect={(item) => `${item} entries per page`}
      onChange={props.onEntriesChange}
      options={Object.values([...pageSizeList, 'max amount of'])}
      prompt="page-size-selector"
      value={
        isNaN(props.activeValue) ? 'max amount of' : props.activeValue.toString()
      }
    />
  );
};

export function caseInsensitiveSort<T extends object>(
  r1: Row<T>,
  r2: Row<T>,
  c: keyof T | string // dunno why the SortByFn is typed this loosely
) {
  const r1Val = r1.original[c as keyof T];
  const r2Val = r2.original[c as keyof T];
  const r1Comp = typeof r1Val === 'string' ? r1Val.toLowerCase() : r1Val;
  const r2Comp = typeof r2Val === 'string' ? r2Val.toLowerCase() : r2Val;
  return r1Comp > r2Comp ? 1 : -1;
}
