import { QuestionSetType } from 'client/shared/core/question-set';
import {
  QuestionHierarchyParentNodeType,
  QuestionStatus,
  AdminOpenQuestionSetStructure,
  AdminPublishingWizardShare,
  AdminOpenQuestionSet,
  AdminReadyToShareQuestions,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  ApiDate,
  BalancingActSimulationType,
  DEFAULT_SURVEY_QUESTION_OPTIONALITY,
  ExtractGql,
  wrap,
} from 'core';
import {
  SavedSurveyItem,
  PositionTypedData,
  PositionType,
  QuestionType,
  SurveyItemType,
  DEFAULT_QUESTION_CONDITIONS,
} from 'client/shared/core/question';
import { QuestionTx } from '.';
import { gqlToClient_conditions } from 'client/shared/core/conditions';
import { AccessSetting, AccessSettingType, QuestionSet } from 'client/admin/core';
import { SelectLanguageTextFunction } from 'client/shared/hooks';
import { PerformanceDataTx } from 'client/shared/graphql-util/transforms';
import { gqlSimulationNodeTypeToClient } from 'client/shared/core/balancing-act-simulation';

type GqlContentSet = NonNullable<
  | AdminOpenQuestionSetStructure['openContentSetById']
  | AdminOpenQuestionSet['openContentSetById']
>;

type GqlContentSetForShortId = NonNullable<
  | AdminOpenQuestionSetStructure['openContentSetById']
  | AdminPublishingWizardShare['openContentSetById']
>;

type GqlAccessSetting = ExtractGql<
  NonNullable<AdminOpenQuestionSetStructure['openContentSetById']>,
  'QuestionSet'
>['accessSetting'];

type GqlSurveyContent = ExtractGql<
  NonNullable<AdminReadyToShareQuestions['adminShareableContentSet']>['contentSet'],
  'Survey'
>['contents'][0];

export namespace ContentSetTx {
  export function gqlToClient(
    gqlQuestionSet: GqlContentSet,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionSet {
    const questionsCount = wrap(() => {
      switch (gqlQuestionSet.__typename) {
        case 'QuestionSet':
        case 'PolcoLive':
          return gqlQuestionSet.createdQuestions.pagination.totalCount;
        case 'Survey':
        case 'ContentPost':
          return gqlQuestionSet.questionsCount;
      }
    });

    const baseSet = {
      id: gqlQuestionSet.id,
      name: selectLanguageText(gqlQuestionSet.name),
      slug: gqlQuestionSet.slug,
      description: selectLanguageText(gqlQuestionSet.description),
      status: gqlQuestionSet.schedule.status,
      createDate: ApiDate.fromApi(gqlQuestionSet.createDate),
      openDate: ApiDate.fromApi(gqlQuestionSet.schedule.openDate),
      closeDate: ApiDate.fromApi(gqlQuestionSet.schedule.closeDate),
      questionsCount,
      allowGuestRespondents: gqlQuestionSet.allowGuestRespondents,
      showConversionPrompts: gqlQuestionSet.showConversionPrompts,
      setImageId: gqlQuestionSet.setImageId,
      hasBenchmarkedContent: gqlQuestionSet.hasBenchmarkedContent,
      polcoManaged: gqlQuestionSet.polcoManaged,
      publisher: {
        id: gqlQuestionSet.publishingEntity.id,
        name: selectLanguageText(gqlQuestionSet.publishingEntity.name),
        slug: gqlQuestionSet.publishingEntity.slug,
      },
      accessSetting: gqlToAccessSetting(gqlQuestionSet.accessSetting),
      horizontalImageUrl: gqlQuestionSet.image?.horizontalUrl ?? null,
      hasAutomaticWeighting: gqlQuestionSet.hasAutomaticWeighting,
      allowMultipleResponses: gqlQuestionSet.allowMultipleResponses,
    };

    if (gqlQuestionSet.__typename === 'PolcoLive') {
      return {
        ...baseSet,
        type: QuestionSetType.POLCO_LIVE,
        eventStartDate: ApiDate.fromApi(gqlQuestionSet.settings.eventStartDate),
        eventEndDate: ApiDate.fromApi(gqlQuestionSet.settings.eventEndDate),
        liveVideoLink: gqlQuestionSet.settings.liveVideoLink,
        state: gqlQuestionSet.settings.state ?? QuestionStatus.DRAFT,
      };
    } else if (gqlQuestionSet.__typename === 'Survey') {
      return {
        ...baseSet,
        type: QuestionSetType.SURVEY,
        hasVisibleReportTabs: gqlQuestionSet.hasVisibleReportTabs,
      };
    } else if (gqlQuestionSet.__typename === 'ContentPost') {
      return {
        ...baseSet,
        type: QuestionSetType.CONTENT_POST,
      };
    }
    return {
      ...baseSet,
      type: QuestionSetType.SET,
    };
  }

  export function gqlToSavedSurveyItem(
    gqlItem: GqlSurveyContent,
    selectLanguageText: SelectLanguageTextFunction,
    defaultQuestionNumber: number
  ): SavedSurveyItem {
    switch (gqlItem.__typename) {
      case 'QuestionHierarchyParentNode':
        switch (gqlItem.structureType) {
          case QuestionHierarchyParentNodeType.HEADER:
            const typedData: PositionTypedData = {
              type: PositionType.SECTION_HEADER,
              label: selectLanguageText(gqlItem.label) ?? '',
              description: selectLanguageText(gqlItem.structureDescription) ?? '',
            };
            const hierarchy: SavedSurveyItem = {
              type: SurveyItemType.HEADER,
              data: {
                id: gqlItem.id,
                title: selectLanguageText(gqlItem.label) ?? '',
                descriptionHtml:
                  selectLanguageText(gqlItem.structureDescription) ?? null,
                typedData,
                questions: [],
                conditions: gqlToClient_conditions(gqlItem.conditions),
              },
            };
            return hierarchy;
          case QuestionHierarchyParentNodeType.GRID:
            const gridHierarchy: SavedSurveyItem = {
              type: SurveyItemType.QUESTION,
              questionNumber: gqlItem.questionNumber ?? defaultQuestionNumber,
              data: {
                id: gqlItem.id,
                title: selectLanguageText(gqlItem.label) ?? '',
                description:
                  selectLanguageText(gqlItem.structureDescription) ?? null,
                images: [],
                demographicAttribute: null, // no demo attribute on grid questions
                conditions: gqlToClient_conditions(gqlItem.conditions),
                typedData: {
                  type: QuestionType.GRID_CHOICE,
                  rows: gqlItem.questions.map((q) => ({
                    questionId: q.id,
                    label: selectLanguageText(q.title),
                    conditions: { ...DEFAULT_QUESTION_CONDITIONS },
                  })),
                  columns: gqlItem.questions[0].choiceSet.choices.map((ch) => ({
                    id: ch.id,
                    label: selectLanguageText(ch.text),
                  })), // since all rows share same choices we only need to look up first index
                  shareableQuestionChoiceSetId:
                    gqlItem.questions[0].choiceSet.sharedChoiceSetData?.id ?? null,
                },
                status: QuestionStatus.PUBLISHED,
                shortId: null,
                optional:
                  gqlItem.questions.length > 0
                    ? gqlItem.questions[0].optional
                    : DEFAULT_SURVEY_QUESTION_OPTIONALITY,
              },
            };
            return gridHierarchy;
        }
      case 'QuestionHierarchyVisualizationNode':
        return {
          type: SurveyItemType.VISUALIZATION,
          data: {
            id: gqlItem.id,
            visualization: gqlItem.trackVariableVisualization
              ? {
                  ...PerformanceDataTx.gqlVisualization_toClient(
                    gqlItem.trackVariableVisualization
                  ),
                  plans: gqlItem.trackVariableVisualization.plans,
                }
              : null,
            conditions: gqlToClient_conditions(gqlItem.conditions),
            label: gqlItem.altLabel,
          },
        };
      case 'QuestionHierarchySimulationNode': {
        return {
          type: SurveyItemType.SIMULATION,
          data: {
            id: gqlItem.id,
            simulation: null,
            conditions: gqlToClient_conditions(gqlItem.conditions),
            simulationType: gqlSimulationNodeTypeToClient(gqlItem.simulationType),
          },
        };
      }
      case 'Question':
        return {
          type: SurveyItemType.QUESTION,
          questionNumber: gqlItem.questionNumber ?? defaultQuestionNumber,
          data: QuestionTx.gqlToClientWithExtendedData(
            {
              ...gqlItem,
              variableName: gqlItem.variableName,
              choiceSet: {
                ...gqlItem.choiceSet,
                choices: gqlItem.choiceSet.choices.map((c) => ({
                  id: c.id,
                  text: c.text,
                  choiceValue: c.choiceValue,
                })),
              },
            },
            selectLanguageText
          ),
        };
    }
  }

  export function gqlToSetType(
    typename: 'QuestionSet' | 'Survey' | 'PolcoLive' | 'ContentPost'
  ): QuestionSetType {
    switch (typename) {
      case 'QuestionSet':
        return QuestionSetType.SET;
      case 'Survey':
        return QuestionSetType.SURVEY;
      case 'PolcoLive':
        return QuestionSetType.POLCO_LIVE;
      case 'ContentPost':
        return QuestionSetType.CONTENT_POST;
    }
  }

  export function gqlToBAType(
    typename:
      | 'BUDGET'
      | 'CLIMATE'
      | 'HOUSING'
      | 'NON_NUMERICAL'
      | 'POINTS'
      | 'PROJECT_MANAGEMENT'
      | 'RECEIPT'
  ): BalancingActSimulationType {
    switch (typename) {
      case 'BUDGET':
        return BalancingActSimulationType.BUDGET;
      case 'CLIMATE':
        return BalancingActSimulationType.CLIMATE;
      case 'HOUSING':
        return BalancingActSimulationType.HOUSING;
      case 'NON_NUMERICAL':
        return BalancingActSimulationType.NON_NUMERICAL;
      case 'POINTS':
        return BalancingActSimulationType.POINTS;
      case 'PROJECT_MANAGEMENT':
        return BalancingActSimulationType.PROJECT_MANAGEMENT;
      case 'RECEIPT':
        return BalancingActSimulationType.RECEIPT;
    }
  }

  export function gqlToAccessSetting(gql: GqlAccessSetting): AccessSetting {
    switch (gql?.__typename) {
      case 'AccessSetting_Email':
      // return {
      //   type: AccessSettingType.EMAIL_LIST,
      //   emails: gql.emailLists.map((e) => ({
      //     id: e.id,
      //     name: e.name,
      //     include: true,
      //   })),
      // };
      case 'AccessSetting_Link':
        return {
          type: AccessSettingType.LINK_ONLY,
          emails: null,
        };
      case 'AccessSetting_Everyone':
      default:
        return {
          type: AccessSettingType.EVERYONE,
          emails: null,
        };
    }
  }

  export function getShortIdFromQuestionSet(
    set: GqlContentSetForShortId
  ): string | null {
    switch (set.__typename) {
      case 'Survey':
        return set.shortId;
      case 'PolcoLive':
        return set.shortId;
      default:
        return null;
    }
  }
}
