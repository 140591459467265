import * as React from 'react';
import { loadAppcuesLaunchpad } from 'client/shared/integrations/appcues';

let widget: ChildNode | null = null;
const APPCUES_LAUNCHPAD_ID = 'appcues-launchpad';

export const AppcuesLaunchpad: React.FC<{ readonly className?: string }> = (p) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (widget && ref.current) {
      ref.current.appendChild(widget);
    } else {
      loadAppcuesLaunchpad(`#${APPCUES_LAUNCHPAD_ID}`);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      widget = ref.current?.firstChild ?? null;
    };
  }, [ref]);
  return (
    <div className={p.className ?? ''} ref={ref}>
      <div id={APPCUES_LAUNCHPAD_ID}></div>
    </div>
  );
};
