import {
  BenchmarkRequest,
  BenchmarkRequestArea,
  BenchmarkRequestAreaGroup,
  BenchmarkRequestQuestionSetData,
  BenchmarkRequestStatus,
  BenchmarkRequestSurveyFormat,
  DataDictionaryRow,
  BenchmarkRequestTableItem,
} from 'client/admin/core/benchmark-request';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { SelectLanguageTextFunction } from 'client/shared/hooks';
import { ApiDate, ExtractGql, nullIfNaN } from 'core';
import { flatten } from 'lodash';
import { clientSurveyTypeToGql, gqlToSurveyType } from './benchmark-type';

type GqlBenchmarkReportRequest =
  Gql.SuperAdminBenchmarkReportRequest['superAdminBenchmarkReportRequestById'];

type GqlBenchmarkReportRequestTableItem =
  Gql.SuperAdminAllBenchmarkReportRequests['superAdminBenchmarkReportRequests'][0];

type GqlDataDictionarySurveyContents = ExtractGql<
  NonNullable<Gql.SuperAdminBenchmarkDataDictionary['openContentSetById']>,
  'Survey'
>['contents'];

type GqlDataDictionarySurveyQuestion =
  | ExtractGql<GqlDataDictionarySurveyContents[0], 'Question'>
  | ExtractGql<
      GqlDataDictionarySurveyContents[0],
      'QuestionHierarchyParentNode'
    >['questions'][0];

export namespace BenchmarkRequestTx {
  export function gqlToClient(
    request: GqlBenchmarkReportRequest,
    selectLanguageText: SelectLanguageTextFunction
  ): BenchmarkRequest {
    return {
      id: request.id,
      status: gqlToStatus(request.status),
      publishingEntity: {
        id: request.publishingEntity.id,
        name: selectLanguageText(request.publishingEntity.name),
        presidingAreaName: request.publishingEntity.presidingArea?.name ?? null,
      },
      reportDueDate: ApiDate.fromApi(request.reportDueAt),
      clientDueDate: ApiDate.fromApi(request.clientDueAt),
      surveyFormat: request.surveyFormat
        ? gqlToSurveyFormat(request.surveyFormat)
        : null,
      surveyType: gqlToSurveyType(request.benchmarkSurveyType),
      questionSetData: request.questionSetData?.map(gqlToQuestionSetData) ?? [],
      closeDate: ApiDate.fromApi(request.closedAt),
      fips: request.fips,
      areas: request.areaData?.map(gqlToAreaData) ?? [],
      hasTrends: request.trendsExpected,
      trendYears: request.expectedTrendYears,
      customBenchmarks: request.customBenchmarks,
      additionalNotes: request.additionalNotes,
      mailedCount: request.mailedCount,
      emailedCount: request.emailedCount,
      eligibleSamplingResponseCount: request.eligibleSamplingResponseCount,
      responseRate: request.responseRate,
    };
  }

  export function gqlToClient_TableItem(
    request: GqlBenchmarkReportRequestTableItem,
    selectLanguageText: SelectLanguageTextFunction
  ): BenchmarkRequestTableItem {
    return {
      id: request.id,
      status: gqlToStatus(request.status),
      publishingEntity: {
        id: request.publishingEntity.id,
        name: selectLanguageText(request.publishingEntity.name),
      },
      reportDueDate: ApiDate.fromApi(request.reportDueAt),
      surveyType: gqlToSurveyType(request.benchmarkSurveyType),
    };
  }

  export function clientToGql(
    request: Omit<BenchmarkRequest, 'publishingEntity'> & {
      readonly publishingEntityId: string;
    }
  ): Gql.BenchmarkReportRequestInput {
    return {
      id: request.id,
      publishingEntityId: request.publishingEntityId,
      reportDueAt: ApiDate.toApi(request.reportDueDate),
      clientDueAt: ApiDate.toApi(request.clientDueDate),
      surveyFormat: request.surveyFormat
        ? clientSurveyFormatToGql(request.surveyFormat)
        : null,
      benchmarkSurveyType: clientSurveyTypeToGql(request.surveyType),
      questionSetData: request.questionSetData.map(clientQuestionSetDataToGql),
      closedAt: ApiDate.toApi(request.closeDate),
      fips: request.fips,
      areaData: request.areas.map(clientAreaToGql),
      trendsExpected: request.hasTrends,
      expectedTrendYears: request.trendYears,
      customBenchmarks: request.customBenchmarks,
      additionalNotes: request.additionalNotes,
      mailedCount: request.mailedCount,
      emailedCount: request.emailedCount,
      eligibleSamplingResponseCount: nullIfNaN(
        request.eligibleSamplingResponseCount
      ),
      responseRate: nullIfNaN(request.responseRate),
    };
  }

  export function gqlToStatus(
    gqlStatus: Gql.BenchmarkReportRequestStatus
  ): BenchmarkRequestStatus {
    switch (gqlStatus) {
      case Gql.BenchmarkReportRequestStatus.DRAFT:
        return BenchmarkRequestStatus.DRAFT;
      case Gql.BenchmarkReportRequestStatus.PUBLISHED:
        return BenchmarkRequestStatus.PUBLISHED;
    }
  }

  export function clientStatusToGql(
    status: BenchmarkRequestStatus
  ): Gql.BenchmarkReportRequestStatus {
    switch (status) {
      case BenchmarkRequestStatus.DRAFT:
        return Gql.BenchmarkReportRequestStatus.DRAFT;
      case BenchmarkRequestStatus.PUBLISHED:
        return Gql.BenchmarkReportRequestStatus.PUBLISHED;
    }
  }

  export function gqlToSurveyFormat(
    gqlFormat: Gql.SurveyFormat
  ): BenchmarkRequestSurveyFormat {
    switch (gqlFormat) {
      case Gql.SurveyFormat.ONLINE_ONLY:
        return BenchmarkRequestSurveyFormat.ONLINE_ONLY;
      case Gql.SurveyFormat.MAIL_ONLY:
        return BenchmarkRequestSurveyFormat.MAIL_ONLY;
      case Gql.SurveyFormat.BOTH_ONLINE_AND_MAIL:
        return BenchmarkRequestSurveyFormat.BOTH_ONLINE_AND_MAIL;
    }
  }

  export function clientSurveyFormatToGql(
    format: BenchmarkRequestSurveyFormat
  ): Gql.SurveyFormat {
    switch (format) {
      case BenchmarkRequestSurveyFormat.ONLINE_ONLY:
        return Gql.SurveyFormat.ONLINE_ONLY;
      case BenchmarkRequestSurveyFormat.MAIL_ONLY:
        return Gql.SurveyFormat.MAIL_ONLY;
      case BenchmarkRequestSurveyFormat.BOTH_ONLINE_AND_MAIL:
        return Gql.SurveyFormat.BOTH_ONLINE_AND_MAIL;
    }
  }

  export function gqlToAreaGroup(
    gqlGroup: Gql.BenchmarkReportRequestAreaGroup
  ): BenchmarkRequestAreaGroup {
    switch (gqlGroup) {
      case Gql.BenchmarkReportRequestAreaGroup.A:
        return BenchmarkRequestAreaGroup.A;
      case Gql.BenchmarkReportRequestAreaGroup.B:
        return BenchmarkRequestAreaGroup.B;
    }
  }

  export function clientAreaGroupToGql(
    group: BenchmarkRequestAreaGroup
  ): Gql.BenchmarkReportRequestAreaGroup {
    switch (group) {
      case BenchmarkRequestAreaGroup.A:
        return Gql.BenchmarkReportRequestAreaGroup.A;
      case BenchmarkRequestAreaGroup.B:
        return Gql.BenchmarkReportRequestAreaGroup.B;
    }
  }

  export function gqlToQuestionSetData(
    gqlQuestionSetData: NonNullable<GqlBenchmarkReportRequest['questionSetData']>[0]
  ): BenchmarkRequestQuestionSetData {
    return {
      id: gqlQuestionSetData.id,
      questionSetId: gqlQuestionSetData.contentSet?.id ?? null,
      language: gqlQuestionSetData.language,
      optIn: gqlQuestionSetData.optIn,
      weighted: gqlQuestionSetData.weighted,
    };
  }

  export function clientQuestionSetDataToGql(
    questionSetData: BenchmarkRequestQuestionSetData
  ): Gql.BenchmarkReportRequestQuestionSetInput {
    return {
      id: questionSetData.id,
      contentSetId: questionSetData.questionSetId,
      language: questionSetData.language,
      optIn: questionSetData.optIn,
      weighted: questionSetData.weighted,
    };
  }

  export function gqlToAreaData(
    gqlArea: NonNullable<GqlBenchmarkReportRequest['areaData']>[0]
  ): BenchmarkRequestArea {
    return {
      id: gqlArea.id,
      name: gqlArea.name,
      url: gqlArea.value,
      group: gqlArea.group ? gqlToAreaGroup(gqlArea.group) : null,
      weight: gqlArea.weight,
    };
  }

  export function clientAreaToGql(
    area: BenchmarkRequestArea
  ): Gql.BenchmarkReportRequestAreaInput {
    return {
      id: area.id,
      name: area.name,
      value: area.url,
      group: area.group ? clientAreaGroupToGql(area.group) : null,
      weight: area.weight,
    };
  }

  export function gqlToDataDictionary(
    gql: GqlDataDictionarySurveyContents,
    selectLanguageText: SelectLanguageTextFunction
  ): readonly DataDictionaryRow[] {
    return flatten(
      gql.map((q) => {
        switch (q.__typename) {
          case 'Question':
            return gqlQuestionToDataDictionary(q, selectLanguageText);
          case 'QuestionHierarchyParentNode':
            return q.questions.map((row) =>
              gqlQuestionToDataDictionary(row, selectLanguageText, {
                gridLabel: selectLanguageText(q.label) ?? null,
              })
            );
          case 'QuestionHierarchyVisualizationNode':
            return [];
          case 'QuestionHierarchySimulationNode':
            return [];
        }
      })
    );
  }

  export function gqlQuestionToDataDictionary(
    question: GqlDataDictionarySurveyQuestion,
    selectLanguageText: SelectLanguageTextFunction,
    opts?: { readonly gridLabel: string | null }
  ): DataDictionaryRow {
    return {
      title: selectLanguageText(question.title),
      reportTitle: question.reportDisplayLabel,
      variableName: question.variableName,
      multipleSelect: question.choiceSet.maxSelection > 1 ? 'yes' : '',
      benchmarkArea: question.dataDictionary?.benchmarkArea ?? null,
      weightingLabel: question.dataDictionary?.weightingLabel ?? null,
      scaleThreshold: question.choiceSet.scaleData?.scaleThreshold ?? null,
      scaleType: question.choiceSet.scaleData?.scaleType ?? null,
      choices: question.choiceSet.choices.map((ch) => ({
        label: selectLanguageText(ch.text),
        value: ch.choiceValue,
      })),
      gridLabel: opts?.gridLabel ?? null,
    };
  }
}
