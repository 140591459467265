import { toTitleCase } from 'core';
import { prompts } from './prompts';

export function useAiPrompt(key: string) {
  const aiPrompt = prompts[key];
  if (!aiPrompt) {
    return null;
  }
  const { labelQuestion, prompt } = aiPrompt;
  return {
    prompt,
    labelQuestion: toTitleCase(labelQuestion),
  };
}
