import * as React from 'react';
import { ErrorNotFound as ErrorNotFoundShared } from 'client/shared/components/error-notice';
import { ClientUrlUtils } from 'client/shared/core/helpers';

export const ERROR_NOT_FOUND_COPY = {
  body: "It looks like you've tried to find a page that doesn't exist. Please use the button below to navigate back to familiarity.",
  continue: 'Continue to admin dashboard',
};

export const ErrorNotFound: React.FC = () => {
  return (
    <ErrorNotFoundShared
      continueRoute={ClientUrlUtils.admin.base.path()}
      copy={ERROR_NOT_FOUND_COPY}
    />
  );
};
