import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { AiAssistantQueryStatusType } from 'client/admin/core';
import { LoadedAdminData } from 'client/admin/shared/types';
import './styles.scss';
import * as React from 'react';

import _ from 'lodash';
import { FeatureSettingType } from 'core';
import { StoreItemPublisherStatus } from 'client/admin/core/premium';
import { getConfig } from 'client/shared/core/config';
import { MainChatContainer } from '../main-chat-container';
import { useAiAssistantSession } from '../../hooks/use-ai-assistant-session';
import { AdminError, ErrorType } from 'client/admin/shared/components/error';
import { AdminPageLoader } from 'client/admin/shared/components/page-loader';
import { RemainingQuestionLimit } from '../../components/remaining-questions-banner';
import { AiAssistantId } from '@polco-us/types';

const baseClass = 'pn-ai-assistant';
const config = getConfig();
const FREE_TRIAL_TIER_KEY = config.envFeatures.freeTrialTierKey;
export const COPY = {
  polly: 'Ask Polly AI',
};

export enum MessageInputDisableReason {
  EXPIRED_SESSION = 'EXPIRED_SESSION',
  QUERY_LIMIT_REACHED = 'QUERY_LIMIT_REACHED',
}
export interface AiAssistantContainerProps {
  readonly adminData: LoadedAdminData;
  readonly className?: string;
  readonly drawerEvents?: {
    readonly onOpenFullScreen?: () => void;
    readonly onCloseDrawer?: () => void;
  };
}

export const AiAssistantContainer: React.FC<AiAssistantContainerProps> = (p) => {
  const {
    aiAssistantMessages,
    loading,
    states,
    events,
    messageInputDisabledReason,
    isMessageInputDisabled,
    queryLimit,
    showQueryLimit,
    messageContent,
    setMessageContent,
  } = useAiAssistantSession({
    assistantId: AiAssistantId.POLLY,
    events: p.drawerEvents,
  });

  const memoizedOnSend = React.useCallback(
    async (onSendComplete?: () => void) =>
      await events.onSend(messageContent, () => {
        setMessageContent('');
        onSendComplete?.();
      }),
    [messageContent, events]
  );

  const memoizedRefreshAndRefetch = React.useMemo(
    () => ({
      refreshAndRefetch: async () => {
        setMessageContent('');
        await events.refreshSessionId();
      },
    }),
    [events]
  );
  const messageInputEvents = {
    setMessageContent,
    onSend: memoizedOnSend,
  };
  if (loading) {
    return <AdminPageLoader />;
  }
  if (_.isUndefined(states)) {
    return <AdminError errorType={ErrorType.NOT_FOUND} />;
  }

  const { isOnSendInProgress, isOnRefreshInProgress } = states.events;
  const { queryStatus } = states.session;

  const requestInProcess =
    queryStatus.value?.type === AiAssistantQueryStatusType.PROCESSING ||
    isOnSendInProgress.value;

  const { activePublishingEntity: pub } = p.adminData.admin;

  const aiSetting = pub.featureSettings.find(
    (f) => f.featureSettingName === FeatureSettingType.AI_ASSISTANT
  );
  const tiers = p.adminData.admin.activePublishingEntity.storeTiers;

  const publisherOnFreeTier = tiers.find(
    (t) =>
      t.storeItem.key === FREE_TRIAL_TIER_KEY &&
      t.status === StoreItemPublisherStatus.ACTIVATED
  );

  const isFree =
    !pub.isEnterprise &&
    !!aiSetting &&
    aiSetting.enabledForPublisher &&
    publisherOnFreeTier;

  const remainingQueryCount = _.isNaN(states?.publisher.queryCount)
    ? 0
    : queryLimit - (states?.publisher.queryCount ?? 0);
  return (
    <div
      className={`${baseClass}-session-container ${
        p.className ?? ''
      }  d-flex flex-column bg-gray-60 h-100 rounded w-100`}
    >
      <div
        className={`${baseClass}-session-header d-flex flex-row justify-content-between align-items-center bg-gray-60 p-2 mb-0 rounded-top`}
      >
        <h3 className={`${baseClass}-assistant-name text-white ml-4 mb-0`}>
          {COPY.polly}
        </h3>
        <div className={`${baseClass}-header-buttons bg-gray-60 border-0`}>
          {events.onOpenFullScreen && (
            <Btn
              action={events.onOpenFullScreen}
              className={`${baseClass}-header-buttons-open-full rounded-circle`}
              customPadding="p-2 mx-1"
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon
                className="font-size-md"
                icon={MaterialIconName.OPEN_IN_NEW}
              />
            </Btn>
          )}
          <Btn
            action={memoizedRefreshAndRefetch.refreshAndRefetch}
            className={`${baseClass}-header-buttons-refresh rounded-circle`}
            customPadding="p-2 mx-1"
            disabled={loading || requestInProcess}
            type={ButtonTypes.SEAMLESS}
          >
            <MaterialIcon className="font-size-md" icon={MaterialIconName.REFRESH} />
          </Btn>
          {events.onCloseDrawer && (
            <Btn
              action={events.onCloseDrawer}
              className={`${baseClass}-header-buttons-close rounded-circle`}
              customPadding="p-2 mx-1"
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon className="font-size-md" icon={MaterialIconName.CLOSE} />
            </Btn>
          )}
        </div>
      </div>
      <RemainingQuestionLimit
        expiresAt={aiSetting?.expiresAt}
        isFree={!!isFree}
        queryCount={states?.publisher.queryCount ?? 0}
        queryLimit={queryLimit}
        showQueryLimit={showQueryLimit}
      />
      <MainChatContainer
        adminData={p.adminData}
        aiAssistantMessages={aiAssistantMessages}
        assistantId={AiAssistantId.POLLY}
        className="border-none rounded-bottom"
        events={events}
        isLoading={isOnRefreshInProgress.value}
        isLoadingMore={false}
        isMessageInputDisabled={isMessageInputDisabled}
        loadingText={queryStatus.value?.loadingText}
        messageContent={messageContent}
        messageInputDisabledReason={messageInputDisabledReason}
        messageInputEvents={messageInputEvents}
        queryLimit={queryLimit}
        refreshAndRefetch={memoizedRefreshAndRefetch.refreshAndRefetch}
        remainingQueryCount={remainingQueryCount}
        requestInProcess={requestInProcess}
        sessionId={states.session.id}
        showCancelQuery={
          queryStatus.value?.type === AiAssistantQueryStatusType.PROCESSING &&
          !isOnSendInProgress.value
        }
        showQueryLimit={showQueryLimit && !messageInputDisabledReason}
      />
    </div>
  );
};
AiAssistantContainer.displayName = 'AiAssistantContainer';
