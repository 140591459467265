import { MessageInput } from '@chatscope/chat-ui-kit-react';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import _ from 'lodash';
import * as React from 'react';
import './styles.scss';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
const baseClass = 'pn-ai-assistant';
const COPY = {
  messageInputPlaceholder: 'Your message',
};

interface MessageInputWithCustomBtnProps {
  readonly messageContent: string;
  readonly isMessageInputDisabled: boolean;
  readonly events: {
    readonly setMessageContent: (messageContent: string) => void;
    readonly onSend: () => void;
    readonly onClickFileUpload: () => void;
  };
}

export const MessageInputWithCustomBtn: React.FC<MessageInputWithCustomBtnProps> = (
  p
) => {
  const fileUploadEnabled = useFlagEnabled(KnownFlag.AI_FILE_UPLOAD_ENABLED);

  return (
    <div className="d-flex flex-grow-1">
      {fileUploadEnabled && (
        <Btn
          action={p.events.onClickFileUpload}
          className="border-0 mr-1 pn-file-upload-btn"
          disabled={p.isMessageInputDisabled}
          type={ButtonTypes.SEAMLESS}
        >
          <MaterialIcon
            className="text-jungle"
            icon={MaterialIconName.ATTACH_FILE}
            iconType={'material-icons-outlined'}
          />
        </Btn>
      )}
      <div className={`${baseClass}-message-input-wrapper flex-grow-1`}>
        <MessageInput
          attachButton={false}
          attachDisabled={true}
          className={`${baseClass}-message-input flex-column rounded`}
          disabled={p.isMessageInputDisabled}
          onChange={(_innerHtml, textContent) => {
            p.events.setMessageContent(textContent);
          }}
          onSend={p.events.onSend}
          placeholder={
            p.isMessageInputDisabled ? undefined : COPY.messageInputPlaceholder
          }
          sendButton={false}
          value={p.messageContent}
        />
      </div>
      <Btn
        action={p.events.onSend}
        className={`${baseClass}-send-message-btn border-0 rounded ml-1`}
        disabled={p.isMessageInputDisabled || _.isEmpty(p.messageContent)}
        type={ButtonTypes.SEAMLESS}
      >
        <MaterialIcon
          className="text-jungle"
          icon={MaterialIconName.SEND}
          iconType="material-icons-outlined"
        />
      </Btn>
    </div>
  );
};
MessageInputWithCustomBtn.displayName = 'MessageInputWithCustomBtn';

interface CustomMessageInputProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  readonly as?: React.ElementType;
}

export const CustomMessageInput: React.FC<CustomMessageInputProps> = (p) => {
  return (
    <div className="d-flex flex-row w-100 border-top border-gray-60 py-3 px-1 bg-grayscale-2">
      {p.children}
    </div>
  );
};
CustomMessageInput.displayName = 'CustomMessageInput';
