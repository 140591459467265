import {
  AttributeType,
  FeatureSettingType,
  TrackDomainTypes,
  TrackDomainsFeatureSettings,
} from 'core';
import moment from 'moment';
import _ from 'lodash';

export interface FeatureSetting {
  readonly featureSettingId: string;
  readonly featureSettingName: string;
  readonly enabledForPublisher: boolean;
  readonly expiresAt: Date;
  readonly quantity: number;
  readonly isUnlimited: boolean;
}

export interface EvaluatedFeatureSetting extends FeatureSetting {
  readonly used?: number;
}

export const BreakdownTitles: Record<AttributeType, string> = {
  [AttributeType.BIRTH_DATE]: 'Age Range',
  [AttributeType.AGE_BRACKET]: 'Age Range', // This is self reported
  [AttributeType.AGE_BRACKET_50_PLUS]: 'Age Range',
  [AttributeType.CENSUS_BLOCK]: 'Census Block',
  [AttributeType.CENSUS_BLOCK_GROUP]: 'Census Block Group',
  [AttributeType.CENSUS_TRACT]: 'Census Tract',
  [AttributeType.CENSUS_BLOCK_GROUP_LOCATION]: 'Census Block Group Location',
  [AttributeType.CITY_COUNCIL_COMMISSIONER_DISTRICT]:
    'City Council Commissioner District',
  [AttributeType.CITY_WARD]: 'City Ward',
  [AttributeType.COUNTY]: 'County',
  [AttributeType.EMPLOYMENT_STATUS]: 'Employment Status',
  [AttributeType.GENDER]: 'Gender',
  [AttributeType.PRECINCT]: 'Precinct',
  [AttributeType.STATE]: 'State',
  [AttributeType.ZIP_CODE]: 'Zip Code',
  [AttributeType.VERIFIED]: 'Verified',
  [AttributeType.LOCATION]: 'Location',
  [AttributeType.RACE]: 'Race',
  [AttributeType.DEPRECATED_RACE_AND_ETHNICITY]: 'Race and Ethnicity',
  [AttributeType.ETHNICITY]: 'Ethnicity',
  [AttributeType.INCOME_LEVEL]: 'Income Level',
  [AttributeType.CHILDREN_IN_HOUSEHOLD]: 'Children in Household',
  [AttributeType.INDIVIDUALS_IN_HOUSEHOLD]: 'Number of Household Members',
  [AttributeType.OVER_65_IN_HOUSEHOLD]: 'Over 65 in Household',
  [AttributeType.HOUSING_COST]: 'Housing Cost',
  [AttributeType.RENT_OR_OWN_HOME]: 'Rent or Own Home',
  [AttributeType.RENT_OR_OWN_HOME_MORTAGE]: 'Rent or Own Home',
  [AttributeType.TYPE_OF_HOME]: 'Type of Home',
  [AttributeType.TYPE_OF_HOME_ASSISTED_CARE]: 'Type of Home',
  [AttributeType.COMPLETED_EDUCATION]: 'Completed Education Level',
  [AttributeType.ARMED_FORCES]: 'Armed Forces',
  [AttributeType.SEXUAL_ORIENTATION]: 'Sexual Orientation',
  [AttributeType.GENDER_IDENTITY]: 'Gender Identity',
  [AttributeType.SCHOOL_AGED_CHILDREN]: 'School Aged Children',
  [AttributeType.ENGLISH_FIRST_LANGUAGE]: 'English First Language',
  [AttributeType.COUNTRY_OF_ORIGIN]: 'Country of Origin',
  [AttributeType.POLITICAL_VIEWS]: 'Political Views',
  [AttributeType.RELIGIOUS_IDENTITY]: 'Religious Identity',
  [AttributeType.BUSINESS_AREA]: 'Business Area',
  [AttributeType.BUSINESS_INDUSTRY]: 'Business Industry',
  [AttributeType.BUSINESS_NUMBER_OF_EMPLOYEES]: 'Number of Employees',
  [AttributeType.HEADQUARTERS_LOCATION]: 'Headquarters Location',
  [AttributeType.BUSINESS_OWNER_DIVERSITY]: 'Business Owner Diversity',
  [AttributeType.BUSINESS_SIZE]: 'Business Size',
  [AttributeType.BATCH_IMPORT_ID]: 'Batch Import ID',
  [AttributeType.DISABILITY_OR_CONDITION]: 'Disability or Condition',
  [AttributeType.TYPE_OF_DISABILITY_OR_CONDITION]: 'Type of Disability or Condition',
};

type FeatureSettingsParams = Pick<
  FeatureSetting,
  'enabledForPublisher' | 'featureSettingName'
>;

export function isFeatureSettingEnabled(
  featureSettings: readonly FeatureSettingsParams[],
  type: FeatureSettingType
) {
  return featureSettings.some(
    (feature) => feature.enabledForPublisher && feature.featureSettingName === type
  );
}

export function getFeatureSettingDefaultExpirationDate() {
  return moment(new Date()).add(1, 'year').endOf('day').toDate();
}

export function getTrackDomainsEnabled(
  featureSettings: readonly FeatureSettingsParams[]
): readonly TrackDomainTypes[] {
  return _(Object.keys(TrackDomainsFeatureSettings))
    .map((td) => td as TrackDomainTypes)
    .filter((domain) => isFeatureSettingEnabled(featureSettings, domain))
    .value();
}
