import * as React from 'react';
import './styles.scss';
import { isString, reduce } from 'lodash';

export interface IUserAvatarProps {
  readonly name: string;
}

export class UserAvatar extends React.Component<IUserAvatarProps, object> {
  public render() {
    return (
      <div className="pn-user-avatar">
        <div className="pn-user-avatar-frame">{this.__genAvatarContent()}</div>
      </div>
    );
  }

  private readonly __genAvatarContent = () => {
    // TODO Support profile pictures
    if (isString(this.props.name)) {
      return this.__genInitialsFromName(this.props.name);
    } else {
      return '';
    }
  };

  private readonly __genInitialsFromName = (name: string) => {
    let arr = name.split(' ');
    if (arr.length > 2) {
      arr = arr.slice(0, 1);
    }

    return reduce(
      arr,
      (result, value) => {
        return result + value.charAt(0).toUpperCase();
      },
      ''
    );
  };
}
