import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import { MutationInfos } from 'client/shared/graphql-mutations';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';

export function useRequestedFeatures() {
  const result = useQueryInfo(QueryInfos.currentAdminRequestedFeatures, {});

  const { fn: requestFeature } = useMutationInfo(
    MutationInfos.adminRequestFeatures,
    {}
  );

  const requestedFeatures =
    result.data?.currentUser.user?.admin?.__typename === 'PrivateAdmin'
      ? result.data?.currentUser.user?.admin.requestedFeatures
      : [];

  const adminData =
    result.data?.currentUser.user?.admin?.__typename === 'PrivateAdmin'
      ? result.data.currentUser.user.admin
      : null;
  return {
    loading: result.loading && !result.data,
    requestedFeatures,
    requestFeature,
    adminData: {
      email: adminData?.email ?? null,
      phoneNumber: adminData?.phoneNumber ?? null,
      phoneExt: adminData?.phoneExt ?? null,
      commsEmail: adminData?.commsEmail ?? null,
    },
  };
}
