import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import * as React from 'react';
import './styles.scss';
import { BenchmarkFilter } from 'client/shared/graphql-client/graphql-operations.g';
import { getFilteredBenchmarkFooter } from 'client/shared/core/performance-data';
import {
  ChartType,
  StatisticType,
  TrackAreaMode,
  TrackDatum,
  TrackVariable,
  VisualizationType,
  wrap,
} from 'core';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { useGenerateImage } from 'client/shared/hooks';
import { DomainCardOptionsMenu } from '../domain-community-statistics-card';
import { EmbedLogoFooter } from '../embed-logo-footer';
import { EmbedDataPointContext } from '../visualization-picker';
import { DownloadImageButton } from '../download-image';
import _ from 'lodash';
import { TrackDatePill } from '../domain-card';
import MapView from '@arcgis/core/views/MapView';

const baseClass = 'pn-dashboard-card';

export interface Props {
  readonly chartTypeToSave?: ChartType;
  readonly className?: string;
  readonly description?: string;
  readonly title: string | JSX.Element;
  readonly imageGenerationInfo?: {
    readonly name: string;
    readonly buttonClass: string;
  };
  readonly recentValue?: TrackDatum;
  readonly variable?: TrackVariable;
  readonly saveVisualization?: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly disableSaveVisualization?: boolean;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly embedContext?: EmbedDataPointContext;
  readonly canNotManageDataPoints?: boolean;
  readonly comparisonGroupId?: string;
  readonly currentFips?: string;
  readonly areaMode: TrackAreaMode;
}

interface MapViewContext {
  readonly setView: React.Dispatch<React.SetStateAction<MapView | null>>;
}
export const MapViewContext = React.createContext<MapViewContext | null>(null);
export const DashboardCard: React.FC<Props> = (p) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [mapView, setMapView] = React.useState<MapView | null>(null);
  const imageMapData = React.useMemo(
    () => ({
      mapView: mapView ?? undefined,
      mapContainerClass: '.pn-track-map',
      mapLegendClass: '.pn-track-map-legend',
      classesToRemove: [
        '.pn-track-view-selector',
        '.pn-index-scores-chart-container-collapse-btns',
      ],
    }),
    [mapView]
  );
  const generateImage = useGenerateImage(ref, {
    imageName: `${p.variable?.label} indicator`,
    mapData: imageMapData,
  });
  const embeddableTrackEnabled = !p.canNotManageDataPoints;
  const { recentValue, variable } = p;

  const allFipsToSave = _.compact(
    p.comparisonGroupId
      ? variable?.areasData.map((ad) => ad.fipsArea).map((a) => a.id)
      : [p.recentValue?.fipsArea.id]
  );
  return (
    <div
      className={`${baseClass} p-3 ${!!p.embedContext ? 'my-0' : 'mt-4'} ${
        p.className ?? ''
      } ${p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''}`}
      ref={ref}
    >
      <div className={`${baseClass}-header py-1 d-flex justify-content-between`}>
        {typeof p.title === 'string' ? <h3>{p.title}</h3> : p.title}
        <div className="d-flex">
          {recentValue && (
            <TrackDatePill
              date={recentValue.recordedAt}
              isIndexScore={variable?.statisticType === StatisticType.INDEX}
            />
          )}
          {embeddableTrackEnabled && p.saveVisualization && !!variable ? (
            <DomainCardOptionsMenu
              benchmarkFilter={p.benchmarkFilter}
              canNotManageDataPoints={p.canNotManageDataPoints}
              comparisonGroupId={p.comparisonGroupId}
              currentFips={p.currentFips}
              disableSaveVisualization={p.disableSaveVisualization}
              embeddableTrackEnabled={embeddableTrackEnabled}
              events={{
                generateImage,
                saveVisualization: p.saveVisualization,
              }}
              groupFips={allFipsToSave}
              imageDownloadEnabled={!!p.imageGenerationInfo}
              recentValue={recentValue}
              variable={variable}
              visualizationType={wrap(() => {
                switch (p.chartTypeToSave) {
                  case ChartType.HISTOGRAM:
                    return VisualizationType.HISTOGRAM;
                  case ChartType.LINE:
                    return VisualizationType.LINE;
                  case ChartType.MAP:
                    return VisualizationType.MAP;
                  default:
                    return null;
                }
              })}
            />
          ) : (
            p.imageGenerationInfo && (
              <DownloadImageButton
                buttonClassName={p.imageGenerationInfo.buttonClass}
                imageRef={ref}
                name={p.imageGenerationInfo.name}
              />
            )
          )}
        </div>
      </div>
      {!!p.description && (
        <div className={`${baseClass}-description mb-3`}>{p.description}</div>
      )}
      <MapViewContext.Provider value={{ setView: setMapView }}>
        {p.children}
      </MapViewContext.Provider>
      {p.imageGenerationInfo && (
        <DownloadImageFooter
          footer={
            p.areaMode === TrackAreaMode.LOCAL
              ? getFilteredBenchmarkFooter(p.benchmarkFilter)
              : ''
          }
        />
      )}
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && (
        <EmbedLogoFooter className="pt-2" />
      )}
    </div>
  );
};
