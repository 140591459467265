import {
  transformGeoBoundingBoxToClientBounds,
  transformGqlMultiPolygonToClientMultiPolygon,
} from 'client/admin/shared/containers/maps/common';
import { useQueryInfo } from '../containers/query';
import { QueryInfos } from '../graphql-queries';
import { SafeRecordDictionary } from 'core';
import * as React from 'react';
import { MultiPolygon } from '@turf/helpers';
export const useComparisonGroupFipsShapes = (comparisonGroupId?: string) => {
  const {
    data: mapData,
    loading,
    error,
  } = useQueryInfo(QueryInfos.adminComparisonGroupFipsShapes, {
    variables: {
      comparisonGroupId: comparisonGroupId ?? '',
    },
    skip: !comparisonGroupId,
    // Don't cache the results of this query.
    // For large comparison groups, this returns tons of data (e.g. 30MB for "US States")
    // and that annihilates the Apollo cache as it deepFreezes objects.
    // At the moment, changing track domains does not refetch this because the comparison group ID doesn't change.
    // However changing comparison groups and then changing back will cause a refetch that could have hit the cache.
    // Supposedly Apollo only deepFreezes in development, but I see this lag in production too.
    // TODO: "Trick is to save data not as string to avoid JSON.stringify blocking your rendering."
    // So, could help to make an actual MultiPolygon type instead of a scalar?
    // Also I wonder if Apollo v4 might behave differently.
    fetchPolicy: 'no-cache',
  });

  const boundingCoordinates = transformGeoBoundingBoxToClientBounds(
    mapData?.openComparisonGroupById?.boundingBox
  );

  const fipsShapeByFipsCode: SafeRecordDictionary<string, MultiPolygon> =
    React.useMemo(() => {
      const result: SafeRecordDictionary<string, MultiPolygon> = {};

      (mapData?.openComparisonGroupById?.fips ?? []).forEach((fipsValue) => {
        if (fipsValue.shape) {
          result[fipsValue.id] =
            transformGqlMultiPolygonToClientMultiPolygon(fipsValue.shape) ??
            undefined;
        }
      });
      return result;
    }, [mapData]);

  const allFips = Object.keys(fipsShapeByFipsCode);

  return {
    loading,
    error,
    fipsShapeByFipsCode,
    boundingCoordinates,
    allFips,
    comparisonGroupName: mapData?.openComparisonGroupById?.name,
  };
};
