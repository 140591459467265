import { without } from 'lodash';
import * as d3 from 'd3';
import { colors, ColorContext } from 'client/shared/core/colors';

export enum DemographicDataSource {
  THIRD_PARTY = 'THIRD_PARTY',
  USER_REPORTED = 'USER_REPORTED',
}

export enum DataPointPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface DataSource {
  readonly source: DemographicDataSource;
  readonly setSource?: (newSource: DemographicDataSource) => Promise<any> | void;
}

export interface Data {
  readonly values: readonly DataPoint[];
  readonly unknownTotal: number;
}

/**
 * A common representation of a data point when the X axis is a
 * small, discrete set of values
 * @property `label` the label to display
 * @property `value` the numeric value associated with the data point
 */
export interface DataPoint {
  readonly label: string;
  readonly value: number;
  readonly isNull?: boolean;
  readonly color?: string;
  readonly position?: DataPointPosition;
  readonly barsId?: string;
}

/**
 * Append the d3 type for a type having a numeric value
 */
export type Valueable<T> = T & { valueOf(): number };

/**
 * DataPoint, but with Valuable added
 */
export type ValueableDataPoint = Valueable<DataPoint>;

/**
 * Adds valueOf() to a DataPoint (returns `DataPoint.value`).
 * @param p the data point to augment
 */
export function withValueOf(p: DataPoint): ValueableDataPoint {
  return {
    ...p,
    valueOf() {
      return p.value;
    },
  };
}

/**
 * Common properties of a d3 transition
 * @property `delay` how long to wait before starting transition (in ms)
 * @property `duration` how long the transition should last (in ms)
 */
export interface TransitionConfig {
  readonly delay: number;
  readonly duration: number;
}

/**
 * A function used to allow a graph type to select the location of a tooltip
 * relative to a DataPoint. Generally uses d3 scales or similar.
 * @param d the DataPoint for which to return a position to center the tooltip
 */
export type TranslateTooltip<TData = DataPoint> = (d: TData) => {
  readonly x: number;
  readonly y: number;
};

/**
 * A representation of the tooltip that will work for now.
 * One bold line and one regular line.
 * @property `line1Bold` the first line, rendered in bold
 * @property `line2` the secondary line, not bold
 */
export interface TooltipContent {
  readonly line1Bold: string;
  readonly line2: string;
  readonly line3?: string;
}

/**
 * Build the content for a tooltip based on a data point
 * @param d the data point
 */
export type BuildTooltipContent<TData = DataPoint> = (d: TData) => TooltipContent;

/**
 * A callback that is expected to set position and size for
 * hoverable elements. Generally some modification of the code to
 * draw the visible shapes in the graph
 * @param sel the hoverable elements
 */
export type PositionValueOverlays<TElem extends SVGElement, TData = DataPoint> = (
  sel: d3.Selection<TElem, TData, SVGElement, {}>
) => void;

// Convenience declaration
type QSel<TElem extends d3.BaseType, TData> = d3.SelectionOrTransition<
  TElem,
  TData,
  SVGElement,
  {}
>;

// Convenience declaration
type TransF<TElem extends d3.BaseType, TData, TRet> = (
  s: QSel<TElem, TData>
) => TRet;

// Convenience declaration
function callF<TElem extends d3.BaseType, TData>(
  s: QSel<TElem, TData>,
  f: TransF<TElem, TData, void>
): QSel<TElem, TData> {
  return (s as any).call(f);
}

/**
 * `d3.Selection.call()`able function that conditionally runs a transition
 * @param cfg the config of the transition (don't run if null or undefined)
 * @param start a function to set initial values before transition (not called if `!cfg`)
 * @param end a function to set final values after transition (may be called on
 * either d3.Selection or d3.Transition depending on if `!!cfg`)
 */
export function transition<TElem extends d3.BaseType, TData>(
  cfg: TransitionConfig | null | undefined,
  start: TransF<TElem, TData, void>,
  end: TransF<TElem, TData, void>
): TransF<TElem, TData, QSel<TElem, TData>> {
  return (sel: QSel<TElem, TData>) =>
    cfg
      ? callF(sel, start)
          .transition()
          .duration(cfg ? cfg.duration : 0)
          .delay(cfg ? cfg.delay : 0)
          .call(end)
      : callF(sel, end);
}

/**
 * Returns the base chart d3 scale for colors.
 */
export function chartColorScale(context: ColorContext) {
  return d3.scaleOrdinal(colors[context].hex);
}

/**
 * Inserts (possibly) new values into a domain, preserving order of existing elements
 * @param previous the previous domain without new additions
 * @param current the inbound domain which may add or remove elements
 * @returns the union of the two inputs, with all values in `previous` staying at the front, in order
 */
export function orderedDomain(
  previous: ReadonlyArray<string>,
  current: ReadonlyArray<string>
) {
  return [...previous, ...without(current, ...previous)];
}

/**
 * Create a new domain scale with new values, but preserves order.
 * @param scale the domain scale that already exists
 * @param currentDomain the new domain which may augment `scale`
 * @returns a d3.ScaleOrdinal<string,string> with additional values but preserved order
 */
export function updateDomain(
  scale: d3.ScaleOrdinal<string, string>,
  currentDomain: ReadonlyArray<string>
) {
  return scale.domain(orderedDomain(scale.domain(), currentDomain));
}
