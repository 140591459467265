import * as React from 'react';
import './styles.scss';
import {
  AppLink,
  ClickableDiv,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { SidebarButtonType, sidebarButtonCssClass } from 'client/admin/core';
import {
  SidebarButton,
  SidebarItem,
  SidebarSection,
  isSidebarSection,
} from '../types';

export interface Props {
  readonly className?: string;
  readonly elementConfig: SidebarItem;
  readonly selectedOptionId?: string;
}

const baseClass = 'pn-admin-sidebar';

export const sectionBaseClass = `${baseClass}-section`;
export const childButtonClass = `${sectionBaseClass}-button-container-child`;

const isSelectedOption = (optionId: string, selectedOptionId?: string) =>
  selectedOptionId === optionId;

export const AdminSidebarComponent: React.FC<Props> = (p) => {
  return isSidebarSection(p.elementConfig) ? (
    <AdminSidebarSectionWrapper {...p} elementConfig={p.elementConfig} />
  ) : (
    <AdminSidebarButton {...p.elementConfig} selectedOptionId={p.selectedOptionId} />
  );
};

const AdminSidebarSectionWrapper: React.FC<
  Props & { readonly elementConfig: SidebarSection }
> = (p) => {
  const {
    elementConfig: { children, name, icon, id },
  } = p;

  const isSectionExpanded = children.some(
    (childMenuItem) => childMenuItem.id === p.selectedOptionId
  );

  const [isExpanded, setIsExpanded] = React.useState(isSectionExpanded);

  return (
    <div className={`${sectionBaseClass} ${id}  py-2`}>
      <ClickableDiv
        action={() => {
          setIsExpanded(!isExpanded);
        }}
        className={`${sectionBaseClass}-header d-flex ${
          isExpanded ? `${sectionBaseClass}-header-active` : ''
        }`}
      >
        {icon && <img alt="icon" src={icon} />}
        <div className="flex-grow-1 font-weight-bold">{name}</div>
        <MaterialIcon
          className="expanded-icon"
          icon={
            isExpanded
              ? MaterialIconName.KEYBOARD_ARROW_UP
              : MaterialIconName.KEYBOARD_ARROW_DOWN
          }
        ></MaterialIcon>
      </ClickableDiv>
      <div
        className={`${sectionBaseClass}-childs-container ${
          isExpanded ? 'visible' : 'hidden'
        }`}
      >
        {children.map((child) => (
          <AdminSidebarButton
            {...child}
            key={child.id}
            selectedOptionId={p.selectedOptionId}
          />
        ))}
      </div>
    </div>
  );
};

const AdminSidebarButton: React.FC<
  SidebarButton & { readonly selectedOptionId?: string }
> = (p) => {
  const {
    id,
    text,
    url,
    icon,
    selectedOptionId,
    materialIcon,
    retainQueryParameters,
  } = p;
  if (!url) {
    return null;
  }
  return (
    <AppLink
      className={`${childButtonClass}
                  ${isSelectedOption(id, selectedOptionId) ? 'selected' : ''}
                  ${sidebarButtonCssClass(id)}
                  d-block font-size-3 text-sans-serif ${id === SidebarButtonType.FEATURE_SPOTLIGHT ? 'p-2 my-3' : 'p-2'}`}
      key={id}
      retainQueryParameters={retainQueryParameters}
      to={url}
      unstyled
    >
      <div className="d-flex align-items-center">
        {icon && <img alt="icon" className="mr-2" src={icon} />}
        {materialIcon && <MaterialIcon className="mr-2" icon={materialIcon} />}
        <span className="flex-grow-1">{text}</span>
      </div>
    </AppLink>
  );
};
