import { FetchResult } from '@apollo/client';
import { AiAssistantId } from '@polco-us/types';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { AdminAiAssistantUploadFile } from 'client/shared/graphql-client/graphql-operations.g';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { GqlError, PolcoGqlError, PolcoGqlErrors, Result } from 'core';
export interface UseAiAssistantFileUploadProps {
  readonly assistantId: AiAssistantId;
  readonly sessionId: string;
}

export function useAiAssistantFileUpload(props: UseAiAssistantFileUploadProps) {
  const { assistantId, sessionId } = props;
  const { fn: aiAssistantUploadFile, result } = useMutationInfo(
    MutationInfos.adminAiAssistantUploadFile
  );

  const uploadFile = async (file: string) => {
    const uploadResult = await Result.fromPromise<
      FetchResult<AdminAiAssistantUploadFile>,
      GqlError<PolcoGqlError<PolcoGqlErrors.PolcoApiError>>
    >(
      aiAssistantUploadFile({
        variables: {
          assistantId,
          sessionId,
          file,
        },
      })
    );

    return uploadResult;
  };

  return {
    loading: result.loading,
    data: result.data?.adminAiAssistantUploadFile,
    uploadFile,
  };
}
