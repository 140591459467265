import React from 'react';
import icon from './icon.svg';
import { Modal } from 'client/shared/components/modal';
import { useRedirect } from 'client/shared/hooks';
import { useRequestedFeatures } from 'client/admin/hooks';
import { useAiAssistantSession } from 'client/admin/ai/hooks/use-ai-assistant-session';
import { useQueryAdminInfo } from 'client/admin/shared/containers/query-admin';
import { isStandardAdminData } from 'client/admin/shared/types';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { KnownStoreKeys } from 'core';
import { AiAssistantId } from '@polco-us/types';

interface Props {
  readonly isOpen: boolean;
  readonly labelQuestion: string;
  readonly onClose: () => void;
}

export const AiQuickActionsUpgradeModal: React.FC<Props> = (p) => {
  const { isOpen, onClose } = p;
  const redirect = useRedirect();
  const { requestFeature } = useRequestedFeatures();
  const { queryLimit } = useAiAssistantSession({
    assistantId: AiAssistantId.POLLY,
  });
  const adminData = useQueryAdminInfo();
  const isEnterprise = isStandardAdminData(adminData.data)
    ? adminData.data.admin.activePublishingEntity.isEnterprise
    : false;

  const modalPrimaryAction = React.useMemo(() => {
    if (isEnterprise) {
      return {
        label: 'Request Information',
        action: async () => {
          await requestFeature({ variables: { features: ['polly'] } });
          onClose();
        },
      };
    } else {
      return {
        label: 'Upgrade Plan',
        action: async () => {
          await requestFeature({ variables: { features: ['polly'] } });
          redirect(
            ClientUrlUtils.admin.storeTiers.path({
              highlightKey: KnownStoreKeys.AI_ASSISTANT_PRO,
            }),
            {
              push: true,
            }
          );
        },
      };
    }
  }, [isEnterprise, redirect, requestFeature, onClose]);
  return (
    <Modal
      footer={{
        primaryAction: modalPrimaryAction,
        secondaryAction: {
          label: 'Close',
          action: onClose,
        },
      }}
      header={{
        title: (
          <>
            <img alt={'AI Icon'} className="mr-2" src={icon} />
            <span className="font-weight-bold font-size-lg">{p.labelQuestion}</span>
          </>
        ),
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="p-3 flex-grow-1 bg-brand-l text-wrap rounded text-center">
        <p>
          You have reached the limit of{' '}
          <span className="font-weight-bold">{queryLimit} questions</span> for this
          month.
        </p>
        <p>
          {isEnterprise ? 'Request information' : 'Upgrade your plan'} below to get
          more questions.
        </p>
      </div>
    </Modal>
  );
};
