import React from 'react';
import {
  Avatar,
  Message,
  MessageGroup,
  MessageModel,
} from '@chatscope/chat-ui-kit-react';
import moment from 'moment';
import { LoadedAdminData } from 'client/admin/shared/types';
import { UserAvatar } from 'client/admin/shared/components/user-avatar';
import {
  AiAssistantMessage,
  AiAssistantMessageGroup,
  AiAssistantMessageRole,
  AiAssistantMessageType,
} from 'client/admin/core/ai-assistant-session';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import './styles.scss';
import pollyParrotImage from 'client/assets/polly-parrot.svg';
import { AiAssistantId } from '@polco-us/types';
import { MarkdownWithEmbeddedContent } from './embedded-content';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';

const baseClass = 'pn-ai-assistant-message';
const ASSISTANT_NAME = 'Polly';

interface BaseMessageProps {
  readonly adminData: LoadedAdminData;
  readonly assistantId: AiAssistantId;
  readonly className?: string;
  readonly key?: string;
}
interface MessageGroupProps extends BaseMessageProps {
  readonly aiAssistantMessageGroup: AiAssistantMessageGroup;
  readonly sessionId: string;
}
interface MessageProps extends BaseMessageProps {
  readonly avatarOverride?: AvatarOverride;
  readonly aiAssistantMessage: AiAssistantMessage;
  readonly sessionId: string;
}

interface AvatarProps {
  readonly avatarOverride?: AvatarOverride;
  readonly adminData: LoadedAdminData;
  readonly assistantId: AiAssistantId;
  readonly role: AiAssistantMessageRole;
}
export const AiAssistantMessageComponent = (p: MessageProps): JSX.Element => {
  const sender = getSender(p.aiAssistantMessage.role, p.adminData);

  return (
    <Message
      avatarPosition={getAvatarPosition(p.aiAssistantMessage.role)}
      avatarSpacer={false}
      className={`${baseClass} ${p.className ?? ''} ${
        isSytem(p.aiAssistantMessage.role) ? 'system-message' : ''
      }`}
      key={p.key}
      model={aiAssistantMessageToMessage(
        p.aiAssistantMessage,
        sender,
        p.adminData.admin.activePublishingEntity.id,
        p.assistantId,
        p.sessionId,
        p.adminData.admin.activePublishingEntity.fips ?? undefined
      )}
      type={'custom'}
    >
      {AiAssistantAvatarComponent({
        avatarOverride: p.avatarOverride,
        adminData: p.adminData,
        assistantId: p.assistantId,
        role: p.aiAssistantMessage.role,
      })}
    </Message>
  );
};

// TODO: unused...?
export const AiAssistantMessageGroupComponent = (
  p: MessageGroupProps
): JSX.Element => {
  const sender = getSender(p.aiAssistantMessageGroup.role, p.adminData);

  return (
    <MessageGroup
      avatarPosition={getAvatarPosition(p.aiAssistantMessageGroup.role)}
      className={`${baseClass}-group`}
      direction={getMessageDirection(p.aiAssistantMessageGroup.role)}
      key={p.key}
      sender={sender}
    >
      <MessageGroup.Messages>
        {p.aiAssistantMessageGroup.content.map((content, idx) => {
          const position = getPosition(
            idx,
            p.aiAssistantMessageGroup.content.length
          );
          return (
            <Message
              className={`${baseClass} ${p.className ?? ''} ${
                isSytem(p.aiAssistantMessageGroup.role) ? 'system-message' : ''
              }`}
              key={idx}
              model={aiAssistantMessageToMessage(
                {
                  content,
                  role: p.aiAssistantMessageGroup.role,
                  timestamp: p.aiAssistantMessageGroup.timestamp,
                  type: AiAssistantMessageType.SINGLE,
                },
                sender,
                p.adminData.admin.activePublishingEntity.id,
                p.assistantId,
                p.sessionId,
                p.adminData.admin.activePublishingEntity.fips ?? undefined,
                position
              )}
              type="custom"
            ></Message>
          );
        })}
      </MessageGroup.Messages>
      {AiAssistantAvatarComponent({
        adminData: p.adminData,
        assistantId: p.assistantId,
        role: p.aiAssistantMessageGroup.role,
      })}
    </MessageGroup>
  );
};

export const AiAssistantAvatarComponent = (p: AvatarProps): JSX.Element => {
  const sender = p.avatarOverride ? 'system' : getSender(p.role, p.adminData);
  return (
    <Avatar
      className={`${baseClass}-avatar
      d-flex justify-content-center align-items-center m-1`}
      name={sender}
      size={'sm'}
      src={
        isAssistant(p.role) && p.assistantId === AiAssistantId.POLLY
          ? pollyParrotImage
          : undefined
      }
    >
      {getAvatarComponent({
        avatarOverride: p.avatarOverride,
        role: p.role,
        adminData: p.adminData,
        assistantId: p.assistantId,
      })}
    </Avatar>
  );
};

AiAssistantAvatarComponent.displayName = 'AiAssistantAvatarComponent';

function aiAssistantMessageToMessage(
  aiAssistantMessage: AiAssistantMessage,
  sender: string,
  pubId: ClientPublishingEntityId,
  assistantId: AiAssistantId,
  sessionId: string,
  fipsId?: string,
  position?: MessageModel['position']
): MessageModel {
  return {
    payload: (
      <Message.CustomContent>
        <MarkdownWithEmbeddedContent
          assistantId={assistantId}
          children={aiAssistantMessage.content}
          className={`${baseClass}-markdown`}
          fipsId={fipsId}
          pubId={pubId}
          sessionId={sessionId}
        />
      </Message.CustomContent>
    ),
    sentTime: moment(aiAssistantMessage.timestamp).fromNow(),
    sender,
    direction: getMessageDirection(aiAssistantMessage.role),
    position: position ?? 'single',
  };
}

export function isAssistant(role: AiAssistantMessageRole) {
  return role === AiAssistantMessageRole.ASSISTANT;
}
export function isSytem(role: AiAssistantMessageRole) {
  return role === AiAssistantMessageRole.SYSTEM;
}

export function getPosition(
  idx: number,
  messageGroupLength: number
): MessageModel['position'] {
  if (idx === 0) {
    return 'first';
  } else if (idx === messageGroupLength - 1) {
    return 'last';
  }
  return 'single';
}

function getSender(role: AiAssistantMessageRole, adminData: LoadedAdminData) {
  switch (role) {
    case AiAssistantMessageRole.ASSISTANT:
      return ASSISTANT_NAME;
    case AiAssistantMessageRole.USER:
      return adminData.admin.name;
    case AiAssistantMessageRole.SYSTEM:
      return 'System';
  }
}

function getAvatarPosition(role: AiAssistantMessageRole) {
  switch (role) {
    case AiAssistantMessageRole.USER:
      return 'tr';
    case AiAssistantMessageRole.ASSISTANT:
    case AiAssistantMessageRole.SYSTEM:
      return 'tl';
  }
}

function getMessageDirection(role: AiAssistantMessageRole) {
  switch (role) {
    case AiAssistantMessageRole.USER:
      return 'outgoing';
    case AiAssistantMessageRole.ASSISTANT:
    case AiAssistantMessageRole.SYSTEM:
      return 'incoming';
  }
}

export enum AvatarOverride {
  UPLOAD = 'UPLOAD',
}

function getAvatarComponent(args: {
  readonly avatarOverride?: AvatarOverride;
  readonly role: AiAssistantMessageRole;
  readonly adminData: LoadedAdminData;
  readonly assistantId: AiAssistantId;
}) {
  if (args.avatarOverride) {
    switch (args.avatarOverride) {
      case AvatarOverride.UPLOAD:
        return (
          <MaterialIcon
            className="text-grayscale-4"
            icon={MaterialIconName.ATTACH_FILE}
            iconType={'material-icons-outlined'}
          />
        );
    }
  }
  const { role, adminData, assistantId } = args;
  switch (role) {
    case AiAssistantMessageRole.ASSISTANT:
      switch (assistantId) {
        case AiAssistantId.POLLY:
          return null;
        case AiAssistantId.GRANT_WRITER:
          return <UserAvatar name="Grace" />;
      }
    case AiAssistantMessageRole.USER:
      return <UserAvatar name={adminData.admin.name} />;
    case AiAssistantMessageRole.SYSTEM:
      return (
        <MaterialIcon
          className="text-grayscale-4"
          icon={MaterialIconName.INFO}
          iconType={'material-icons-outlined'}
        />
      );
  }
}
