import * as React from 'react';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { RequestContactInfoModal } from '../request-contatct-info-modal';
import { wrap } from 'core';
import { useRequestedFeatures } from 'client/admin/hooks';

interface Props {
  readonly events: {
    readonly onRequest: (featureHubspotKey: string) => void;
  };
  readonly featureState: RequestableFeatureState;
  readonly hubspotKey?: string;
  readonly className?: string;
  readonly customFeatureStateCopy?: Record<
    RequestableFeatureState,
    RequestableFeatureButtonStateCopy
  >;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly buttonType?: ButtonTypes;
}
export enum RequestableFeatureState {
  AVAILABLE = 'AVAILABLE',
  PENDING = 'PENDING',
  ENABLED = 'ENABLED',
}

export const COPY = {
  requestFeature: 'Request Information',
  featureEnabled: 'Feature Enabled',
  informationRequested: 'Information Requested',
};

export interface RequestableFeatureButtonStateCopy {
  readonly className?: string;
  readonly label: string;
  readonly icon?: MaterialIconName;
  readonly iconClass?: string;
}

export const DefaultRequestFeatureCopy: Record<
  RequestableFeatureState,
  RequestableFeatureButtonStateCopy
> = {
  [RequestableFeatureState.AVAILABLE]: { label: COPY.requestFeature },
  [RequestableFeatureState.PENDING]: { label: COPY.informationRequested },
  [RequestableFeatureState.ENABLED]: { label: COPY.featureEnabled },
};

export const RequestableFeatureButton: React.FC<Props> = (props) => {
  const {
    hubspotKey,
    featureState,
    className,
    customFeatureStateCopy,
    disabled,
    loading,
  } = props;
  const [showContactInfoModal, setShowContactInfoModal] =
    React.useState<boolean>(false);
  const { adminData } = useRequestedFeatures();
  const featureStateCopy = customFeatureStateCopy ?? DefaultRequestFeatureCopy;
  if (!hubspotKey) {
    return null;
  }
  return (
    <>
      <RequestContactInfoModal
        adminEmail={adminData.email}
        commsEmailDefault={adminData.commsEmail}
        feature={props.hubspotKey ?? 'unnamed-feature'}
        isOpen={showContactInfoModal}
        onClose={() => setShowContactInfoModal(false)}
        phoneExtDefault={adminData.phoneExt}
        phoneNumberDefault={adminData.phoneNumber}
      />
      {wrap(() => {
        switch (featureState) {
          case RequestableFeatureState.AVAILABLE:
            return (
              <Btn
                action={() => {
                  props.events.onRequest(hubspotKey);
                  setShowContactInfoModal(true);
                }}
                className={`text-nowrap ${className ?? ''} ${featureStateCopy[featureState].className ?? ''}`}
                disabled={disabled}
                loading={loading}
                type={props.buttonType ?? ButtonTypes.PRIMARY}
              >
                {featureStateCopy[featureState].label}
              </Btn>
            );
          case RequestableFeatureState.PENDING:
            return (
              <div
                className={`${className} ${featureStateCopy[featureState].className ?? ''} pn-request-feature-pending d-flex align-items-center text-gray-40 font-weight-bold text-nowrap`}
              >
                <MaterialIcon
                  className={`${featureStateCopy[featureState].iconClass ?? ''} mr-1`}
                  icon={
                    featureStateCopy[featureState].icon ??
                    MaterialIconName.ACCESS_TIME
                  }
                />
                {featureStateCopy[featureState].label}
              </div>
            );
          case RequestableFeatureState.ENABLED:
            return (
              <div
                className={`${className} ${featureStateCopy[featureState].className ?? ''} d-flex align-items-center text-gray-40 font-weight-bold text-nowrap`}
              >
                <MaterialIcon
                  className={`${featureStateCopy[featureState].iconClass ?? ''} mr-1`}
                  icon={
                    featureStateCopy[featureState].icon ?? MaterialIconName.CHECK
                  }
                />
                {featureStateCopy[featureState].label}
              </div>
            );
        }
      })}
    </>
  );
};

RequestableFeatureButton.displayName = 'RequestableFeatureButton';
