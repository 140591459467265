import * as React from 'react';
import './styles.scss';
import {
  ActionLink,
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { Popover } from 'client/shared/components/base/popover';
import { AdminShareEmailForm } from './share-feature-form';

const baseClass = 'pn-admin-share-feature';

const SHARE_FEATURE_COPY = {
  title: 'Share Polco',
  emailSubject: 'Polco support request',
  share:
    'Share Polco with a friend or colleague by inviting them to sign up for their own free account.',
  sent: 'Success! Your message was sent.',
  shareAgain: 'Share Again',
};

export const ShareFeatureDropdown: React.FC = (p) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const onEscape = () => {
    setIsOpen(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <div id="admin-share-polco" ref={containerRef}>
      <Popover
        containerClassName={`${baseClass}-tooltip`}
        containerParent={containerRef.current || undefined}
        content={<ShareFeatureBody onClose={onClose} />}
        isOpen={isOpen}
        onEscape={() => onEscape()}
        positions={['bottom']}
      >
        <div className={`${baseClass}-action-container`}>
          <ActionLink
            action={() => setIsOpen(!isOpen)}
            className={`${baseClass}-selector-button`}
          >
            {p.children}
          </ActionLink>
        </div>
      </Popover>
    </div>
  );
};

const ShareFeatureBody: React.FC<{
  readonly onClose?: () => void;
}> = ({ onClose }) => {
  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);

  return (
    <div className={`pt-2 pb-3 px-3 ${baseClass}-popover-body d-flex flex-column`}>
      <div className="d-flex flex-row align-items-center">
        <div className={'font-size-lg font-weight-bold mr-auto'}>
          {SHARE_FEATURE_COPY.title}
        </div>
        {onClose && (
          <ActionLink
            action={() => onClose()}
            className="d-flex flex-column align-items-center"
          >
            <MaterialIcon icon={MaterialIconName.CLOSE} />
          </ActionLink>
        )}
      </div>
      {formSubmitted ? (
        <div className={`${baseClass}-submitted d-flex flex-column`}>
          <div className="d-flex align-items-center pb-3 font-weight-bold">
            <MaterialIcon
              className={`${baseClass}-submitted-check mr-2`}
              icon={MaterialIconName.CHECK}
            />
            {SHARE_FEATURE_COPY.sent}
          </div>
          <div className="mt-3">
            <Btn
              action={(e) => {
                e.preventDefault();
                setFormSubmitted(false);
              }}
              type={ButtonTypes.PRIMARY}
            >
              {SHARE_FEATURE_COPY.shareAgain}
            </Btn>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-4">{SHARE_FEATURE_COPY.share}</div>
          <div>
            <AdminShareEmailForm setFormSubmitted={setFormSubmitted} />
          </div>
        </div>
      )}
    </div>
  );
};
