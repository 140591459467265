import {
  MaterialIcon,
  MaterialIconName,
  TextArea,
  TextInput,
} from 'client/shared/components/base';
import { Modal } from 'client/shared/components/modal';
import React from 'react';
import './styles.scss';
import { InputType } from 'client/shared/components/base/text-input';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { formatPhoneNumber } from 'core';

interface Props {
  readonly className?: string;
  readonly onClose: () => void;
  readonly isOpen: boolean;
  readonly feature: string;
  readonly adminEmail: string | null;
  readonly phoneNumberDefault: string | null;
  readonly phoneExtDefault: string | null;
  readonly commsEmailDefault: string | null;
}

const baseClass = 'pn-request-contact-info-modal';

export const RequestContactInfoModal: React.FC<Props> = (p) => {
  const [phoneNumber, setPhoneNumber] = React.useState<string | null>(
    p.phoneNumberDefault
  );
  const [phoneExt, setPhoneExt] = React.useState<string | null>(p.phoneExtDefault);
  const [commsEmail, setCommsEmail] = React.useState<string | null>(
    p.commsEmailDefault
  );
  const [message, setMessage] = React.useState<string | null>(null);

  const { fn: contactInfoMn } = useMutationInfo(
    MutationInfos.adminRequestFeatureContactInfo
  );
  return (
    <Modal
      bodyClassName="px-4 pt-1"
      className={`${p.className ?? ''} ${baseClass}`}
      footer={{
        primaryAction: {
          label: 'Submit',
          action: async () => {
            await contactInfoMn({
              variables: {
                input: {
                  phone: phoneNumber,
                  phoneExt: phoneExt,
                  email: commsEmail,
                  message: message,
                  feature: p.feature,
                },
              },
            });
            p.onClose();
          },
        },
        secondaryAction: {
          label: 'Close',
          action: p.onClose,
        },
      }}
      header={{
        title: (
          <div className={`${baseClass}-header d-flex`}>
            <MaterialIcon
              className={`text-color-brand ml-0 mr-3 ${baseClass}-icon`}
              icon={MaterialIconName.DONE_OUTLINE}
              style={{ fontSize: '48px' }}
            />
            <p className="text-center font-size-base font-weight-normal">
              We got your request. A member of our team will be reaching out shortly
              to <b>{p.adminEmail}</b>.
            </p>
          </div>
        ),
        small: true,
        className: 'p-2',
      }}
      isOpen={p.isOpen}
      onRequestClose={p.onClose}
    >
      <hr className="w-100 mb-4 mt-0" />
      <div className={`${baseClass}-body mt-0`}>
        <p className="font-weight-bold mb-2">
          Is there a better way to get a hold of you?
        </p>
        <div className={`${baseClass}-body-contact-info d-flex mb-2 flex-wrap`}>
          <div className="mr-3">
            <p className="font-size-sm mb-1">Daytime Phone</p>
            <TextInput
              inputClassName={`${baseClass}-input phone p-2`}
              inputType={InputType.PHONE}
              onChange={(value) => setPhoneNumber(formatPhoneNumber(value))}
              placeholder="(555) 123-4567"
              value={phoneNumber ?? ''}
            />
          </div>
          <div className="mr-3">
            <p className="font-size-sm mb-1">Ext</p>
            <TextInput
              inputClassName={`${baseClass}-input phoneExt p-2`}
              onChange={setPhoneExt}
              placeholder="57755"
              value={phoneExt ?? ''}
            />
          </div>
          <div>
            <p className="font-size-sm mb-1">Additional Email Address</p>
            <TextInput
              inputClassName={`${baseClass}-input email p-2`}
              onChange={setCommsEmail}
              placeholder={'youremail@domain.com'}
              value={commsEmail ?? ''}
            />
          </div>
        </div>
        <div className={`${baseClass}-body-message`}>
          <p className="font-weight-bold mb-2">
            Are there any specifics you would like to cover?
          </p>
          <p className="font-size-sm mb-1">Message</p>
          <TextArea
            autoExpand
            inputClassName={`${baseClass}-input message`}
            onChange={setMessage}
            placeholder="Please enter your message here"
            value={message ?? ''}
          />
        </div>
      </div>
    </Modal>
  );
};
