import { MaterialIconName } from 'client/shared/components/base';

export interface SidebarButton {
  readonly id: string;
  readonly text: string;
  readonly url?: string;
  readonly icon?: string;
  readonly isNew?: boolean;
  readonly materialIcon?: MaterialIconName;
  readonly retainQueryParameters?: readonly string[];
}

export interface SidebarSection {
  readonly id: string;
  readonly name: string;
  readonly icon?: string;
  readonly children: readonly SidebarButton[];
}

export type SidebarItem = SidebarSection | SidebarButton;

export function isSidebarButton(option: SidebarItem): option is SidebarButton {
  return Object.keys(option).includes('text');
}

export function isSidebarSection(option: SidebarItem): option is SidebarSection {
  return Object.keys(option).includes('children');
}
