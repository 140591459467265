export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  MultiPolygon: readonly (readonly (readonly (readonly number[])[])[])[];
}

export type AccessSetting = AccessSetting_Email | AccessSetting_Everyone | AccessSetting_Link;

export interface AccessSettingInput {
  readonly EMAIL_LIST: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly type: AccessSettingType;
}

export enum AccessSettingType {
  EVERYONE = 'EVERYONE',
  LINK_ONLY = 'LINK_ONLY'
}

export interface AccessSetting_Email {
  readonly __typename: 'AccessSetting_Email';
  readonly emailLists: ReadonlyArray<EmailList>;
}

export interface AccessSetting_Everyone {
  readonly __typename: 'AccessSetting_Everyone';
  readonly _ignore: Maybe<Scalars['Boolean']>;
}

export interface AccessSetting_Link {
  readonly __typename: 'AccessSetting_Link';
  readonly _ignore: Maybe<Scalars['Boolean']>;
}

export interface ActiveFlags {
  readonly __typename: 'ActiveFlags';
  readonly flags: ReadonlyArray<FeatureFlag>;
}

export type Admin = PrivateAdmin | PublicAdmin;

export interface AdminBalancingActSimulation {
  readonly __typename: 'AdminBalancingActSimulation';
  readonly allowCustomMapsInModals: Scalars['Boolean'];
  readonly canPreview: Scalars['Boolean'];
  readonly canPublish: Scalars['Boolean'];
  readonly categories: ReadonlyArray<SimulationCategory>;
  readonly createDate: GqlDate;
  readonly customizations: Maybe<Scalars['String']>;
  readonly description: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isPrioritization: Scalars['Boolean'];
  readonly locale: Scalars['String'];
  readonly name: Scalars['String'];
  readonly pageviews: Scalars['Int'];
  readonly requireCustomMapsInModals: Scalars['Boolean'];
  readonly results: Maybe<BalancingActSimulationReportingResults>;
  readonly slug: Scalars['String'];
  readonly status: QuestionSetStatus;
  readonly submissionsAvailable: Scalars['Boolean'];
  readonly type: BalancingActSimulationType;
  readonly updatedAt: GqlDate;
  readonly url: Scalars['String'];
}

export interface AdminBase {
  readonly createDate: GqlDate;
  /** @deprecated Removing planhat */
  readonly hasPlanhatPortal: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isEnvisioUser: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly user: User;
}

export interface AdminCreateSocialConnectionInput {
  readonly provider: SocialConnectionProvider;
  readonly token: Scalars['String'];
}

export interface AdminPermission {
  readonly __typename: 'AdminPermission';
  readonly category: AdminPermissionCategory;
  readonly displayName: Scalars['String'];
  readonly name: Scalars['String'];
}

export interface AdminPermissionCategory {
  readonly __typename: 'AdminPermissionCategory';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface AdminPersona {
  readonly __typename: 'AdminPersona';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface AdminRegistration {
  readonly __typename: 'AdminRegistration';
  readonly createdTime: GqlDate;
  readonly decidingAdmin: Maybe<Admin>;
  readonly decision: Maybe<AdminRegistrationDecision>;
  readonly decisionTime: Maybe<GqlDate>;
  readonly desiredOutcome: Maybe<AdminRegistrationOutcome>;
  readonly id: Scalars['ID'];
  readonly interests: ReadonlyArray<AdminRegistrationInterest>;
  readonly metadata: ReadonlyArray<AdminRegistrationMetadata>;
  readonly permissions: ReadonlyArray<Scalars['String']>;
  readonly publishingEntity: Maybe<PublishingEntity>;
  readonly registrationReason: Maybe<AdminRegistrationReason>;
  readonly user: User;
}

export interface AdminRegistrationAdditionalDataInput {
  readonly demoRequested: InputMaybe<Scalars['Boolean']>;
  readonly interestIds: ReadonlyArray<Scalars['ID']>;
  readonly outcomeId: InputMaybe<Scalars['String']>;
  readonly phoneNumber: InputMaybe<Scalars['String']>;
  readonly reasonId: InputMaybe<Scalars['String']>;
}

export enum AdminRegistrationDecision {
  APPROVED = 'APPROVED',
  APPROVED_AUTOMATIC = 'APPROVED_AUTOMATIC',
  REJECTED = 'REJECTED'
}

export interface AdminRegistrationInterest {
  readonly __typename: 'AdminRegistrationInterest';
  readonly id: Scalars['ID'];
  readonly interest: Scalars['String'];
  readonly url: Scalars['String'];
}

export interface AdminRegistrationMetadata {
  readonly __typename: 'AdminRegistrationMetadata';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
}

export interface AdminRegistrationOutcome {
  readonly __typename: 'AdminRegistrationOutcome';
  readonly id: Scalars['ID'];
  readonly outcome: Scalars['String'];
}

export interface AdminRegistrationProfileSelectionInput {
  readonly adminRoleId: InputMaybe<Scalars['ID']>;
  readonly location: LocationInput;
  readonly organizationAreaId: Scalars['ID'];
  readonly organizationSubtypeId: InputMaybe<Scalars['ID']>;
  readonly organizationTypeId: Scalars['ID'];
  readonly profileName: Scalars['String'];
}

export interface AdminRegistrationReason {
  readonly __typename: 'AdminRegistrationReason';
  readonly id: Scalars['ID'];
  readonly reason: Scalars['String'];
}

export interface AdminRegistrationResult {
  readonly __typename: 'AdminRegistrationResult';
  readonly id: Scalars['ID'];
}

export interface AdminRegistrationRole {
  readonly __typename: 'AdminRegistrationRole';
  readonly archived: Scalars['Boolean'];
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly persona: Maybe<AdminPersona>;
}

export interface AdminRegistrationUserInput {
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly password: Scalars['String'];
  readonly referralSource: InputMaybe<Scalars['String']>;
}

export interface AdminRole {
  readonly __typename: 'AdminRole';
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly permissions: ReadonlyArray<AdminPermission>;
}

export enum AdminRoleFilter {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL'
}

export interface AdminShareEmailInput {
  readonly message: InputMaybe<Scalars['String']>;
  readonly recipientEmail: Scalars['String'];
  readonly recipientName: Scalars['String'];
}

export interface AdminUpdatePublishingEntityInput {
  readonly description: Scalars['String'];
  readonly mailingData: InputMaybe<PublishingEntityMailingsInput>;
  readonly name: Scalars['String'];
  readonly postedBy: Scalars['String'];
  readonly primaryImageUrl: InputMaybe<Scalars['String']>;
  readonly profileCoverImageUrl: InputMaybe<Scalars['String']>;
}

export interface AdminUpdateSocialConnectionInput {
  readonly provider: SocialConnectionProvider;
  readonly selectedProfiles: ReadonlyArray<Scalars['String']>;
}

export interface AggregateResults {
  readonly __typename: 'AggregateResults';
  readonly aggregateValue: Maybe<Scalars['Float']>;
  readonly averageChoiceValueData: Maybe<AverageChoiceValueData>;
  readonly benchmark: Maybe<BenchmarkAggregateResult>;
  readonly category: Maybe<Scalars['String']>;
  readonly choices: ReadonlyArray<AggregateVotingChoice>;
  readonly percentToMax: Maybe<Scalars['Float']>;
  readonly scaleData: Maybe<ScaleData>;
  readonly topic: Maybe<Scalars['String']>;
  readonly trend: Maybe<TrendAggregateResult>;
}

export interface AggregateVotingChoice {
  readonly __typename: 'AggregateVotingChoice';
  readonly choice: QuestionChoice;
  readonly rawTotal: Scalars['Float'];
  readonly weightedTotal: Maybe<Scalars['Float']>;
}

export interface AiAssistantConversation {
  readonly __typename: 'AiAssistantConversation';
  readonly messages: Maybe<ReadonlyArray<AiAssistantMessage>>;
  readonly modelVersion: Maybe<Scalars['String']>;
  readonly publishingEntityId: Maybe<Scalars['ID']>;
  readonly userId: Maybe<Scalars['ID']>;
}

export interface AiAssistantMessage {
  readonly __typename: 'AiAssistantMessage';
  readonly content: Scalars['String'];
  readonly role: AiAssistantMessageRole;
  readonly timestamp: GqlDate;
}

export enum AiAssistantMessageRole {
  ASSISTANT = 'ASSISTANT',
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}

export interface AiAssistantPublisher {
  readonly __typename: 'AiAssistantPublisher';
  readonly publishingEntityId: Scalars['ID'];
  readonly queryCount: Scalars['Int'];
  readonly sessionCount: Scalars['Int'];
}

export interface AiAssistantQueryStatus {
  readonly __typename: 'AiAssistantQueryStatus';
  readonly loadingText: Maybe<Scalars['String']>;
  readonly message: Maybe<Scalars['String']>;
  /** @deprecated Use message and loadingText instead */
  readonly statusMessage: Scalars['String'];
  readonly type: AiAssistantQueryStatusType;
}

export enum AiAssistantQueryStatusType {
  PROCESSING = 'PROCESSING',
  RESPONDED = 'RESPONDED'
}

export interface AiAssistantSession extends AiAssistantSessionBase {
  readonly __typename: 'AiAssistantSession';
  readonly assistantId: Scalars['String'];
  readonly conversation: Maybe<AiAssistantConversation>;
  readonly id: Scalars['ID'];
  readonly queryStatus: Maybe<AiAssistantQueryStatus>;
  readonly status: Maybe<AiAssistantSessionStatusType>;
}

export interface AiAssistantSessionBase {
  readonly assistantId: Scalars['String'];
  readonly conversation: Maybe<AiAssistantConversation>;
  readonly id: Scalars['ID'];
  readonly queryStatus: Maybe<AiAssistantQueryStatus>;
  readonly status: Maybe<AiAssistantSessionStatusType>;
}

export enum AiAssistantSessionStatusType {
  ACTIVE = 'ACTIVE'
}

export interface AiAssistantSessionWithStats extends AiAssistantSessionBase {
  readonly __typename: 'AiAssistantSessionWithStats';
  readonly assistantId: Scalars['String'];
  readonly conversation: Maybe<AiAssistantConversation>;
  readonly id: Scalars['ID'];
  readonly queryStatus: Maybe<AiAssistantQueryStatus>;
  readonly stats: AiAssistantPublisher;
  readonly status: Maybe<AiAssistantSessionStatusType>;
}

export interface AnalyticsBenchmark {
  readonly __typename: 'AnalyticsBenchmark';
  readonly filter: BenchmarkFilter;
  readonly percentileBucketIndex: Maybe<Scalars['Int']>;
  readonly value: AnalyticsBenchmarkValue;
}

export enum AnalyticsBenchmarkValue {
  HIGHER = 'HIGHER',
  LESS_FAVORABLE = 'LESS_FAVORABLE',
  LOWER = 'LOWER',
  MORE_FAVORABLE = 'MORE_FAVORABLE',
  MUCH_HIGHER = 'MUCH_HIGHER',
  MUCH_LOWER = 'MUCH_LOWER',
  SIMILAR = 'SIMILAR'
}

export enum AnalyticsDomain {
  COMMUNITY_CHARACTERISTICS = 'COMMUNITY_CHARACTERISTICS',
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  ECONOMY = 'ECONOMY',
  EDUCATION_ARTS_CULTURE = 'EDUCATION_ARTS_CULTURE',
  GOVERNANCE = 'GOVERNANCE',
  HEALTH_AND_WELLNESS = 'HEALTH_AND_WELLNESS',
  INCLUSIVITY_AND_ENGAGEMENT = 'INCLUSIVITY_AND_ENGAGEMENT',
  MOBILITY = 'MOBILITY',
  NATURAL_ENVIRONMENT = 'NATURAL_ENVIRONMENT',
  PARKS_AND_RECREATION = 'PARKS_AND_RECREATION',
  SAFETY = 'SAFETY',
  UTILITIES = 'UTILITIES'
}

export interface AnalyticsDomainType {
  readonly __typename: 'AnalyticsDomainType';
  readonly domain: AnalyticsDomain;
  readonly subdomain: Maybe<AnalyticsSubdomain>;
}

export enum AnalyticsSubdomain {
  AIR_QUALITY = 'AIR_QUALITY',
  ALTERNATE_MODES = 'ALTERNATE_MODES',
  ARTS_CULTURE = 'ARTS_CULTURE',
  AUTO = 'AUTO',
  BROADBAND = 'BROADBAND',
  BUSINESS_VITALITY = 'BUSINESS_VITALITY',
  CLIMATE = 'CLIMATE',
  COMMUNITY_CHARACTERISTICS = 'COMMUNITY_CHARACTERISTICS',
  COMMUNITY_CLEANLINESS = 'COMMUNITY_CLEANLINESS',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  DIVERSITY_INCLUSION = 'DIVERSITY_INCLUSION',
  ECONOMY = 'ECONOMY',
  EDUCATION = 'EDUCATION',
  EMPLOYMENT = 'EMPLOYMENT',
  ENGAGEMENT_CIVIC_SOCIAL = 'ENGAGEMENT_CIVIC_SOCIAL',
  ENVIRONMENTAL_HEALTH = 'ENVIRONMENTAL_HEALTH',
  EQUITY = 'EQUITY',
  FEELINGS_OF_SAFETY = 'FEELINGS_OF_SAFETY',
  FIRE_EMS = 'FIRE_EMS',
  FOOD_SECURITY = 'FOOD_SECURITY',
  GOVERNANCE = 'GOVERNANCE',
  HEALTHCARE = 'HEALTHCARE',
  HEALTHY_LIFESTYLES = 'HEALTHY_LIFESTYLES',
  HOUSING = 'HOUSING',
  INCOME = 'INCOME',
  LAND_USE = 'LAND_USE',
  LAW_ENFORCEMENT = 'LAW_ENFORCEMENT',
  LIBRARIES = 'LIBRARIES',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
  MOBILITY = 'MOBILITY',
  PARKS = 'PARKS',
  PATHS_AND_TRAILS = 'PATHS_AND_TRAILS',
  POWER = 'POWER',
  RECREATION = 'RECREATION',
  RESIDENT_HEALTH = 'RESIDENT_HEALTH',
  SAFETY = 'SAFETY',
  SOLID_WASTE_MANAGEMENT = 'SOLID_WASTE_MANAGEMENT',
  UTILITIES = 'UTILITIES',
  WATER_SEWER = 'WATER_SEWER',
  WELLNESS = 'WELLNESS'
}

export enum AnalyticsValueType {
  AMOUNT = 'AMOUNT',
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT'
}

export interface AnalyticsVariable {
  readonly __typename: 'AnalyticsVariable';
  readonly dateLevel: DateLevel;
  readonly demographicSegment: VariableDemographicSegment;
  readonly description: Maybe<Scalars['String']>;
  readonly direction: Maybe<TrackVariableDirection>;
  readonly domains: ReadonlyArray<AnalyticsDomainType>;
  readonly id: Scalars['ID'];
  readonly includedInIndex: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly isOA: Scalars['Boolean'];
  readonly label: Scalars['String'];
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly statisticType: StatisticType;
  readonly suffix: Maybe<Scalars['String']>;
  readonly valueType: AnalyticsValueType;
}

export interface ArbitraryQueryParamInput {
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
}

export enum AreaLookupType {
  ANY = 'ANY',
  CITY = 'CITY',
  COUNTY = 'COUNTY',
  STATE = 'STATE',
  SUB_CITY = 'SUB_CITY'
}

export interface AverageChoiceValueData {
  readonly __typename: 'AverageChoiceValueData';
  readonly averageChoiceValue: Scalars['Float'];
  readonly scaleName: Scalars['String'];
}

export interface BalancingActCustomer {
  readonly __typename: 'BalancingActCustomer';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
}

export interface BalancingActReportingCategory extends BalancingActReportingCategoryBase {
  readonly __typename: 'BalancingActReportingCategory';
  readonly averageAmountDecreased: Scalars['Float'];
  readonly averageAmountIncreased: Scalars['Float'];
  readonly averagePercentChange: Scalars['Float'];
  readonly averageSessionAmountChanged: Scalars['Float'];
  readonly averageSessionDecreased: Scalars['Float'];
  readonly averageSessionIncreased: Scalars['Float'];
  readonly averageSubmittedAmount: Scalars['Float'];
  readonly averageSubmittedAmountChanged: Scalars['Float'];
  readonly decreaseClicks: Scalars['Int'];
  readonly detailsClicks: Scalars['Int'];
  readonly displayOrder: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly increaseClicks: Scalars['Int'];
  readonly infoClicks: Scalars['Int'];
  readonly maxSubmittedAmount: Scalars['Float'];
  readonly medianSessionAmountChanged: Scalars['Float'];
  readonly medianSubmittedAmountChanged: Scalars['Float'];
  readonly minSubmittedAmount: Scalars['Float'];
  readonly modeSessionAmountChanged: Scalars['Float'];
  readonly modeSubmittedAmountChanged: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly openClicks: Scalars['Int'];
  readonly percentSubmissionsChanged: Scalars['Float'];
  readonly percentSubmissionsDecreased: Scalars['Float'];
  readonly percentSubmissionsIncreased: Scalars['Float'];
  readonly percentSubmissionsUnchanged: Scalars['Float'];
  readonly subcategories: ReadonlyArray<BalancingActReportingSubcategory>;
  readonly total: Scalars['Float'];
  readonly totalSubmissionsChanged: Scalars['Int'];
  readonly totalSubmissionsDecreased: Scalars['Int'];
  readonly totalSubmissionsIncreased: Scalars['Int'];
  readonly totalSubmissionsUnchanged: Scalars['Int'];
  readonly type: Scalars['String'];
}

export interface BalancingActReportingCategoryBase {
  readonly averageAmountDecreased: Scalars['Float'];
  readonly averageAmountIncreased: Scalars['Float'];
  readonly averagePercentChange: Scalars['Float'];
  readonly averageSessionAmountChanged: Scalars['Float'];
  readonly averageSessionDecreased: Scalars['Float'];
  readonly averageSessionIncreased: Scalars['Float'];
  readonly averageSubmittedAmount: Scalars['Float'];
  readonly averageSubmittedAmountChanged: Scalars['Float'];
  readonly decreaseClicks: Scalars['Int'];
  readonly detailsClicks: Scalars['Int'];
  readonly displayOrder: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly increaseClicks: Scalars['Int'];
  readonly infoClicks: Scalars['Int'];
  readonly maxSubmittedAmount: Scalars['Float'];
  readonly medianSessionAmountChanged: Scalars['Float'];
  readonly medianSubmittedAmountChanged: Scalars['Float'];
  readonly minSubmittedAmount: Scalars['Float'];
  readonly modeSessionAmountChanged: Scalars['Float'];
  readonly modeSubmittedAmountChanged: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly percentSubmissionsChanged: Scalars['Float'];
  readonly percentSubmissionsDecreased: Scalars['Float'];
  readonly percentSubmissionsIncreased: Scalars['Float'];
  readonly percentSubmissionsUnchanged: Scalars['Float'];
  readonly totalSubmissionsChanged: Scalars['Int'];
  readonly totalSubmissionsDecreased: Scalars['Int'];
  readonly totalSubmissionsIncreased: Scalars['Int'];
  readonly totalSubmissionsUnchanged: Scalars['Int'];
}

export interface BalancingActReportingMetric {
  readonly __typename: 'BalancingActReportingMetric';
  readonly amount: Scalars['Float'];
  readonly label: Scalars['String'];
  readonly percentage: Scalars['Float'];
  readonly rank: Scalars['Float'];
}

export interface BalancingActReportingMetricGroups {
  readonly __typename: 'BalancingActReportingMetricGroups';
  readonly age: Maybe<ReadonlyArray<BalancingActReportingMetric>>;
  readonly city: Maybe<ReadonlyArray<BalancingActReportingMetric>>;
  readonly deviceType: Maybe<ReadonlyArray<BalancingActReportingMetric>>;
  readonly gender: Maybe<ReadonlyArray<BalancingActReportingMetric>>;
  readonly userType: Maybe<ReadonlyArray<BalancingActReportingMetric>>;
}

export interface BalancingActReportingSource {
  readonly __typename: 'BalancingActReportingSource';
  readonly percentage: Maybe<Scalars['Float']>;
  readonly rank: Maybe<Scalars['Float']>;
  readonly sessions: Maybe<Scalars['Int']>;
  readonly source: Maybe<Scalars['String']>;
}

export interface BalancingActReportingSubcategory extends BalancingActReportingCategoryBase {
  readonly __typename: 'BalancingActReportingSubcategory';
  readonly amount: Scalars['Float'];
  readonly averageAmountDecreased: Scalars['Float'];
  readonly averageAmountIncreased: Scalars['Float'];
  readonly averagePercentChange: Scalars['Float'];
  readonly averageSessionAmountChanged: Scalars['Float'];
  readonly averageSessionDecreased: Scalars['Float'];
  readonly averageSessionIncreased: Scalars['Float'];
  readonly averageSubmittedAmount: Scalars['Float'];
  readonly averageSubmittedAmountChanged: Scalars['Float'];
  readonly averageSubmittedRate: Scalars['Float'];
  readonly comments: ReadonlyArray<BalancingActReportingSubcategoryComment>;
  readonly customQuestionText: Maybe<Scalars['String']>;
  readonly customizedQuestionJson: Maybe<Scalars['String']>;
  readonly decreaseClicks: Scalars['Int'];
  readonly detailsClicks: Scalars['Int'];
  readonly displayOrder: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly increaseClicks: Scalars['Int'];
  readonly infoClicks: Scalars['Int'];
  readonly maxSubmittedAmount: Scalars['Float'];
  readonly medianSessionAmountChanged: Scalars['Float'];
  readonly medianSubmittedAmountChanged: Scalars['Float'];
  readonly minSubmittedAmount: Scalars['Float'];
  readonly modeSessionAmountChanged: Scalars['Float'];
  readonly modeSubmittedAmountChanged: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly percentSubmissionsChanged: Scalars['Float'];
  readonly percentSubmissionsDecreased: Scalars['Float'];
  readonly percentSubmissionsIncreased: Scalars['Float'];
  readonly percentSubmissionsUnchanged: Scalars['Float'];
  readonly questionOptions: ReadonlyArray<BalancingActReportingSubcategoryQuestionOption>;
  readonly totalSubmissionsChanged: Scalars['Int'];
  readonly totalSubmissionsDecreased: Scalars['Int'];
  readonly totalSubmissionsIncreased: Scalars['Int'];
  readonly totalSubmissionsUnchanged: Scalars['Int'];
}

export interface BalancingActReportingSubcategoryComment {
  readonly __typename: 'BalancingActReportingSubcategoryComment';
  readonly text: Scalars['String'];
  readonly timestamp: Scalars['String'];
}

export interface BalancingActReportingSubcategoryQuestionOption {
  readonly __typename: 'BalancingActReportingSubcategoryQuestionOption';
  readonly clickedCount: Scalars['Int'];
  readonly clickedPercentage: Scalars['Float'];
  readonly deltaAmount: Scalars['Float'];
  readonly displayOrder: Scalars['Int'];
  readonly netAmount: Scalars['Float'];
  readonly submittedCount: Scalars['Int'];
  readonly submittedPercentage: Scalars['Float'];
  readonly title: Scalars['String'];
}

export interface BalancingActSimulationReportingResults {
  readonly __typename: 'BalancingActSimulationReportingResults';
  readonly averageSecondsOnSite: Scalars['Int'];
  readonly averageSubmittedExpenses: Scalars['Float'];
  readonly averageSubmittedRevenue: Scalars['Float'];
  readonly budgetName: Scalars['String'];
  readonly categories: ReadonlyArray<BalancingActReportingCategory>;
  readonly clientName: Scalars['String'];
  readonly locale: Maybe<Scalars['String']>;
  readonly metric: BalancingActReportingMetricGroups;
  readonly referrerPaths: ReadonlyArray<BalancingActReportingSource>;
  readonly resetAllClicks: Scalars['Int'];
  readonly socialNetworks: ReadonlyArray<BalancingActReportingSource>;
  readonly sources: ReadonlyArray<BalancingActReportingSource>;
  readonly status: QuestionSetStatus;
  readonly submissionCount: Scalars['Int'];
  readonly taxpayerReceiptExperiences: Scalars['Int'];
  readonly totalPageviews: Scalars['Int'];
}

export enum BalancingActSimulationType {
  BUDGET = 'BUDGET',
  CLIMATE = 'CLIMATE',
  HOUSING = 'HOUSING',
  NON_NUMERICAL = 'NON_NUMERICAL',
  POINTS = 'POINTS',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  RECEIPT = 'RECEIPT'
}

export interface BalancingActSimulationWithPubId {
  readonly __typename: 'BalancingActSimulationWithPubId';
  readonly customerId: Scalars['Int'];
  readonly description: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly prioritization: Scalars['Boolean'];
  readonly publishedAt: GqlDate;
  readonly publishingEntityId: Scalars['ID'];
  readonly simulationType: Scalars['String'];
  readonly url: Scalars['String'];
}

export interface BenchmarkAggregateResult {
  readonly __typename: 'BenchmarkAggregateResult';
  readonly benchmarkSignificance: Maybe<Scalars['Int']>;
  readonly dateRange: BenchmarkDateRange;
  readonly rank: Scalars['Int'];
  readonly totalDataPoints: Scalars['Int'];
  readonly value: BenchmarkValue;
}

export interface BenchmarkDateRange {
  readonly __typename: 'BenchmarkDateRange';
  readonly end: GqlDate;
  readonly start: GqlDate;
}

export enum BenchmarkFilter {
  DEFAULT = 'DEFAULT',
  POPULATION = 'POPULATION',
  SES_DEFAULT = 'SES_DEFAULT',
  SES_POPULATION = 'SES_POPULATION',
  SES_STATE = 'SES_STATE',
  SES_STATE_AND_POP = 'SES_STATE_AND_POP',
  STATE = 'STATE',
  STATE_AND_POP = 'STATE_AND_POP'
}

export interface BenchmarkProgramInput {
  readonly addonItems: ReadonlyArray<ReviewSelectionNotification>;
  readonly singleAddonItem: ReadonlyArray<SurveyItems>;
}

export interface BenchmarkReportRequest {
  readonly __typename: 'BenchmarkReportRequest';
  readonly additionalNotes: Maybe<Scalars['String']>;
  readonly areaData: Maybe<ReadonlyArray<BenchmarkReportRequestAreaData>>;
  readonly benchmarkSurveyType: BenchmarkSurveyTypes;
  readonly clientDueAt: Maybe<GqlDate>;
  readonly closedAt: Maybe<GqlDate>;
  readonly customBenchmarks: Maybe<Scalars['String']>;
  readonly eligibleSamplingResponseCount: Maybe<Scalars['Int']>;
  readonly emailedCount: Maybe<Scalars['Int']>;
  readonly expectedTrendYears: Maybe<Scalars['String']>;
  readonly fips: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly mailedCount: Maybe<Scalars['Int']>;
  readonly publishingEntity: PublishingEntity;
  readonly questionSetData: Maybe<ReadonlyArray<BenchmarkReportRequestQuestionSetData>>;
  readonly reportDueAt: Maybe<GqlDate>;
  readonly responseRate: Maybe<Scalars['Float']>;
  readonly startAt: Maybe<GqlDate>;
  readonly status: BenchmarkReportRequestStatus;
  readonly surveyFormat: Maybe<SurveyFormat>;
  readonly trendsExpected: Scalars['Boolean'];
}

export interface BenchmarkReportRequestAreaData {
  readonly __typename: 'BenchmarkReportRequestAreaData';
  readonly group: Maybe<BenchmarkReportRequestAreaGroup>;
  readonly id: Scalars['ID'];
  readonly name: Maybe<Scalars['String']>;
  readonly value: Maybe<Scalars['String']>;
  readonly weight: Maybe<Scalars['Float']>;
}

export enum BenchmarkReportRequestAreaGroup {
  A = 'A',
  B = 'B'
}

export interface BenchmarkReportRequestAreaInput {
  readonly group: InputMaybe<BenchmarkReportRequestAreaGroup>;
  readonly id: InputMaybe<Scalars['ID']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly value: InputMaybe<Scalars['String']>;
  readonly weight: InputMaybe<Scalars['Float']>;
}

export interface BenchmarkReportRequestInput {
  readonly additionalNotes: InputMaybe<Scalars['String']>;
  readonly areaData: ReadonlyArray<BenchmarkReportRequestAreaInput>;
  readonly benchmarkSurveyType: BenchmarkSurveyTypes;
  readonly clientDueAt: InputMaybe<GqlDateInput>;
  readonly closedAt: InputMaybe<GqlDateInput>;
  readonly customBenchmarks: InputMaybe<Scalars['String']>;
  readonly eligibleSamplingResponseCount: InputMaybe<Scalars['Int']>;
  readonly emailedCount: InputMaybe<Scalars['Int']>;
  readonly expectedTrendYears: InputMaybe<Scalars['String']>;
  readonly fips: InputMaybe<Scalars['String']>;
  readonly id: InputMaybe<Scalars['ID']>;
  readonly mailedCount: InputMaybe<Scalars['Int']>;
  readonly publishingEntityId: Scalars['ID'];
  readonly questionSetData: ReadonlyArray<BenchmarkReportRequestQuestionSetInput>;
  readonly reportDueAt: InputMaybe<GqlDateInput>;
  readonly responseRate: InputMaybe<Scalars['Float']>;
  readonly startAt: InputMaybe<GqlDateInput>;
  readonly surveyFormat: InputMaybe<SurveyFormat>;
  readonly trendsExpected: InputMaybe<Scalars['Boolean']>;
}

export interface BenchmarkReportRequestQuestionSetData {
  readonly __typename: 'BenchmarkReportRequestQuestionSetData';
  readonly contentSet: Maybe<ContentSet>;
  readonly id: Scalars['ID'];
  readonly language: Maybe<Scalars['String']>;
  readonly optIn: Scalars['Boolean'];
  readonly weighted: Scalars['Boolean'];
}

export interface BenchmarkReportRequestQuestionSetInput {
  readonly contentSetId: InputMaybe<Scalars['ID']>;
  readonly id: InputMaybe<Scalars['ID']>;
  readonly language: InputMaybe<Scalars['String']>;
  readonly optIn: InputMaybe<Scalars['Boolean']>;
  readonly weighted: InputMaybe<Scalars['Boolean']>;
}

export enum BenchmarkReportRequestStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum BenchmarkSurveyTypes {
  ARPA_BUSINESS = 'ARPA_BUSINESS',
  ARPA_RESIDENT = 'ARPA_RESIDENT',
  CASOA = 'CASOA',
  NBS = 'NBS',
  NCI = 'NCI',
  NCS = 'NCS',
  NCS_OA = 'NCS_OA',
  NES = 'NES',
  NES_LE = 'NES_LE',
  NLES = 'NLES',
  NPSS = 'NPSS'
}

export interface BenchmarkTopic {
  readonly __typename: 'BenchmarkTopic';
  readonly category: Maybe<TopicCategory>;
  readonly description: Maybe<Scalars['String']>;
  readonly icon: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly trackDomain: Maybe<AnalyticsDomain>;
}

export interface BenchmarkType {
  readonly __typename: 'BenchmarkType';
  readonly type: Maybe<BenchmarkSurveyTypes>;
  readonly weighted: Scalars['Boolean'];
}

export enum BenchmarkValue {
  HIGHER = 'HIGHER',
  LOWER = 'LOWER',
  MUCH_HIGHER = 'MUCH_HIGHER',
  MUCH_LOWER = 'MUCH_LOWER',
  SIMILAR = 'SIMILAR'
}

export enum BillingScheme {
  PER_UNIT = 'PER_UNIT',
  TIERED = 'TIERED'
}

export interface BooleanFlag {
  readonly __typename: 'BooleanFlag';
  readonly enabled: Scalars['Boolean'];
  readonly flagKey: Scalars['String'];
  readonly reason: FlagEvaluationReason;
}

export enum BottomIcon {
  LIVE_TV = 'LIVE_TV'
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export interface CensusPlace {
  readonly __typename: 'CensusPlace';
  readonly center: CoordinateCenter;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly shape: GeoJsonMultiPolygon;
}

export enum ClientOnlyAnalyticsEvent {
  ADMIN_ATTEMPTED_PROFILE_COVER_IMAGE_UPLOAD = 'ADMIN_ATTEMPTED_PROFILE_COVER_IMAGE_UPLOAD',
  ADMIN_ATTEMPTED_PROFILE_IMAGE_UPLOAD = 'ADMIN_ATTEMPTED_PROFILE_IMAGE_UPLOAD',
  ADMIN_POSTED_DRAFT_SURVEY = 'ADMIN_POSTED_DRAFT_SURVEY',
  ADMIN_VIEWED_ENGAGEMENT_BOOST_OPTIONS = 'ADMIN_VIEWED_ENGAGEMENT_BOOST_OPTIONS'
}

export interface Comment {
  readonly __typename: 'Comment';
  readonly comment: Scalars['String'];
  readonly commentDate: GqlDate;
  readonly commenter: Respondent;
  readonly commenterChoice: Maybe<QuestionChoice>;
  readonly id: Scalars['ID'];
  readonly question: Question;
  readonly upvoteCount: Scalars['Int'];
}

export interface CommentFilter {
  readonly filter: CommentFilterOption;
  readonly publishingEntityId: Scalars['ID'];
}

export enum CommentFilterOption {
  SUBSCRIBERS = 'SUBSCRIBERS'
}

export interface CommentUpvoteResult {
  readonly __typename: 'CommentUpvoteResult';
  readonly comment: Comment;
  readonly direction: VoteType;
  readonly questionId: Scalars['ID'];
  readonly voteId: Scalars['ID'];
}

export interface CommentsData {
  readonly __typename: 'CommentsData';
  readonly comments: ReadonlyArray<Comment>;
  readonly totalCount: Scalars['Int'];
}


export interface CommentsDataCommentsArgs {
  pagination: InputMaybe<PaginationParams>;
}

export interface ComparisonGroup {
  readonly __typename: 'ComparisonGroup';
  readonly boundingBox: Maybe<GeoBoundingBox>;
  /** @deprecated Use trackDomain fo track data */
  readonly domainTrackVariables: DomainTrackVariables;
  readonly fips: ReadonlyArray<FipsArea>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly publishers: ReadonlyArray<PublishingEntity>;
  readonly trackDomain: TrackDomain;
}


export interface ComparisonGroupDomainTrackVariablesArgs {
  displayType: InputMaybe<VariableDisplayType>;
  domain: AnalyticsDomain;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
  includeHierarchicalData: InputMaybe<Scalars['Boolean']>;
}


export interface ComparisonGroupTrackDomainArgs {
  displayType: InputMaybe<VariableDisplayType>;
  domain: AnalyticsDomain;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
  includeHierarchicalData: InputMaybe<Scalars['Boolean']>;
}

export interface ConditionalLogic {
  readonly __typename: 'ConditionalLogic';
  readonly metaConditions: ReadonlyArray<MetaCondition>;
  readonly questionChoiceConditions: ReadonlyArray<QuestionChoiceCondition>;
}

export interface ConditionsInput {
  readonly metaConditions: ReadonlyArray<MetaConditionInput>;
  readonly questionChoiceConditions: ReadonlyArray<QuestionChoiceConditionInput>;
}

export enum ContentOutcomeState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export interface ContentPost extends ContentSetBase {
  readonly __typename: 'ContentPost';
  readonly accessSetting: Maybe<AccessSetting>;
  readonly allConditions: ConditionalLogic;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly alternateLanguages: ReadonlyArray<Language>;
  readonly benchmarkType: Maybe<BenchmarkType>;
  readonly contents: ReadonlyArray<QuestionHierarchyNode>;
  readonly createDate: GqlDate;
  readonly criticalFocusTopics: ReadonlyArray<SurveyBenchmarkTopic>;
  readonly crosstab: Maybe<ReadonlyArray<CrosstabResults>>;
  readonly description: Maybe<MultiLanguageContent>;
  readonly dimensionGroupDefinitions: ReadonlyArray<DimensionGroupDefinition>;
  readonly estCompletionTime: Scalars['String'];
  readonly hasAutomaticWeighting: Scalars['Boolean'];
  readonly hasBenchmarkedContent: Scalars['Boolean'];
  readonly hasPdfUrl: Scalars['Boolean'];
  readonly hasVisibleReportTabs: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly image: Maybe<QuestionSetImage>;
  readonly isRepostable: Scalars['Boolean'];
  readonly name: MultiLanguageContent;
  readonly outcome: Maybe<Outcome>;
  readonly polcoManaged: Scalars['Boolean'];
  readonly publishingEntity: PublishingEntity;
  readonly questionsCount: Scalars['Int'];
  readonly reportTabs: Maybe<ReadonlyArray<ReportTab>>;
  readonly reposters: ReadonlyArray<PublishingEntity>;
  readonly representivityData: Maybe<RepresentivityBreakdown>;
  readonly responseStat: Maybe<Stat>;
  readonly responsesOverTime: Maybe<TimeBreakdown>;
  readonly schedule: QuestionSetSchedule;
  readonly setImageId: Maybe<Scalars['String']>;
  readonly shareable: Scalars['Boolean'];
  readonly shareableContentSet: Maybe<ShareableContentSet>;
  readonly shortId: Maybe<Scalars['String']>;
  readonly shortUrls: ReadonlyArray<ShortUrl>;
  readonly showConversionPrompts: Scalars['Boolean'];
  readonly slug: Maybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Tag>;
  readonly topicDisparities: ReadonlyArray<TopicDisparity>;
  readonly topics: ReadonlyArray<SurveyBenchmarkTopic>;
  readonly totalRespondents: Maybe<Scalars['Int']>;
  readonly totalWeightedRespondents: Maybe<Scalars['Int']>;
}


export interface ContentPostContentsArgs {
  filterByTopicIds: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  filterNonGridQuestions: InputMaybe<Scalars['Boolean']>;
  pagination: InputMaybe<PaginationParams>;
  useMicroservice: InputMaybe<Scalars['Boolean']>;
}


export interface ContentPostCriticalFocusTopicsArgs {
  filter: TopicFilter;
}


export interface ContentPostCrosstabArgs {
  dimensionGroupDefinitionId: Scalars['ID'];
  topicId: Scalars['ID'];
}


export interface ContentPostReportTabsArgs {
  publiclyShared: Scalars['Boolean'];
}


export interface ContentPostRepresentivityDataArgs {
  attributeType: Scalars['String'];
}


export interface ContentPostResponseStatArgs {
  input: ResponseStatsInput;
}


export interface ContentPostResponsesOverTimeArgs {
  scopeByPublishingEntityId: Scalars['ID'];
}


export interface ContentPostTopicDisparitiesArgs {
  dimensionGroupDefinitionIds: ReadonlyArray<Scalars['ID']>;
  topicFilter: TopicFilter;
}


export interface ContentPostTopicsArgs {
  filter: TopicFilter;
  sort: InputMaybe<TopicSortInput>;
  useMicroservice: InputMaybe<Scalars['Boolean']>;
}


export interface ContentPostTotalRespondentsArgs {
  scopeByPublishingEntityId: InputMaybe<Scalars['ID']>;
}

export type ContentSet = ContentPost | PolcoLive | QuestionSet | Survey;

export interface ContentSetBase {
  readonly accessSetting: Maybe<AccessSetting>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly alternateLanguages: ReadonlyArray<Language>;
  readonly createDate: GqlDate;
  readonly description: Maybe<MultiLanguageContent>;
  readonly hasAutomaticWeighting: Scalars['Boolean'];
  readonly hasBenchmarkedContent: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly image: Maybe<QuestionSetImage>;
  readonly isRepostable: Scalars['Boolean'];
  readonly name: MultiLanguageContent;
  readonly outcome: Maybe<Outcome>;
  readonly polcoManaged: Scalars['Boolean'];
  readonly publishingEntity: PublishingEntity;
  readonly questionsCount: Scalars['Int'];
  readonly reportTabs: Maybe<ReadonlyArray<ReportTab>>;
  readonly reposters: ReadonlyArray<PublishingEntity>;
  readonly schedule: QuestionSetSchedule;
  readonly setImageId: Maybe<Scalars['String']>;
  readonly shareable: Scalars['Boolean'];
  readonly shareableContentSet: Maybe<ShareableContentSet>;
  readonly shortUrls: ReadonlyArray<ShortUrl>;
  readonly showConversionPrompts: Scalars['Boolean'];
  readonly slug: Maybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Tag>;
  readonly totalRespondents: Maybe<Scalars['Int']>;
}


export interface ContentSetBaseReportTabsArgs {
  publiclyShared: Scalars['Boolean'];
}


export interface ContentSetBaseTotalRespondentsArgs {
  scopeByPublishingEntityId: InputMaybe<Scalars['ID']>;
}

export interface Coordinate {
  readonly __typename: 'Coordinate';
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
}

export interface CoordinateCenter {
  readonly __typename: 'CoordinateCenter';
  readonly coordinate: Coordinate;
  readonly size: Scalars['Float'];
}

export interface CoordinateInput {
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
}

export interface CreateDemoUserInput {
  readonly email: Scalars['String'];
}

export interface CreateGoalInput {
  readonly planId: Scalars['ID'];
  readonly publishingEntityId: Scalars['ID'];
  readonly value: Scalars['Float'];
  readonly variableId: Scalars['ID'];
}

export interface CreateSuperAdminInput {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
}

export interface CreateSuperAdminResult {
  readonly __typename: 'CreateSuperAdminResult';
  readonly activationLink: Maybe<Scalars['String']>;
}

export interface CreatedQuestionsData {
  readonly __typename: 'CreatedQuestionsData';
  readonly pagination: PaginationData;
  readonly questions: ReadonlyArray<Question>;
}

export enum CrmTrackedEvent {
  ADMIN_VIEWED_DEMO_SITE = 'ADMIN_VIEWED_DEMO_SITE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_CASOA = 'ADMIN_VIEWED_LEARN_MORE_ITEM_CASOA',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NBS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NBS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NCS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NCS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NCS_S = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NCS_S',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NES = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NES',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NES_LE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NES_LE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NLES = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NLES',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES'
}

export interface CrosstabGridResults {
  readonly __typename: 'CrosstabGridResults';
  readonly label: Scalars['String'];
  readonly questionResults: ReadonlyArray<CrosstabQuestionResults>;
}

export interface CrosstabGroup {
  readonly __typename: 'CrosstabGroup';
  readonly label: Scalars['String'];
}

export interface CrosstabQuestionResults {
  readonly __typename: 'CrosstabQuestionResults';
  readonly question: Question;
  readonly results: Maybe<ReadonlyArray<CrosstabResultValue>>;
}

export interface CrosstabResultValue {
  readonly __typename: 'CrosstabResultValue';
  readonly crosstabGroup: CrosstabGroup;
  readonly statisticalDifferences: Maybe<ReadonlyArray<CrosstabGroup>>;
  readonly value: Maybe<Scalars['Int']>;
}

export type CrosstabResults = CrosstabGridResults | CrosstabQuestionResults;

export interface CsvReportInput {
  readonly contentSetId: Scalars['ID'];
  readonly scopedPublishingEntityId: Scalars['ID'];
}

export interface CurrentUser {
  readonly __typename: 'CurrentUser';
  readonly id: Scalars['ID'];
  readonly location: Maybe<UserLocation>;
  readonly nearbyLocations: Maybe<NearbyLocations>;
  readonly user: Maybe<User>;
}


export interface CurrentUserNearbyLocationsArgs {
  zipCode: InputMaybe<Scalars['String']>;
}

export interface DataDictionaryInput {
  readonly reportDisplayLabel: InputMaybe<Scalars['String']>;
  readonly scaleData: InputMaybe<ScaleDataInput>;
}

export enum DateLevel {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export interface DeleteGoalInput {
  readonly goalId: Scalars['ID'];
  readonly publishingEntityId: Scalars['ID'];
}

export interface DemographicAttribute {
  readonly __typename: 'DemographicAttribute';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly owner: Maybe<PublishingEntity>;
  readonly premium: Scalars['Boolean'];
}

export interface DemographicLocationStatSourceInput {
  readonly geoViewId: Scalars['ID'];
}

export enum DemographicStatSource {
  THIRD_PARTY = 'THIRD_PARTY',
  USER_REPORTED = 'USER_REPORTED'
}

export interface DemographicStatSourceInput {
  readonly source: DemographicStatSource;
}

export interface DimensionGroupDefinition {
  readonly __typename: 'DimensionGroupDefinition';
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
}

export enum DisparityLevel {
  LARGE = 'LARGE',
  SIMILAR = 'SIMILAR',
  SMALL = 'SMALL'
}

export interface DistributionBin {
  readonly __typename: 'DistributionBin';
  readonly count: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly percentValue: Scalars['Float'];
  readonly percentile: Scalars['Float'];
}

export interface DomainPerformanceData {
  readonly __typename: 'DomainPerformanceData';
  readonly id: Scalars['ID'];
  readonly indexScore: Maybe<ReadonlyArray<PerformanceDatum>>;
  readonly indicators: ReadonlyArray<PerformanceDatum>;
  readonly sentimentValues: ReadonlyArray<PerformanceDatum>;
}

export interface DomainTrackVariables {
  readonly __typename: 'DomainTrackVariables';
  readonly id: Scalars['ID'];
  readonly indexScores: ReadonlyArray<TrackVariable>;
  readonly indicators: ReadonlyArray<TrackVariable>;
  readonly sentimentValues: ReadonlyArray<TrackVariable>;
}

export interface DuplicateSimulationResult {
  readonly __typename: 'DuplicateSimulationResult';
  readonly id: Scalars['Int'];
}

export interface EmailList {
  readonly __typename: 'EmailList';
  readonly emails: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface EmailLoginFailed {
  readonly __typename: 'EmailLoginFailed';
  readonly reason: EmailLoginFailedReason;
}

export enum EmailLoginFailedReason {
  INVALID = 'INVALID'
}

export type EmailLoginResult = CurrentUser | EmailLoginFailed;

export interface EmailSignupFailed {
  readonly __typename: 'EmailSignupFailed';
  readonly reason: EmailSignupFailedReason;
}

export enum EmailSignupFailedReason {
  COULD_NOT_INSERT_USER = 'COULD_NOT_INSERT_USER',
  EMAIL_TAKEN = 'EMAIL_TAKEN',
  INVALID = 'INVALID',
  RESPONDENT_TAKEN = 'RESPONDENT_TAKEN'
}

export type EmailSignupResult = CurrentUser | EmailSignupFailed;

export enum EmailType {
  AdminShareEmail = 'AdminShareEmail',
  OutcomeEmail = 'OutcomeEmail',
  OutreachEmail = 'OutreachEmail',
  WeeklyEmail = 'WeeklyEmail'
}

export enum EmailVerificationStatus {
  NEEDS_VERIFICATION = 'NEEDS_VERIFICATION',
  VERIFICATION_NOT_NEEDED = 'VERIFICATION_NOT_NEEDED',
  VERIFIED = 'VERIFIED'
}

export interface EmptyBreakdown {
  readonly __typename: 'EmptyBreakdown';
  readonly label: Scalars['String'];
}

export interface ErrorFlag {
  readonly __typename: 'ErrorFlag';
  readonly flagKey: Scalars['String'];
  readonly namespaceKey: Scalars['String'];
  readonly reason: FlagEvaluationErrorReason;
}

export type FeatureFlag = BooleanFlag | ErrorFlag | VariantFlag;

export interface FeatureSettingInput {
  readonly dateToEnableUntil: GqlDateInput;
  readonly featureSettingId: Scalars['ID'];
  readonly isUnlimited: InputMaybe<Scalars['Boolean']>;
  readonly quantity: InputMaybe<Scalars['Int']>;
}

export interface FeatureSettingMissingBreakdown {
  readonly __typename: 'FeatureSettingMissingBreakdown';
  readonly missingFeatureSetting: Scalars['String'];
}

export interface FipsArea {
  readonly __typename: 'FipsArea';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly population: Maybe<Scalars['Int']>;
  readonly populationSizeSegment: Maybe<Scalars['String']>;
  readonly shape: Maybe<GeoJsonMultiPolygon>;
  readonly shortName: Maybe<Scalars['String']>;
  readonly type: Maybe<FipsAreaType>;
}

export enum FipsAreaType {
  COUNTY = 'COUNTY',
  COUNTY_SUBDIVISION = 'COUNTY_SUBDIVISION',
  MSA = 'MSA',
  NATION = 'NATION',
  PLACE = 'PLACE',
  STATE = 'STATE'
}

export enum FlagEvaluationErrorReason {
  NOT_FOUND = 'NOT_FOUND',
  UNKNOWN = 'UNKNOWN'
}

export enum FlagEvaluationReason {
  DEFAULT = 'DEFAULT',
  FLAG_DISABLED = 'FLAG_DISABLED',
  MATCH = 'MATCH',
  UNKNOWN = 'UNKNOWN'
}

export interface FullPerformanceDatum {
  readonly __typename: 'FullPerformanceDatum';
  readonly benchmarkValue: Maybe<AnalyticsBenchmarkValue>;
  readonly benchmarkValues: ReadonlyArray<AnalyticsBenchmark>;
  readonly distributions: ReadonlyArray<PerformanceDatumDistribution>;
  readonly fipsArea: FipsArea;
  readonly id: Scalars['ID'];
  readonly previousValue: Maybe<PerformanceDatumPreviousValue>;
  readonly recordedAt: GqlDate;
  readonly value: Scalars['Float'];
  readonly variable: AnalyticsVariable;
}

export interface GeoArea {
  readonly __typename: 'GeoArea';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly shape: GeoJsonMultiPolygon;
}

export interface GeoBoundingBox {
  readonly __typename: 'GeoBoundingBox';
  readonly id: Scalars['ID'];
  readonly xMax: Scalars['Float'];
  readonly xMin: Scalars['Float'];
  readonly yMax: Scalars['Float'];
  readonly yMin: Scalars['Float'];
}

export interface GeoJsonMultiPolygon {
  readonly __typename: 'GeoJsonMultiPolygon';
  readonly coordinates: Scalars['MultiPolygon'];
  readonly type: Scalars['String'];
}

export interface GeoJsonMultiPolygonInput {
  readonly coordinates: Scalars['MultiPolygon'];
  readonly type: Scalars['String'];
}

export interface GeoView extends GeoViewBase {
  readonly __typename: 'GeoView';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface GeoViewBase {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface Goal {
  readonly __typename: 'Goal';
  readonly id: Scalars['ID'];
  readonly value: Scalars['Float'];
  readonly variable: TrackVariable;
}

export interface GqlDate {
  readonly __typename: 'GqlDate';
  readonly raw: Scalars['Float'];
}

export interface GqlDateInput {
  readonly raw: Scalars['Float'];
}

export enum GqlQuestionSetGeneratedTypes {
  POLCO_BASE = 'POLCO_BASE',
  RAILS_BASE = 'RAILS_BASE',
  RAILS_SET = 'RAILS_SET'
}

export interface GridInput {
  readonly choices: ReadonlyArray<QuestionChoiceInput>;
  readonly dataDictionary: InputMaybe<DataDictionaryInput>;
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly gridRootHierarchyId: InputMaybe<Scalars['ID']>;
  readonly metaConditionReferenceId: InputMaybe<Scalars['ID']>;
  readonly randomizeChoices: InputMaybe<Scalars['Boolean']>;
  readonly rows: ReadonlyArray<GridRowInput>;
  readonly shareableQuestionChoiceSetId: InputMaybe<Scalars['ID']>;
  readonly title: Scalars['String'];
}

export interface GridRowInput {
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly id: InputMaybe<Scalars['ID']>;
  readonly label: Scalars['String'];
  readonly metaConditionReferenceId: InputMaybe<Scalars['ID']>;
  readonly optional: Scalars['Boolean'];
  readonly reportDisplayLabel: InputMaybe<Scalars['String']>;
  readonly variableName: InputMaybe<Scalars['String']>;
}

export interface GridVoteInput {
  readonly choiceId: Scalars['ID'];
  readonly questionId: Scalars['ID'];
}

export interface Idea {
  readonly __typename: 'Idea';
  readonly aggregations: Maybe<IdeaAggregations>;
  readonly createDate: GqlDate;
  readonly description: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly publishingEntity: PublishingEntity;
  readonly respondent: Respondent;
  readonly respondentVote: VoteType;
  readonly title: Scalars['String'];
}


export interface IdeaRespondentVoteArgs {
  respondentId: InputMaybe<Scalars['ID']>;
}

export interface IdeaAggregations {
  readonly __typename: 'IdeaAggregations';
  readonly voteDown: Scalars['Int'];
  readonly voteUp: Scalars['Int'];
}

export interface IdeaInput {
  readonly description: InputMaybe<Scalars['String']>;
  readonly publishingEntityId: Scalars['ID'];
  readonly title: Scalars['String'];
}

export enum IdeaInteractionAction {
  REMOVE_VOTE = 'REMOVE_VOTE',
  VOTE_DOWN = 'VOTE_DOWN',
  VOTE_UP = 'VOTE_UP'
}

export enum InputVoteType {
  VOTE_ACKNOWLEDGE = 'VOTE_ACKNOWLEDGE',
  VOTE_FREE_TEXT = 'VOTE_FREE_TEXT',
  VOTE_MULTIPLE_CHOICES = 'VOTE_MULTIPLE_CHOICES',
  VOTE_POINT_ALLOCATION = 'VOTE_POINT_ALLOCATION'
}

export interface InteractionInput {
  readonly action: IdeaInteractionAction;
  readonly ideaId: Scalars['String'];
}

export enum KnownOrganizationAreaKey {
  OTHER = 'OTHER',
  UNITED_STATES = 'UNITED_STATES'
}

export interface Language {
  readonly __typename: 'Language';
  readonly id: Scalars['ID'];
  readonly key: Scalars['String'];
  readonly name: Scalars['String'];
}

export enum LanguageKey {
  AMHARIC = 'AMHARIC',
  ARABIC = 'ARABIC',
  BENGALI = 'BENGALI',
  CAPE_VERDEAN_CREOLE = 'CAPE_VERDEAN_CREOLE',
  CHINESE_SIMPLIFIED = 'CHINESE_SIMPLIFIED',
  CHINESE_TRADITIONAL = 'CHINESE_TRADITIONAL',
  ENGLISH = 'ENGLISH',
  FARSI = 'FARSI',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  GUJARATI = 'GUJARATI',
  HAITIAN_CREOLE = 'HAITIAN_CREOLE',
  HINDI = 'HINDI',
  HMONG = 'HMONG',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  MARSHALLESE = 'MARSHALLESE',
  NEPALI = 'NEPALI',
  OROMO = 'OROMO',
  POLISH = 'POLISH',
  PORTUGUESE = 'PORTUGUESE',
  RUSSIAN = 'RUSSIAN',
  SOMALI = 'SOMALI',
  SPANISH = 'SPANISH',
  SWAHILI = 'SWAHILI',
  TAGALOG = 'TAGALOG',
  UKRAINIAN = 'UKRAINIAN',
  VIETNAMESE = 'VIETNAMESE',
  YIDDISH = 'YIDDISH'
}

export interface LanguageSpecificContent {
  readonly __typename: 'LanguageSpecificContent';
  readonly languageKey: Scalars['String'];
  readonly value: Scalars['String'];
}

export interface LibrarySearchGridResult {
  readonly __typename: 'LibrarySearchGridResult';
  readonly children: ReadonlyArray<Question>;
  readonly hierarchy: QuestionHierarchyParentNode;
}

export interface LibrarySearchResult {
  readonly __typename: 'LibrarySearchResult';
  readonly gridNodes: ReadonlyArray<LibrarySearchGridResult>;
  readonly paywalledItemCount: Scalars['Int'];
  readonly questions: ReadonlyArray<Question>;
  readonly simulations: ReadonlyArray<AdminBalancingActSimulation>;
  readonly surveys: ReadonlyArray<ContentSet>;
}

export interface LocationInput {
  readonly coordinate: CoordinateInput;
  readonly level: InputMaybe<LocationLevel>;
  readonly rawAddress: Scalars['String'];
}

export enum LocationLevel {
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  COUNTY = 'COUNTY',
  STATE = 'STATE'
}

export interface LoginDemoUserFailed {
  readonly __typename: 'LoginDemoUserFailed';
  readonly reason: LoginDemoUserFailedReason;
}

export enum LoginDemoUserFailedReason {
  INVALID = 'INVALID'
}

export type LoginDemoUserResult = CurrentUser | LoginDemoUserFailed;

export interface McChoice {
  readonly id: Scalars['ID'];
}

export interface MagicLinkRequestInput {
  readonly email: Scalars['String'];
  readonly params: InputMaybe<ReadonlyArray<ArbitraryQueryParamInput>>;
  readonly path: Scalars['String'];
}

export interface MapStatBucketGroup {
  readonly __typename: 'MapStatBucketGroup';
  readonly buckets: ReadonlyArray<StatBucket>;
  readonly hits: Scalars['Int'];
  readonly key: Scalars['String'];
  readonly shape: GeoJsonMultiPolygon;
}

export enum MapStatsStatus {
  ENABLED = 'ENABLED',
  NOT_ENABLED = 'NOT_ENABLED',
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA'
}

export enum MatrixSource {
  ARPA_BUSINESS = 'ARPA_BUSINESS',
  ARPA_RESIDENT = 'ARPA_RESIDENT',
  BALANCING_ACT = 'BALANCING_ACT',
  CASOA = 'CASOA',
  ENGAGE = 'ENGAGE',
  GPAL = 'GPAL',
  NATIONAL = 'NATIONAL',
  NBS = 'NBS',
  NCI = 'NCI',
  NCS = 'NCS',
  NES = 'NES',
  NES_LE = 'NES_LE',
  NLES = 'NLES',
  NPSS = 'NPSS'
}

export enum MatrixSourceType {
  DERIVED = 'DERIVED',
  STATED = 'STATED'
}

export enum MatrixTopic {
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  ECONOMY = 'ECONOMY',
  EDUCATION_ARTS_CULTURE = 'EDUCATION_ARTS_CULTURE',
  GOVERNANCE = 'GOVERNANCE',
  HEALTH_AND_WELLNESS = 'HEALTH_AND_WELLNESS',
  INCLUSIVITY_AND_ENGAGEMENT = 'INCLUSIVITY_AND_ENGAGEMENT',
  MOBILITY = 'MOBILITY',
  NATURAL_ENVIRONMENT = 'NATURAL_ENVIRONMENT',
  PARKS_AND_RECREATION = 'PARKS_AND_RECREATION',
  SAFETY = 'SAFETY',
  UTILITIES = 'UTILITIES'
}

export interface MenuItem {
  readonly __typename: 'MenuItem';
  readonly childrenMenuItems: ReadonlyArray<MenuItem>;
  readonly icon: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly isNew: Maybe<Scalars['Boolean']>;
  readonly isShown: Maybe<Scalars['Boolean']>;
  readonly materialIcon: Maybe<Scalars['String']>;
  readonly text: Scalars['String'];
  readonly url: Maybe<Scalars['String']>;
}

export interface MetaCondition {
  readonly __typename: 'MetaCondition';
  readonly childMetaConditionIds: ReadonlyArray<Scalars['ID']>;
  readonly combinationOperator: MetaConditionCombinationOperator;
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
}

export enum MetaConditionCombinationOperator {
  ALL = 'ALL',
  NONE = 'NONE',
  NOT_ALL = 'NOT_ALL',
  SOME = 'SOME'
}

export interface MetaConditionInput {
  readonly childMetaConditionReferenceIds: ReadonlyArray<Scalars['ID']>;
  readonly combinationOperator: MetaConditionCombinationOperator;
  readonly displayName: Scalars['String'];
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly id: InputMaybe<Scalars['ID']>;
}

export interface MultiLanguageContent {
  readonly __typename: 'MultiLanguageContent';
  readonly alternates: ReadonlyArray<LanguageSpecificContent>;
  readonly defaultValue: Scalars['String'];
}

export interface Mutation {
  readonly __typename: 'Mutation';
  readonly addAdminToPublishingEntity: PublishingEntity;
  readonly addComment: Comment;
  readonly adminAiAssistantCancelProcessing: AiAssistantSession;
  readonly adminAiAssistantPostMessage: AiAssistantSessionWithStats;
  readonly adminAiAssistantUploadFile: Scalars['Boolean'];
  readonly adminCopyContentSet: ContentSet;
  readonly adminCreateEmailList: EmailList;
  readonly adminCreateSocialConnection: PendingSocialConnection;
  readonly adminCsvReportUrl: Maybe<Scalars['String']>;
  readonly adminDeleteSocialConnection: Scalars['Boolean'];
  readonly adminDeleteTrackGoal: PublishingEntity;
  readonly adminEnableTrackDomainFeatureSettings: ReadonlyArray<PublishingEntityFeatureSetting>;
  readonly adminMakeNoPaymentStorePurchase: ReadonlyArray<PublishingEntityStoreItem>;
  readonly adminRegistrationAdditionalData: AdminRegistration;
  readonly adminRegistrationForRespondent: CurrentUser;
  readonly adminRegistrationProfileSelection: Admin;
  readonly adminRegistrationUserCreation: CurrentUser;
  readonly adminRequestFeatureContactInfo: Admin;
  readonly adminRequestFeatures: Admin;
  readonly adminResendVerificationEmail: ResendVerificationEmailResult;
  readonly adminRevertSurveyToDraft: ContentSet;
  readonly adminSaveNewTrackGoal: PublishingEntity;
  readonly adminSaveNewVisualizationWithMultipleFips: PublishingEntity;
  readonly adminSendOutreachEmail: Scalars['Boolean'];
  readonly adminSendSharePolcoEmail: Scalars['Boolean'];
  readonly adminSetActivePublishingEntity: Admin;
  readonly adminSetActiveSocialConnectionProfiles: SocialConnection;
  /** @deprecated use superAdminUpdatePublisherAttributes */
  readonly adminSetPublishingEntityBalancingActCustomerId: PublishingEntity;
  /** @deprecated use superAdminUpdatePublisherAttributes */
  readonly adminSetPublishingEntityEnvisioId: PublishingEntity;
  /** @deprecated use superAdminUpdatePublisherAttributes */
  readonly adminSetPublishingEntityFips: PublishingEntity;
  readonly adminSetPublishingEntityShortUrl: Maybe<ShortUrl>;
  readonly adminSetShowExampleQuestions: PublishingEntity;
  readonly adminTrackEventInCrm: Scalars['Boolean'];
  readonly adminUpdateEmailList: EmailList;
  readonly adminUpdatePublishingEntity: PublishingEntity;
  readonly adminUpdateTrackGoal: PublishingEntity;
  readonly adminUpdateTrackVariableVisualizationState: TrackVariableVisualization;
  readonly applyRecommendedContentSet: ContentSet;
  readonly archiveQuestionSet: ContentSet;
  readonly contentSetSocialShare: ContentSet;
  readonly createAndLoginDemoUser: LoginDemoUserResult;
  readonly createClientOnlyAnalyticsEvent: Scalars['Boolean'];
  readonly createDemoUser: Scalars['String'];
  readonly createIdea: Idea;
  readonly createOutcome: ContentSet;
  readonly createQuestionSet: ContentSet;
  readonly deleteAdminFromPublishingEntity: PublishingEntity;
  readonly deleteEmailList: Scalars['Boolean'];
  readonly deleteOutcome: Scalars['Boolean'];
  readonly deletePollSetQuestion: Scalars['Boolean'];
  readonly deleteQuestionSet: Scalars['Boolean'];
  readonly duplicateSimulation: DuplicateSimulationResult;
  readonly emailLogin: EmailLoginResult;
  readonly emailSignup: EmailSignupResult;
  readonly followPublishingEntity: PublishingEntity;
  readonly generateBenchmarkSurveyPdf: Scalars['Boolean'];
  readonly interactWithIdea: Idea;
  readonly logOut: CurrentUser;
  readonly passwordReset: ResetPasswordResult;
  readonly publishOutcome: Outcome;
  readonly publishingEntitySocialShare: PublishingEntity;
  readonly reorderQuestionSet: ContentSet;
  readonly repostContentSet: ContentSet;
  readonly requestBenchmarkProgram: Scalars['Boolean'];
  readonly requestMagicLinkEmail: Scalars['Boolean'];
  readonly requestPasswordReset: Scalars['Boolean'];
  readonly retrieveBenchmarkSurveyPdf: Maybe<Scalars['String']>;
  /** @deprecated Removing planhat */
  readonly retrievePlanhatPortalUrlForCurrentAdmin: Maybe<Scalars['String']>;
  readonly scheduleQuestions: ContentSet;
  readonly scheduleSurvey: ContentSet;
  readonly setPollSetQuestion: Question;
  readonly setSimulationContents: AdminBalancingActSimulation;
  readonly setSurveyContents: Survey;
  readonly socialLogin: Maybe<SocialUserResult>;
  readonly softDeleteQuestionSet: ContentSet;
  readonly superAdminApproveRegistration: AdminRegistrationResult;
  readonly superAdminAssociatePublishingEntityWithRegistration: AdminRegistration;
  readonly superAdminCreateContentSetReportTab: ContentSet;
  readonly superAdminCreateOrUpdateRecommendedContent: ShareableContentSet;
  readonly superAdminCreatePublishingEntity: PublishingEntity;
  readonly superAdminCreateSuperAdmin: CreateSuperAdminResult;
  readonly superAdminCreateThirdPartyRecord: ThirdPartyRecord;
  readonly superAdminDeleteBenchmarkReportRequest: Scalars['Boolean'];
  readonly superAdminDeleteContentSetReportTab: ContentSet;
  readonly superAdminDeletePublishingEntity: Scalars['Boolean'];
  readonly superAdminDeleteShortUrl: Scalars['Boolean'];
  readonly superAdminDeleteThirdPartyRecord: Scalars['Boolean'];
  readonly superAdminDownloadBenchmarkReportRequestData: Scalars['String'];
  readonly superAdminGenerateContentSetShortUrls: ContentSet;
  readonly superAdminImportTranslations: Scalars['Boolean'];
  readonly superAdminPublishBenchmarkReportRequest: BenchmarkReportRequest;
  readonly superAdminRejectRegistration: AdminRegistrationResult;
  readonly superAdminRemoveSuperAdmin: Scalars['Boolean'];
  readonly superAdminReorderReportTabs: ContentSet;
  readonly superAdminResetFeatureSettings: ReadonlyArray<PublishingEntityFeatureSetting>;
  readonly superAdminSetPurchaseStatus: Maybe<PurchasedStoreItem>;
  readonly superAdminTogglePublishingEntityFeatureSetting: PublishingEntityFeatureSetting;
  readonly superAdminTogglePublishingEntityFeatureSettings: ReadonlyArray<PublishingEntityFeatureSetting>;
  readonly superAdminUpdateContentSetReportTab: ReportTab;
  readonly superAdminUpdatePresidingArea: PublishingEntity;
  readonly superAdminUpdatePublisherAttributes: PublishingEntity;
  readonly superAdminUpdateShortUrl: ShortUrl;
  readonly superAdminUpdateThirdPartyRecord: ThirdPartyRecord;
  readonly superAdminUploadContentSetShortUrls: Scalars['Boolean'];
  readonly superAdminUpsertBenchmarkReportRequest: BenchmarkReportRequest;
  readonly unUpvoteComment: CommentUpvoteResult;
  readonly unsubscribeFromAdminShareEmail: Scalars['Boolean'];
  readonly unsubscribeFromOutreachEmail: Scalars['Boolean'];
  readonly updateAdminForPublishingEntity: PublishingEntity;
  readonly updateEmailSettings: Scalars['Boolean'];
  readonly updateOutcome: ContentSet;
  readonly updateQuestionSet: ContentSet;
  readonly updateRespondent: Respondent;
  readonly upvoteComment: CommentUpvoteResult;
  readonly verifyAdminEmail: User;
  readonly voteForQuestion: Vote;
  readonly voteForSurvey: SurveyVoteResult;
}


export interface MutationAddAdminToPublishingEntityArgs {
  adminEmail: Scalars['String'];
  adminName: Scalars['String'];
  publishingEntityId: Scalars['ID'];
  roleId: InputMaybe<Scalars['ID']>;
}


export interface MutationAddCommentArgs {
  comment: Scalars['String'];
  questionId: Scalars['ID'];
}


export interface MutationAdminAiAssistantCancelProcessingArgs {
  assistantId: Scalars['String'];
  sessionId: Scalars['ID'];
}


export interface MutationAdminAiAssistantPostMessageArgs {
  assistantId: Scalars['String'];
  messageContent: Scalars['String'];
  sessionId: Scalars['ID'];
}


export interface MutationAdminAiAssistantUploadFileArgs {
  assistantId: Scalars['String'];
  file: Scalars['String'];
  sessionId: Scalars['ID'];
}


export interface MutationAdminCopyContentSetArgs {
  contentSetId: Scalars['ID'];
  newName: Scalars['String'];
  questionRelationship: QuestionRelationshipType;
  receivingPublishingEntityId: Scalars['ID'];
  templateParams: ReadonlyArray<TemplateParams>;
}


export interface MutationAdminCreateEmailListArgs {
  emails: InputMaybe<ReadonlyArray<Scalars['String']>>;
  listName: Scalars['String'];
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminCreateSocialConnectionArgs {
  input: AdminCreateSocialConnectionInput;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminCsvReportUrlArgs {
  input: CsvReportInput;
}


export interface MutationAdminDeleteSocialConnectionArgs {
  publishingEntityId: Scalars['ID'];
  socialConnectionType: SocialConnectionProvider;
}


export interface MutationAdminDeleteTrackGoalArgs {
  input: DeleteGoalInput;
}


export interface MutationAdminEnableTrackDomainFeatureSettingsArgs {
  featureSettings: ReadonlyArray<FeatureSettingInput>;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminMakeNoPaymentStorePurchaseArgs {
  input: StorePurchaseNoPayment;
}


export interface MutationAdminRegistrationAdditionalDataArgs {
  additionalData: AdminRegistrationAdditionalDataInput;
  registrationId: Scalars['ID'];
}


export interface MutationAdminRegistrationForRespondentArgs {
  metadata: ReadonlyArray<RegistrationMetadataEntry>;
}


export interface MutationAdminRegistrationProfileSelectionArgs {
  profileData: AdminRegistrationProfileSelectionInput;
  registrationId: Scalars['ID'];
}


export interface MutationAdminRegistrationUserCreationArgs {
  metadata: ReadonlyArray<RegistrationMetadataEntry>;
  userData: AdminRegistrationUserInput;
}


export interface MutationAdminRequestFeatureContactInfoArgs {
  input: RequestFeatureContactInfo;
}


export interface MutationAdminRequestFeaturesArgs {
  features: ReadonlyArray<Scalars['String']>;
}


export interface MutationAdminRevertSurveyToDraftArgs {
  surveyId: Scalars['ID'];
}


export interface MutationAdminSaveNewTrackGoalArgs {
  input: CreateGoalInput;
}


export interface MutationAdminSaveNewVisualizationWithMultipleFipsArgs {
  input: TrackVariableVisualizationInput;
}


export interface MutationAdminSendOutreachEmailArgs {
  emailListIds: ReadonlyArray<Scalars['ID']>;
  emailSubscribers: Scalars['Boolean'];
  message: Scalars['String'];
  publishingEntityId: Scalars['ID'];
  questionSetId: Scalars['ID'];
  sendDate: InputMaybe<GqlDateInput>;
  subject: Scalars['String'];
}


export interface MutationAdminSendSharePolcoEmailArgs {
  emailArgs: AdminShareEmailInput;
}


export interface MutationAdminSetActivePublishingEntityArgs {
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminSetActiveSocialConnectionProfilesArgs {
  input: AdminUpdateSocialConnectionInput;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminSetPublishingEntityBalancingActCustomerIdArgs {
  customerId: InputMaybe<Scalars['Int']>;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminSetPublishingEntityEnvisioIdArgs {
  envisioId: InputMaybe<Scalars['String']>;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminSetPublishingEntityFipsArgs {
  fips: InputMaybe<Scalars['String']>;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminSetPublishingEntityShortUrlArgs {
  publishingEntityId: Scalars['ID'];
  url: InputMaybe<Scalars['String']>;
}


export interface MutationAdminSetShowExampleQuestionsArgs {
  publishingEntityId: Scalars['ID'];
  show: Scalars['Boolean'];
}


export interface MutationAdminTrackEventInCrmArgs {
  event: CrmTrackedEvent;
}


export interface MutationAdminUpdateEmailListArgs {
  emailListId: Scalars['ID'];
  emails: ReadonlyArray<Scalars['String']>;
  name: Scalars['String'];
}


export interface MutationAdminUpdatePublishingEntityArgs {
  input: AdminUpdatePublishingEntityInput;
  publishingEntityId: Scalars['ID'];
}


export interface MutationAdminUpdateTrackGoalArgs {
  input: UpdateGoalInput;
}


export interface MutationAdminUpdateTrackVariableVisualizationStateArgs {
  input: UpdatePerformanceDataVisualizationInput;
}


export interface MutationApplyRecommendedContentSetArgs {
  contentSetId: Scalars['ID'];
  publishingEntityId: Scalars['ID'];
  questions: InputMaybe<ReadonlyArray<Scalars['ID']>>;
}


export interface MutationArchiveQuestionSetArgs {
  accessSetting: InputMaybe<AccessSettingInput>;
  questionSetId: Scalars['ID'];
  status: QuestionStatus;
}


export interface MutationContentSetSocialShareArgs {
  contentSetId: Scalars['ID'];
  message: InputMaybe<Scalars['String']>;
  publishingEntityId: Scalars['ID'];
  shareTypes: ReadonlyArray<SocialConnectionProvider>;
}


export interface MutationCreateClientOnlyAnalyticsEventArgs {
  event: ClientOnlyAnalyticsEvent;
  metadata: InputMaybe<Scalars['String']>;
}


export interface MutationCreateDemoUserArgs {
  input: CreateDemoUserInput;
}


export interface MutationCreateIdeaArgs {
  idea: IdeaInput;
}


export interface MutationCreateOutcomeArgs {
  input: OutcomeInput;
  setId: Scalars['ID'];
}


export interface MutationCreateQuestionSetArgs {
  input: QuestionSetInput;
  publishingEntityId: Scalars['ID'];
}


export interface MutationDeleteAdminFromPublishingEntityArgs {
  adminId: Scalars['ID'];
  publishingEntityId: Scalars['ID'];
}


export interface MutationDeleteEmailListArgs {
  emailListId: Scalars['ID'];
  publishingEntityId: Scalars['ID'];
}


export interface MutationDeleteOutcomeArgs {
  outcomeId: Scalars['ID'];
}


export interface MutationDeletePollSetQuestionArgs {
  questionId: Scalars['ID'];
}


export interface MutationDeleteQuestionSetArgs {
  id: Scalars['ID'];
}


export interface MutationDuplicateSimulationArgs {
  balancingActSimulationId: Scalars['Int'];
  publishingEntityId: Scalars['ID'];
  type: BalancingActSimulationType;
}


export interface MutationEmailLoginArgs {
  email: Scalars['String'];
  fingerprint: Scalars['String'];
  password: Scalars['String'];
}


export interface MutationEmailSignupArgs {
  email: Scalars['String'];
  fingerprint: Scalars['String'];
  firstName: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  zipCode: Scalars['String'];
}


export interface MutationFollowPublishingEntityArgs {
  follow: Scalars['Boolean'];
  publishingEntityId: Scalars['ID'];
  respondentId: Scalars['ID'];
}


export interface MutationGenerateBenchmarkSurveyPdfArgs {
  surveyId: Scalars['ID'];
}


export interface MutationInteractWithIdeaArgs {
  interaction: InteractionInput;
}


export interface MutationPasswordResetArgs {
  context: RequestPasswordResetContext;
  email: Scalars['String'];
  fingerprint: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}


export interface MutationPublishOutcomeArgs {
  outcomeId: Scalars['ID'];
}


export interface MutationPublishingEntitySocialShareArgs {
  publishingEntityId: Scalars['ID'];
  shareTypes: ReadonlyArray<SocialConnectionProvider>;
}


export interface MutationReorderQuestionSetArgs {
  questionIds: ReadonlyArray<Scalars['ID']>;
  setId: Scalars['ID'];
}


export interface MutationRepostContentSetArgs {
  contentSetId: Scalars['ID'];
  forPublisherId: Scalars['ID'];
}


export interface MutationRequestBenchmarkProgramArgs {
  benchmarkProgram: BenchmarkProgramInput;
}


export interface MutationRequestMagicLinkEmailArgs {
  input: MagicLinkRequestInput;
}


export interface MutationRequestPasswordResetArgs {
  context: RequestPasswordResetContext;
  email: Scalars['String'];
}


export interface MutationRetrieveBenchmarkSurveyPdfArgs {
  surveyId: Scalars['ID'];
}


export interface MutationScheduleQuestionsArgs {
  accessSetting: InputMaybe<AccessSettingInput>;
  questionSetId: Scalars['ID'];
  questions: ReadonlyArray<QuestionScheduleWithIdInput>;
}


export interface MutationScheduleSurveyArgs {
  accessSetting: InputMaybe<AccessSettingInput>;
  schedule: QuestionScheduleInput;
  surveyId: Scalars['ID'];
}


export interface MutationSetPollSetQuestionArgs {
  input: QuestionInput;
  questionSetId: Scalars['ID'];
  schedule: InputMaybe<QuestionScheduleInput>;
}


export interface MutationSetSimulationContentsArgs {
  simId: Scalars['ID'];
  simUpdate: SimulationInput;
}


export interface MutationSetSurveyContentsArgs {
  conditions: ConditionsInput;
  input: ReadonlyArray<SurveyNodeInput>;
  questionSetId: Scalars['ID'];
}


export interface MutationSocialLoginArgs {
  data: SocialLoginData;
  fingerprint: Scalars['String'];
}


export interface MutationSoftDeleteQuestionSetArgs {
  accessSetting: InputMaybe<AccessSettingInput>;
  questionSetId: Scalars['ID'];
}


export interface MutationSuperAdminApproveRegistrationArgs {
  registrationId: Scalars['ID'];
}


export interface MutationSuperAdminAssociatePublishingEntityWithRegistrationArgs {
  publishingEntityId: Scalars['ID'];
  registrationId: Scalars['ID'];
}


export interface MutationSuperAdminCreateContentSetReportTabArgs {
  input: ReportTabCreateInput;
  setId: Scalars['ID'];
}


export interface MutationSuperAdminCreateOrUpdateRecommendedContentArgs {
  adminDescription: InputMaybe<Scalars['String']>;
  domains: ReadonlyArray<ShareableContentDomains>;
  isPaidContent: Scalars['Boolean'];
  setId: Scalars['ID'];
  shareableContentType: ShareableContentType;
  tags: ReadonlyArray<TagInput>;
}


export interface MutationSuperAdminCreatePublishingEntityArgs {
  input: PublishingEntityCreationInput;
}


export interface MutationSuperAdminCreateSuperAdminArgs {
  input: CreateSuperAdminInput;
}


export interface MutationSuperAdminCreateThirdPartyRecordArgs {
  input: ThirdPartyRecordInput;
}


export interface MutationSuperAdminDeleteBenchmarkReportRequestArgs {
  requestId: Scalars['ID'];
}


export interface MutationSuperAdminDeleteContentSetReportTabArgs {
  tabId: Scalars['ID'];
}


export interface MutationSuperAdminDeletePublishingEntityArgs {
  publishingEntityId: Scalars['ID'];
}


export interface MutationSuperAdminDeleteShortUrlArgs {
  shortUrlId: Scalars['ID'];
}


export interface MutationSuperAdminDeleteThirdPartyRecordArgs {
  thirdPartyRecordId: Scalars['ID'];
}


export interface MutationSuperAdminDownloadBenchmarkReportRequestDataArgs {
  requestId: Scalars['ID'];
}


export interface MutationSuperAdminGenerateContentSetShortUrlsArgs {
  parameters: ReadonlyArray<NewShortUrlParameters>;
  setId: Scalars['ID'];
}


export interface MutationSuperAdminImportTranslationsArgs {
  surveyId: Scalars['ID'];
  translationSets: ReadonlyArray<TranslationSet>;
}


export interface MutationSuperAdminPublishBenchmarkReportRequestArgs {
  requestId: Scalars['ID'];
}


export interface MutationSuperAdminRejectRegistrationArgs {
  registrationId: Scalars['ID'];
}


export interface MutationSuperAdminRemoveSuperAdminArgs {
  adminId: Scalars['ID'];
}


export interface MutationSuperAdminReorderReportTabsArgs {
  setId: Scalars['ID'];
  tabOrder: ReadonlyArray<Scalars['ID']>;
}


export interface MutationSuperAdminResetFeatureSettingsArgs {
  publishingEntityId: Scalars['ID'];
  resetState: InputMaybe<ResetFeatureSettingState>;
}


export interface MutationSuperAdminSetPurchaseStatusArgs {
  markPaid: Scalars['Boolean'];
  purchaseId: Scalars['ID'];
}


export interface MutationSuperAdminTogglePublishingEntityFeatureSettingArgs {
  dateToEnableUntil: GqlDateInput;
  featureSettingId: Scalars['ID'];
  isUnlimited: InputMaybe<Scalars['Boolean']>;
  publishingEntityId: Scalars['ID'];
  quantity: InputMaybe<Scalars['Int']>;
}


export interface MutationSuperAdminTogglePublishingEntityFeatureSettingsArgs {
  featureSettings: ReadonlyArray<FeatureSettingInput>;
  publishingEntityId: Scalars['ID'];
}


export interface MutationSuperAdminUpdateContentSetReportTabArgs {
  input: ReportTabUpdateInput;
  setId: Scalars['ID'];
}


export interface MutationSuperAdminUpdatePresidingAreaArgs {
  area: InputMaybe<PresidingAreaInput>;
  publishingEntityId: Scalars['ID'];
}


export interface MutationSuperAdminUpdatePublisherAttributesArgs {
  attributes: SuperAdminPublisherAttributesInput;
  publishingEntityId: Scalars['ID'];
}


export interface MutationSuperAdminUpdateShortUrlArgs {
  shortUrlId: Scalars['ID'];
  updatedShortUrl: Scalars['String'];
}


export interface MutationSuperAdminUpdateThirdPartyRecordArgs {
  input: ThirdPartyRecordInput;
  thirdPartyRecordId: Scalars['ID'];
}


export interface MutationSuperAdminUploadContentSetShortUrlsArgs {
  setId: Scalars['ID'];
  shortUrls: ReadonlyArray<UploadShortUrlInput>;
}


export interface MutationSuperAdminUpsertBenchmarkReportRequestArgs {
  data: BenchmarkReportRequestInput;
}


export interface MutationUnUpvoteCommentArgs {
  commentId: Scalars['ID'];
  questionId: Scalars['ID'];
}


export interface MutationUnsubscribeFromAdminShareEmailArgs {
  shareEmailMetadataId: Scalars['ID'];
}


export interface MutationUnsubscribeFromOutreachEmailArgs {
  emailListEntryId: Scalars['ID'];
}


export interface MutationUpdateAdminForPublishingEntityArgs {
  publishingEntityId: Scalars['ID'];
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface MutationUpdateEmailSettingsArgs {
  token: Scalars['String'];
  unsubscribeTypes: ReadonlyArray<EmailType>;
}


export interface MutationUpdateOutcomeArgs {
  input: OutcomeInput;
  outcomeId: Scalars['ID'];
}


export interface MutationUpdateQuestionSetArgs {
  id: Scalars['ID'];
  input: QuestionSetInput;
}


export interface MutationUpdateRespondentArgs {
  input: UpdateRespondentProfileInput;
  respondentId: Scalars['ID'];
}


export interface MutationUpvoteCommentArgs {
  commentId: Scalars['ID'];
  questionId: Scalars['ID'];
}


export interface MutationVerifyAdminEmailArgs {
  token: Scalars['String'];
  userId: Scalars['ID'];
}


export interface MutationVoteForQuestionArgs {
  input: VoteInput;
  questionId: Scalars['ID'];
  upvoteComments: ReadonlyArray<Scalars['ID']>;
  voteMetadata: ReadonlyArray<VoteMetadataEntry>;
}


export interface MutationVoteForSurveyArgs {
  questionSetId: Scalars['ID'];
  surveyInput: ReadonlyArray<SurveyVoteInputWithId>;
  voteMetadata: ReadonlyArray<VoteMetadataEntry>;
}

export interface NcsFacetSummary {
  readonly __typename: 'NcsFacetSummary';
  readonly GEO_NAME: Scalars['String'];
  readonly added: Scalars['String'];
  readonly area: Scalars['Float'];
  readonly avgptm: Scalars['Float'];
  readonly bmark: Scalars['String'];
  readonly fips: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly n: Scalars['Float'];
  readonly ncs: Scalars['Float'];
  readonly newmax: Scalars['Float'];
  readonly pctpos: Scalars['Float'];
  readonly surveyid: Scalars['String'];
  readonly title: Scalars['String'];
  readonly year: Scalars['Float'];
}

export interface NearbyLocations {
  readonly __typename: 'NearbyLocations';
  readonly locations: ReadonlyArray<FipsArea>;
  readonly zipCode: Scalars['String'];
}

export interface NewShortUrlParameters {
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InputMaybe<Scalars['String']>>;
}

export interface NullPerformanceDatum {
  readonly __typename: 'NullPerformanceDatum';
  readonly variable: AnalyticsVariable;
}

export interface Outcome {
  readonly __typename: 'Outcome';
  readonly id: Scalars['ID'];
  readonly state: ContentOutcomeState;
  readonly text: Scalars['String'];
}

export interface OutcomeInput {
  readonly tabIds: ReadonlyArray<Scalars['ID']>;
  readonly text: Scalars['String'];
}

export interface PaginationData {
  readonly __typename: 'PaginationData';
  readonly page: Scalars['Int'];
  readonly perPage: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
}

export interface PaginationParams {
  readonly page: Scalars['Int'];
  readonly perPage: Scalars['Int'];
}

export interface PdfData {
  readonly __typename: 'PdfData';
  readonly lastModified: Maybe<GqlDate>;
  readonly status: Maybe<ReportGenerationStatus>;
  readonly uploadUrl: Maybe<Scalars['String']>;
}

export interface PdfDataResult {
  readonly __typename: 'PdfDataResult';
  readonly error: Maybe<Scalars['String']>;
  readonly pdfData: Maybe<PdfData>;
  readonly publisherName: Scalars['String'];
  readonly publisherSlug: Scalars['String'];
  readonly survey: Survey;
}

export interface PendingSocialConnection {
  readonly __typename: 'PendingSocialConnection';
  readonly availableAccounts: Maybe<ReadonlyArray<SocialConnectionAccount>>;
  readonly provider: SocialConnectionProvider;
}

export type PerformanceDatum = FullPerformanceDatum | NullPerformanceDatum;

export interface PerformanceDatumDistribution {
  readonly __typename: 'PerformanceDatumDistribution';
  readonly benchmarkFilter: BenchmarkFilter;
  readonly bins: ReadonlyArray<DistributionBin>;
  readonly comparisonGroup: Maybe<ComparisonGroup>;
  readonly geoType: FipsAreaType;
  readonly id: Scalars['ID'];
}

export interface PerformanceDatumPreviousValue {
  readonly __typename: 'PerformanceDatumPreviousValue';
  readonly id: Scalars['ID'];
  readonly recordedAt: GqlDate;
  readonly value: Scalars['Float'];
}

export interface PointAllocationChoice {
  readonly id: Scalars['ID'];
  readonly point: Scalars['Float'];
}

export interface PolcoLive extends ContentSetBase {
  readonly __typename: 'PolcoLive';
  readonly accessSetting: Maybe<AccessSetting>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly alternateLanguages: ReadonlyArray<Language>;
  readonly createDate: GqlDate;
  readonly createdQuestions: CreatedQuestionsData;
  readonly description: Maybe<MultiLanguageContent>;
  readonly hasAutomaticWeighting: Scalars['Boolean'];
  readonly hasBenchmarkedContent: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly image: Maybe<QuestionSetImage>;
  readonly isRepostable: Scalars['Boolean'];
  readonly name: MultiLanguageContent;
  readonly outcome: Maybe<Outcome>;
  readonly polcoManaged: Scalars['Boolean'];
  readonly publishingEntity: PublishingEntity;
  readonly questionsCount: Scalars['Int'];
  readonly reportTabs: Maybe<ReadonlyArray<ReportTab>>;
  readonly reposters: ReadonlyArray<PublishingEntity>;
  readonly schedule: QuestionSetSchedule;
  readonly setForRespondentVote: RespondentQuestionVoteData;
  readonly setImageId: Maybe<Scalars['String']>;
  readonly settings: PolcoLiveSettings;
  readonly shareable: Scalars['Boolean'];
  readonly shareableContentSet: Maybe<ShareableContentSet>;
  readonly shortId: Maybe<Scalars['String']>;
  readonly shortUrls: ReadonlyArray<ShortUrl>;
  readonly showConversionPrompts: Scalars['Boolean'];
  readonly slug: Maybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Tag>;
  readonly totalRespondents: Maybe<Scalars['Int']>;
}


export interface PolcoLiveCreatedQuestionsArgs {
  pagination: InputMaybe<PaginationParams>;
  status: ReadonlyArray<QuestionStatus>;
}


export interface PolcoLiveReportTabsArgs {
  publiclyShared: Scalars['Boolean'];
}


export interface PolcoLiveSetForRespondentVoteArgs {
  respondentId: InputMaybe<Scalars['ID']>;
}


export interface PolcoLiveTotalRespondentsArgs {
  scopeByPublishingEntityId: InputMaybe<Scalars['ID']>;
}

export interface PolcoLiveSettings {
  readonly __typename: 'PolcoLiveSettings';
  readonly eventEndDate: Maybe<GqlDate>;
  readonly eventStartDate: Maybe<GqlDate>;
  readonly id: Scalars['ID'];
  readonly liveVideoLink: Maybe<Scalars['String']>;
  readonly state: QuestionStatus;
}

export interface PresidingArea {
  readonly __typename: 'PresidingArea';
  readonly center: CoordinateCenter;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly shape: Maybe<GeoJsonMultiPolygon>;
}

export interface PresidingAreaInput {
  readonly name: Scalars['String'];
  readonly shape: InputMaybe<GeoJsonMultiPolygonInput>;
}

export interface PrivateAdmin extends AdminBase {
  readonly __typename: 'PrivateAdmin';
  readonly activePublishingEntity: Maybe<PublishingEntity>;
  readonly commsEmail: Maybe<Scalars['String']>;
  readonly createDate: GqlDate;
  readonly demoPollyUrl: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly emailVerificationStatus: EmailVerificationStatus;
  /** @deprecated Removing planhat */
  readonly hasPlanhatPortal: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isEnvisioUser: Scalars['Boolean'];
  readonly managedPublishingEntities: ReadonlyArray<PublishingEntity>;
  readonly menuItems: ReadonlyArray<MenuItem>;
  readonly name: Scalars['String'];
  readonly permissions: ReadonlyArray<Scalars['String']>;
  readonly phoneExt: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly registrationRequests: ReadonlyArray<AdminRegistration>;
  readonly registrationRole: Maybe<AdminRegistrationRole>;
  readonly requestedFeatures: ReadonlyArray<Scalars['String']>;
  readonly superAdmin: Scalars['Boolean'];
  readonly user: User;
}


export interface PrivateAdminManagedPublishingEntitiesArgs {
  filter: InputMaybe<Scalars['String']>;
  page: InputMaybe<Scalars['Int']>;
}

export interface PrivateRespondent extends RespondentBase {
  readonly __typename: 'PrivateRespondent';
  readonly avatarUrl: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly emailSettings: Maybe<UserEmailSettings>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly guest: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly inputtedLat: Maybe<Scalars['Float']>;
  readonly inputtedLon: Maybe<Scalars['Float']>;
  readonly invitedPlaceholderAccount: Scalars['Boolean'];
  readonly lastName: Maybe<Scalars['String']>;
  readonly openSurvey: Survey;
  readonly postAnonymously: Scalars['Boolean'];
  readonly publicName: Scalars['String'];
  readonly questionSetVote: Maybe<RespondentQuestionVoteData>;
  readonly requestLocationLastShown: Maybe<GqlDate>;
  readonly requiresVerificationPrompt: Scalars['Boolean'];
  readonly subscribablePublishers: ReadonlyArray<PublishingEntity>;
  readonly subscriptions: ReadonlyArray<PublishingEntity>;
  readonly subscriptionsCanPublishIdeas: ReadonlyArray<PublishingEntity>;
  readonly verified: Scalars['Boolean'];
  readonly zipCode: Maybe<Scalars['String']>;
}


export interface PrivateRespondentOpenSurveyArgs {
  questionSetId: Scalars['ID'];
}


export interface PrivateRespondentQuestionSetVoteArgs {
  questionSetId: Scalars['ID'];
}

export interface ProtectedStatBreakdown {
  readonly __typename: 'ProtectedStatBreakdown';
  readonly availableDataPoints: Scalars['Int'];
  readonly neededDataPoints: Scalars['Int'];
}

export interface PublicAdmin extends AdminBase {
  readonly __typename: 'PublicAdmin';
  readonly createDate: GqlDate;
  /** @deprecated Removing planhat */
  readonly hasPlanhatPortal: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isEnvisioUser: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly user: User;
}

export interface PublicRespondent extends RespondentBase {
  readonly __typename: 'PublicRespondent';
  readonly avatarUrl: Maybe<Scalars['String']>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly lastName: Maybe<Scalars['String']>;
  readonly postAnonymously: Scalars['Boolean'];
  readonly publicName: Scalars['String'];
}

export interface PublishingEntity {
  readonly __typename: 'PublishingEntity';
  readonly activationState: PublishingEntityActivationState;
  readonly activeEngageContentCount: Scalars['Int'];
  readonly activeLiveEvents: ReadonlyArray<PolcoLive>;
  readonly adminRoles: Maybe<ReadonlyArray<AdminRole>>;
  readonly aiAssistantInteractions: Maybe<AiAssistantPublisher>;
  readonly approvalDecision: PublishingEntityApprovalDecision;
  readonly assets: ReadonlyArray<PublishingEntityAsset>;
  readonly availableGeoViews: ReadonlyArray<GeoViewBase>;
  readonly balancingActCustomer: Maybe<BalancingActCustomer>;
  readonly comparisonGroups: ReadonlyArray<ComparisonGroup>;
  readonly createdBenchmarkSurveys: ReadonlyArray<Survey>;
  readonly createdQuestionSets: Maybe<QuestionSetsData>;
  readonly createdQuestions: CreatedQuestionsData;
  readonly defaultGeoView: GeoViewBase;
  readonly demographicQuestions: ReadonlyArray<Question>;
  readonly description: MultiLanguageContent;
  /** @deprecated Use domainTrackVariables fo track data */
  readonly domainPerformanceData: DomainPerformanceData;
  /** @deprecated Use trackDomain fo track data */
  readonly domainTrackVariables: DomainTrackVariables;
  readonly emailLists: ReadonlyArray<EmailList>;
  readonly envisioSetting: Maybe<PublishingEntityEnvisioSetting>;
  readonly featureSettingQuantity: Scalars['Int'];
  readonly featureSettings: Maybe<ReadonlyArray<PublishingEntityFeatureSetting>>;
  /** @deprecated Use fipsArea instead */
  readonly fips: Maybe<Scalars['String']>;
  readonly fipsArea: Maybe<FipsArea>;
  readonly id: Scalars['ID'];
  readonly ideaSubmissions: ReadonlyArray<Idea>;
  readonly isEnterprise: Scalars['Boolean'];
  readonly mailingsData: Maybe<PublishingEntityMailingsData>;
  readonly managingAdmins: Maybe<ReadonlyArray<PublishingEntityAdmin>>;
  readonly name: MultiLanguageContent;
  readonly ncsFacetSummaries: Maybe<ReadonlyArray<NcsFacetSummary>>;
  readonly occupiedSeats: Scalars['Int'];
  readonly organizationArea: Maybe<PublishingEntityOrganizationArea>;
  readonly organizationSubtype: Maybe<PublishingEntityOrganizationSubtype>;
  readonly organizationType: Maybe<PublishingEntityOrganizationType>;
  readonly postedBy: MultiLanguageContent;
  readonly presidingArea: Maybe<PresidingArea>;
  readonly publisherFeed: RespondentFeed;
  readonly questionSetImages: ReadonlyArray<QuestionSetImage>;
  readonly searchShareableContent: LibrarySearchResult;
  readonly shareableContentSetsForSidebar: Maybe<ReadonlyArray<ShareableContentSet>>;
  readonly shortUrl: Maybe<ShortUrl>;
  readonly showExampleQuestions: Scalars['Boolean'];
  readonly simulations: ReadonlyArray<AdminBalancingActSimulation>;
  readonly slug: Scalars['String'];
  readonly socialConnections: Maybe<ReadonlyArray<SocialConnection>>;
  readonly storeItems: ReadonlyArray<PublishingEntityStoreItem>;
  readonly strategicPlans: ReadonlyArray<StrategicPlan>;
  readonly subscribersOverTime: Maybe<TimeBreakdown>;
  readonly subscribersStat: Maybe<Stat>;
  readonly subscriptionMode: SubscriptionMode;
  readonly subscriptionType: Maybe<SubscriptionType>;
  readonly systemType: Maybe<Scalars['String']>;
  readonly thirdPartyRecords: Maybe<ReadonlyArray<ThirdPartyRecord>>;
  readonly totalResponses: Maybe<StatValueDelta>;
  readonly totalSubscribers: Maybe<Scalars['Int']>;
  readonly trackDomain: TrackDomain;
  readonly trackVariableVisualizations: ReadonlyArray<TrackVariableVisualization>;
  readonly type: Maybe<PublishingEntityType>;
  /** @deprecated Use SAVED_DATA_POINTS feature setting quantity */
  readonly visualizationsLimit: Maybe<Scalars['Int']>;
}


export interface PublishingEntityAiAssistantInteractionsArgs {
  assistantId: Scalars['String'];
}


export interface PublishingEntityCreatedBenchmarkSurveysArgs {
  type: InputMaybe<BenchmarkSurveyTypes>;
}


export interface PublishingEntityCreatedQuestionSetsArgs {
  generatedTypes: ReadonlyArray<GqlQuestionSetGeneratedTypes>;
  status: ReadonlyArray<QuestionSetStatus>;
}


export interface PublishingEntityCreatedQuestionsArgs {
  status: ReadonlyArray<QuestionStatus>;
}


export interface PublishingEntityDomainPerformanceDataArgs {
  displayType: InputMaybe<VariableDisplayType>;
  domain: AnalyticsDomain;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
  includeHierarchicalData: InputMaybe<Scalars['Boolean']>;
}


export interface PublishingEntityDomainTrackVariablesArgs {
  areaMode: TrackAreaMode;
  displayType: InputMaybe<VariableDisplayType>;
  domain: AnalyticsDomain;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
  includeHierarchicalData: InputMaybe<Scalars['Boolean']>;
}


export interface PublishingEntityFeatureSettingQuantityArgs {
  featureSettingName: Scalars['String'];
}


export interface PublishingEntityFeatureSettingsArgs {
  names: InputMaybe<ReadonlyArray<Scalars['String']>>;
}


export interface PublishingEntityIdeaSubmissionsArgs {
  pagination: PaginationParams;
}


export interface PublishingEntityPublisherFeedArgs {
  before: InputMaybe<GqlDateInput>;
  respondentId: InputMaybe<Scalars['ID']>;
  resultLimit: InputMaybe<Scalars['Int']>;
}


export interface PublishingEntitySearchShareableContentArgs {
  domains: InputMaybe<ReadonlyArray<ShareableContentDomains>>;
  searchQuery: Scalars['String'];
}


export interface PublishingEntitySimulationsArgs {
  simulationId: InputMaybe<Scalars['Int']>;
}


export interface PublishingEntityStoreItemsArgs {
  type: StoreItemsType;
}


export interface PublishingEntitySubscribersStatArgs {
  input: SubscriberStatsInput;
}


export interface PublishingEntitySubscriptionTypeArgs {
  respondentId: InputMaybe<Scalars['ID']>;
}


export interface PublishingEntityTrackDomainArgs {
  areaMode: TrackAreaMode;
  displayType: InputMaybe<VariableDisplayType>;
  domain: AnalyticsDomain;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
  includeHierarchicalData: InputMaybe<Scalars['Boolean']>;
}

export enum PublishingEntityActivationState {
  ACTIVATED = 'ACTIVATED',
  ACTIVATION_REQUESTED = 'ACTIVATION_REQUESTED',
  NOT_ACTIVATED = 'NOT_ACTIVATED'
}

export interface PublishingEntityAdmin {
  readonly __typename: 'PublishingEntityAdmin';
  readonly admin: Admin;
  readonly id: Scalars['ID'];
  readonly publishingEntity: PublishingEntity;
  readonly roleId: Scalars['ID'];
  readonly roleName: Scalars['String'];
}

export enum PublishingEntityApprovalDecision {
  APPROVED = 'APPROVED',
  APPROVED_AUTOMATIC = 'APPROVED_AUTOMATIC',
  NOT_APPROVED = 'NOT_APPROVED'
}

export interface PublishingEntityAsset {
  readonly __typename: 'PublishingEntityAsset';
  readonly id: Scalars['ID'];
  readonly type: PublishingEntityAssetType;
  readonly url: Scalars['String'];
}

export enum PublishingEntityAssetType {
  BACKGROUND = 'BACKGROUND',
  BANNER = 'BANNER',
  GIS_PRIMARY = 'GIS_PRIMARY',
  GIS_SECONDARY = 'GIS_SECONDARY',
  MAILING_LOGO = 'MAILING_LOGO',
  PRIMARY = 'PRIMARY',
  PRIMARY_SIGNATURE = 'PRIMARY_SIGNATURE',
  PROFILE_COVER = 'PROFILE_COVER',
  SECONDARY = 'SECONDARY',
  SECONDARY_SIGNATURE = 'SECONDARY_SIGNATURE'
}

export interface PublishingEntityCreationInput {
  readonly organizationAreaId: Scalars['ID'];
  readonly organizationSubtypeId: InputMaybe<Scalars['ID']>;
  readonly organizationTypeId: Scalars['ID'];
  readonly presidingArea: InputMaybe<RequiredPresidingAreaInput>;
  readonly pubName: Scalars['String'];
  readonly subscriptionMode: SubscriptionMode;
}

export interface PublishingEntityEnvisioSetting {
  readonly __typename: 'PublishingEntityEnvisioSetting';
  readonly id: Scalars['ID'];
  readonly instanceId: Maybe<Scalars['String']>;
  readonly magicToken: Scalars['String'];
}

export interface PublishingEntityFeatureSetting {
  readonly __typename: 'PublishingEntityFeatureSetting';
  readonly activeCount: Maybe<Scalars['Int']>;
  readonly enabled: Scalars['Boolean'];
  readonly expiresAt: GqlDate;
  readonly featureSettingId: Scalars['String'];
  readonly featureSettingName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isUnlimited: Scalars['Boolean'];
  readonly publishingEntity: PublishingEntity;
  readonly quantity: Scalars['Int'];
}

export interface PublishingEntityGeoView extends GeoViewBase {
  readonly __typename: 'PublishingEntityGeoView';
  readonly geoAreas: ReadonlyArray<GeoArea>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly publishingEntity: PublishingEntity;
}

export interface PublishingEntityMailingsData {
  readonly __typename: 'PublishingEntityMailingsData';
  readonly adminIndicatedComplete: Scalars['Boolean'];
  readonly contactPhoneNumber: Maybe<Scalars['String']>;
  readonly fullName: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly returnMailingAddress: Maybe<Scalars['String']>;
  readonly samplingZipCodes: Maybe<Scalars['String']>;
  readonly title: Maybe<Scalars['String']>;
  readonly useExistingInfo: Scalars['Boolean'];
}

export interface PublishingEntityMailingsInput {
  readonly adminIndicatedComplete: Scalars['Boolean'];
  readonly contactPhoneNumber: InputMaybe<Scalars['String']>;
  readonly fullName: InputMaybe<Scalars['String']>;
  readonly gisPrimaryUrl: InputMaybe<Scalars['String']>;
  readonly gisSecondaryUrl: InputMaybe<Scalars['String']>;
  readonly mailingLogoUrl: InputMaybe<Scalars['String']>;
  readonly primarySignatureUrl: InputMaybe<Scalars['String']>;
  readonly returnMailingAddress: InputMaybe<Scalars['String']>;
  readonly samplingZipCodes: InputMaybe<Scalars['String']>;
  readonly secondarySigatureUrl: InputMaybe<Scalars['String']>;
  readonly title: InputMaybe<Scalars['String']>;
  readonly useExistingInfo: Scalars['Boolean'];
}

export interface PublishingEntityOrganizationArea {
  readonly __typename: 'PublishingEntityOrganizationArea';
  readonly archived: Scalars['Boolean'];
  readonly areaLookupEntryLabel: Maybe<Scalars['String']>;
  readonly areaLookupType: AreaLookupType;
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly knownKey: Maybe<KnownOrganizationAreaKey>;
}

export interface PublishingEntityOrganizationSubtype {
  readonly __typename: 'PublishingEntityOrganizationSubtype';
  readonly archived: Scalars['Boolean'];
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
}

export interface PublishingEntityOrganizationType {
  readonly __typename: 'PublishingEntityOrganizationType';
  readonly archived: Scalars['Boolean'];
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly nameEntryLabel: Scalars['String'];
  readonly subtypes: ReadonlyArray<PublishingEntityOrganizationSubtype>;
}

export interface PublishingEntityStoreItem {
  readonly __typename: 'PublishingEntityStoreItem';
  readonly expiresAt: GqlDate;
  readonly id: Scalars['ID'];
  readonly purchasedInStripe: Scalars['Boolean'];
  readonly status: StoreItemPublisherStatus;
  readonly storeItem: StoreItem;
}

export interface PublishingEntityType {
  readonly __typename: 'PublishingEntityType';
  readonly archived: Scalars['Boolean'];
  readonly areaLookupEntryLabel: Scalars['String'];
  readonly areaLookupType: AreaLookupType;
  readonly displayName: Scalars['String'];
  readonly displayNamePlural: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly nameEntryLabel: Scalars['String'];
}

export enum PublishingEntityTypeFilter {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL'
}

export enum PurchaseStatusType {
  FREE_TRIAL = 'FREE_TRIAL',
  PAID = 'PAID',
  UNPAID = 'UNPAID'
}

export interface PurchasedStoreItem {
  readonly __typename: 'PurchasedStoreItem';
  readonly createdAt: GqlDate;
  readonly id: Scalars['ID'];
  readonly price: Scalars['Float'];
  readonly publishingEntity: PublishingEntity;
  readonly purchasedByAdmin: Admin;
  readonly purchasedInStripe: Scalars['Boolean'];
  readonly status: PurchaseStatusType;
  readonly storeItem: StoreItem;
}

export interface QiMatrix {
  readonly __typename: 'QiMatrix';
  readonly date: GqlDate;
  readonly points: ReadonlyArray<QiMatrixPoint>;
  readonly publishingEntityId: Scalars['ID'];
}

export interface QiMatrixAxis {
  readonly __typename: 'QiMatrixAxis';
  readonly sources: ReadonlyArray<QiMatrixSource>;
  readonly value: Scalars['Float'];
}

export interface QiMatrixPoint {
  readonly __typename: 'QiMatrixPoint';
  readonly importance: QiMatrixAxis;
  readonly quality: QiMatrixAxis;
  readonly topic: MatrixTopic;
}

export interface QiMatrixSource {
  readonly __typename: 'QiMatrixSource';
  readonly questionSetId: Maybe<Scalars['ID']>;
  readonly source: Maybe<MatrixSource>;
  readonly sourceLabel: Maybe<Scalars['String']>;
  readonly sourceType: MatrixSourceType;
}

export enum QualitativeQuestionResultSortType {
  BENCHMARK_DIFFERENCE = 'BENCHMARK_DIFFERENCE',
  TREND_DIFFERENCE = 'TREND_DIFFERENCE',
  WEIGHTED_PERCENT = 'WEIGHTED_PERCENT'
}

export enum QualitativeTopics {
  IMPORTANCE = 'IMPORTANCE',
  QUALITY = 'QUALITY'
}

export interface Query {
  readonly __typename: 'Query';
  readonly adminOrderedStoreItems: ReadonlyArray<PublishingEntityStoreItem>;
  readonly adminPublishers: ReadonlyArray<PublishingEntity>;
  readonly adminRegistrationRoles: ReadonlyArray<AdminRegistrationRole>;
  readonly adminShareableContentSet: Maybe<ShareableContentSet>;
  readonly adminStoreItemByIdOrKey: Maybe<PublishingEntityStoreItem>;
  readonly currentAiAssistantSession: AiAssistantSession;
  readonly currentUser: CurrentUser;
  readonly feed: RespondentFeed;
  readonly flags: ActiveFlags;
  readonly openAnalyticsVariables: ReadonlyArray<NullPerformanceDatum>;
  readonly openBalancingActSimulation: BalancingActSimulationWithPubId;
  readonly openBalancingActTaxReceipt: BalancingActSimulationWithPubId;
  readonly openComparisonGroupById: Maybe<ComparisonGroup>;
  readonly openContentSetById: Maybe<ContentSet>;
  readonly openContentSetBySlug: Maybe<ContentSet>;
  readonly openIdea: Idea;
  readonly openPublishingEntityById: Maybe<PublishingEntity>;
  readonly openPublishingEntityBySlug: Maybe<PublishingEntity>;
  readonly openPublishingEntityGeoViewById: Maybe<PublishingEntityGeoView>;
  readonly openQualityImportanceMatrix: ReadonlyArray<QiMatrix>;
  readonly openQuestion: Maybe<Question>;
  readonly openRespondent: Maybe<Respondent>;
  readonly openSavedTrackVariableVisualizationById: Maybe<TrackVariableVisualization>;
  readonly openTrackVariables: ReadonlyArray<TrackVariableFull>;
  readonly publishingEntityOrganizationAreas: ReadonlyArray<PublishingEntityOrganizationArea>;
  readonly publishingEntityOrganizations: ReadonlyArray<PublishingEntityOrganizationType>;
  readonly publishingEntityTypes: ReadonlyArray<PublishingEntityType>;
  readonly registrationInformation: RegistrationInformation;
  readonly superAdminAllPublishers: ReadonlyArray<PublishingEntity>;
  readonly superAdminAllSuperAdmins: ReadonlyArray<User>;
  readonly superAdminBenchmarkPdfs: ReadonlyArray<PdfDataResult>;
  readonly superAdminBenchmarkReportRequestById: BenchmarkReportRequest;
  readonly superAdminBenchmarkReportRequests: ReadonlyArray<BenchmarkReportRequest>;
  readonly superAdminCensusPlaces: ReadonlyArray<CensusPlace>;
  readonly superAdminFilteredPublishers: ReadonlyArray<PublishingEntity>;
  readonly superAdminPurchasedStoreItems: ReadonlyArray<PurchasedStoreItem>;
  readonly superAdminRegistrationDecisions: ReadonlyArray<AdminRegistration>;
}


export interface QueryAdminOrderedStoreItemsArgs {
  publishingEntityId: Scalars['ID'];
  type: StoreItemsType;
}


export interface QueryAdminPublishersArgs {
  filter: InputMaybe<Scalars['String']>;
  page: InputMaybe<Scalars['Int']>;
}


export interface QueryAdminRegistrationRolesArgs {
  filter: AdminRoleFilter;
}


export interface QueryAdminShareableContentSetArgs {
  forPublisherId: Scalars['ID'];
  setId: Scalars['ID'];
  shareType: ShareableContentType;
}


export interface QueryAdminStoreItemByIdOrKeyArgs {
  idOrKey: Scalars['String'];
  publishingEntityId: Scalars['ID'];
}


export interface QueryCurrentAiAssistantSessionArgs {
  assistantId: Scalars['String'];
  sessionId: Scalars['ID'];
}


export interface QueryFeedArgs {
  before: InputMaybe<GqlDateInput>;
  latLng: InputMaybe<CoordinateInput>;
  respId: InputMaybe<Scalars['ID']>;
  resultLimit: InputMaybe<Scalars['Int']>;
  zipCode: InputMaybe<Scalars['String']>;
}


export interface QueryFlagsArgs {
  publishingEntityId: InputMaybe<Scalars['ID']>;
  userId: InputMaybe<Scalars['ID']>;
}


export interface QueryOpenAnalyticsVariablesArgs {
  domain: InputMaybe<AnalyticsDomain>;
}


export interface QueryOpenBalancingActSimulationArgs {
  balancingActSimulationId: Scalars['Int'];
}


export interface QueryOpenBalancingActTaxReceiptArgs {
  balancingActTaxReceiptId: Scalars['Int'];
}


export interface QueryOpenComparisonGroupByIdArgs {
  comparisonGroupId: Scalars['ID'];
}


export interface QueryOpenContentSetByIdArgs {
  setId: Scalars['ID'];
}


export interface QueryOpenContentSetBySlugArgs {
  publishingEntitySlug: Scalars['String'];
  questionSetSlug: Scalars['String'];
}


export interface QueryOpenIdeaArgs {
  ideaId: Scalars['ID'];
  respondentId: InputMaybe<Scalars['ID']>;
}


export interface QueryOpenPublishingEntityByIdArgs {
  publishingEntityId: Scalars['ID'];
}


export interface QueryOpenPublishingEntityBySlugArgs {
  slug: Scalars['String'];
}


export interface QueryOpenPublishingEntityGeoViewByIdArgs {
  geoViewId: Scalars['ID'];
}


export interface QueryOpenQualityImportanceMatrixArgs {
  publishingEntityId: Scalars['ID'];
}


export interface QueryOpenQuestionArgs {
  questionId: Scalars['ID'];
}


export interface QueryOpenRespondentArgs {
  respondentId: Scalars['ID'];
}


export interface QueryOpenSavedTrackVariableVisualizationByIdArgs {
  visualizationId: Scalars['ID'];
}


export interface QueryOpenTrackVariablesArgs {
  comparisonGroupId: InputMaybe<Scalars['ID']>;
  fipsCodes: ReadonlyArray<Scalars['String']>;
  publishingEntityId: InputMaybe<Scalars['ID']>;
  statisticType: StatisticType;
  variableNames: ReadonlyArray<Scalars['String']>;
}


export interface QueryPublishingEntityTypesArgs {
  filter: PublishingEntityTypeFilter;
}


export interface QuerySuperAdminAllPublishersArgs {
  filter: InputMaybe<Scalars['String']>;
  page: InputMaybe<Scalars['Int']>;
}


export interface QuerySuperAdminBenchmarkPdfsArgs {
  filter: InputMaybe<Scalars['String']>;
}


export interface QuerySuperAdminBenchmarkReportRequestByIdArgs {
  reportRequestId: Scalars['ID'];
}


export interface QuerySuperAdminCensusPlacesArgs {
  filter: Scalars['String'];
}


export interface QuerySuperAdminFilteredPublishersArgs {
  filter: SuperAdminPublisherFilter;
}

export interface Question {
  readonly __typename: 'Question';
  readonly aggregateResults: Maybe<AggregateResults>;
  readonly choiceSet: QuestionChoiceSet;
  readonly commentsData: CommentsData;
  readonly commentsWordCount: Maybe<ReadonlyArray<WordCount>>;
  readonly conditions: ConditionalLogic;
  readonly dataDictionary: Maybe<QuestionDataDictionary>;
  readonly demographicAttribute: Maybe<DemographicAttribute>;
  readonly description: Maybe<MultiLanguageContent>;
  readonly hierarchyParentNode: Maybe<QuestionHierarchyParentNode>;
  readonly id: Scalars['ID'];
  readonly isBenchmarkedQuestion: Scalars['Boolean'];
  readonly optional: Scalars['Boolean'];
  readonly previousVote: Maybe<Vote>;
  readonly questionNumber: Maybe<Scalars['Int']>;
  readonly questionSet: ContentSet;
  readonly recommendedQuestion: RecommendedQuestionsData;
  readonly reportDisplayLabel: Maybe<Scalars['String']>;
  readonly responseStat: Maybe<Stat>;
  readonly responsesOverTime: Maybe<TimeBreakdown>;
  readonly results: ReadonlyArray<VotingChoice>;
  readonly schedule: QuestionSchedule;
  readonly shortId: Maybe<Scalars['String']>;
  readonly stats: QuestionStats;
  readonly summary: Maybe<Scalars['String']>;
  readonly title: MultiLanguageContent;
  readonly topics: ReadonlyArray<BenchmarkTopic>;
  readonly totalVotesCount: Maybe<Scalars['Int']>;
  readonly variableName: Maybe<Scalars['String']>;
}


export interface QuestionAggregateResultsArgs {
  omitBenchmarks: InputMaybe<Scalars['Boolean']>;
  omitTrends: InputMaybe<Scalars['Boolean']>;
  reportingBetaMode: InputMaybe<Scalars['Boolean']>;
}


export interface QuestionCommentsDataArgs {
  filter: InputMaybe<CommentFilter>;
}


export interface QuestionCommentsWordCountArgs {
  filter: InputMaybe<CommentFilter>;
}


export interface QuestionDataDictionaryArgs {
  reportRequestId: Scalars['ID'];
}


export interface QuestionPreviousVoteArgs {
  respondentId: InputMaybe<Scalars['ID']>;
}


export interface QuestionRecommendedQuestionArgs {
  page: Scalars['Int'];
}


export interface QuestionResponseStatArgs {
  input: ResponseStatsInput;
}


export interface QuestionResponsesOverTimeArgs {
  scopeByPublishingEntityId: Scalars['ID'];
}


export interface QuestionResultsArgs {
  filter: InputMaybe<ResultFilter>;
}


export interface QuestionTotalVotesCountArgs {
  filter: InputMaybe<ResultFilter>;
}

export interface QuestionChoice {
  readonly __typename: 'QuestionChoice';
  readonly choiceValue: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly text: MultiLanguageContent;
}

export interface QuestionChoiceCondition {
  readonly __typename: 'QuestionChoiceCondition';
  readonly id: Scalars['ID'];
  readonly metaConditionId: Scalars['ID'];
  readonly questionChoiceId: Scalars['ID'];
  readonly questionId: Scalars['ID'];
}

export interface QuestionChoiceConditionInput {
  readonly id: InputMaybe<Scalars['ID']>;
  readonly metaConditionReferenceId: Scalars['ID'];
  readonly questionChoiceReferenceId: Scalars['ID'];
  readonly questionReferenceId: Scalars['ID'];
}

export interface QuestionChoiceInput {
  readonly choiceValue: InputMaybe<Scalars['Float']>;
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly id: InputMaybe<Scalars['ID']>;
  readonly text: Scalars['String'];
}

export interface QuestionChoiceSet {
  readonly __typename: 'QuestionChoiceSet';
  readonly choices: ReadonlyArray<QuestionChoice>;
  readonly id: Scalars['ID'];
  readonly maxSelection: Scalars['Int'];
  readonly randomizeChoices: Maybe<Scalars['Boolean']>;
  readonly scaleData: Maybe<ScaleData>;
  readonly sharedChoiceSetData: Maybe<ShareableQuestionChoiceSet>;
  readonly type: QuestionType;
}

export interface QuestionDataDictionary {
  readonly __typename: 'QuestionDataDictionary';
  readonly benchmarkArea: Maybe<Scalars['String']>;
  readonly weightingLabel: Maybe<Scalars['String']>;
}

export interface QuestionHeaderInput {
  readonly description: InputMaybe<Scalars['String']>;
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly hierarchyId: InputMaybe<Scalars['ID']>;
  readonly label: Scalars['String'];
  readonly metaConditionReferenceId: InputMaybe<Scalars['ID']>;
}

export enum QuestionHierarchyChildInputType {
  GRID = 'GRID',
  HEADER = 'HEADER',
  QUESTION = 'QUESTION',
  SIMULATION = 'SIMULATION',
  VISUALIZATION = 'VISUALIZATION'
}

export type QuestionHierarchyNode = Question | QuestionHierarchyParentNode | QuestionHierarchySimulationNode | QuestionHierarchyVisualizationNode;

export interface QuestionHierarchyParentNode {
  readonly __typename: 'QuestionHierarchyParentNode';
  readonly conditions: ConditionalLogic;
  readonly description: Maybe<MultiLanguageContent>;
  readonly hasBenchmarkedContent: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly label: Maybe<MultiLanguageContent>;
  readonly questionNumber: Maybe<Scalars['Int']>;
  readonly questions: ReadonlyArray<Question>;
  readonly survey: Survey;
  readonly type: QuestionHierarchyParentNodeType;
}


export interface QuestionHierarchyParentNodeQuestionsArgs {
  filterByTopicIds: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  useMicroservice: InputMaybe<Scalars['Boolean']>;
}

export enum QuestionHierarchyParentNodeType {
  GRID = 'GRID',
  HEADER = 'HEADER'
}

export interface QuestionHierarchySimulationNode {
  readonly __typename: 'QuestionHierarchySimulationNode';
  readonly conditions: ConditionalLogic;
  readonly id: Scalars['ID'];
  readonly simulation: Maybe<SimulationNodeData>;
  readonly simulationType: SimulationNodeType;
}

export interface QuestionHierarchyVisualizationNode {
  readonly __typename: 'QuestionHierarchyVisualizationNode';
  readonly altLabel: Maybe<Scalars['String']>;
  readonly conditions: ConditionalLogic;
  readonly id: Scalars['ID'];
  readonly trackVariableVisualization: Maybe<TrackVariableVisualization>;
}

export interface QuestionInput {
  readonly backgroundInfo: Scalars['String'];
  readonly choices: ReadonlyArray<QuestionChoiceInput>;
  readonly dataDictionary: InputMaybe<DataDictionaryInput>;
  readonly demographicAttributeId: InputMaybe<Scalars['String']>;
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly imageUrls: ReadonlyArray<Scalars['String']>;
  readonly maxSelection: InputMaybe<Scalars['Int']>;
  readonly metaConditionReferenceId: InputMaybe<Scalars['ID']>;
  readonly optional: Scalars['Boolean'];
  readonly questionId: InputMaybe<Scalars['ID']>;
  readonly randomizeChoices: InputMaybe<Scalars['Boolean']>;
  readonly shareableQuestionChoiceSetId: InputMaybe<Scalars['ID']>;
  readonly title: Scalars['String'];
  readonly type: QuestionType;
  readonly variableName: InputMaybe<Scalars['String']>;
}

export interface QuestionMapStats {
  readonly __typename: 'QuestionMapStats';
  readonly buckets: ReadonlyArray<MapStatBucketGroup>;
  readonly center: CoordinateCenter;
  readonly hits: Scalars['Int'];
}

export interface QuestionMapStatsWithStatus {
  readonly __typename: 'QuestionMapStatsWithStatus';
  readonly mapStatsStatus: MapStatsStatus;
  readonly questionMapStats: Maybe<QuestionMapStats>;
}

export enum QuestionRelationshipType {
  BENCHMARK = 'BENCHMARK',
  COPY = 'COPY'
}

export interface QuestionSchedule {
  readonly __typename: 'QuestionSchedule';
  readonly closeDate: Maybe<GqlDate>;
  readonly openDate: Maybe<GqlDate>;
  readonly status: QuestionStatus;
}

export interface QuestionScheduleInput {
  readonly future: InputMaybe<QuestionSchedule_Future>;
  readonly now: InputMaybe<QuestionSchedule_Now>;
  readonly soon: InputMaybe<QuestionSchedule_Soon>;
  readonly type: QuestionSchedule_Type;
}

export interface QuestionScheduleWithIdInput {
  readonly id: Scalars['ID'];
  readonly schedule: QuestionScheduleInput;
}

export interface QuestionSchedule_Future {
  readonly closeDate: InputMaybe<GqlDateInput>;
  readonly openDate: GqlDateInput;
}

export interface QuestionSchedule_Now {
  readonly closeDate: InputMaybe<GqlDateInput>;
}

export interface QuestionSchedule_Soon {
  readonly seconds: Scalars['Int'];
}

export enum QuestionSchedule_Type {
  CLOSE_NOW = 'CLOSE_NOW',
  CLOSE_SOON = 'CLOSE_SOON',
  EXTEND = 'EXTEND',
  MOVE_TO_CART = 'MOVE_TO_CART',
  MOVE_TO_DRAFT = 'MOVE_TO_DRAFT',
  PUBLISH_NOW = 'PUBLISH_NOW',
  SCHEDULE_FUTURE = 'SCHEDULE_FUTURE'
}

export interface QuestionSet extends ContentSetBase {
  readonly __typename: 'QuestionSet';
  readonly accessSetting: Maybe<AccessSetting>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly alternateLanguages: ReadonlyArray<Language>;
  readonly createDate: GqlDate;
  readonly createdQuestions: CreatedQuestionsData;
  readonly description: Maybe<MultiLanguageContent>;
  readonly hasAutomaticWeighting: Scalars['Boolean'];
  readonly hasBenchmarkedContent: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly image: Maybe<QuestionSetImage>;
  readonly isRepostable: Scalars['Boolean'];
  readonly name: MultiLanguageContent;
  readonly outcome: Maybe<Outcome>;
  readonly polcoManaged: Scalars['Boolean'];
  readonly publishingEntity: PublishingEntity;
  readonly questionsCount: Scalars['Int'];
  readonly reportTabs: Maybe<ReadonlyArray<ReportTab>>;
  readonly reposters: ReadonlyArray<PublishingEntity>;
  readonly schedule: QuestionSetSchedule;
  readonly setForRespondentVote: RespondentQuestionVoteData;
  readonly setImageId: Maybe<Scalars['String']>;
  readonly shareable: Scalars['Boolean'];
  readonly shareableContentSet: Maybe<ShareableContentSet>;
  readonly shortUrls: ReadonlyArray<ShortUrl>;
  readonly showConversionPrompts: Scalars['Boolean'];
  readonly slug: Maybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Tag>;
  readonly totalRespondents: Maybe<Scalars['Int']>;
}


export interface QuestionSetCreatedQuestionsArgs {
  pagination: InputMaybe<PaginationParams>;
  status: ReadonlyArray<QuestionStatus>;
}


export interface QuestionSetReportTabsArgs {
  publiclyShared: Scalars['Boolean'];
}


export interface QuestionSetSetForRespondentVoteArgs {
  respondentId: InputMaybe<Scalars['ID']>;
}


export interface QuestionSetTotalRespondentsArgs {
  scopeByPublishingEntityId: InputMaybe<Scalars['ID']>;
}

export interface QuestionSetContentPostInput {
  readonly accessSetting: InputMaybe<AccessSettingInput>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly description: InputMaybe<Scalars['String']>;
  readonly hasAutomaticWeighting: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly setImageId: InputMaybe<Scalars['String']>;
  readonly showConversionPrompts: Scalars['Boolean'];
}

export interface QuestionSetImage {
  readonly __typename: 'QuestionSetImage';
  readonly backgroundColor: Scalars['String'];
  readonly horizontalUrl: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly verticalUrl: Scalars['String'];
}

export interface QuestionSetInput {
  readonly CONTENT_POST: InputMaybe<QuestionSetContentPostInput>;
  readonly POLCO_LIVE: InputMaybe<QuestionSetPolcoLiveInput>;
  readonly SET: InputMaybe<QuestionSetPollSetInput>;
  readonly SURVEY: InputMaybe<QuestionSetSurveyInput>;
  readonly type: QuestionSetType;
}

export interface QuestionSetPolcoLiveInput {
  readonly accessSetting: InputMaybe<AccessSettingInput>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly description: InputMaybe<Scalars['String']>;
  readonly eventEndDate: InputMaybe<GqlDateInput>;
  readonly eventStartDate: InputMaybe<GqlDateInput>;
  readonly liveVideoLink: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly setImageId: InputMaybe<Scalars['String']>;
  readonly showConversionPrompts: Scalars['Boolean'];
  readonly state: QuestionStatus;
}

export interface QuestionSetPollSetInput {
  readonly accessSetting: InputMaybe<AccessSettingInput>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly description: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly setImageId: InputMaybe<Scalars['String']>;
  readonly showConversionPrompts: Scalars['Boolean'];
}

export interface QuestionSetSchedule {
  readonly __typename: 'QuestionSetSchedule';
  readonly closeDate: Maybe<GqlDate>;
  readonly openDate: Maybe<GqlDate>;
  readonly status: QuestionSetStatus;
}

export enum QuestionSetStatus {
  ARCHIVED = 'ARCHIVED',
  CLOSED = 'CLOSED',
  HISTORIC_RECORD = 'HISTORIC_RECORD',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  SCHEDULED = 'SCHEDULED',
  SOFT_DELETED = 'SOFT_DELETED'
}

export interface QuestionSetSurveyInput {
  readonly accessSetting: InputMaybe<AccessSettingInput>;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly description: InputMaybe<Scalars['String']>;
  readonly hasAutomaticWeighting: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly setImageId: InputMaybe<Scalars['String']>;
  readonly showConversionPrompts: Scalars['Boolean'];
}

export enum QuestionSetType {
  CONTENT_POST = 'CONTENT_POST',
  POLCO_LIVE = 'POLCO_LIVE',
  SET = 'SET',
  SURVEY = 'SURVEY'
}

export interface QuestionSetsData {
  readonly __typename: 'QuestionSetsData';
  readonly questionSets: ReadonlyArray<ContentSet>;
  readonly totalQuestionSetsCount: Scalars['Int'];
}

export interface QuestionStat {
  readonly __typename: 'QuestionStat';
  readonly choiceId: Scalars['ID'];
  readonly label: Scalars['String'];
  readonly percent: Scalars['Float'];
  readonly value: Scalars['Float'];
}

export interface QuestionStats {
  readonly __typename: 'QuestionStats';
  readonly breakdowns: Maybe<ReadonlyArray<QuestionStatsBreakdown>>;
  readonly id: Scalars['ID'];
  readonly map: Maybe<QuestionMapStatsWithStatus>;
  readonly overview: Maybe<ReadonlyArray<QuestionStatsOverview>>;
}


export interface QuestionStatsBreakdownsArgs {
  scopedPublishingEntityId: Scalars['ID'];
  source: RespondentAttributeValueSource;
}


export interface QuestionStatsMapArgs {
  geoViewId: Scalars['ID'];
  scopedPublishingEntityId: Scalars['ID'];
  source: RespondentAttributeValueSource;
}


export interface QuestionStatsOverviewArgs {
  scopedPublishingEntityId: Scalars['ID'];
}

export interface QuestionStatsBreakdown {
  readonly __typename: 'QuestionStatsBreakdown';
  readonly buckets: ReadonlyArray<StatBucketGroup>;
  readonly hits: Scalars['Int'];
  readonly key: Scalars['String'];
}

export interface QuestionStatsData {
  readonly __typename: 'QuestionStatsData';
  readonly byChoice: ReadonlyArray<QuestionStat>;
  readonly totalResponses: Scalars['Int'];
}

export interface QuestionStatsOverview {
  readonly __typename: 'QuestionStatsOverview';
  readonly label: Scalars['String'];
  readonly stats: QuestionStatsData;
}

export enum QuestionStatus {
  ARCHIVED = 'ARCHIVED',
  CART = 'CART',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  HISTORIC_RECORD = 'HISTORIC_RECORD',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  SOFT_DELETED = 'SOFT_DELETED'
}

export enum QuestionType {
  FREE_TEXT = 'FREE_TEXT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  POINT_ALLOCATION = 'POINT_ALLOCATION'
}

export interface RecommendedQuestionsData {
  readonly __typename: 'RecommendedQuestionsData';
  readonly questions: ReadonlyArray<Question>;
  readonly totalRecommendedQuestionsCount: Scalars['Int'];
}

export interface RegistrationInformation {
  readonly __typename: 'RegistrationInformation';
  readonly interests: ReadonlyArray<AdminRegistrationInterest>;
  readonly outcomes: ReadonlyArray<AdminRegistrationOutcome>;
  readonly reasons: ReadonlyArray<AdminRegistrationReason>;
}

export interface RegistrationMetadataEntry {
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
}

export enum ReportGenerationStatus {
  NOT_TAGGED = 'NOT_TAGGED',
  PENDING = 'PENDING',
  TAGGED = 'TAGGED'
}

export interface ReportTab {
  readonly __typename: 'ReportTab';
  readonly content: ReportTabContent;
  readonly contentSet: ContentSet;
  readonly id: Scalars['ID'];
  readonly order: Scalars['Int'];
  readonly publiclyShared: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly trackDomain: Maybe<AnalyticsDomain>;
  readonly visibleToAdmins: Scalars['Boolean'];
}

export interface ReportTabContent {
  readonly __typename: 'ReportTabContent';
  readonly HTML: Maybe<Scalars['String']>;
  readonly TABLEAU: Maybe<Scalars['String']>;
  readonly type: ReportTabContentType;
}

export interface ReportTabContentInput {
  readonly HTML: InputMaybe<Scalars['String']>;
  readonly TABLEAU: InputMaybe<Scalars['String']>;
  readonly type: ReportTabContentType;
}

export enum ReportTabContentType {
  ADVANCED_RESULTS = 'ADVANCED_RESULTS',
  COMMUNITY_NEEDS = 'COMMUNITY_NEEDS',
  COMMUNITY_READINESS = 'COMMUNITY_READINESS',
  CROSSTABS = 'CROSSTABS',
  ECONOMIC_CONTRIBUTION = 'ECONOMIC_CONTRIBUTION',
  FULL_TRENDS = 'FULL_TRENDS',
  HTML = 'HTML',
  INTRODUCTION = 'INTRODUCTION',
  KEY_FINDINGS = 'KEY_FINDINGS',
  METHODS = 'METHODS',
  OVERVIEW = 'OVERVIEW',
  PARTICIPATION = 'PARTICIPATION',
  QUALITY_IMPORTANCE = 'QUALITY_IMPORTANCE',
  REPOSTS = 'REPOSTS',
  RESULTS = 'RESULTS',
  TABLEAU = 'TABLEAU',
  TOPICS = 'TOPICS',
  UNDERSTANDING_THE_REPORT = 'UNDERSTANDING_THE_REPORT'
}

export interface ReportTabCreateInput {
  readonly content: ReportTabContentInput;
  readonly title: Scalars['String'];
  readonly visible: Scalars['Boolean'];
}

export interface ReportTabUpdateInput {
  readonly content: ReportTabContentInput;
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly trackDomain: InputMaybe<AnalyticsDomain>;
  readonly visible: Scalars['Boolean'];
}

export interface RepresentivityBreakdown {
  readonly __typename: 'RepresentivityBreakdown';
  readonly breakdowns: ReadonlyArray<RepresentivityBreakdownValue>;
  readonly label: Scalars['String'];
  readonly unknownBreakdown: Maybe<RepresentivityUnknownBreakdownValue>;
}

export interface RepresentivityBreakdownValue {
  readonly __typename: 'RepresentivityBreakdownValue';
  readonly communityPercent: Maybe<Scalars['Float']>;
  readonly label: Scalars['String'];
  readonly respondentSelfReportedPercent: Scalars['Float'];
  readonly respondentThirdPartyPercent: Scalars['Float'];
}

export interface RepresentivityUnknownBreakdownValue {
  readonly __typename: 'RepresentivityUnknownBreakdownValue';
  readonly label: Scalars['String'];
  readonly respondentSelfReportedPercent: Scalars['Float'];
  readonly respondentThirdPartyPercent: Scalars['Float'];
}

export interface RequestFeatureContactInfo {
  readonly email: InputMaybe<Scalars['String']>;
  readonly feature: Scalars['String'];
  readonly message: InputMaybe<Scalars['String']>;
  readonly phone: InputMaybe<Scalars['String']>;
  readonly phoneExt: InputMaybe<Scalars['String']>;
}

export enum RequestPasswordResetContext {
  ADMIN_CLAIM_ACCOUNT = 'ADMIN_CLAIM_ACCOUNT',
  ADMIN_FORGOT_PASSWORD = 'ADMIN_FORGOT_PASSWORD',
  RESPONDENT_FORGOT_PASSWORD = 'RESPONDENT_FORGOT_PASSWORD'
}

export interface RequiredPresidingAreaInput {
  readonly name: Scalars['String'];
  readonly shape: GeoJsonMultiPolygonInput;
}

export enum ResendVerificationEmailResult {
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  EMAIL_SENT = 'EMAIL_SENT',
  FAILED_TO_SEND = 'FAILED_TO_SEND'
}

export interface ResetFeatureSettingState {
  readonly enableUntil: GqlDateInput;
  readonly tierId: Scalars['ID'];
}

export interface ResetPasswordFailed {
  readonly __typename: 'ResetPasswordFailed';
  readonly reason: ResetPasswordFailedReason;
}

export enum ResetPasswordFailedReason {
  INVALID_TOKEN = 'INVALID_TOKEN',
  RESET_FAILED = 'RESET_FAILED'
}

export type ResetPasswordResult = CurrentUser | ResetPasswordFailed;

export type Respondent = PrivateRespondent | PublicRespondent;

export enum RespondentAttributeValueSource {
  THIRD_PARTY = 'THIRD_PARTY',
  USER_REPORTED = 'USER_REPORTED'
}

export interface RespondentBase {
  readonly avatarUrl: Maybe<Scalars['String']>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly lastName: Maybe<Scalars['String']>;
  readonly postAnonymously: Scalars['Boolean'];
  readonly publicName: Scalars['String'];
}

export interface RespondentFeed {
  readonly __typename: 'RespondentFeed';
  readonly cursor: GqlDate;
  readonly items: ReadonlyArray<RespondentFeedItem>;
}

export interface RespondentFeedItem {
  readonly __typename: 'RespondentFeedItem';
  readonly badges: ReadonlyArray<RespondentFeedItemBadge>;
  readonly bottomData: Maybe<Scalars['String']>;
  readonly bottomIcon: Maybe<BottomIcon>;
  readonly dotActions: ReadonlyArray<RespondentFeedItemAction>;
  readonly id: Scalars['ID'];
  readonly image: Maybe<Scalars['String']>;
  readonly primaryAction: RespondentFeedItemAction;
  readonly primaryCallout: Scalars['String'];
  readonly publishingEntity: PublishingEntity;
  readonly secondaryBottomActions: ReadonlyArray<RespondentFeedItemAction>;
  readonly title: MultiLanguageContent;
  readonly topAction: Maybe<RespondentFeedItemAction>;
  readonly typedData: RespondentFeedItemTypedData;
}

export interface RespondentFeedItemAction {
  readonly __typename: 'RespondentFeedItemAction';
  readonly type: RespondentFeedItemActionType;
}

export enum RespondentFeedItemActionType {
  OPEN_ITEM = 'OPEN_ITEM',
  OPEN_OUTCOME = 'OPEN_OUTCOME',
  OPEN_PUBLISHER = 'OPEN_PUBLISHER',
  SHARE = 'SHARE',
  TOGGLE_FOLLOW = 'TOGGLE_FOLLOW',
  VOTE = 'VOTE'
}

export interface RespondentFeedItemBadge {
  readonly __typename: 'RespondentFeedItemBadge';
  readonly label: Scalars['String'];
  readonly type: RespondentFeedItemBadgeType;
}

export enum RespondentFeedItemBadgeType {
  BALANCING_ACT_SIMULATION = 'BALANCING_ACT_SIMULATION',
  CONTENT_POST = 'CONTENT_POST',
  IDEA = 'IDEA',
  POLCO_LIVE = 'POLCO_LIVE',
  POLL_SET = 'POLL_SET',
  QUESTION = 'QUESTION',
  REPOST = 'REPOST',
  SURVEY = 'SURVEY'
}

export enum RespondentFeedItemStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export type RespondentFeedItemTypedData = RespondentFeedItemTypedData_BalancingActSimulation | RespondentFeedItemTypedData_Idea | RespondentFeedItemTypedData_Question | RespondentFeedItemTypedData_QuestionSet;

export interface RespondentFeedItemTypedData_BalancingActSimulation {
  readonly __typename: 'RespondentFeedItemTypedData_BalancingActSimulation';
  readonly contentState: RespondentFeedItemStatus;
  readonly id: Scalars['ID'];
  readonly simulationType: Scalars['String'];
}

export interface RespondentFeedItemTypedData_Idea {
  readonly __typename: 'RespondentFeedItemTypedData_Idea';
  readonly id: Scalars['ID'];
  readonly idea: Idea;
}

export interface RespondentFeedItemTypedData_Question {
  readonly __typename: 'RespondentFeedItemTypedData_Question';
  readonly acknowledgeOnly: Scalars['Boolean'];
  readonly contentState: RespondentFeedItemStatus;
  readonly id: Scalars['ID'];
  readonly setId: Scalars['ID'];
  readonly setSlug: Maybe<Scalars['String']>;
  readonly voted: Scalars['Boolean'];
}

export interface RespondentFeedItemTypedData_QuestionSet {
  readonly __typename: 'RespondentFeedItemTypedData_QuestionSet';
  readonly contentState: RespondentFeedItemStatus;
  readonly contentType: QuestionSetType;
  readonly id: Scalars['ID'];
  readonly shareable: Scalars['Boolean'];
  readonly slug: Maybe<Scalars['String']>;
}

export interface RespondentQuestionChoice {
  readonly __typename: 'RespondentQuestionChoice';
  readonly allocation: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly questionChoice: QuestionChoice;
}

export interface RespondentQuestionVoteData {
  readonly __typename: 'RespondentQuestionVoteData';
  readonly id: Scalars['ID'];
  readonly questions: ReadonlyArray<Question>;
  readonly status: RespondentsSetStatus;
}

export enum RespondentsSetStatus {
  CLOSED = 'CLOSED',
  COMPLETE = 'COMPLETE',
  NOT_STARTED = 'NOT_STARTED',
  START = 'START'
}

export enum ResponseStatType {
  AGE = 'AGE',
  GENDER = 'GENDER',
  LANGUAGE = 'LANGUAGE',
  LOCATION = 'LOCATION',
  RACE = 'RACE',
  SAMPLE = 'SAMPLE',
  VERIFIED_RESPONSES = 'VERIFIED_RESPONSES'
}

export interface ResponseStatsInput {
  readonly AGE: InputMaybe<DemographicStatSourceInput>;
  readonly GENDER: InputMaybe<DemographicStatSourceInput>;
  readonly LOCATION: InputMaybe<DemographicLocationStatSourceInput>;
  readonly scopeByPublishingEntityId: Scalars['ID'];
  readonly type: ResponseStatType;
}

export interface ResultFilter {
  readonly publishingEntityId: Scalars['ID'];
  readonly type: ResultFilterOption;
}

export enum ResultFilterOption {
  REGISTERED = 'REGISTERED',
  SUBSCRIBERS = 'SUBSCRIBERS',
  VERIFIED = 'VERIFIED'
}

export interface ReviewSelectionNotification {
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly itemName: Scalars['String'];
}

export enum SavedVisualizationActivationState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface ScaleData {
  readonly __typename: 'ScaleData';
  readonly alternateScaleName: Maybe<Scalars['String']>;
  readonly scaleThreshold: Scalars['Int'];
  readonly scaleType: ScaleType;
}

export interface ScaleDataInput {
  readonly scaleThreshold: Scalars['Int'];
  readonly scaleType: ScaleType;
}

export enum ScaleType {
  POSITIVE = 'POSITIVE',
  PROBLEMATIC = 'PROBLEMATIC'
}

export enum ShareableContentDomains {
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  ECONOMY = 'ECONOMY',
  EDUCATION_ARTS_CULTURE = 'EDUCATION_ARTS_CULTURE',
  GENERAL = 'GENERAL',
  GOVERNANCE = 'GOVERNANCE',
  HEALTH_WELLNESS = 'HEALTH_WELLNESS',
  INCLUSIVITY_ENGAGEMENT = 'INCLUSIVITY_ENGAGEMENT',
  MOBILITY = 'MOBILITY',
  NATURAL_ENVIRONMENT = 'NATURAL_ENVIRONMENT',
  PARKS_RECREATION = 'PARKS_RECREATION',
  SAFETY = 'SAFETY',
  UTILITIES = 'UTILITIES'
}

export interface ShareableContentSet {
  readonly __typename: 'ShareableContentSet';
  readonly adminDescription: Maybe<Scalars['String']>;
  readonly contentSet: ContentSet;
  readonly domains: ReadonlyArray<ShareableContentDomains>;
  readonly id: Scalars['ID'];
  readonly isPaidContent: Scalars['Boolean'];
  readonly numberOfShares: Scalars['Int'];
  readonly order: Maybe<Scalars['Int']>;
  readonly shareType: ShareableContentType;
}

export enum ShareableContentType {
  BENCHMARK = 'BENCHMARK',
  RECOMMENDED = 'RECOMMENDED',
  REPOST = 'REPOST',
  STANDARD_DEMO_QUESTIONS = 'STANDARD_DEMO_QUESTIONS'
}

export interface ShareableQuestionChoiceSet {
  readonly __typename: 'ShareableQuestionChoiceSet';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface ShortUrl {
  readonly __typename: 'ShortUrl';
  readonly fullUrl: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isPrimary: Scalars['Boolean'];
  readonly shortUrl: Scalars['String'];
}

export enum SimulationAlertType {
  BOTH = 'BOTH',
  DECREASE = 'DECREASE',
  INCREASE = 'INCREASE',
  NONE = 'NONE',
  THRESHOLD = 'THRESHOLD'
}

export interface SimulationCategory {
  readonly __typename: 'SimulationCategory';
  readonly additionalDetails: Maybe<Scalars['String']>;
  readonly details: Maybe<Scalars['String']>;
  readonly displayOrder: Scalars['Int'];
  readonly id: Scalars['Int'];
  readonly isLocked: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly subcategories: ReadonlyArray<SimulationSubcategory>;
  readonly type: SimulationCategoryType;
}

export interface SimulationCategoryEdit {
  readonly additionalDetails: InputMaybe<Scalars['String']>;
  readonly adjustment: Scalars['Int'];
  readonly details: InputMaybe<Scalars['String']>;
  readonly displayOrder: Scalars['Int'];
  readonly id: InputMaybe<Scalars['Int']>;
  readonly isLocked: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly subcategories: ReadonlyArray<SimulationSubcategoryEdit>;
  readonly type: SimulationCategoryType;
}

export enum SimulationCategoryType {
  EXPENSE = 'EXPENSE',
  REVENUE = 'REVENUE'
}

export interface SimulationInput {
  readonly adjustment: Scalars['Int'];
  readonly categories: ReadonlyArray<SimulationCategoryEdit>;
  readonly description: InputMaybe<Scalars['String']>;
  readonly entryScreenClosingText: InputMaybe<Scalars['String']>;
  readonly entryScreenIntroductionText: InputMaybe<Scalars['String']>;
  readonly exitScreenClosingText: InputMaybe<Scalars['String']>;
  readonly expenseDisclaimer: InputMaybe<Scalars['String']>;
  readonly generalDisclaimer: InputMaybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isBudgetHidden: Scalars['Boolean'];
  readonly isBudgetSubmittableWithDeficit: Scalars['Boolean'];
  readonly isCommentingEnabled: Scalars['Boolean'];
  readonly isExpenseBarChartEnabled: Scalars['Boolean'];
  readonly isExpenseChartEnabled: Scalars['Boolean'];
  readonly isExpenseOrderRandomized: Scalars['Boolean'];
  readonly isFooterHidden: Scalars['Boolean'];
  readonly isPb: Scalars['Boolean'];
  readonly isQuestionManagementEnabled: Scalars['Boolean'];
  readonly isRevenueChartEnabled: Scalars['Boolean'];
  readonly isRevenueOrderRandomized: Scalars['Boolean'];
  readonly isSharingEnabled: Scalars['Boolean'];
  readonly isTaxReceiptSubtotalBreakdownEnabled: Scalars['Boolean'];
  readonly locale: Scalars['String'];
  readonly mapFocusDisplay: InputMaybe<Scalars['Boolean']>;
  readonly maxItems: InputMaybe<Scalars['Int']>;
  readonly mode: BalancingActSimulationType;
  readonly name: Scalars['String'];
  readonly revenueDisclaimer: InputMaybe<Scalars['String']>;
  readonly shareResultsEnabled: Scalars['Boolean'];
  readonly slug: Scalars['String'];
  readonly status: SimulationStatus;
  readonly submissionRedirectUrl: InputMaybe<Scalars['String']>;
  readonly timerMinutes: Scalars['Int'];
}

export interface SimulationNodeAdminData {
  readonly __typename: 'SimulationNodeAdminData';
  readonly description: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly prioritization: Scalars['Boolean'];
  readonly simulationType: BalancingActSimulationType;
  readonly status: QuestionSetStatus;
}

export interface SimulationNodeData {
  readonly __typename: 'SimulationNodeData';
  readonly adminData: Maybe<SimulationNodeAdminData>;
  readonly id: Scalars['Int'];
  readonly pubId: Scalars['ID'];
  readonly url: Scalars['String'];
}

export interface SimulationNodeInput {
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly hierarchyId: InputMaybe<Scalars['ID']>;
  readonly metaConditionReferenceId: InputMaybe<Scalars['ID']>;
  readonly simulationId: Scalars['Int'];
  readonly simulationType: SimulationNodeType;
}

export enum SimulationNodeType {
  BUDGET = 'BUDGET',
  HOUSING = 'HOUSING',
  PRIORITIZE = 'PRIORITIZE',
  RECEIPT = 'RECEIPT'
}

export enum SimulationStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW'
}

export interface SimulationSubcategory {
  readonly __typename: 'SimulationSubcategory';
  readonly additionalDetails: Maybe<Scalars['String']>;
  readonly alertThreshold: Maybe<Scalars['Int']>;
  readonly alertType: SimulationAlertType;
  readonly amount: Scalars['Int'];
  readonly ceilingAlertText: Maybe<Scalars['String']>;
  readonly ceilingAmount: Scalars['Int'];
  readonly customIncrementAmount: Scalars['Int'];
  readonly customIncrementRate: Scalars['Int'];
  readonly customIncrementRateForTaxRate: Scalars['Int'];
  readonly customizedQuestionJson: Maybe<Scalars['String']>;
  readonly details: Maybe<Scalars['String']>;
  readonly displayOrder: Scalars['Int'];
  readonly floorAlertText: Maybe<Scalars['String']>;
  readonly floorAmount: Scalars['Int'];
  readonly geojson: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly inTour: Scalars['Boolean'];
  readonly isLocked: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly suggestionBox: Scalars['Boolean'];
  readonly suggestionBoxMode: Maybe<Scalars['String']>;
}

export interface SimulationSubcategoryEdit {
  readonly additionalDetails: InputMaybe<Scalars['String']>;
  readonly adjustment: Scalars['Int'];
  readonly alertThreshold: InputMaybe<Scalars['Int']>;
  readonly alertType: SimulationAlertType;
  readonly amount: Scalars['Int'];
  readonly ceilingAlertText: InputMaybe<Scalars['String']>;
  readonly ceilingAmount: Scalars['Int'];
  readonly customIncrementAmount: Scalars['Int'];
  readonly customIncrementRate: Scalars['Int'];
  readonly customIncrementRateForTaxRate: Scalars['Int'];
  readonly customizedQuestionJson: InputMaybe<Scalars['String']>;
  readonly details: InputMaybe<Scalars['String']>;
  readonly displayOrder: Scalars['Int'];
  readonly floorAlertText: InputMaybe<Scalars['String']>;
  readonly floorAmount: Scalars['Int'];
  readonly geojson: InputMaybe<Scalars['String']>;
  readonly id: InputMaybe<Scalars['Int']>;
  readonly inTour: Scalars['Boolean'];
  readonly isLocked: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly suggestionBox: Scalars['Boolean'];
  readonly suggestionBoxMode: InputMaybe<Scalars['String']>;
}

export interface SocialConnection {
  readonly __typename: 'SocialConnection';
  readonly id: Scalars['ID'];
  readonly profileNames: ReadonlyArray<Scalars['String']>;
  readonly provider: SocialConnectionProvider;
}

export interface SocialConnectionAccount {
  readonly __typename: 'SocialConnectionAccount';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export enum SocialConnectionProvider {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER'
}

export interface SocialLoginData {
  readonly token: Scalars['String'];
  readonly type: SocialLoginType;
}

export enum SocialLoginType {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE'
}

export interface SocialUserResult {
  readonly __typename: 'SocialUserResult';
  readonly currentUser: CurrentUser;
  readonly isNewUser: Scalars['Boolean'];
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Stat = EmptyBreakdown | FeatureSettingMissingBreakdown | ProtectedStatBreakdown | StatBreakdown | StatBreakdownLocation | StatValueDelta;

export interface StatBreakdown {
  readonly __typename: 'StatBreakdown';
  readonly label: Scalars['String'];
  readonly unknownTotal: Scalars['Int'];
  readonly values: ReadonlyArray<StatBreakdownValue>;
}

export interface StatBreakdownLocation {
  readonly __typename: 'StatBreakdownLocation';
  readonly center: CoordinateCenter;
  readonly values: ReadonlyArray<StatBreakdownLocationValue>;
}

export interface StatBreakdownLocationValue {
  readonly __typename: 'StatBreakdownLocationValue';
  readonly key: Scalars['String'];
  readonly shape: GeoJsonMultiPolygon;
  readonly total: Scalars['Int'];
}

export interface StatBreakdownValue {
  readonly __typename: 'StatBreakdownValue';
  readonly label: Scalars['String'];
  readonly total: Scalars['Int'];
}

export interface StatBucket {
  readonly __typename: 'StatBucket';
  readonly hits: Scalars['Float'];
  readonly key: Scalars['String'];
}

export interface StatBucketGroup {
  readonly __typename: 'StatBucketGroup';
  readonly buckets: ReadonlyArray<StatBucket>;
  readonly hits: Scalars['Int'];
  readonly key: Scalars['String'];
}

export interface StatValueDelta {
  readonly __typename: 'StatValueDelta';
  readonly changeLabel: Scalars['String'];
  readonly currentValue: Scalars['Int'];
  readonly label: Scalars['String'];
  readonly recentChange: Scalars['Float'];
}

export enum StatisticType {
  INDEX = 'INDEX',
  INDICATOR = 'INDICATOR',
  SENTIMENT_VALUE = 'SENTIMENT_VALUE'
}

export interface StoreItem {
  readonly __typename: 'StoreItem';
  readonly category: StoreItemCategory;
  readonly childStoreItems: ReadonlyArray<StoreItem>;
  readonly freeTrialLengthMs: Maybe<Scalars['Float']>;
  readonly id: Scalars['ID'];
  readonly isHiddenInUI: Scalars['Boolean'];
  readonly key: Scalars['String'];
  readonly linkedFeatureSettingId: Maybe<Scalars['String']>;
  readonly longDescription: Scalars['String'];
  readonly mainImageUrl: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly oneTime: Scalars['Boolean'];
  readonly order: Maybe<Scalars['Float']>;
  readonly plans: ReadonlyArray<StoreItemPlan>;
  readonly provider: StoreItemProvider;
  readonly purchaseLengthMs: Scalars['Float'];
  readonly repurchaseDelayMs: Scalars['Float'];
  readonly shortDescription: Scalars['String'];
  readonly stripeId: Maybe<Scalars['String']>;
  readonly thumbnailUrl: Maybe<Scalars['String']>;
}

export enum StoreItemCategory {
  COMING_SOON = 'COMING_SOON',
  FEATURE = 'FEATURE',
  FREE_SERVICE = 'FREE_SERVICE',
  SERVICE = 'SERVICE',
  SURVEY = 'SURVEY',
  TIER = 'TIER',
  UNKNOWN = 'UNKNOWN'
}

export interface StoreItemPlan {
  readonly __typename: 'StoreItemPlan';
  readonly active: Scalars['Boolean'];
  readonly amount: Maybe<Scalars['Int']>;
  readonly billingScheme: Maybe<BillingScheme>;
  readonly id: Scalars['String'];
  readonly interval: StripeInterval;
  readonly intervalCount: Scalars['Int'];
  readonly nickname: Maybe<Scalars['String']>;
  readonly product: Scalars['String'];
  readonly trialPeriodDays: Maybe<Scalars['Int']>;
  readonly usageType: StripeUsageType;
}

export enum StoreItemProvider {
  NRC = 'NRC',
  POLCO = 'POLCO'
}

export enum StoreItemPublisherStatus {
  ACTIVATED = 'ACTIVATED',
  ACTIVATION_EXPIRED = 'ACTIVATION_EXPIRED',
  NEVER_ACTIVATED_OR_TRIALED = 'NEVER_ACTIVATED_OR_TRIALED',
  TRIAL_ACTIVATED = 'TRIAL_ACTIVATED',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  UNPAID = 'UNPAID'
}

export enum StoreItemsType {
  A_LA_CARTE = 'A_LA_CARTE',
  TIER = 'TIER'
}

export interface StorePurchaseNoPayment {
  readonly publishingEntityId: Scalars['ID'];
  readonly storeItemIds: ReadonlyArray<Scalars['ID']>;
  readonly transactionType: TransactionType;
}

export interface StrategicPlan {
  readonly __typename: 'StrategicPlan';
  readonly goals: ReadonlyArray<Goal>;
  readonly id: Scalars['ID'];
}

export enum StripeInterval {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export enum StripeUsageType {
  LICENSED = 'LICENSED',
  METERED = 'METERED'
}

export enum SubscriberStatType {
  AGE = 'AGE',
  GENDER = 'GENDER',
  LOCATION = 'LOCATION',
  RACE = 'RACE'
}

export interface SubscriberStatsInput {
  readonly AGE: InputMaybe<DemographicStatSourceInput>;
  readonly GENDER: InputMaybe<DemographicStatSourceInput>;
  readonly LOCATION: InputMaybe<DemographicLocationStatSourceInput>;
  readonly type: SubscriberStatType;
}

export interface Subscription {
  readonly __typename: 'Subscription';
  readonly liveContentSet: PolcoLive;
  readonly questionNewComment: Maybe<Comment>;
  readonly questionResults: Question;
}


export interface SubscriptionLiveContentSetArgs {
  contentSetId: Scalars['ID'];
}


export interface SubscriptionQuestionNewCommentArgs {
  questionId: Scalars['ID'];
}


export interface SubscriptionQuestionResultsArgs {
  questionId: Scalars['ID'];
}

export enum SubscriptionMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export enum SubscriptionType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  MANUAL_UNFOLLOWED = 'MANUAL_UNFOLLOWED'
}

export interface SuperAdminPublisherAttributesInput {
  readonly baCustomerId: InputMaybe<Scalars['Int']>;
  readonly envisioId: InputMaybe<Scalars['String']>;
  readonly fips: InputMaybe<Scalars['String']>;
  readonly isEnterprise: InputMaybe<Scalars['Boolean']>;
}

export enum SuperAdminPublisherFilter {
  ACTIVATED_WITHOUT_GEO_MAPPING = 'ACTIVATED_WITHOUT_GEO_MAPPING',
  ACTIVATED_WITHOUT_HUBSPOT_MAPPING = 'ACTIVATED_WITHOUT_HUBSPOT_MAPPING'
}

export interface Survey extends ContentSetBase {
  readonly __typename: 'Survey';
  readonly accessSetting: Maybe<AccessSetting>;
  readonly allConditions: ConditionalLogic;
  readonly allowGuestRespondents: Scalars['Boolean'];
  readonly allowMultipleResponses: Scalars['Boolean'];
  readonly alternateLanguages: ReadonlyArray<Language>;
  readonly benchmarkType: Maybe<BenchmarkType>;
  readonly contents: ReadonlyArray<QuestionHierarchyNode>;
  readonly createDate: GqlDate;
  readonly criticalFocusTopics: ReadonlyArray<SurveyBenchmarkTopic>;
  readonly crosstab: Maybe<ReadonlyArray<CrosstabResults>>;
  readonly description: Maybe<MultiLanguageContent>;
  readonly dimensionGroupDefinitions: ReadonlyArray<DimensionGroupDefinition>;
  readonly estCompletionTime: Scalars['String'];
  readonly hasAutomaticWeighting: Scalars['Boolean'];
  readonly hasBenchmarkedContent: Scalars['Boolean'];
  readonly hasPdfUrl: Scalars['Boolean'];
  readonly hasVisibleReportTabs: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly image: Maybe<QuestionSetImage>;
  readonly isRepostable: Scalars['Boolean'];
  readonly name: MultiLanguageContent;
  readonly outcome: Maybe<Outcome>;
  readonly polcoManaged: Scalars['Boolean'];
  readonly publishingEntity: PublishingEntity;
  readonly questionsCount: Scalars['Int'];
  readonly reportTabs: Maybe<ReadonlyArray<ReportTab>>;
  readonly reposters: ReadonlyArray<PublishingEntity>;
  readonly representivityData: Maybe<RepresentivityBreakdown>;
  readonly responseStat: Maybe<Stat>;
  readonly responsesOverTime: Maybe<TimeBreakdown>;
  readonly schedule: QuestionSetSchedule;
  readonly setImageId: Maybe<Scalars['String']>;
  readonly shareable: Scalars['Boolean'];
  readonly shareableContentSet: Maybe<ShareableContentSet>;
  readonly shortId: Maybe<Scalars['String']>;
  readonly shortUrls: ReadonlyArray<ShortUrl>;
  readonly showConversionPrompts: Scalars['Boolean'];
  readonly slug: Maybe<Scalars['String']>;
  readonly tags: ReadonlyArray<Tag>;
  readonly topicDisparities: ReadonlyArray<TopicDisparity>;
  readonly topics: ReadonlyArray<SurveyBenchmarkTopic>;
  readonly totalRespondents: Maybe<Scalars['Int']>;
  readonly totalWeightedRespondents: Maybe<Scalars['Int']>;
}


export interface SurveyContentsArgs {
  filterByTopicIds: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  filterNonGridQuestions: InputMaybe<Scalars['Boolean']>;
  pagination: InputMaybe<PaginationParams>;
  useMicroservice: InputMaybe<Scalars['Boolean']>;
}


export interface SurveyCriticalFocusTopicsArgs {
  filter: TopicFilter;
}


export interface SurveyCrosstabArgs {
  dimensionGroupDefinitionId: Scalars['ID'];
  topicId: Scalars['ID'];
}


export interface SurveyReportTabsArgs {
  publiclyShared: Scalars['Boolean'];
}


export interface SurveyRepresentivityDataArgs {
  attributeType: Scalars['String'];
}


export interface SurveyResponseStatArgs {
  input: ResponseStatsInput;
}


export interface SurveyResponsesOverTimeArgs {
  scopeByPublishingEntityId: Scalars['ID'];
}


export interface SurveyTopicDisparitiesArgs {
  dimensionGroupDefinitionIds: ReadonlyArray<Scalars['ID']>;
  topicFilter: TopicFilter;
}


export interface SurveyTopicsArgs {
  filter: TopicFilter;
  sort: InputMaybe<TopicSortInput>;
  useMicroservice: InputMaybe<Scalars['Boolean']>;
}


export interface SurveyTotalRespondentsArgs {
  scopeByPublishingEntityId: InputMaybe<Scalars['ID']>;
}

export interface SurveyBenchmarkTopic {
  readonly __typename: 'SurveyBenchmarkTopic';
  readonly id: Scalars['ID'];
  readonly qualitativeAggregateResult: Maybe<TopicAggregateResult>;
  readonly qualitativeQuestion: Maybe<Question>;
  readonly relatedQuestions: ReadonlyArray<Question>;
  readonly survey: ContentSet;
  readonly topic: BenchmarkTopic;
}


export interface SurveyBenchmarkTopicQualitativeAggregateResultArgs {
  qualitativeTopic: QualitativeTopics;
}


export interface SurveyBenchmarkTopicQualitativeQuestionArgs {
  qualitativeTopic: QualitativeTopics;
}

export enum SurveyFormat {
  BOTH_ONLINE_AND_MAIL = 'BOTH_ONLINE_AND_MAIL',
  MAIL_ONLY = 'MAIL_ONLY',
  ONLINE_ONLY = 'ONLINE_ONLY'
}

export interface SurveyItems {
  readonly cost: Scalars['Float'];
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
}

export interface SurveyNodeInput {
  readonly GRID: InputMaybe<GridInput>;
  readonly HEADER: InputMaybe<QuestionHeaderInput>;
  readonly QUESTION: InputMaybe<QuestionInput>;
  readonly SIMULATION: InputMaybe<SimulationNodeInput>;
  readonly VISUALIZATION: InputMaybe<VisualizationNodeInput>;
  readonly type: QuestionHierarchyChildInputType;
}

export interface SurveyVoteInput {
  readonly GRID: InputMaybe<ReadonlyArray<GridVoteInput>>;
  readonly QUESTION: InputMaybe<VoteInput>;
  readonly type: SurveyVoteInputType;
}

export enum SurveyVoteInputType {
  GRID = 'GRID',
  QUESTION = 'QUESTION'
}

export interface SurveyVoteInputWithId {
  readonly input: SurveyVoteInput;
  readonly questionOrGridId: Scalars['ID'];
}

export interface SurveyVoteResult {
  readonly __typename: 'SurveyVoteResult';
  readonly id: Scalars['ID'];
  readonly voter: Respondent;
}

export interface Tag {
  readonly __typename: 'Tag';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
}

export interface TagInput {
  readonly name: Scalars['String'];
}

export interface TemplateParams {
  readonly key: Scalars['String'];
  readonly replaceWith: Scalars['String'];
}

export enum ThirdParty {
  HUBSPOT = 'HUBSPOT'
}

export interface ThirdPartyRecord {
  readonly __typename: 'ThirdPartyRecord';
  readonly id: Scalars['ID'];
  readonly thirdParty: ThirdParty;
  readonly thirdPartyIdentifier: Scalars['String'];
}

export interface ThirdPartyRecordInput {
  readonly publishingEntityId: Scalars['String'];
  readonly thirdParty: ThirdParty;
  readonly thirdPartyIdentifier: Scalars['String'];
}

export interface TimeBreakdown {
  readonly __typename: 'TimeBreakdown';
  readonly data: ReadonlyArray<TimeBreakdownData>;
  readonly interval: TimeBreakdownInterval;
}

export interface TimeBreakdownData {
  readonly __typename: 'TimeBreakdownData';
  readonly time: Scalars['String'];
  readonly value: Scalars['Int'];
}

export enum TimeBreakdownInterval {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export interface TopicAggregateResult {
  readonly __typename: 'TopicAggregateResult';
  readonly aggregateValue: Maybe<Scalars['Float']>;
  readonly trend: Maybe<TrendAggregateResult>;
}

export enum TopicCategories {
  ARPA_KPI = 'ARPA_KPI',
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  EMPLOYMENT_AND_FINANCES = 'EMPLOYMENT_AND_FINANCES',
  EQUITY_AND_INCLUSION = 'EQUITY_AND_INCLUSION',
  HEALTH_AND_WELLNESS = 'HEALTH_AND_WELLNESS',
  INFORMATION_AND_ASSISTANCE = 'INFORMATION_AND_ASSISTANCE',
  OVERALL = 'OVERALL',
  OVERALL_COMMUNITY_QUALITY = 'OVERALL_COMMUNITY_QUALITY',
  PRODUCTIVE_ACTIVITIES = 'PRODUCTIVE_ACTIVITIES'
}

export interface TopicCategory {
  readonly __typename: 'TopicCategory';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Maybe<Scalars['Int']>;
}

export interface TopicDisparity {
  readonly __typename: 'TopicDisparity';
  readonly dimensionGroupDefinition: DimensionGroupDefinition;
  readonly disparityLevel: Maybe<DisparityLevel>;
  readonly topic: BenchmarkTopic;
}

export interface TopicFilter {
  readonly CATEGORIES: InputMaybe<ReadonlyArray<TopicCategories>>;
  readonly DOMAIN: InputMaybe<Scalars['String']>;
  readonly IDS: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly type: TopicFilterType;
}

export enum TopicFilterType {
  ALL_CATEGORIZED = 'ALL_CATEGORIZED',
  ALL_NON_QUALITATIVE = 'ALL_NON_QUALITATIVE',
  CATEGORIES = 'CATEGORIES',
  DOMAIN = 'DOMAIN',
  IDS = 'IDS'
}

export interface TopicQualitativeQuestionResultSortInput {
  readonly qualitativeQuestionType: QualitativeTopics;
  readonly sortBy: QualitativeQuestionResultSortType;
}

export interface TopicSortInput {
  readonly QUALITATIVE_QUESTION_RESULT: InputMaybe<TopicQualitativeQuestionResultSortInput>;
  readonly order: SortOrder;
  readonly type: TopicSortType;
}

export enum TopicSortType {
  QUALITATIVE_QUESTION_RESULT = 'QUALITATIVE_QUESTION_RESULT'
}

export interface TrackAreaData {
  readonly __typename: 'TrackAreaData';
  readonly area: FipsArea;
  readonly performanceData: ReadonlyArray<TrackPerformanceDatum>;
}

export enum TrackAreaMode {
  COMPARISON_GROUP = 'COMPARISON_GROUP',
  LOCAL = 'LOCAL',
  NATIONAL = 'NATIONAL'
}

export interface TrackDomain {
  readonly __typename: 'TrackDomain';
  readonly domain: AnalyticsDomain;
  readonly fips: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly indexScores: ReadonlyArray<TrackVariable>;
  readonly indicators: ReadonlyArray<TrackVariable>;
  readonly sentimentValues: ReadonlyArray<TrackVariable>;
}


export interface TrackDomainIndexScoresArgs {
  areaMode: TrackAreaMode;
  displayType: InputMaybe<VariableDisplayType>;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
}


export interface TrackDomainIndicatorsArgs {
  areaMode: TrackAreaMode;
  displayType: InputMaybe<VariableDisplayType>;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
  includeHierarchicalData: InputMaybe<Scalars['Boolean']>;
}


export interface TrackDomainSentimentValuesArgs {
  areaMode: TrackAreaMode;
  displayType: InputMaybe<VariableDisplayType>;
  historicalDataEntries: InputMaybe<Scalars['Int']>;
}

export interface TrackPerformanceDatum {
  readonly __typename: 'TrackPerformanceDatum';
  readonly benchmarkValues: ReadonlyArray<AnalyticsBenchmark>;
  readonly distributions: ReadonlyArray<PerformanceDatumDistribution>;
  readonly fipsArea: FipsArea;
  readonly id: Scalars['ID'];
  readonly previousValue: Maybe<PerformanceDatumPreviousValue>;
  readonly recordedAt: GqlDate;
  readonly value: Scalars['Float'];
}

export interface TrackVariable {
  readonly __typename: 'TrackVariable';
  readonly areasData: ReadonlyArray<TrackAreaData>;
  readonly dateLevel: DateLevel;
  readonly demographicSegment: VariableDemographicSegment;
  readonly description: Maybe<Scalars['String']>;
  readonly direction: Maybe<TrackVariableDirection>;
  readonly domains: ReadonlyArray<AnalyticsDomainType>;
  readonly id: Scalars['ID'];
  readonly includedInIndex: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly isOA: Scalars['Boolean'];
  readonly label: Scalars['String'];
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly statisticType: StatisticType;
  readonly suffix: Maybe<Scalars['String']>;
  readonly valueType: AnalyticsValueType;
}


export interface TrackVariableAreasDataArgs {
  areaMode: InputMaybe<TrackAreaMode>;
  comparisonGroupId: InputMaybe<Scalars['ID']>;
  fipsCodes: InputMaybe<ReadonlyArray<Scalars['String']>>;
}

export enum TrackVariableDirection {
  NEG = 'NEG',
  POS = 'POS'
}

export type TrackVariableFull = TrackVariable | TrackVariableWithDistributions;

export interface TrackVariableVisualization {
  readonly __typename: 'TrackVariableVisualization';
  readonly benchmarkFilter: BenchmarkFilter;
  readonly boundingBox: Maybe<GeoBoundingBox>;
  readonly comparisonGroupId: Maybe<Scalars['String']>;
  readonly fips: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly plans: ReadonlyArray<StrategicPlan>;
  readonly publishingEntityId: Scalars['ID'];
  readonly recordedAt: Maybe<GqlDate>;
  readonly state: SavedVisualizationActivationState;
  readonly trackVariables: ReadonlyArray<TrackVariableFull>;
  readonly visualizationAreas: ReadonlyArray<FipsArea>;
  readonly visualizationType: Maybe<VisualizationType>;
}

export interface TrackVariableVisualizationInput {
  readonly baseFips: InputMaybe<Scalars['String']>;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly comparisongroupId: InputMaybe<Scalars['String']>;
  readonly groupFips: ReadonlyArray<Scalars['String']>;
  readonly publishingEntityId: Scalars['ID'];
  readonly recordedAt: InputMaybe<GqlDateInput>;
  readonly state: SavedVisualizationActivationState;
  readonly variableId: Scalars['ID'];
  readonly visualizationType: InputMaybe<VisualizationType>;
}

export interface TrackVariableWithDistributions {
  readonly __typename: 'TrackVariableWithDistributions';
  readonly areasData: ReadonlyArray<TrackAreaData>;
  readonly dateLevel: DateLevel;
  readonly demographicSegment: VariableDemographicSegment;
  readonly description: Maybe<Scalars['String']>;
  readonly direction: Maybe<TrackVariableDirection>;
  readonly domains: ReadonlyArray<AnalyticsDomainType>;
  readonly id: Scalars['ID'];
  readonly includedInIndex: Scalars['Boolean'];
  readonly isDefault: Scalars['Boolean'];
  readonly isOA: Scalars['Boolean'];
  readonly label: Scalars['String'];
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly statisticType: StatisticType;
  readonly suffix: Maybe<Scalars['String']>;
  readonly valueType: AnalyticsValueType;
}


export interface TrackVariableWithDistributionsAreasDataArgs {
  areaMode: InputMaybe<TrackAreaMode>;
  comparisonGroupId: InputMaybe<Scalars['ID']>;
}

export enum TransactionType {
  EXPRESS_INTEREST = 'EXPRESS_INTEREST',
  FREE_ITEM = 'FREE_ITEM',
  FREE_TRIAL = 'FREE_TRIAL',
  QUOTE = 'QUOTE'
}

export interface Translation {
  readonly language: LanguageKey;
  readonly value: Scalars['String'];
}

export interface TranslationSet {
  readonly defaultValue: Scalars['String'];
  readonly translations: ReadonlyArray<Translation>;
}

export interface TrendAggregateResult {
  readonly __typename: 'TrendAggregateResult';
  readonly historicalDataPoints: ReadonlyArray<TrendHistoricalDataPoint>;
  readonly trendSignificance: Maybe<Scalars['Int']>;
  readonly value: TrendValue;
}

export interface TrendHistoricalDataPoint {
  readonly __typename: 'TrendHistoricalDataPoint';
  readonly averageChoiceValueData: Maybe<AverageChoiceValueData>;
  readonly date: GqlDate;
  readonly scalePercent: Scalars['Int'];
  readonly value: Maybe<TrendValue>;
}

export enum TrendValue {
  HIGHER = 'HIGHER',
  LOWER = 'LOWER',
  SIMILAR = 'SIMILAR'
}

export interface UpdateGoalInput {
  readonly goalId: Scalars['ID'];
  readonly publishingEntityId: Scalars['ID'];
  readonly value: Scalars['Float'];
}

export interface UpdatePerformanceDataVisualizationInput {
  readonly state: SavedVisualizationActivationState;
  readonly visualizationId: Scalars['ID'];
}

export interface UpdateRespondentProfileInput {
  readonly avatarUrl: InputMaybe<Scalars['String']>;
  readonly email: InputMaybe<Scalars['String']>;
  readonly firstName: InputMaybe<Scalars['String']>;
  readonly inputtedLat: InputMaybe<Scalars['Float']>;
  readonly inputtedLon: InputMaybe<Scalars['Float']>;
  readonly lastName: InputMaybe<Scalars['String']>;
  readonly outcomeEmailEnabled: InputMaybe<Scalars['Boolean']>;
  readonly outreachEmailEnabled: InputMaybe<Scalars['Boolean']>;
  readonly postAnonymously: InputMaybe<Scalars['Boolean']>;
  readonly requestLocationLastShown: InputMaybe<GqlDateInput>;
  readonly weeklyEmailEnabled: InputMaybe<Scalars['Boolean']>;
  readonly zipCode: InputMaybe<Scalars['String']>;
}

export interface UploadShortUrlInput {
  readonly fullUrl: Scalars['String'];
  readonly shortUrl: Scalars['String'];
}

export interface User {
  readonly __typename: 'User';
  readonly admin: Maybe<Admin>;
  readonly id: Scalars['ID'];
  readonly respondent: Maybe<Respondent>;
}

export interface UserEmailSettings {
  readonly __typename: 'UserEmailSettings';
  readonly id: Scalars['ID'];
  readonly outcomeEmailEnabled: Scalars['Boolean'];
  readonly outreachEmailEnabled: Scalars['Boolean'];
  readonly weeklyEmailEnabled: Scalars['Boolean'];
  readonly weeklyEmailedAt: Maybe<GqlDate>;
}

export interface UserLocation {
  readonly __typename: 'UserLocation';
  readonly coordinate: Maybe<Coordinate>;
  readonly id: Scalars['ID'];
  readonly ip: Maybe<Scalars['String']>;
  readonly zipCode: Maybe<Scalars['String']>;
}

export enum VariableDemographicSegment {
  ALL = 'ALL',
  OA = 'OA'
}

export enum VariableDisplayType {
  DEFAULT = 'DEFAULT',
  OLDER_ADULTS = 'OLDER_ADULTS'
}

export interface VariantFlag {
  readonly __typename: 'VariantFlag';
  readonly flagKey: Scalars['String'];
  readonly match: Scalars['Boolean'];
  readonly reason: FlagEvaluationReason;
  readonly segmentKeys: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly variantKey: Scalars['String'];
}

export interface VisualizationNodeInput {
  readonly ephemeralReferenceId: Scalars['ID'];
  readonly hierarchyId: InputMaybe<Scalars['ID']>;
  readonly label: InputMaybe<Scalars['String']>;
  readonly metaConditionReferenceId: InputMaybe<Scalars['ID']>;
  readonly visualizationId: Scalars['ID'];
}

export enum VisualizationType {
  HISTOGRAM = 'HISTOGRAM',
  LINE = 'LINE',
  MAP = 'MAP',
  PROGRESS = 'PROGRESS'
}

export interface Vote {
  readonly __typename: 'Vote';
  readonly choices: Maybe<ReadonlyArray<RespondentQuestionChoice>>;
  readonly comment: Maybe<Comment>;
  readonly id: Scalars['ID'];
  readonly question: Question;
  readonly upvotedComments: ReadonlyArray<Comment>;
  readonly voteDate: GqlDate;
  readonly voter: Respondent;
}

export interface VoteAcknowledge {
  readonly _dontUse: InputMaybe<Scalars['Boolean']>;
}

export interface VoteFreeText {
  readonly comment: Scalars['String'];
}

export interface VoteInput {
  readonly VOTE_ACKNOWLEDGE: InputMaybe<VoteAcknowledge>;
  readonly VOTE_FREE_TEXT: InputMaybe<VoteFreeText>;
  readonly VOTE_MULTIPLE_CHOICES: InputMaybe<VoteMultipleChoicesInput>;
  readonly VOTE_POINT_ALLOCATION: InputMaybe<VotePointAllocation>;
  readonly type: InputVoteType;
}

export interface VoteMetadataEntry {
  readonly type: Scalars['String'];
  readonly value: Scalars['String'];
}

export interface VoteMultipleChoicesInput {
  readonly choices: ReadonlyArray<McChoice>;
}

export interface VotePointAllocation {
  readonly choices: ReadonlyArray<PointAllocationChoice>;
}

export enum VoteType {
  DOWN = 'DOWN',
  NONE = 'NONE',
  UP = 'UP'
}

export interface VotingChoice {
  readonly __typename: 'VotingChoice';
  readonly choice: QuestionChoice;
  readonly result: Maybe<Scalars['Float']>;
  readonly votesCount: Maybe<Scalars['Float']>;
}

export interface WordCount {
  readonly __typename: 'WordCount';
  readonly text: Scalars['String'];
  readonly value: Scalars['Int'];
}
