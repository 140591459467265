import * as React from 'react';
import { ErrorForbidden } from '../error-forbidden';
import { ErrorNotFound } from '../error-not-found';
import { AdminSidebarContainer } from 'client/admin/shared/containers/sidebar';
import { AdminHeaderContainer } from 'client/admin/shared/containers/header-container';
import { useQueryAdminInfo } from 'client/admin/shared/containers/query-admin';
import { AdminState } from 'client/admin/shared/types';
import { AdminPageLoader } from '../../page-loader';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { Redirect } from 'react-router';

interface Props {
  readonly errorType: ErrorType;
  readonly withLayout?: boolean;
}
export enum ErrorType {
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
}

const typeToComponent = {
  [ErrorType.FORBIDDEN]: ErrorForbidden,
  [ErrorType.NOT_FOUND]: ErrorNotFound,
};

export const AdminError: React.FC<Props> = (p) => {
  const queryAdminResult = useQueryAdminInfo();
  const [sidebarOpen, toggleSidebar] = React.useState(true);
  const ErrorComponent = typeToComponent[p.errorType];

  if (queryAdminResult.data.type === AdminState.LOADING) {
    return <AdminPageLoader />;
  }
  if (queryAdminResult.data.type === AdminState.NOT_LOGGED_IN) {
    return <Redirect to={ClientUrlUtils.admin.login.path()} />;
  }

  if (!p.withLayout) {
    return <ErrorComponent />;
  }
  return (
    <div className="pn-admin-main-page-body d-flex flex-sm-row flex-column">
      <AdminSidebarContainer
        handleSidebarToggle={toggleSidebar}
        showSidebar={sidebarOpen}
      />

      <div className="pn-admin-main-page-body-content d-flex flex-grow-1">
        <div className="pn-admin-main-page-body-inline-left-col">
          <AdminHeaderContainer hideLiveEventWarning />
          <main className="pn-admin-main-page-body-main container-fluid">
            <ErrorComponent />
          </main>
        </div>
      </div>
      <div className="pn-admin-main-page-body-bottom-spacer d-block d-sm-none d-print-none" />
    </div>
  );
};
