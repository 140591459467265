import * as React from 'react';
import './styles.scss';
import { LoaderBars } from 'client/shared/components/loader-bars';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLORS_JUNGLE_HEX,
  COLORS_POLCO_GREEN_HEX,
} from 'client/shared/core/colors';

export interface Props {
  readonly percentComplete?: number; // 0 - 1 (.45 ==> 45%)
}

const baseClass = 'pn-admin-page-loader';
const componentStyles = makeStyles(() => ({
  colorPrimary: {
    background: COLORS_JUNGLE_HEX,
  },
  barColorPrimary: {
    background: COLORS_POLCO_GREEN_HEX,
  },
}));

/**
 * Provides a general page loader for the admin page.
 * @param p A Props object that maybe contain a class name.
 */
export const AdminPageLoader: React.FC<Props> = (p) => {
  return (
    <div className={baseClass}>
      <LoaderBars className={`${baseClass}-bars`} />
      {p.percentComplete !== undefined && (
        <LinearProgress
          className={`${baseClass}-progress mt-3`}
          classes={{
            colorPrimary: componentStyles().colorPrimary,
            barColorPrimary: componentStyles().barColorPrimary,
          }}
          value={Math.round(p.percentComplete * 100)}
          variant="determinate"
        />
      )}
    </div>
  );
};
AdminPageLoader.displayName = 'AdminPageLoader';
