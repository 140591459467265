import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { PublishingEntityShareDestination } from 'client/admin/core';

export namespace ShareTx {
  export function socialType_gqlToClient(
    provider: Gql.SocialConnectionProvider
  ): PublishingEntityShareDestination {
    switch (provider) {
      case Gql.SocialConnectionProvider.FACEBOOK:
        return PublishingEntityShareDestination.FACEBOOK;
      case Gql.SocialConnectionProvider.TWITTER:
        return PublishingEntityShareDestination.TWITTER;
    }
  }

  export function socialType_clientToGql(
    provider: PublishingEntityShareDestination
  ): Gql.SocialConnectionProvider {
    switch (provider) {
      case PublishingEntityShareDestination.FACEBOOK:
        return Gql.SocialConnectionProvider.FACEBOOK;
      case PublishingEntityShareDestination.TWITTER:
        return Gql.SocialConnectionProvider.TWITTER;
    }
  }
}
