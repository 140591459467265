import * as React from 'react';
import { ErrorForbidden as ErrorForbiddenShared } from 'client/shared/components/error-notice';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { ERROR_FORBIDDEN_COPY } from './copy';
import { useRedirect } from 'client/shared/hooks';

export const ErrorForbidden: React.FC = () => {
  const redirect = useRedirect();
  return (
    <ErrorForbiddenShared
      bodyText={ERROR_FORBIDDEN_COPY.body}
      continueButton={{
        title: ERROR_FORBIDDEN_COPY.title,
        action: () => redirect(ClientUrlUtils.admin.base.path(), { push: true }),
      }}
    />
  );
};
