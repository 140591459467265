import {
  AdminBalancingActReportingResult,
  BalancingActReportingCategory,
  BalancingActReportingMetric,
  BalancingActReportingMetricGroups,
  BalancingActReportingSource,
  BalancingActReportingSubcategory,
  BalancingActReportingSubcategoryComment,
  BalancingActReportingSubcategoryQuestionOption,
  BalancingAct,
} from 'client/shared/core/balancing-act-simulation';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { BalancingActSimulationType, QuestionSetStatus } from 'core';

export type GqlAdminSimulation = NonNullable<
  NonNullable<
    Gql.AdminPublishingEntitySimulations['openPublishingEntityById']
  >['simulations']
>[0];

export type GqlAdminSimulationReportingResult = NonNullable<
  NonNullable<
    NonNullable<
      Gql.AdminPublishingEntitySimulationWithResults['openPublishingEntityById']
    >['simulations']
  >[0]['results']
>;

export type GqlReportingMetricGroup = GqlAdminSimulationReportingResult['metric'];

export type GqlReportingMetric = NonNullable<
  | GqlReportingMetricGroup['age']
  | GqlReportingMetricGroup['city']
  | GqlReportingMetricGroup['deviceType']
  | GqlReportingMetricGroup['gender']
  | GqlReportingMetricGroup['userType']
>[0];

export type GqlReportingSource =
  | GqlAdminSimulationReportingResult['referrerPaths'][0]
  | GqlAdminSimulationReportingResult['sources'][0]
  | GqlAdminSimulationReportingResult['socialNetworks'][0];

export type GqlReportingCategory =
  GqlAdminSimulationReportingResult['categories'][0];

export type GqlReportingSubcategory = GqlReportingCategory['subcategories'][0];
export type GqlReportingSubcategoryComment = GqlReportingSubcategory['comments'][0];
export type GqlReportingSubcategoryQuestionOptions =
  GqlReportingSubcategory['questionOptions'][0];

export namespace BalancingActTx {
  export function gqlToClient(sim: GqlAdminSimulation): BalancingAct.Simulation {
    return {
      ...sim,
      type: gqlSimulationTypeToClient(sim.type),
      status: gqlSimulationStatusToClient(sim.status),
      createdAt: new Date(sim.createDate.raw),
      // TODO fake data until GQL server exposes them
      categories: [
        {
          id: 1,
          name: 'Cats',
          type: BalancingAct.CategoryType.EXPENSE,
          subcategories: [
            {
              id: 2,
              name: 'Food',
              amount: 100,
              isLocked: false,
              description: 'Friskies',
            },
            {
              id: 2,
              name: 'Litter',
              amount: 10000,
              isLocked: true,
              description: 'Unscented',
            },
          ],
        },
      ],
    };
  }

  export function gqlSimulationReportToClient(
    sim: GqlAdminSimulationReportingResult
  ): AdminBalancingActReportingResult {
    return {
      ...sim,
      metrics: gqlReportingMetricGroupToClient(sim.metric),
      status: gqlSimulationStatusToClient(sim.status),
      referrerPaths: sim.referrerPaths.map(gqlReportingSourceToClient),
      sources: sim.sources.map(gqlReportingSourceToClient),
      socialNetworks: sim.socialNetworks.map(gqlReportingSourceToClient),
      categories: sim.categories.map(gqlReportingCategoryToClient),
      demographics: [],
    };
  }

  function gqlReportingMetricGroupToClient(
    metricGroup: GqlReportingMetricGroup
  ): BalancingActReportingMetricGroups {
    return {
      age: metricGroup.age?.map(gqlReportingMetricToClient),
      city: metricGroup.city?.map(gqlReportingMetricToClient),
      deviceType: metricGroup.deviceType?.map(gqlReportingMetricToClient),
      gender: metricGroup.gender?.map(gqlReportingMetricToClient),
      userType: metricGroup.userType?.map(gqlReportingMetricToClient),
    };
  }

  function gqlReportingMetricToClient(
    metric: GqlReportingMetric
  ): BalancingActReportingMetric {
    return {
      ...metric,
    };
  }

  function gqlReportingSourceToClient(
    source: GqlReportingSource
  ): BalancingActReportingSource {
    return {
      ...source,
    };
  }

  function gqlReportingCategoryToClient(
    category: GqlReportingCategory
  ): BalancingActReportingCategory {
    return {
      ...category,
      subcategories: category.subcategories.map(gqlReportingSubcategoryToClient),
    };
  }

  function gqlReportingSubcategoryToClient(
    subcategory: GqlReportingSubcategory
  ): BalancingActReportingSubcategory {
    return {
      ...subcategory,
      comments: subcategory.comments.map(gqlReportingSubcategoryCommentToClient),
      questionOptions: subcategory.questionOptions.map(
        gqlReportingSubcategoryQuestionOptionToClient
      ),
    };
  }

  function gqlReportingSubcategoryCommentToClient(
    comment: GqlReportingSubcategoryComment
  ): BalancingActReportingSubcategoryComment {
    return {
      ...comment,
    };
  }
  function gqlReportingSubcategoryQuestionOptionToClient(
    option: GqlReportingSubcategoryQuestionOptions
  ): BalancingActReportingSubcategoryQuestionOption {
    return {
      ...option,
    };
  }
  export function gqlSimulationStatusToClient(
    status: Gql.QuestionSetStatus
  ): QuestionSetStatus {
    switch (status) {
      case Gql.QuestionSetStatus.IN_PROGRESS:
        return QuestionSetStatus.IN_PROGRESS;
      case Gql.QuestionSetStatus.CLOSED:
        return QuestionSetStatus.CLOSED;
      case Gql.QuestionSetStatus.NOT_STARTED:
        return QuestionSetStatus.NOT_STARTED;
      case Gql.QuestionSetStatus.ARCHIVED:
        return QuestionSetStatus.ARCHIVED;
      case Gql.QuestionSetStatus.SCHEDULED:
        return QuestionSetStatus.SCHEDULED;
      case Gql.QuestionSetStatus.HISTORIC_RECORD:
        return QuestionSetStatus.HISTORIC_RECORD;
      case Gql.QuestionSetStatus.SOFT_DELETED:
        return QuestionSetStatus.SOFT_DELETED;
    }
  }

  export function gqlSimulationTypeToClient(
    type: Gql.BalancingActSimulationType
  ): BalancingActSimulationType {
    switch (type) {
      case Gql.BalancingActSimulationType.BUDGET:
        return BalancingActSimulationType.BUDGET;
      case Gql.BalancingActSimulationType.HOUSING:
        return BalancingActSimulationType.HOUSING;
      case Gql.BalancingActSimulationType.CLIMATE:
        return BalancingActSimulationType.CLIMATE;
      case Gql.BalancingActSimulationType.NON_NUMERICAL:
        return BalancingActSimulationType.NON_NUMERICAL;
      case Gql.BalancingActSimulationType.POINTS:
        return BalancingActSimulationType.POINTS;
      case Gql.BalancingActSimulationType.PROJECT_MANAGEMENT:
        return BalancingActSimulationType.PROJECT_MANAGEMENT;
      case Gql.BalancingActSimulationType.RECEIPT:
        return BalancingActSimulationType.RECEIPT;
    }
  }

  export function gqlCategoryTypeToClient(
    type: Gql.SimulationCategoryType
  ): BalancingAct.CategoryType {
    switch (type) {
      case Gql.SimulationCategoryType.EXPENSE:
        return BalancingAct.CategoryType.EXPENSE;
      case Gql.SimulationCategoryType.REVENUE:
        return BalancingAct.CategoryType.REVENUE;
    }
  }
}
