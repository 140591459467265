import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { transformLocationBreakdownToMapData } from 'client/admin/shared/containers/maps/common';
import { ChartDataProps } from 'client/admin/core/common-stats';
import { Case, ExtractGql, MIN_ZOOM, unionOfEnum } from 'core';
import { MapData, MapStatsStatus } from 'client/admin/shared/types';
import { MeterData } from 'client/shared/components/data-viz-d3/base/meter';
import { DemographicDataSource } from 'client/shared/components/data-viz-d3/base/core';
import _ from 'lodash';
import { TimeBreakdown } from 'client/shared/components/data-viz-d3/line-chart';
import moment from 'moment';

// This type represents all the StatBreakdown types. They are all the same, so just using gender rather than `|`ing them all together
type GqlSurvey = ExtractGql<
  NonNullable<Gql.AdminContentSetRespondentsStats['openContentSetById']>,
  'Survey'
>;
type Breakdown = GqlSurvey['genderBreakdownThirdParty'];
type LocationBreakdown = NonNullable<
  ExtractGql<
    NonNullable<Gql.AdminContentSetRespondentsStatsLocation['openContentSetById']>,
    'Survey'
  >['locationBreakdown']
>;
type StatValueDelta = NonNullable<GqlSurvey['verifiedResponses']>;
type TimeBreakdownGql = NonNullable<GqlSurvey['responsesOverTime']>;

interface BreakdownStat {
  readonly unknownTotal: number;
  readonly values: readonly {
    readonly label: string;
    readonly total: number;
  }[];
}

export namespace StatsTx {
  export enum ChartDataTransform {
    DATA = 'DATA',
    NO_DATA = 'NO_DATA',
    FEATURE_SETTING_MISSING = 'FEATURE_SETTING_MISSING',
  }

  export type BreakdownChartDataTransformResult =
    | Case<ChartDataTransform.DATA, ChartDataProps>
    | Case<ChartDataTransform.NO_DATA | ChartDataTransform.FEATURE_SETTING_MISSING>;
  const BreakdownChartDataTransformResult = unionOfEnum(ChartDataTransform, {
    ...ChartDataTransform,
  }).andType<BreakdownChartDataTransformResult>();

  export type MeterChartDataTransformResult =
    | Case<ChartDataTransform.DATA, MeterData>
    | Case<ChartDataTransform.NO_DATA | ChartDataTransform.FEATURE_SETTING_MISSING>;
  const MeterChartDataTransformResult = unionOfEnum(ChartDataTransform, {
    ...ChartDataTransform,
  }).andType<MeterChartDataTransformResult>();

  export function gqlLocationBreakdownToMapData(
    breakdown: LocationBreakdown
  ): MapData | null {
    switch (breakdown.__typename) {
      case 'EmptyBreakdown':
      case 'ProtectedStatBreakdown':
        return null;
      case 'StatBreakdown':
      case 'StatValueDelta':
        // Throwing here as this is an implementation error.
        throw new Error('Unexpected data type for location breakdown');
      case 'FeatureSettingMissingBreakdown':
        return {
          mapStatsStatus: MapStatsStatus.NOT_ENABLED,
          questionMapStats: {
            hits: 0,
            buckets: [],
            center: {
              size: MIN_ZOOM,
              coordinate: {
                lat: 0,
                lng: 0,
              },
            },
          },
        };
      case 'StatBreakdownLocation':
        return transformLocationBreakdownToMapData(breakdown);
    }
  }

  export function gqlBreakdownToChartData(
    breakdown: Breakdown
  ): BreakdownChartDataTransformResult {
    if (!breakdown) {
      return BreakdownChartDataTransformResult.NO_DATA({});
    }

    switch (breakdown.__typename) {
      case 'StatValueDelta':
      case 'StatBreakdownLocation':
        console.error('Unexpected Breakdown Type');
        return BreakdownChartDataTransformResult.NO_DATA({}); // fail gracefully

      case 'ProtectedStatBreakdown':
      case 'EmptyBreakdown':
        return BreakdownChartDataTransformResult.NO_DATA({});

      case 'FeatureSettingMissingBreakdown':
        return BreakdownChartDataTransformResult.FEATURE_SETTING_MISSING({});

      case 'StatBreakdown':
        return BreakdownChartDataTransformResult.DATA(
          gqlStatBreakdownToChartData(breakdown)
        );
    }
  }

  function gqlStatBreakdownToChartData(stat: BreakdownStat): ChartDataProps {
    return {
      unknownTotal: stat.unknownTotal,
      values: stat.values.map((v) => ({
        label: v.label,
        value: v.total,
      })),
    };
  }

  export function gqlStatValueDeltaToChartData(
    deltaData: StatValueDelta
  ): MeterChartDataTransformResult {
    switch (deltaData.__typename) {
      case 'StatBreakdown':
      case 'StatBreakdownLocation':
        // Throwing here as this is an implementation error.
        throw new Error('Unexpected data type for meter breakdown');

      case 'ProtectedStatBreakdown':
      case 'EmptyBreakdown':
        return MeterChartDataTransformResult.NO_DATA({});

      case 'FeatureSettingMissingBreakdown':
        return MeterChartDataTransformResult.FEATURE_SETTING_MISSING({});

      case 'StatValueDelta':
        return MeterChartDataTransformResult.DATA({
          value: deltaData.currentValue,
          changePeriod: deltaData.changeLabel,
          changeRatio: deltaData.recentChange / deltaData.currentValue,
        });
    }
  }

  export function gqlTimeDataToChartData(timeData: TimeBreakdownGql): TimeBreakdown {
    return {
      interval: timeData.interval,
      data: timeData.data.map((data) => ({
        time: moment(data.time).toDate(),
        value: data.value,
      })),
    };
  }

  /**
   * This function takes the source the user requests (the source param), and the all the data and returns
   * the expected subset of the data.
   *
   * The reason this function is not just 3 lines, is because if the user has not yet selected a source (the
   * source param is null), then we have to find the source that has the most data and use that.
   */
  export function breakdownBySource(
    source: DemographicDataSource | null,
    data: Record<DemographicDataSource, StatsTx.BreakdownChartDataTransformResult>
  ): {
    readonly source: DemographicDataSource;
    readonly data: ChartDataProps | null;
  } {
    switch (source) {
      // If a user explicitly selects an option, just return that data
      case DemographicDataSource.USER_REPORTED:
      case DemographicDataSource.THIRD_PARTY:
        const dataForSource = data[source];
        return {
          data:
            dataForSource.type === StatsTx.ChartDataTransform.DATA
              ? dataForSource
              : null,
          source: source,
        };
      // If the users does not select an option, find the source with the most data to return
      case null:
        const largestSource = (
          Object.entries(data) as readonly (readonly [
            DemographicDataSource,
            StatsTx.BreakdownChartDataTransformResult
          ])[]
        ).reduce(
          (acc, [demoSource, demoData]) => {
            const newTotal =
              demoData.type === StatsTx.ChartDataTransform.DATA
                ? _.sumBy(demoData.values, (value) => value.value)
                : 0;
            return newTotal > acc.count
              ? { source: demoSource, count: newTotal }
              : acc;
          },
          { source: null, count: 0 } as {
            readonly source: DemographicDataSource | null;
            readonly count: number;
          }
        );

        const sourceToUser = largestSource.source
          ? largestSource.source
          : DemographicDataSource.THIRD_PARTY;
        const dataToReturn = data[sourceToUser];
        return {
          data:
            dataToReturn.type === StatsTx.ChartDataTransform.DATA
              ? dataToReturn
              : null,
          source: sourceToUser,
        };
    }
  }
}
