import { SavedQuestion } from 'client/shared/core/question';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { Flavor } from 'core';
import { QuestionSetType } from 'client/shared/core/question-set';

export enum PublishingEntityShareDestination {
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
}
export interface PublishingEntityShareProfiles {
  readonly destination: PublishingEntityShareDestination;
  readonly profiles: readonly string[];
}

export interface ShareData {
  readonly shortURL: string | null;
  readonly longURL: string;
  readonly iframeHTML: string | null;
}
export interface EmailListOption {
  readonly id: ClientEmailListId;
  readonly name: string;
  readonly include: boolean;
}
export type ClientEmailListId = Flavor<string, 'EmailList'>;

const IFRAME_TPL = (url: string, contentName: string) =>
  `<iframe src="${url}" title="${contentName}" style="width:475px; height:500px; box-shadow:0px 0px 1px 0px rgba(0,0,0,0.75)" frameborder="0"></iframe>`;
const CONTENT_POST_IFRAME_TPL = (url: string) =>
  `<iframe src="${url}" style="width:650px; height:850px; border:0;" title="Polco Post"></iframe>`;

export function generateShareDataForContentSet(
  setIdOrSlug: string,
  shortId: string | null,
  pubIdOrSlug: string,
  setType: QuestionSetType
): ShareData {
  const longURL = ClientUrlUtils.respondent.set.url({
    pubSlug: pubIdOrSlug,
    setIdOrSlug,
    setType,
  });

  return {
    shortURL: shortId ? ClientUrlUtils.common.shortUrl(shortId) : null,
    longURL,
    iframeHTML:
      setType === QuestionSetType.CONTENT_POST
        ? CONTENT_POST_IFRAME_TPL(
            ClientUrlUtils.embed.contentPost.url({
              contentPostSlug: setIdOrSlug,
              pubSlug: pubIdOrSlug,
            })
          )
        : null,
  };
}

export function generateShareDataForQuestion(
  question: Pick<SavedQuestion, 'id' | 'shortId' | 'title'>,
  pubIdOrSlug: string,
  setIdOrSlug: string
): ShareData {
  return {
    shortURL: question.shortId
      ? ClientUrlUtils.common.shortUrl(question.shortId)
      : null,
    longURL: ClientUrlUtils.respondent.set.url({
      pubSlug: pubIdOrSlug,
      setIdOrSlug: setIdOrSlug,
      setType: QuestionSetType.SET,
    }),
    iframeHTML: IFRAME_TPL(
      ClientUrlUtils.embed.question.url({
        questionId: question.id,
        setIdOrSlug: setIdOrSlug,
        pubSlug: pubIdOrSlug,
      }),
      question.title
    ),
  };
}

export enum SHARE_ERROR_COPY {
  publishing = 'There was an error publishing your content. Please contact help@polco.us for support.',
  social = 'There was an error sharing on social media. Please contact help@polco.us for support.',
  outreach = 'There was an error sending the email invite. Please contact help@polco.us for support.',
  access = 'There was an error updating your access settings. Please contact help@polco.us for support.',
  accessEmailList = 'You must select at least 1 email list under Manage Access if you would like to limit access by email list',
}

export function getShareMessage(pubName: string): string {
  return `We just posted new questions on Polco!

${pubName} is using Polco to seek input and feedback on issues and topics facing the community. You can sign up for a confidential account on Polco to begin providing input and help build a more engaged community!`;
}
