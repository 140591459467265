import { QuestionSchedule } from 'client/shared/core/question';
import {
  QuestionSchedule_Type,
  QuestionScheduleInput,
} from 'client/shared/graphql-client/graphql-operations.g';
import { QuestionTx } from './question';
import { ApiDate } from 'core';

export namespace ScheduleTx {
  export function gqlToClient(
    c: QuestionTx.GqlQuestionSchedulePart
  ): QuestionSchedule {
    return {
      openDate: ApiDate.fromApi(c.schedule.openDate),
      closeDate: ApiDate.fromApi(c.schedule.closeDate),
    };
  }

  export function clientToGql(
    sched: QuestionSchedule,
    keepInCart?: boolean
  ): QuestionScheduleInput {
    const openDate = ApiDate.toApi(sched.openDate);
    const closeDate = ApiDate.toApi(sched.closeDate);
    return {
      type: keepInCart
        ? QuestionSchedule_Type.MOVE_TO_CART
        : openDate
        ? QuestionSchedule_Type.SCHEDULE_FUTURE
        : QuestionSchedule_Type.PUBLISH_NOW,
      future: openDate
        ? {
            openDate,
            closeDate,
          }
        : null,
      now: {
        closeDate,
      },
    };
  }
}
