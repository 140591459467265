import * as React from 'react';
import './styles.scss';
import {
  TextInput,
  ActionLink,
  ClickableDiv,
  MaterialIcon,
  Btn,
  ButtonTypes,
  MaterialIconName,
} from 'client/shared/components/base';
import { Popover } from 'client/shared/components/base/popover';
import { AccordionSummary, AccordionDetails, Accordion } from '@material-ui/core';
import { useDebounce } from 'client/shared/helpers/hooks';
import pluralize from 'pluralize';

export interface SelectorPublisher {
  readonly id: string;
  readonly name: string;
  readonly presidingAreaName: string | null;
}

export interface IAccountSelectorProps {
  readonly activePublishingEntityId: string | null;
  readonly containerClassName?: string;
  readonly publishers: readonly SelectorPublisher[];
  readonly onSelectNewEntity: (id: string) => void;
  readonly onFilterChange?: (newFilter: string) => void;
  readonly onClickLogout?: () => void;
  readonly onOpen?: () => void;
  readonly title?: string;
}

const baseClass = 'pn-publishing-entity';

const getSearchResultText = (
  searchString: string,
  filteredPubs: ReadonlyArray<SelectorPublisher>
) => {
  if (!searchString.trim().length) return '';
  return `${filteredPubs.length} ${pluralize(
    'publisher',
    filteredPubs.length
  )} found`;
};

export const PublishingEntitySelector: React.FC<IAccountSelectorProps> = (p) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // After user selects a new pub dropdown should not be opened.
    setIsOpen(false);
  }, [p.activePublishingEntityId]);

  React.useEffect(() => {
    if (isOpen) {
      p.onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const debouncedOnFilterChange = useDebounce(p.onFilterChange ?? (() => {}), 200);

  return (
    <div ref={containerRef}>
      <Popover
        containerClassName={p.containerClassName}
        containerParent={containerRef.current || undefined}
        content={
          <SelectorBody
            activePubId={p.activePublishingEntityId}
            isOpen={isOpen}
            logout={
              p.onClickLogout &&
              (() => {
                setIsOpen(false);
                p.onClickLogout && p.onClickLogout();
              })
            }
            onFilterChange={p.onFilterChange ? debouncedOnFilterChange : undefined}
            pubSelected={p.onSelectNewEntity}
            publishers={p.publishers}
            title={p.title}
          />
        }
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        onEscape={() => setIsOpen(false)}
        positions={['bottom']}
      >
        <div className={`${baseClass}-selector`}>
          <ActionLink
            action={() => setIsOpen(!isOpen)}
            className={`${baseClass}-selector-button`}
          >
            {p.children}
          </ActionLink>
        </div>
      </Popover>
    </div>
  );
};

const SelectorBody: React.FC<{
  readonly publishers: readonly SelectorPublisher[];
  readonly activePubId: string | null;
  readonly isOpen: boolean;
  readonly onFilterChange?: (newFilter: string) => void;
  readonly pubSelected: (pubId: string) => void;
  readonly logout?: () => void;
  readonly title?: string;
}> = (p) => {
  const [innerFilterValue, setInnerFilterValue] = React.useState('');

  if (!p.isOpen) {
    return null; // Avoid rendering if we dont have to
  }

  const publishingEntities = p.publishers.map((pub) => (
    <li key={pub.id}>
      <ClickableDiv
        action={() => p.pubSelected(pub.id)}
        className={`${baseClass}-selector-popup-option d-flex flex-column align-items-start p-3 ${
          p.activePubId === pub.id ? 'is-selected' : ''
        }`}
      >
        <div className="d-flex flex-row">
          <span>{pub.name}</span>
        </div>
        <span className="text-gray-50">{pub.presidingAreaName}</span>
      </ClickableDiv>
    </li>
  ));

  return (
    <div className={`${baseClass}-selector-popup`}>
      <Accordion>
        <AccordionSummary
          aria-controls="city-content"
          className={`${baseClass}-expansion-summary`}
          expandIcon={<MaterialIcon icon={MaterialIconName.ARROW_DROP_DOWN} />}
        >
          <div className={'font-size-sm'}>{p.title ?? 'Switch profile'}</div>
        </AccordionSummary>
        <AccordionDetails className={`${baseClass}-expansion-details`}>
          <ul className={`${baseClass}-selector-popup-ul list-unstyled`}>
            {p.onFilterChange ? (
              <>
                <TextInput
                  inputClassName={'accessible-input rounded'}
                  onChange={(val) => {
                    setInnerFilterValue(val);
                    p.onFilterChange && p.onFilterChange(val);
                  }}
                  placeholder="Search"
                  value={innerFilterValue}
                />
                <div
                  aria-live="assertive"
                  className="text-gray-50 mb-2 font-size-sm"
                >
                  {getSearchResultText(innerFilterValue, p.publishers)}
                </div>
              </>
            ) : null}
            {publishingEntities}
          </ul>
        </AccordionDetails>
      </Accordion>
      {p.logout ? (
        <div className="w-100 p-2">
          <Btn action={p.logout} className="w-100" type={ButtonTypes.SEAMLESS}>
            Logout
          </Btn>
        </div>
      ) : null}
    </div>
  );
};
