export function formatPhoneNumber(phoneNumber: string): string {
  // Remove all non-digit characters
  const digits = phoneNumber.replace(/\D/g, '');

  // Format the number as (123) 456-7890
  return digits.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/, (_match, p1, p2, p3) => {
    let result = '';
    if (p1) result += `(${p1}`;
    if (p1 && p1.length === 3) result += `) `;
    if (p2) result += p2;
    if (p2 && p2.length === 3 && p3) result += `-`;
    if (p3) result += p3;
    return result;
  });
}
