import * as React from 'react';
import './styles.scss';
import { Label } from '../label';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { handlePressEnter } from 'client/shared/core/helpers';
import { swallowExpression } from 'core';

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  PHONE = 'tel',
}

export interface InputProps {
  readonly id?: string;
  readonly feedbackId?: string;
  readonly label?: string;
  readonly icon?: MaterialIconName;
  readonly disabled?: boolean;
  readonly autoComplete?: string;
  readonly error?: string;
  readonly showErrorText?: boolean;
  readonly placeholder?: string;
  readonly inputType?: InputType;
  readonly onEnter?: () => void;
  readonly onBlur?: React.EventHandler<any>;
  readonly onFocus?: React.EventHandler<any>;
  readonly readOnly?: boolean;
  readonly as?: 'input' | 'textarea';
  readonly ariaLabel?: string;
  readonly ariaInvalid?: boolean;
  readonly numericInput?: boolean;
  readonly pattern?: string;
}

export interface TextInputProps extends InputProps {
  readonly applyAccessibilityStyles?: boolean;
  readonly value: string;
  readonly onChange: (val: string) => void;
  readonly onPaste?: (e: React.ClipboardEvent) => void;
  readonly onMount?: (e: HTMLInputElement) => void;
  readonly className?: string;
  readonly labelClassName?: string;
  readonly inputClassName?: string;
  readonly identifier?: string;
  readonly identifierTrailing?: boolean;
  readonly maxLength?: number;
  readonly refProp?: React.Ref<HTMLInputElement> | null;
  readonly required?: boolean;
  readonly rounded?: boolean;
  readonly optional?: boolean;
  readonly horizontal?: boolean;
  readonly radioInput?: boolean;
  readonly hideIdentifierBackground?: boolean;
}

const baseClass = 'pn-text-input';

export const InputIdentifier: React.FC<{
  readonly value: string;
  readonly hideBackground?: boolean;
  readonly trailing?: boolean;
}> = ({ value, hideBackground, trailing }) => {
  return (
    <div
      className={`pn-input-identifier ${hideBackground ? 'hide-background' : ''} ${trailing ? 'trailing' : ''}`}
    >
      {value}
    </div>
  );
};

export const TextInput: React.FC<TextInputProps> = (p) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    p.onChange(evt.target.value);
  };

  React.useEffect(() => {
    const elRef = ref.current;
    if (typeof p.refProp === 'function') {
      p.refProp(elRef);
    }
    if (!elRef) {
      return;
    }

    p.onMount && p.onMount(elRef);

    const focusHandler = (evt: HTMLElementEventMap['focus']) => {
      setFocused(true);
      ensureSyntheticEvent(evt);
      p.onFocus && p.onFocus(evt);
    };
    elRef.addEventListener('focus', focusHandler);

    const blurHandler = (evt: HTMLElementEventMap['blur']) => {
      setFocused(false);
      ensureSyntheticEvent(evt);
      p.onBlur && p.onBlur(evt);
    };
    elRef.addEventListener('blur', blurHandler);

    return () => {
      if (!elRef) {
        return;
      }

      elRef.removeEventListener('focus', focusHandler);
      elRef.removeEventListener('blur', blurHandler);
    };
  }, [p]);

  const [isFocused, setFocused] = React.useState(false);

  return (
    <Form.Group
      className={classNames(
        baseClass,
        p.className,
        p.horizontal ? 'd-flex align-items-baseline' : '',
        {
          'has-identifier': p.identifier,
          'has-icon': p.icon,
          'is-error': p.error,
          'is-focused': isFocused,
        }
      )}
      id={p.id}
      onKeyPress={handlePressEnter(() => {
        swallowExpression(p.onEnter?.());
      })}
    >
      {p.label && (
        <Label
          className={`${p.labelClassName} ${p.horizontal ? 'pr-3' : ''}`}
          htmlFor={p.id ? `${p.id}-form-control` : p.label ?? p.ariaLabel}
          required={!!p.required}
          text={p.label}
        />
      )}
      {p.optional && (
        <span
          className={`${baseClass} is-optional font-size-xs`}
        >{` (optional)`}</span>
      )}
      <InputGroup>
        {p.identifier && (
          <InputIdentifier
            hideBackground={p.hideIdentifierBackground}
            trailing={p.identifierTrailing}
            value={p.identifier}
          />
        )}
        <Form.Control
          aria-errormessage={p.ariaInvalid ? p.feedbackId : undefined}
          aria-invalid={p.ariaInvalid}
          aria-label={p.ariaLabel || p.label}
          as={p.as}
          autoComplete={p.autoComplete}
          className={classNames(
            p.rounded && 'rounded',
            p.radioInput && 'mod-radio-input',
            `${baseClass}-el`,
            p.inputClassName,
            !!p.applyAccessibilityStyles && 'accessible-input rounded',
            p.error && 'has-error'
          )}
          disabled={p.disabled}
          id={p.id ? `${p.id}-form-control` : p.label ?? p.ariaLabel}
          inputMode={p.numericInput ? 'numeric' : undefined}
          isInvalid={p.ariaInvalid || !!p.error}
          maxLength={p.maxLength}
          onChange={onChange}
          onPaste={p.onPaste}
          placeholder={p.placeholder}
          readOnly={p.readOnly}
          ref={ref as any}
          required={!!p.required}
          type={p.inputType || InputType.TEXT}
          value={p.value}
        />
        {p.showErrorText && (
          <Form.Control.Feedback id={p.feedbackId} type="invalid">
            {p.error}
          </Form.Control.Feedback>
        )}
        {p.icon && <MaterialIcon className={`${baseClass}-icon`} icon={p.icon} />}
      </InputGroup>
    </Form.Group>
  );
};

/*
 * NOTE: this function provides just enough to fool DayPickerInput into
 * not blowing up. DayPickerInput (rightly) expects a SyntheticEvent,
 * but we don't provide one.
 */
function ensureSyntheticEvent(evt: any) {
  if (evt && !evt.persist) {
    evt.persist = () => {};
  }
}
