import {
  MainContainer,
  ChatContainer,
  MessageList,
  TypingIndicator,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import {
  AiAssistantMessage,
  AiAssistantMessageRole,
  AiAssistantMessageType,
} from 'client/admin/core';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import React from 'react';
import {
  AiAssistantMessageComponent,
  AiAssistantAvatarComponent,
  AvatarOverride,
} from '../../components/message';
import {
  CustomMessageInput,
  MessageInputWithCustomBtn,
} from '../../components/message-input';
import { LoadedAdminData } from 'client/admin/shared/types';
import { MessageInputDisableReason } from '../ai-assistant-container';
import { SUPPORT_EMAIL } from 'core';
import { AiAssistantId } from '@polco-us/types';
import { useFlagEnabled, KnownFlag } from 'client/shared/contexts/flags-context';
import { MAX_FILE_SIZE_MB } from '../../components/embedded-file-upload';

const baseClass = 'pn-ai-assistant';

export const COPY = {
  assistantDetails: {
    [AiAssistantId.POLLY]: 'Polly, your dedicated Polco Data Analyst',
    [AiAssistantId.GRANT_WRITER]:
      'Grace, your dedicated Polco Grant Writing Assistant',
  },
  askAnotherQuestion: 'Ask another question',
  queryLimit: "You've reached the limit of questions for this month.",
  thinking: {
    [AiAssistantId.POLLY]: 'Polly is thinking',
    [AiAssistantId.GRANT_WRITER]: 'Grace is thinking',
  },
  cancelProcessing: 'Cancel Request',
  missingFips: (assistantId: AiAssistantId) =>
    `We\'re unable to load ${COPY.assistantDetails[assistantId]}. Contact our support staff at ${SUPPORT_EMAIL} if the issue persists, we'll be happy to resolve it!`,
  fileUploadMessage: `Upload your data file to this conversation to utilize your document for more tailored responses. \n\n- ${MAX_FILE_SIZE_MB}MB max, per file\n\n- file types accepted: TXT, PDF, JSON, DOC, DOCX, PPTX \n\n<div></div>\n\n<EmbeddedContent type={file} data={""}/>`,
};

interface Props {
  readonly assistantId: AiAssistantId;
  readonly aiAssistantMessages: readonly AiAssistantMessage[];
  readonly adminData: LoadedAdminData;
  readonly isLoading: boolean;
  readonly isLoadingMore: boolean;
  readonly requestInProcess: boolean;
  readonly events: {
    readonly onSend: (textContent: string, clearMessageContent: () => void) => void;
    readonly onLoadMore: () => void;
    readonly onCancelProcessing: () => void;
    readonly refreshSessionId: () => void;
    readonly onOpenFullScreen?: () => void;
    readonly onCloseDrawer?: () => void;
  };
  readonly showCancelQuery: boolean;
  readonly isMessageInputDisabled: boolean;
  readonly remainingQueryCount: number;
  readonly showQueryLimit: boolean;
  readonly messageInputDisabledReason?: MessageInputDisableReason;
  readonly loadingText?: string;
  readonly className?: string;
  readonly queryLimit: number;
  readonly refreshAndRefetch: () => Promise<void>;
  readonly messageInputEvents: {
    readonly setMessageContent: React.Dispatch<React.SetStateAction<string>>;
    readonly onSend: (onSendComplete?: () => void) => void;
  };
  readonly messageContent: string;
  readonly sessionId: string;
}

export const MainChatContainer: React.FC<Props> = (p) => {
  const [showFileUpload, setShowFileUpload] = React.useState(false);
  const toggleFileUpload = () => setShowFileUpload(!showFileUpload);
  const fileUploadEnabled = useFlagEnabled(KnownFlag.AI_FILE_UPLOAD_ENABLED);

  return (
    <MainContainer
      className={`${baseClass} bg-grayscale-2 ${p.className ?? ''}`}
      responsive={false}
    >
      <ChatContainer className={`${baseClass}-container bg-grayscale-2 mx-4`}>
        <MessageList
          className={`${baseClass}-message-list px-1 py-3 bg-grayscale-2`}
          disableOnYReachWhenNoScroll={true}
          loading={p.isLoading}
          loadingMore={p.isLoadingMore}
          loadingMorePosition={'top'}
          onYReachStart={() => {
            p.events.onLoadMore();
          }}
          typingIndicator={
            p.requestInProcess ? (
              <TypingIndicator
                className={`${baseClass}-typing-indicator mx-1 align-items-center justify-content-center bg-transparent text-color-gray-60 text-center p-3`}
                content={<div>{p.loadingText ?? COPY.thinking[p.assistantId]}</div>}
              />
            ) : null
          }
        >
          <MessageList.Content>
            {p.aiAssistantMessages.map((message, idx) =>
              AiAssistantMessageComponent({
                key: `${idx}`,
                adminData: p.adminData,
                aiAssistantMessage: message,
                assistantId: p.assistantId,
                className: `my-3`,
                sessionId: p.sessionId,
              })
            )}
            {showFileUpload &&
              fileUploadEnabled &&
              AiAssistantMessageComponent({
                avatarOverride: AvatarOverride.UPLOAD,
                key: `${p.aiAssistantMessages.length}`,
                adminData: p.adminData,
                aiAssistantMessage: {
                  type: AiAssistantMessageType.SINGLE,
                  role: AiAssistantMessageRole.ASSISTANT,
                  content: COPY.fileUploadMessage,
                  timestamp: new Date(),
                },
                assistantId: p.assistantId,
                className: `my-3`,
                sessionId: p.sessionId,
              })}
            {p.requestInProcess && p.showCancelQuery ? (
              <div
                className={`${baseClass}-cancel-request-w-avatar-spacer d-flex pb-4 mb-4 justify-content-end align-items-center`}
              >
                <Btn
                  action={p.events.onCancelProcessing}
                  className={`${baseClass}-cancel-request-btn text-color-jungle text-center px-3 py-2`}
                  type={ButtonTypes.SECONDARY}
                >
                  {COPY.cancelProcessing}
                </Btn>
                <div
                  className={`${baseClass}-cancel-request-avatar-spacer invisible ml-3`}
                >
                  {AiAssistantAvatarComponent({
                    adminData: p.adminData,
                    assistantId: p.assistantId,
                    role: AiAssistantMessageRole.USER,
                  })}
                </div>
              </div>
            ) : (
              <div className="pb-4 mb-4" />
            )}
          </MessageList.Content>
        </MessageList>
        <CustomMessageInput as={MessageInput}>
          {p.messageInputDisabledReason ? (
            getMessageInputDisabledButton({
              assistantId: p.assistantId,
              reason: p.messageInputDisabledReason,
              queryLimit: p.queryLimit,
              actions: {
                refresh: p.refreshAndRefetch,
              },
            })
          ) : (
            <MessageInputWithCustomBtn
              events={{
                ...p.messageInputEvents,
                onSend: () => {
                  p.messageInputEvents.onSend(() => setShowFileUpload(false));
                },
                onClickFileUpload: toggleFileUpload,
              }}
              isMessageInputDisabled={p.isLoading || p.isMessageInputDisabled}
              messageContent={p.messageContent}
            />
          )}
        </CustomMessageInput>
      </ChatContainer>
    </MainContainer>
  );
};
MainChatContainer.displayName = 'MainChatContainer';

function getMessageInputDisabledButton(args: {
  readonly assistantId: AiAssistantId;
  readonly reason: MessageInputDisableReason;
  readonly queryLimit: number;
  readonly actions: {
    readonly refresh: () => void;
  };
}) {
  const { reason, actions } = args;
  const defaultStyling = 'flex-grow-1 bg-brand-l text-wrap rounded text-center';
  switch (reason) {
    case MessageInputDisableReason.EXPIRED_SESSION: {
      return (
        <Btn
          action={actions.refresh}
          className={`${baseClass}-expired-session-btn bg-gray-60 font-size-sm`}
          customPadding="flex-grow-1 text-wrap"
          type={ButtonTypes.PRIMARY}
        >
          {COPY.askAnotherQuestion}
        </Btn>
      );
    }
    case MessageInputDisableReason.QUERY_LIMIT_REACHED: {
      return (
        <div className={`${baseClass}-query-limit-reached p-3 ${defaultStyling}`}>
          {COPY.queryLimit}
        </div>
      );
    }
  }
}
